import { Button, Card, Expander, Menu, MenuItem, Spinner, Switch, Tag } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import {
    FeatureFlagValuesJson,
    Organization,
    useGetFeatureFlagsByOrganization,
} from "handlers/generated/hive";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaAngleDown, FaCalendarCheck } from "react-icons/fa";
import { createUseStyles } from "react-jss";
import { useMutation } from "react-query";
import { QueryKey } from "resources/apiHandler";
import { updateFeatureFlags, useDefaultFeatureFlags } from "resources/FeatureFlagResource";
import { globalQueryClient } from "resources/globalQueryClient";
import editor from "styles/editor";
import { handleNetworkError } from "utils";

const useStyles = createUseStyles(editor);

interface IOrganizationFeaturesPanelProps {
    organizationData: Organization;
}

export const OrganizationFeaturesPanel: React.FunctionComponent<
    IOrganizationFeaturesPanelProps
> = ({ organizationData }) => {
    const classes = useStyles();
    const [saveError, setSaveError] = useState<string>();

    const { data: defaultFeatureFlags } = useDefaultFeatureFlags();

    const isNew = organizationData.organizationId === -1;
    const { data: featureFlags, isLoading: featureFlagsLoading } = useGetFeatureFlagsByOrganization(
        organizationData.organizationId,
        { query: { enabled: !isNew } }
    );

    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { handleSubmit, formState, setValue, reset, control } = useForm<FeatureFlagValuesJson>({
        defaultValues: featureFlags,
    });

    // update the form values any time we get new organization details
    useEffect(() => {
        reset(featureFlags);
        setSaveError(undefined);
    }, [featureFlags, reset]);

    const { isLoading: isUpdating, mutateAsync: mutationUpdateFeatureFlags } = useMutation(
        updateFeatureFlags,
        {
            onSuccess: () => {
                // eslint-disable-next-line no-void
                void globalQueryClient.invalidateQueries([
                    QueryKey.Featureflags,
                    organizationData.organizationId,
                ]);
            },
        }
    );

    const handleSave = useCallback(
        async (formValues: FeatureFlagValuesJson) => {
            try {
                await mutationUpdateFeatureFlags({
                    organizationId: organizationData.organizationId,
                    flags: formValues,
                });
                setSaveError(undefined);
                reset(formValues);
            } catch (error) {
                setSaveError(handleNetworkError(error));
            }
        },
        [mutationUpdateFeatureFlags, organizationData, reset]
    );

    const resetDefaults = useCallback(() => {
        if (defaultFeatureFlags === undefined) {
            return;
        }

        const defaultFeatureFlagsObject: FeatureFlagValuesJson = {
            featureFlagValues: {},
        };

        defaultFeatureFlags.featureFlags.forEach((flag) => {
            defaultFeatureFlagsObject.featureFlagValues[flag.name] = flag.defaultValue;
        });

        setValue("featureFlagValues", defaultFeatureFlagsObject.featureFlagValues, {
            shouldDirty: true,
        });
        setSaveError(undefined);
    }, [defaultFeatureFlags, setValue]);

    const featureFlagsList = React.useMemo(() => {
        if (featureFlags === undefined) {
            return [];
        }

        return Object.keys(featureFlags.featureFlagValues).sort();
    }, [featureFlags]);

    return (
        <form autoComplete="off" onSubmit={handleSubmit(handleSave)} className={classes.scrollPane}>
            <div>
                <div className={classes.fullWidth} />
                {featureFlagsLoading && <Spinner />}
                <div style={{ display: "flex" }}>
                    <div style={{ flex: "auto" }} />
                    <div>
                        <Popover2
                            content={
                                <Menu>
                                    <MenuItem
                                        text="Reset flags to default"
                                        icon={<FaCalendarCheck />}
                                        onClick={resetDefaults}
                                    />
                                </Menu>
                            }
                            position="bottom-left"
                        >
                            <Button small minimal rightIcon={<FaAngleDown />}>
                                Default Value
                            </Button>
                        </Popover2>
                    </div>
                </div>
                {featureFlagsList.map((flagName) => (
                    <Card style={{ margin: 1, flex: "auto" }}>
                        <div style={{ display: "flex" }}>
                            <div style={{ flex: "auto" }}>
                                <Controller
                                    control={control}
                                    name={`featureFlagValues.${flagName}`}
                                    render={({ ref, value, onChange, onBlur }) => (
                                        <Switch
                                            style={{ textTransform: "capitalize", margin: 0 }}
                                            inputRef={ref}
                                            checked={value as boolean}
                                            onChange={(event) =>
                                                onChange(event.currentTarget.checked)
                                            }
                                            onBlur={onBlur}
                                            large
                                            innerLabel="Off"
                                            innerLabelChecked="On"
                                        >
                                            {flagName.replaceAll("-", " ")}
                                        </Switch>
                                    )}
                                />
                            </div>
                            <div style={{ flex: "auto" }}>
                                <Tag>{flagName}</Tag>
                            </div>
                            <div>
                                {defaultFeatureFlags?.featureFlags.find(
                                    (flag) => flag.name === flagName
                                )?.defaultValue ? (
                                    <Tag intent="primary" minimal round>
                                        On
                                    </Tag>
                                ) : (
                                    <Tag minimal round>
                                        Off
                                    </Tag>
                                )}
                            </div>
                        </div>
                    </Card>
                ))}
            </div>
            <div className={classes.footer}>
                {saveError && <div className={classes.formError}>{saveError}</div>}
                <Expander />
                <Button
                    type="submit"
                    intent="primary"
                    loading={formState.isSubmitting || isUpdating}
                    disabled={featureFlagsLoading || !formState.isDirty || formState.isSubmitting}
                >
                    Update Features
                </Button>
            </div>
        </form>
    );
};
