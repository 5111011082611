import React from "react";
import { formatDate, useTimezone } from "utils";

interface IDateDisplayProps {
    date: Date | null | undefined;

    /** @default users current timezone */
    zone?: string;

    showTime?: boolean;
    showYear?: boolean;
}

export const DateDisplay: React.FunctionComponent<IDateDisplayProps> = ({
    date,
    zone,
    showTime = true,
    showYear = true,
}) => {
    const { zone: usersTimezone } = useTimezone();

    return <>{formatDate(date, zone ?? usersTimezone, showTime, showYear)}</>;
};
