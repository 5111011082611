import { Card } from "@blueprintjs/core";
import classNames from "classnames";
import { CurrencyDisplay } from "components/CurrencyDisplay";
import { DateDisplay } from "components/DateDisplay";
import { StatusTag } from "components/Invoice/StatusTag";
import { ReducedInvoiceWithLineItems } from "handlers/generated/billing";
import internalSitemap from "internalSitemap";
import { TabId } from "pages/InternalOrganizations/OrganizationEditor";
import React from "react";
import { FaFileInvoice, FaRegCalendarAlt } from "react-icons/fa";
import { createUseStyles } from "react-jss";
import { Link, useRouteMatch } from "react-router-dom";
import sitemap from "sitemap";
import { common } from "styles/common";
import variables from "styles/variables";

interface ICardInvoiceProps {
    invoice: ReducedInvoiceWithLineItems;
}

const useStyles = createUseStyles({
    ...common,
    flex: {
        display: "flex",
        fontSize: 16,
        lineHeight: "24px",
    },
    col: {
        flex: "1",
        alignSelf: "self-end",
    },
    colWide: {
        flex: "2",
    },
    textCenter: {
        textAlign: "center",
    },
    amountDue: {
        textAlign: "right",
        paddingRight: variables.gridSize,
        paddingLeft: variables.gridSize,
    },
    textRight: {
        textAlign: "right",
    },
    textSpacing: {
        paddingLeft: 8,
    },
});

export const CardInvoice: React.FunctionComponent<ICardInvoiceProps> = ({ invoice }) => {
    const classes = useStyles();

    // finalized invoices will have a "number" (basically invoice title)
    // future invoices will have a description set. That should be used as the title
    const invoiceTitleLong = invoice.invoiceNumber ?? "Invoice";
    const invoiceTitleShort = invoiceTitleLong.substring(0, 50);
    const invoiceDueDate = invoice.dueDate;

    const isInternal = useRouteMatch(internalSitemap.organizations.pattern)?.isExact;

    const internalInvoicePath = internalSitemap.organizations.generatePath({
        organizationId: invoice.organizationId?.toString() ?? "",
        tab: TabId.payment,
        stripeInvoiceId: invoice.stripeInvoiceId,
    });
    const customerInvoicePath = sitemap.invoice.generatePath({
        organizationId: invoice.organizationId?.toString() ?? "",
        stripeInvoiceId: invoice.stripeInvoiceId ?? "",
    });

    return (
        <Card className={classes.flex}>
            <div className={classes.colWide}>
                <FaFileInvoice color={variables.gray1} size={24} />
                <span className={classes.textSpacing}>
                    {invoice.organizationId !== undefined &&
                    invoice.stripeInvoiceId !== undefined ? (
                        <Link to={isInternal ? internalInvoicePath : customerInvoicePath}>
                            {invoiceTitleShort}
                        </Link>
                    ) : (
                        <>{invoiceTitleShort}</>
                    )}
                </span>
            </div>
            <div className={classes.col}>
                {invoiceDueDate !== null && (
                    <>
                        <FaRegCalendarAlt color={variables.gray1} size={24} />
                        <span className={classes.textSpacing}>
                            <DateDisplay date={invoiceDueDate} zone="UTC" showTime={false} />
                        </span>
                    </>
                )}
            </div>
            <div className={classNames(classes.col, classes.amountDue)}>
                <CurrencyDisplay amount={(invoice.totalAmount ?? 0) / 100} />
            </div>
            <div className={classNames(classes.col, classes.textRight)}>
                <StatusTag status={invoice.status} />
            </div>
        </Card>
    );
};
