import { Device, GetDevicesParams, useGetDevices } from "handlers/generated/hive";
import { PacketType } from "pages/Commands/types";
import { SWARM_DEVICE_KIT, SWARM_DEVICE_KIT_VALUES } from "pages/Reports/Reports.types";
import { useMemo } from "react";
import { IDeviceBillingStatus, useDeviceBillingList } from "resources/BillingDevicesResource";
import { IDevice, useDeviceList } from "resources/DeviceResource";
import { DeviceType } from "resources/DeviceResource.types";

export function formatDeviceId(deviceId: number): string {
    let hexId = Math.abs(deviceId).toString(16);

    // Zero-pad to 5 hex digits
    if (hexId.length < 5) {
        hexId = `000000${hexId}`.substr(-5);
    }
    return `${deviceId < 0 ? "-" : ""}0x${hexId}`;
}

export function formatDeviceType(deviceType: DeviceType): string {
    switch (deviceType) {
        case DeviceType.ANY:
            return "A";
        case DeviceType.FIELD:
            return "F";
        case DeviceType.GROUND_STATION:
            return "G";
        case DeviceType.SATELLITE:
            return "S";
        case DeviceType.STRATO:
            return "B";
        case DeviceType.HIVE:
            return "H";
        case DeviceType.UNKNOWN_1:
            return "U1";
        case DeviceType.UNKNOWN_2:
            return "U2";
        default:
            return "U";
    }
}

export function formatDeviceTypeLong(deviceType: DeviceType | undefined): string {
    switch (deviceType) {
        case DeviceType.ANY:
            return "Any";
        case DeviceType.FIELD:
            return "Field Device";
        case DeviceType.GROUND_STATION:
            return "Ground Station";
        case DeviceType.SATELLITE:
            return "Satellite";
        case DeviceType.STRATO:
            return "StratoBEE";
        case DeviceType.HIVE:
            return "Hive";
        case DeviceType.UNKNOWN_1:
            return "Unknown1";
        case DeviceType.UNKNOWN_2:
            return "Unknown2";
        default:
            return "Unknown";
    }
}

/**
 * @deprecated use deviceUniqueId(device) or deviceDisplayName(device) instead
 * @see deviceUniqueId
 * @see deviceDisplayName
 */
export function formatDeviceIdAndType(deviceId: number, deviceType: DeviceType): string {
    return `${formatDeviceType(deviceType)}-${formatDeviceId(deviceId)}`;
}

interface IHasDeviceIdAndType {
    deviceId: number;
    deviceType: DeviceType;
}

export type WithDevice<T> = T & {
    device: Device | undefined;
};

/**
 * returns a list with device (if any exist)
 * @example useWithDevice(messages)
 */
export function useWithDevice<ListType extends IHasDeviceIdAndType>(
    list: ListType[],
    deviceFilter?: GetDevicesParams
): WithDevice<ListType>[] {
    const deviceList = useGetDevices(deviceFilter);

    return useMemo(() => {
        const devicesMap = new Map<string, Device>();
        deviceList.data?.forEach((device) => {
            devicesMap.set(formatDeviceIdAndType(device.deviceId, device.deviceType), device);
        });

        return list.map((item) => ({
            ...item,
            device: devicesMap.get(formatDeviceIdAndType(item.deviceId, item.deviceType)),
        }));
    }, [deviceList.data, list]);
}

export function formatPacketType(packetType: PacketType): string {
    switch (packetType) {
        case PacketType.ACK:
            return "Ack";
        case PacketType.DATA:
            return "Data";
        case PacketType.TELEMETRY:
            return "Telemetry";
        case PacketType.COMMAND:
            return "Command";
        default:
            return "Unknown";
    }
}

export interface IDeviceWithPaymentStatus extends IDevice {
    billingStatus?: IDeviceBillingStatus;
}

interface IDeviceBillingList {
    devicesWithPaymentStatus: IDeviceWithPaymentStatus[] | undefined;
    isLoading: boolean;
}

export function useDevicesWithBilling(organizationId: number | undefined): IDeviceBillingList {
    const { data: devices, isLoading: devicesLoading } = useDeviceList({
        organizationId: organizationId,
    });

    const {
        data: deviceBillingList,
        isLoading: deviceBillingListLoading,
        error: deviceBillingListError,
    } = useDeviceBillingList({
        organizationId: organizationId ?? null,
        deviceIds: [],
    });

    const isLoading = devicesLoading || deviceBillingListLoading;

    if (
        deviceBillingList === undefined ||
        deviceBillingListLoading ||
        deviceBillingListError ||
        devices === undefined
    ) {
        return { devicesWithPaymentStatus: devices, isLoading };
    }
    const paymentStatusMap = new Map<string, IDeviceBillingStatus>();
    deviceBillingList.deviceBillingStatuses.forEach((item) => {
        paymentStatusMap.set(formatDeviceIdAndType(item.deviceId, DeviceType.FIELD), item);
    });

    return {
        devicesWithPaymentStatus: devices.map((device) => {
            return { ...device, billingStatus: paymentStatusMap.get(device.displayIdAndType) };
        }),
        isLoading,
    };
}

/**
 * note: defaults to true while loading
 */
export function hasAssetTrackers(devices: Device[] | undefined): boolean {
    if (devices === undefined) {
        return true;
    }

    return devices.some(
        (device) => device.metadata[SWARM_DEVICE_KIT] === SWARM_DEVICE_KIT_VALUES.asset_tracker
    );
}
