import { generatePath } from "react-router-dom";

type StripeCustomerParams = {
    customerId: string;
};

export const externalSitemap = {
    stripeCustomer: {
        pattern: "https://dashboard.stripe.com/customers/:customerId",
        generatePath: (params: StripeCustomerParams): string =>
            generatePath(externalSitemap.stripeCustomer.pattern, params),
    },
};
