/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Hive Service REST API
 * OpenAPI spec version: v1
 */
import { useQuery, useMutation } from "react-query";
import type {
    UseQueryOptions,
    UseMutationOptions,
    QueryFunction,
    MutationFunction,
    UseQueryResult,
    QueryKey,
} from "react-query";
import type { FeatureFlagValuesJson, ApiError, StatusJson, FeatureFlagListJson } from "../models";
import { apiHandler } from "../../../../resources/apiHandler";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
    config: any,
    args: infer P
) => any
    ? P
    : never;

/**
 * Get feature flags for an organization.
 * @summary GET feature flags for an organization
 */
export const getFeatureFlagsByOrganization = (
    organizationId: number,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<FeatureFlagValuesJson>(
        { url: `/hive/api/v1/featureflags/${organizationId}`, method: "get", signal },
        options
    );
};

export const getGetFeatureFlagsByOrganizationQueryKey = (organizationId: number) =>
    [`/hive/api/v1/featureflags/${organizationId}`] as const;

export const getGetFeatureFlagsByOrganizationQueryOptions = <
    TData = Awaited<ReturnType<typeof getFeatureFlagsByOrganization>>,
    TError = ApiError
>(
    organizationId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof getFeatureFlagsByOrganization>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getFeatureFlagsByOrganization>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetFeatureFlagsByOrganizationQueryKey(organizationId);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFeatureFlagsByOrganization>>> = ({
        signal,
    }) => getFeatureFlagsByOrganization(organizationId, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!organizationId, ...queryOptions };
};

export type GetFeatureFlagsByOrganizationQueryResult = NonNullable<
    Awaited<ReturnType<typeof getFeatureFlagsByOrganization>>
>;
export type GetFeatureFlagsByOrganizationQueryError = ApiError;

/**
 * @summary GET feature flags for an organization
 */
export const useGetFeatureFlagsByOrganization = <
    TData = Awaited<ReturnType<typeof getFeatureFlagsByOrganization>>,
    TError = ApiError
>(
    organizationId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof getFeatureFlagsByOrganization>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetFeatureFlagsByOrganizationQueryOptions(organizationId, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Update feature flags for an organization. Only updates passed-in values.
 * @summary PATCH feature flags for an organization
 */
export const updateFeatureFlags = (
    organizationId: number,
    featureFlagValuesJson: FeatureFlagValuesJson,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<StatusJson>(
        {
            url: `/hive/api/v1/featureflags/${organizationId}`,
            method: "patch",
            headers: { "Content-Type": "application/json" },
            data: featureFlagValuesJson,
        },
        options
    );
};

export const getUpdateFeatureFlagsMutationOptions = <
    TError = ApiError,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof updateFeatureFlags>>,
        TError,
        { organizationId: number; data: FeatureFlagValuesJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof updateFeatureFlags>>,
    TError,
    { organizationId: number; data: FeatureFlagValuesJson },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof updateFeatureFlags>>,
        { organizationId: number; data: FeatureFlagValuesJson }
    > = (props) => {
        const { organizationId, data } = props ?? {};

        return updateFeatureFlags(organizationId, data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type UpdateFeatureFlagsMutationResult = NonNullable<
    Awaited<ReturnType<typeof updateFeatureFlags>>
>;
export type UpdateFeatureFlagsMutationBody = FeatureFlagValuesJson;
export type UpdateFeatureFlagsMutationError = ApiError;

/**
 * @summary PATCH feature flags for an organization
 */
export const useUpdateFeatureFlags = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof updateFeatureFlags>>,
        TError,
        { organizationId: number; data: FeatureFlagValuesJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getUpdateFeatureFlagsMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Get default feature flags registered in Hive.
 * @summary GET default feature flags
 */
export const getDefaultFeatureFlags = (
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<FeatureFlagListJson>(
        { url: `/hive/api/v1/featureflags`, method: "get", signal },
        options
    );
};

export const getGetDefaultFeatureFlagsQueryKey = () => [`/hive/api/v1/featureflags`] as const;

export const getGetDefaultFeatureFlagsQueryOptions = <
    TData = Awaited<ReturnType<typeof getDefaultFeatureFlags>>,
    TError = ApiError
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getDefaultFeatureFlags>>, TError, TData>;
    request?: SecondParameter<typeof apiHandler>;
}): UseQueryOptions<Awaited<ReturnType<typeof getDefaultFeatureFlags>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetDefaultFeatureFlagsQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDefaultFeatureFlags>>> = ({
        signal,
    }) => getDefaultFeatureFlags(requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetDefaultFeatureFlagsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getDefaultFeatureFlags>>
>;
export type GetDefaultFeatureFlagsQueryError = ApiError;

/**
 * @summary GET default feature flags
 */
export const useGetDefaultFeatureFlags = <
    TData = Awaited<ReturnType<typeof getDefaultFeatureFlags>>,
    TError = ApiError
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getDefaultFeatureFlags>>, TError, TData>;
    request?: SecondParameter<typeof apiHandler>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetDefaultFeatureFlagsQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};
