import { Card } from "@blueprintjs/core";
import classNames from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";
import { common } from "styles/common";

const useStyles = createUseStyles({
    ...common,
    flex: {
        display: "flex",
        fontSize: 16,
        lineHeight: "24px",
    },
    col: {
        flex: "1",
        alignSelf: "center",
    },
    colWide: {
        flex: "2",
        paddingLeft: "5px",
        paddingRight: "5px",
    },
    textCenter: {
        textAlign: "center",
    },
    textLeft: {
        textAlign: "left",
    },
    heading: {
        background: "gainsboro",
        fontWeight: "bold",
    },
});

export const CardLineItemHeader: React.FunctionComponent = () => {
    const classes = useStyles();

    return (
        <Card className={classNames(classes.flex, classes.heading)}>
            <div className={classNames(classes.colWide, classes.textLeft)}>
                <span>Plan</span>
            </div>
            <div className={classNames(classes.colWide)}>Device Ids</div>
            <div className={classNames(classes.col, classes.textCenter)}>
                <span>Quantity</span>
            </div>
            <div className={classNames(classes.col, classes.textCenter)}>Price Per Unit</div>
            <div className={classNames(classes.col, classes.textCenter)}>Price</div>
        </Card>
    );
};
