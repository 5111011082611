import {
    Button,
    Classes,
    Expander,
    FormGroup,
    HTMLSelect,
    InputGroup,
    Spinner,
} from "@blueprintjs/core";
import { BillingCustomerCard } from "components/BillingCustomerCard";
import SimpleSelect from "components/SimpleSelect";
import { getGetCustomersQueryKey, useGetCustomers } from "handlers/generated/billing";
import {
    getGetAllOrganizationsQueryKey,
    Organization,
    useCreateOrganization,
    usePatchOrganization,
} from "handlers/generated/hive";
import internalSitemap from "internalSitemap";
import { BILLING_TYPES } from "pages/InternalOrganizations/organization.types";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaExternalLinkAlt } from "react-icons/fa";
import { createUseStyles } from "react-jss";
import { Link, useHistory } from "react-router-dom";
import { useUIDSeed } from "react-uid";
import { globalQueryClient } from "resources/globalQueryClient";
import editor from "styles/editor";
import { EMAIL_REGEX, handleNetworkError } from "utils";

const useStyles = createUseStyles(editor);

type SelectOption = { value: string; label: string };

interface IOrganizationDetailsPanelProps {
    organizationData: Organization | undefined;
}

export const OrganizationDetailsPanel: React.FunctionComponent<IOrganizationDetailsPanelProps> = ({
    organizationData,
}) => {
    const history = useHistory();
    const uidSeed = useUIDSeed();
    const classes = useStyles();
    const [saveError, setSaveError] = useState<string>();

    const {
        data: billingCustomers,
        isLoading: billingCustomersIsLoading,
        error: billingCustomersError,
    } = useGetCustomers();

    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { handleSubmit, errors, formState, register, reset, control } = useForm<Organization>({
        defaultValues: organizationData,
    });

    // update the form values any time we get new organization details
    useEffect(() => {
        reset(organizationData);
        setSaveError(undefined);
    }, [organizationData, reset]);

    const { isLoading: isCreating, mutateAsync: mutationCreateOrganization } =
        useCreateOrganization({
            mutation: {
                onSuccess: () => {
                    // eslint-disable-next-line no-void
                    void globalQueryClient.invalidateQueries(getGetAllOrganizationsQueryKey());

                    // eslint-disable-next-line no-void
                    void globalQueryClient.invalidateQueries(getGetCustomersQueryKey());
                },
            },
        });

    const { isLoading: isUpdating, mutateAsync: mutationUpdateOrganization } = usePatchOrganization(
        {
            mutation: {
                onSuccess: () => {
                    // eslint-disable-next-line no-void
                    void globalQueryClient.invalidateQueries(getGetAllOrganizationsQueryKey());

                    // eslint-disable-next-line no-void
                    void globalQueryClient.invalidateQueries(getGetCustomersQueryKey());
                },
            },
        }
    );

    const isCreatingOrUpdating = isCreating || isUpdating;
    const loadingProps = {
        disabled: isCreatingOrUpdating,
        className: isCreatingOrUpdating ? Classes.SKELETON : "",
    } as const;

    const handleSave = useCallback(
        async (formValues: Organization) => {
            try {
                if (organizationData?.organizationId === -1) {
                    const newOrg = await mutationCreateOrganization({ data: formValues });
                    setSaveError(undefined);
                    history.push(
                        internalSitemap.organizations.generatePath({
                            organizationId: newOrg.organizationId.toString(),
                        })
                    );
                } else {
                    await mutationUpdateOrganization({
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        orgId: organizationData!.organizationId,
                        data: formValues,
                    });
                    setSaveError(undefined);
                    reset(formValues);
                }
            } catch (error) {
                setSaveError(handleNetworkError(error));
            }
        },
        [history, mutationCreateOrganization, mutationUpdateOrganization, organizationData, reset]
    );

    const isNew = organizationData?.organizationId === -1;

    return (
        <form autoComplete="off" onSubmit={handleSubmit(handleSave)} className={classes.scrollPane}>
            <div className={classes.controls}>
                {organizationData !== undefined && !isNew && (
                    <>
                        <Link
                            to={internalSitemap.messages.generatePath({
                                organizationId: organizationData.organizationId.toString(),
                            })}
                        >
                            View Messages <FaExternalLinkAlt />
                        </Link>
                        <div className={classes.fullWidth} />
                        <FormGroup
                            label="Organization Id"
                            labelFor={uidSeed("organizationId")}
                            className={classes.partWidth}
                            helperText={errors.name?.message}
                            intent={errors.name ? "danger" : "none"}
                        >
                            <InputGroup
                                id={uidSeed("organizationId")}
                                name="organizationId"
                                inputRef={register()}
                                intent={errors.name ? "danger" : "none"}
                                {...loadingProps}
                                disabled
                            />
                        </FormGroup>
                    </>
                )}
                <div className={classes.fullWidth} />
                <FormGroup
                    label="Name"
                    labelFor={uidSeed("name")}
                    className={classes.partWidth}
                    helperText={errors.name?.message}
                    intent={errors.name ? "danger" : "none"}
                >
                    <InputGroup
                        id={uidSeed("name")}
                        name="name"
                        inputRef={register()}
                        intent={errors.name ? "danger" : "none"}
                        {...loadingProps}
                    />
                </FormGroup>
                <div className={classes.fullWidth} />
                <FormGroup
                    label="Billing Status"
                    labelFor={uidSeed("billingType")}
                    className={classes.partWidth}
                    helperText={errors.billingType?.message}
                    intent={errors.billingType ? "danger" : "none"}
                >
                    <HTMLSelect
                        id={uidSeed("billingType")}
                        name="billingType"
                        elementRef={register()}
                        options={BILLING_TYPES}
                        {...loadingProps}
                    />
                </FormGroup>
                <div className={classes.fullWidth} />
                <FormGroup
                    label="Billing Email"
                    labelFor={uidSeed("billingEmail")}
                    className={classes.partWidth}
                    helperText={errors.billingEmail?.message}
                    intent={errors.billingEmail ? "danger" : "none"}
                >
                    <InputGroup
                        id={uidSeed("billingEmail")}
                        name="billingEmail"
                        inputRef={register({
                            required: "Required",
                            pattern: { value: EMAIL_REGEX, message: "Invalid email address" },
                        })}
                        intent={errors.billingEmail ? "danger" : "none"}
                        {...loadingProps}
                    />
                </FormGroup>
                {!isNew && (
                    <>
                        <div className={classes.fullWidth} />
                        <Controller
                            id={uidSeed("stripeCustomerId")}
                            name="stripeCustomerId"
                            control={control}
                            rules={{ required: "Required" }}
                            render={({ value, onChange }) => {
                                const billingCustomer = billingCustomers?.find(
                                    (i) => i.id === value
                                );

                                const billingCustomerOptions: SelectOption[] =
                                    billingCustomers?.map((bc) => ({
                                        value: bc.id,
                                        label: `${bc.name ?? "No Name"} (${bc.email ?? ""})`,
                                    })) ?? [];

                                return (
                                    <>
                                        <FormGroup
                                            label="Stripe Customer"
                                            labelFor={uidSeed("stripeCustomerId")}
                                            className={classes.partWidth}
                                            helperText={errors.stripeCustomerId?.message}
                                            intent={errors.stripeCustomerId ? "danger" : "none"}
                                        >
                                            {billingCustomersIsLoading && <Spinner />}
                                            {billingCustomersError && (
                                                <>Error loading stripe customers list</>
                                            )}
                                            {!billingCustomersIsLoading && (
                                                <SimpleSelect
                                                    fill
                                                    items={billingCustomerOptions}
                                                    getKey={(item) => item.value}
                                                    renderItem={(item) => item.label}
                                                    renderSelectedItem={(item) => {
                                                        if (item === undefined) {
                                                            return "";
                                                        }
                                                        return item.label;
                                                    }}
                                                    itemSearchText={(item) => item.label}
                                                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                                    selectedKey={value}
                                                    onSelectionChange={(item) =>
                                                        onChange(item.value)
                                                    }
                                                    virtualScrollProps={{
                                                        height: 300,
                                                        width: "100%",
                                                        itemSize: 30,
                                                    }}
                                                    {...loadingProps}
                                                />
                                            )}
                                        </FormGroup>
                                        {billingCustomer !== undefined && (
                                            <BillingCustomerCard
                                                billingCustomer={billingCustomer}
                                            />
                                        )}
                                    </>
                                );
                            }}
                        />
                    </>
                )}
            </div>
            <div className={classes.footer}>
                {saveError && <div className={classes.formError}>{saveError}</div>}
                <Expander />
                <Button
                    type="button"
                    disabled={isCreatingOrUpdating || formState.isSubmitting}
                    onClick={() => history.push(internalSitemap.organizations.generatePath())}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    intent="primary"
                    loading={formState.isSubmitting}
                    disabled={isCreatingOrUpdating || !formState.isDirty || formState.isSubmitting}
                >
                    Save
                </Button>
            </div>
        </form>
    );
};
