import { MetadataDeviceType } from "components/Metadata/Metadata.utils";
import { IconForMetadataDeviceType } from "components/Metadata/MetadataField/IconForMetadataDeviceType";
import "mapbox-gl/dist/mapbox-gl.css";
import React from "react";
import { createUseStyles } from "react-jss";
import variables from "styles/variables";

/** gets the computed size (width/height) including padding for the icon */
export function getDeviceLogoSize(size: number) {
    const padding = size / 3;
    return size + padding * 2;
}

const useStyles = createUseStyles({
    deviceLinkIcon: {
        background: variables.blue1,
        display: "inline-block",
        borderRadius: "100%",
        // blueprint adds -7px margin to the first icon within all inputs, this removes it
        "& > svg": {
            margin: "0 !important",
        },
    },
});

interface IDeviceLogoProps {
    color: string;
    size: number;
    type: MetadataDeviceType;
}

export const DeviceLogo: React.FunctionComponent<IDeviceLogoProps> = ({ color, size, type }) => {
    const classes = useStyles();

    const sizePixels = `${size}px`;

    return (
        <div
            className={classes.deviceLinkIcon}
            style={{
                background: color,
                // color needs to be set on the parent as well because blueprint adds "inherit !important"
                color: variables.white,
                width: sizePixels,
                height: sizePixels,
                lineHeight: sizePixels,
                padding: size / 3,
                marginRight: size / 3,
                boxSizing: "content-box",
            }}
        >
            <IconForMetadataDeviceType color={variables.white} size={size} type={type} />
        </div>
    );
};
