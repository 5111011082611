import { Button, NonIdealState, Pre } from "@blueprintjs/core";
import { ImageSize } from "images/types";
import React from "react";
import { FaExclamationTriangle } from "react-icons/fa";
import { createUseStyles } from "react-jss";
import { getMailToLink } from "utils";

interface Props<T> {
    error: T;
}

const useStyles = createUseStyles({
    error: {
        maxHeight: "200px",
        overflow: "auto",
    },
});

function ErrorPage<T>({ error }: Props<T>): React.ReactElement<Props<T>> {
    const classes = useStyles();
    return (
        <NonIdealState
            icon={<FaExclamationTriangle size={ImageSize.LARGE} />}
            title="Something Went Wrong"
            description={
                <div>
                    <p>
                        An unexpected error occurred, please{" "}
                        <a href={getMailToLink("support@swarm.space", "", "")}>contact support</a>{" "}
                        if the issue persists.
                    </p>
                    {error !== undefined && error !== null && (
                        <Pre className={classes.error}>
                            {error instanceof Error ? error.message : JSON.stringify(error)}
                        </Pre>
                    )}
                </div>
            }
            action={
                <Button intent="primary" onClick={() => window.location.reload()}>
                    Reload
                </Button>
            }
        />
    );
}

export default ErrorPage;
