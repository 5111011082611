import React from "react";
import { numberDisplay } from "utils";

interface INumberDisplayProps {
    amount: number;

    /** @default 2 */
    minDigits?: number;

    /** @default 2 */
    maxDigits?: number;
}

export const NumberDisplay: React.FunctionComponent<INumberDisplayProps> = (props) => {
    return (
        <>
            {numberDisplay({
                amount: props.amount,
                minDigits: props.minDigits,
                maxDigits: props.maxDigits,
            })}
        </>
    );
};
