import { Button, H1, H4, Spinner } from "@blueprintjs/core";
import { CurrencyDisplay } from "components/CurrencyDisplay";
import { DateDisplay } from "components/DateDisplay";
import InternalDeviceDetail from "components/InternalDeviceDetail";
import { CardLineItem } from "components/Invoice/CardLineItem";
import { CardLineItemHeader } from "components/Invoice/CardLineItemHeader";
import { StatusTag } from "components/Invoice/StatusTag";
import { useGetInvoiceWithLineItems } from "handlers/generated/billing";
import internalSitemap, { RouteParams } from "internalSitemap";
import { TabId } from "pages/InternalOrganizations/OrganizationEditor";
import React, { useCallback, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDeviceList } from "resources/DeviceResource";
import { formatDeviceIdAndType } from "resources/DeviceResource.utils";

interface IInvoiceDetailProps {
    organizationId: number;
    stripeInvoiceId: string;
}

export const InternalInvoiceDetails: React.FunctionComponent<IInvoiceDetailProps> = ({
    organizationId,
    stripeInvoiceId,
}) => {
    const { data: invoice, isLoading } = useGetInvoiceWithLineItems(
        organizationId,
        stripeInvoiceId
    );

    const { organizationId: orgId, deviceKey: activeDeviceKey } =
        useParams<RouteParams<"organizations">>();
    const history = useHistory();

    const { data: deviceList } = useDeviceList({});
    const activeDevice = useMemo(() => {
        if (deviceList === undefined) {
            return undefined;
        }

        return deviceList.find(
            (device) =>
                formatDeviceIdAndType(device.deviceId, device.deviceType) === activeDeviceKey
        );
    }, [activeDeviceKey, deviceList]);

    const closeActiveDevice = useCallback((): void => {
        history.push(
            internalSitemap.organizations.generatePath({
                organizationId: orgId,
                tab: TabId.payment,
                stripeInvoiceId,
            })
        );
    }, [history, orgId, stripeInvoiceId]);

    const handleBackButton = () => {
        history.push(
            internalSitemap.organizations.generatePath({
                organizationId: orgId,
                tab: TabId.payment,
            })
        );
    };

    const backButton = (
        <Button
            type="submit"
            onClick={handleBackButton}
            minimal
            icon="chevron-left"
            large
            outlined
            text="Back"
        />
    );

    if (isLoading) {
        return (
            <>
                {backButton}
                <div style={{ textAlign: "center", margin: "32px 0 64px 0" }}>
                    <Spinner />
                </div>
            </>
        );
    }

    if (invoice === undefined) {
        return (
            <>
                {backButton}
                <div style={{ textAlign: "center", margin: "32px 0 64px 0" }}>
                    Invoice not found
                </div>
            </>
        );
    }

    return (
        <>
            {backButton}

            <div style={{ padding: 12 }}>
                <H1>{invoice.invoiceNumber}</H1>

                <div style={{ padding: 12 }}>
                    <H4>Status</H4>
                    <StatusTag status={invoice.status} />
                </div>

                {invoice.dueDate !== undefined && (
                    <div style={{ padding: 12 }}>
                        <H4>Due Date</H4>
                        <DateDisplay date={invoice.dueDate} zone="UTC" showTime={false} />
                    </div>
                )}

                {invoice.startDate !== null && invoice.endDate !== null && (
                    <div style={{ padding: 12 }}>
                        <H4>Billing Period</H4>
                        <div style={{ display: "flex" }}>
                            <DateDisplay date={invoice.startDate} zone="UTC" showTime={false} />
                            <p> - </p>
                            <DateDisplay date={invoice.endDate} zone="UTC" showTime={false} />
                        </div>
                    </div>
                )}

                <div style={{ padding: 12 }}>
                    <H4>Total Due </H4>
                    <CurrencyDisplay amount={(invoice.totalAmount ?? 0) / 100} />
                </div>

                <div style={{ display: "flex" }}>
                    {invoice.invoiceDownloadUrl !== undefined &&
                        invoice.invoiceDownloadUrl != null && (
                            <div style={{ padding: 12 }}>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={invoice.invoiceDownloadUrl}
                                >
                                    <Button icon="download" text="Stripe Invoice Download" />
                                </a>
                            </div>
                        )}

                    {invoice.invoicePageUrl !== undefined && (
                        <div style={{ padding: 12 }}>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={invoice.invoicePageUrl}
                            >
                                <Button icon="share" text="Stripe Invoice Page" />
                            </a>
                        </div>
                    )}
                </div>

                {invoice.lineItems != null && invoice.lineItems.length !== 0 && (
                    <div style={{ paddingTop: 12 }} data-testid="lineItems">
                        <CardLineItemHeader />
                        {invoice.lineItems.map((invoiceItem) => (
                            <CardLineItem lineItem={invoiceItem} />
                        ))}
                    </div>
                )}

                {invoice.showFooter && (
                    <div style={{ padding: 18 }} data-testid="footer">
                        <div>
                            <span style={{ fontWeight: "bold" }}>'On Hold'</span> devices are
                            devices that have not been used within 60 days of their renewal date.
                            They will not be charged for data as long as they remain unused.
                        </div>
                        <div>
                            <span style={{ fontWeight: "bold" }}>'Reactivating'</span> devices are
                            previously-'On Hold' devices that have sent a message. They will be
                            automatically charged on the following invoice, similar to a new device
                            activation.
                        </div>
                    </div>
                )}
            </div>

            <InternalDeviceDetail device={activeDevice} onClose={() => closeActiveDevice()} />
        </>
    );
};
