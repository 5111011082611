/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Hive Service REST API
 * OpenAPI spec version: v1
 */
import { useQuery, useMutation } from "react-query";
import type {
    UseQueryOptions,
    UseMutationOptions,
    QueryFunction,
    MutationFunction,
    UseQueryResult,
    QueryKey,
} from "react-query";
import type {
    RFPacket,
    ApiError,
    GetRFPacketsParams,
    DeletePacketsParams,
    RFPacketData,
    GetRFPacketDataParams,
    GetRFPacketCountParams,
} from "../models";
import { apiHandler } from "../../../../resources/apiHandler";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
    config: any,
    args: infer P
) => any
    ? P
    : never;

/**
 * Get a JSON array of RF packets matching the given parameters.
 * @summary GET RF packets
 */
export const getRFPackets = (
    params?: GetRFPacketsParams,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<RFPacket[]>(
        { url: `/hive/api/v1/rfpackets`, method: "get", params, signal },
        options
    );
};

export const getGetRFPacketsQueryKey = (params?: GetRFPacketsParams) =>
    [`/hive/api/v1/rfpackets`, ...(params ? [params] : [])] as const;

export const getGetRFPacketsQueryOptions = <
    TData = Awaited<ReturnType<typeof getRFPackets>>,
    TError = ApiError
>(
    params?: GetRFPacketsParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getRFPackets>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getRFPackets>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetRFPacketsQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRFPackets>>> = ({ signal }) =>
        getRFPackets(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetRFPacketsQueryResult = NonNullable<Awaited<ReturnType<typeof getRFPackets>>>;
export type GetRFPacketsQueryError = ApiError;

/**
 * @summary GET RF packets
 */
export const useGetRFPackets = <
    TData = Awaited<ReturnType<typeof getRFPackets>>,
    TError = ApiError
>(
    params?: GetRFPacketsParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getRFPackets>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetRFPacketsQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Post a packet in JSON form; returns a JSON containing a hex encoding of that packet and other metadata.
 * @summary POST packet (plaintext)
 */
export const addRFPacketTextPlain1 = (
    rFPacket: RFPacket,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<string>(
        {
            url: `/hive/api/v1/rfpackets`,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: rFPacket,
        },
        options
    );
};

export const getAddRFPacketTextPlain1MutationOptions = <
    TError = ApiError,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof addRFPacketTextPlain1>>,
        TError,
        { data: RFPacket },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof addRFPacketTextPlain1>>,
    TError,
    { data: RFPacket },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof addRFPacketTextPlain1>>,
        { data: RFPacket }
    > = (props) => {
        const { data } = props ?? {};

        return addRFPacketTextPlain1(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type AddRFPacketTextPlain1MutationResult = NonNullable<
    Awaited<ReturnType<typeof addRFPacketTextPlain1>>
>;
export type AddRFPacketTextPlain1MutationBody = RFPacket;
export type AddRFPacketTextPlain1MutationError = ApiError;

/**
 * @summary POST packet (plaintext)
 */
export const useAddRFPacketTextPlain1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof addRFPacketTextPlain1>>,
        TError,
        { data: RFPacket },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getAddRFPacketTextPlain1MutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Delete RF packet with the given packet id.
 * @summary DELETE RF packet
 */
export const deletePackets = (
    params?: DeletePacketsParams,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<string>({ url: `/hive/api/v1/rfpackets`, method: "delete", params }, options);
};

export const getDeletePacketsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deletePackets>>,
        TError,
        { params?: DeletePacketsParams },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof deletePackets>>,
    TError,
    { params?: DeletePacketsParams },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deletePackets>>,
        { params?: DeletePacketsParams }
    > = (props) => {
        const { params } = props ?? {};

        return deletePackets(params, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DeletePacketsMutationResult = NonNullable<Awaited<ReturnType<typeof deletePackets>>>;

export type DeletePacketsMutationError = ApiError;

/**
 * @summary DELETE RF packet
 */
export const useDeletePackets = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deletePackets>>,
        TError,
        { params?: DeletePacketsParams },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getDeletePacketsMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Encode the given RFPacket into a hex string.
 * @summary GET encode packet
 */
export const encodeRFPacket = (
    rFPacket: RFPacket,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<string>(
        {
            url: `/hive/api/v1/rfpackets/encode`,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: rFPacket,
        },
        options
    );
};

export const getEncodeRFPacketMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof encodeRFPacket>>,
        TError,
        { data: RFPacket },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof encodeRFPacket>>,
    TError,
    { data: RFPacket },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof encodeRFPacket>>,
        { data: RFPacket }
    > = (props) => {
        const { data } = props ?? {};

        return encodeRFPacket(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type EncodeRFPacketMutationResult = NonNullable<Awaited<ReturnType<typeof encodeRFPacket>>>;
export type EncodeRFPacketMutationBody = RFPacket;
export type EncodeRFPacketMutationError = ApiError;

/**
 * @summary GET encode packet
 */
export const useEncodeRFPacket = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof encodeRFPacket>>,
        TError,
        { data: RFPacket },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getEncodeRFPacketMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Get a JSON array of RFPacketData matching the given parameters.
 * @summary GET RFPacketData
 */
export const getRFPacketData = (
    params?: GetRFPacketDataParams,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<RFPacketData[]>(
        { url: `/hive/api/v1/rfpackets/inner`, method: "get", params, signal },
        options
    );
};

export const getGetRFPacketDataQueryKey = (params?: GetRFPacketDataParams) =>
    [`/hive/api/v1/rfpackets/inner`, ...(params ? [params] : [])] as const;

export const getGetRFPacketDataQueryOptions = <
    TData = Awaited<ReturnType<typeof getRFPacketData>>,
    TError = ApiError
>(
    params?: GetRFPacketDataParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getRFPacketData>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getRFPacketData>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetRFPacketDataQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRFPacketData>>> = ({ signal }) =>
        getRFPacketData(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetRFPacketDataQueryResult = NonNullable<Awaited<ReturnType<typeof getRFPacketData>>>;
export type GetRFPacketDataQueryError = ApiError;

/**
 * @summary GET RFPacketData
 */
export const useGetRFPacketData = <
    TData = Awaited<ReturnType<typeof getRFPacketData>>,
    TError = ApiError
>(
    params?: GetRFPacketDataParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getRFPacketData>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetRFPacketDataQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Decode the given hexPacket into an RFPacket.
 * @summary GET decode packet
 */
export const decodeRFPacket = (
    hexPacket: string,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<RFPacket>(
        { url: `/hive/api/v1/rfpackets/decode/${hexPacket}`, method: "get", signal },
        options
    );
};

export const getDecodeRFPacketQueryKey = (hexPacket: string) =>
    [`/hive/api/v1/rfpackets/decode/${hexPacket}`] as const;

export const getDecodeRFPacketQueryOptions = <
    TData = Awaited<ReturnType<typeof decodeRFPacket>>,
    TError = ApiError
>(
    hexPacket: string,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof decodeRFPacket>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof decodeRFPacket>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getDecodeRFPacketQueryKey(hexPacket);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof decodeRFPacket>>> = ({ signal }) =>
        decodeRFPacket(hexPacket, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!hexPacket, ...queryOptions };
};

export type DecodeRFPacketQueryResult = NonNullable<Awaited<ReturnType<typeof decodeRFPacket>>>;
export type DecodeRFPacketQueryError = ApiError;

/**
 * @summary GET decode packet
 */
export const useDecodeRFPacket = <
    TData = Awaited<ReturnType<typeof decodeRFPacket>>,
    TError = ApiError
>(
    hexPacket: string,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof decodeRFPacket>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getDecodeRFPacketQueryOptions(hexPacket, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Decode the "outer" portion of the given hexPacket into an RFPacket.
 * @summary GET decode outer packet
 */
export const decodeRFPacketOuter = (
    hexPacket: string,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<RFPacket>(
        { url: `/hive/api/v1/rfpackets/decode/outer/${hexPacket}`, method: "get", signal },
        options
    );
};

export const getDecodeRFPacketOuterQueryKey = (hexPacket: string) =>
    [`/hive/api/v1/rfpackets/decode/outer/${hexPacket}`] as const;

export const getDecodeRFPacketOuterQueryOptions = <
    TData = Awaited<ReturnType<typeof decodeRFPacketOuter>>,
    TError = ApiError
>(
    hexPacket: string,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof decodeRFPacketOuter>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof decodeRFPacketOuter>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getDecodeRFPacketOuterQueryKey(hexPacket);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof decodeRFPacketOuter>>> = ({ signal }) =>
        decodeRFPacketOuter(hexPacket, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!hexPacket, ...queryOptions };
};

export type DecodeRFPacketOuterQueryResult = NonNullable<
    Awaited<ReturnType<typeof decodeRFPacketOuter>>
>;
export type DecodeRFPacketOuterQueryError = ApiError;

/**
 * @summary GET decode outer packet
 */
export const useDecodeRFPacketOuter = <
    TData = Awaited<ReturnType<typeof decodeRFPacketOuter>>,
    TError = ApiError
>(
    hexPacket: string,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof decodeRFPacketOuter>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getDecodeRFPacketOuterQueryOptions(hexPacket, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Decode the "inner" portion of the given hexPacket into an RFPacket.
 * @summary GET decode inner packet
 */
export const decodeRFPacketData = (
    hexPacket: string,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<RFPacketData>(
        { url: `/hive/api/v1/rfpackets/decode/inner/${hexPacket}`, method: "get", signal },
        options
    );
};

export const getDecodeRFPacketDataQueryKey = (hexPacket: string) =>
    [`/hive/api/v1/rfpackets/decode/inner/${hexPacket}`] as const;

export const getDecodeRFPacketDataQueryOptions = <
    TData = Awaited<ReturnType<typeof decodeRFPacketData>>,
    TError = ApiError
>(
    hexPacket: string,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof decodeRFPacketData>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof decodeRFPacketData>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getDecodeRFPacketDataQueryKey(hexPacket);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof decodeRFPacketData>>> = ({ signal }) =>
        decodeRFPacketData(hexPacket, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!hexPacket, ...queryOptions };
};

export type DecodeRFPacketDataQueryResult = NonNullable<
    Awaited<ReturnType<typeof decodeRFPacketData>>
>;
export type DecodeRFPacketDataQueryError = ApiError;

/**
 * @summary GET decode inner packet
 */
export const useDecodeRFPacketData = <
    TData = Awaited<ReturnType<typeof decodeRFPacketData>>,
    TError = ApiError
>(
    hexPacket: string,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof decodeRFPacketData>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getDecodeRFPacketDataQueryOptions(hexPacket, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Get, in plaintext, the number of RF packets matching the given parameters.
 * @summary GET RF packet count
 */
export const getRFPacketCount = (
    params?: GetRFPacketCountParams,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<number>(
        { url: `/hive/api/v1/rfpackets/count`, method: "get", params, signal },
        options
    );
};

export const getGetRFPacketCountQueryKey = (params?: GetRFPacketCountParams) =>
    [`/hive/api/v1/rfpackets/count`, ...(params ? [params] : [])] as const;

export const getGetRFPacketCountQueryOptions = <
    TData = Awaited<ReturnType<typeof getRFPacketCount>>,
    TError = ApiError
>(
    params?: GetRFPacketCountParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getRFPacketCount>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getRFPacketCount>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetRFPacketCountQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRFPacketCount>>> = ({ signal }) =>
        getRFPacketCount(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetRFPacketCountQueryResult = NonNullable<Awaited<ReturnType<typeof getRFPacketCount>>>;
export type GetRFPacketCountQueryError = ApiError;

/**
 * @summary GET RF packet count
 */
export const useGetRFPacketCount = <
    TData = Awaited<ReturnType<typeof getRFPacketCount>>,
    TError = ApiError
>(
    params?: GetRFPacketCountParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getRFPacketCount>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetRFPacketCountQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};
