import { Classes } from "@blueprintjs/core";
import classNames from "classnames";
import { DateDisplay } from "components/DateDisplay";
import { DateDisplayTimeAgo } from "components/DateDisplayTimeAgo";
import LabelTable from "components/InternalDeviceDetail/LabelTable";
import { LastHeard } from "components/LastHeard";
import { SXCard } from "components/SXCard";
import React from "react";
import { DeviceBillingPlan } from "resources/BillingDevicesResource";
import { IDeviceWithPaymentStatus } from "resources/DeviceResource.utils";
import { assertUnreachable } from "utils";

interface IDeviceInfoProps {
    device: Readonly<IDeviceWithPaymentStatus>;
}

function formatPlanName(plan: DeviceBillingPlan) {
    switch (plan) {
        case DeviceBillingPlan.STANDARD_DATA_PLAN_YEARLY:
            return "Standard Data Plan - Yearly";
        case DeviceBillingPlan.REDUCED_DATA_PLAN_YEARLY:
            return "Reduced Data Plan - Yearly";
        case DeviceBillingPlan.NONE:
            return "None";
        case DeviceBillingPlan.ERROR:
            return "Error";
        default:
            return assertUnreachable(plan);
    }
}

export const DeviceInfo: React.FunctionComponent<IDeviceInfoProps> = ({ device }) => {
    return (
        <SXCard title="Info">
            <LabelTable>
                <tr>
                    <td>Device ID</td>
                    <td>{device.displayIdAndType}</td>
                </tr>
                <tr>
                    <td>
                        <LastHeard />
                    </td>
                    <td>
                        <DateDisplay date={device.hiveLastheardTime} />
                        <br />
                        <span className={classNames(Classes.TEXT_SMALL, Classes.TEXT_MUTED)}>
                            <DateDisplayTimeAgo date={device.hiveLastheardTime} unit="auto" />
                        </span>
                    </td>
                </tr>
                <tr>
                    <td>Data Activation</td>
                    <td>
                        <DateDisplay date={device.billingStatus?.activationDate} showTime={false} />
                    </td>
                </tr>
                {device.billingStatus && (
                    <tr>
                        <td>Data Plan</td>
                        <td>{formatPlanName(device.billingStatus.plan)}</td>
                    </tr>
                )}
                <tr>
                    <td>Data Plan Paid Through</td>
                    <td>
                        <DateDisplay date={device.billingStatus?.paidThrough} showTime={false} />
                    </td>
                </tr>
                <tr>
                    <td>Firmware Version</td>
                    <td>{device.firmwareVersion}</td>
                </tr>
            </LabelTable>
        </SXCard>
    );
};
