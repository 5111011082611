import { useGetUserContext } from "handlers/generated/hive";
import { PageSkeleton } from "PageSkeleton";
import React, { createContext, useContext } from "react";
import { QueryClientProvider } from "react-query";
import { Redirect, useLocation } from "react-router-dom";
import { FeatureFlag } from "resources/FeatureFlagResource";
import { globalQueryClient } from "resources/globalQueryClient";
import { IUserContext } from "resources/UserContext";
import { getUnauthenticatedRedirect, getUserPortal, UserPortal } from "utils";

interface IAppProviderProps {
    children: React.ReactNode;
}

export const AppProvider: React.FunctionComponent<IAppProviderProps> = ({ children }) => {
    return <QueryClientProvider client={globalQueryClient}>{children}</QueryClientProvider>;
};

export const UserContext = createContext<IUserContext>({} as IUserContext);

export const UserContextProvider = ({ children }) => {
    const { data, isLoading, isFetching, error } = useGetUserContext();

    // todo we should display our generic ErrorPage when the response code isn't 401
    if (error !== null && !isFetching) {
        return <Redirect to={getUnauthenticatedRedirect(window.location.pathname)} />;
    }

    if (data === undefined || isLoading) {
        return <PageSkeleton />;
    }

    return <UserContext.Provider value={data as IUserContext}>{children}</UserContext.Provider>;
};

export const useUserContext = () => {
    const context = useContext(UserContext);

    if (context === undefined) {
        throw new Error("useUserContext was used outside of its Provider");
    }

    return context;
};

export const useUserPortal = (): UserPortal => {
    const location = useLocation();
    return getUserPortal(location.pathname);
};

export const useFeatureFlag = (flagName: FeatureFlag, defaultValue = false) => {
    const context = useUserContext();

    if (context === undefined) {
        throw new Error("useFeatureFlag was used outside of useUserContext Provider");
    }

    return context.featureFlags[flagName] ?? defaultValue;
};
