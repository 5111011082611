import { Classes, FormGroup, Spinner, UL } from "@blueprintjs/core";
import { DateDisplay } from "components/DateDisplay";
import { RFDisplay } from "components/InternalDeviceDetail/RFDisplay";
import { RFPacketTelemetry, useGetPackets } from "handlers/generated/hive";
import moment from "moment-timezone";
import React, { Suspense } from "react";
import { createUseStyles } from "react-jss";
import { IDevice } from "resources/DeviceResource";
import { DeviceType } from "resources/DeviceResource.types";
import { formatDeviceTypeLong } from "resources/DeviceResource.utils";
import { toLocaleFixed } from "utils";
import CollapsibleGroup from "./CollapsibleGroup";
import LabelTable from "./LabelTable";

const useStyles = createUseStyles({
    noBullets: {
        listStyleType: "none",
        paddingLeft: 0,
        marginTop: 0,
    },
});

interface ITelemetryDisplayProps {
    device: IDevice;
    telemetry: RFPacketTelemetry;
}

export const TelemetryDisplay: React.FunctionComponent<ITelemetryDisplayProps> = ({
    device,
    telemetry,
}) => {
    const classes = useStyles();

    const { data, isLoading, error } = useGetPackets({
        packetid: telemetry.packetId,
        sortAsc: false,
        count: 1,
        recursively: true,
    });

    if (data === undefined || isLoading) {
        return <Spinner />;
    }

    // If the telemetry exists, the packet should exist as well
    if (data?.length === 0 || error) {
        return <>Unable to display requested packet</>;
    }

    const packet = data[0];

    return (
        <>
            <FormGroup
                helperText={`${moment(packet.hiveRxTime)
                    .diff(telemetry.telemetryAt, "s")
                    .toLocaleString()}s delay`}
            >
                <LabelTable>
                    <tr>
                        <td>Packet ID</td>
                        <td>{telemetry.packetId}</td>
                    </tr>
                    <tr>
                        <td>Sent</td>
                        <td>
                            <DateDisplay date={telemetry.telemetryAt} />
                        </td>
                    </tr>
                    <tr>
                        <td>Received</td>
                        <td>
                            <DateDisplay date={packet.hiveRxTime} />
                        </td>
                    </tr>
                </LabelTable>
            </FormGroup>
            <CollapsibleGroup
                label="Location"
                labelInfo={`(using ${telemetry.telemetrySatCount} satellites)`}
            >
                <LabelTable>
                    <tr>
                        <td>Latitude</td>
                        <td>
                            {telemetry.telemetryLatitude.toFixed(4)}
                            &deg; {telemetry.telemetryLatitude < 0 ? "S" : "N"}
                        </td>
                    </tr>
                    <tr>
                        <td>Longitude</td>
                        <td>
                            {telemetry.telemetryLongitude.toFixed(4)}
                            &deg; {telemetry.telemetryLongitude < 0 ? "W" : "E"}
                        </td>
                    </tr>
                    <tr>
                        <td>Altitude</td>
                        <td>{telemetry.telemetryAltitude.toLocaleString()} m</td>
                    </tr>
                </LabelTable>
            </CollapsibleGroup>
            {device.deviceType !== DeviceType.GROUND_STATION && (
                <Suspense fallback={<></>}>
                    <RFDisplay packet={packet} telemetry={telemetry} />
                </Suspense>
            )}
            {device.deviceType === DeviceType.SATELLITE && (
                <CollapsibleGroup label="Trajectory">
                    <LabelTable>
                        <tr>
                            <td>Course</td>
                            <td>{telemetry.telemetryCourse.toLocaleString()}&deg;</td>
                        </tr>
                        <tr>
                            <td>Speed</td>
                            <td>{toLocaleFixed(telemetry.telemetrySpeed / 100, 0)} km/h</td>
                        </tr>
                        <tr>
                            <td>Avg. V. Velocity</td>
                            <td>{toLocaleFixed(telemetry.verticalVelocityTrend, 3)} m/s</td>
                        </tr>
                    </LabelTable>
                </CollapsibleGroup>
            )}
            <CollapsibleGroup label="Hardware">
                <LabelTable>
                    <tr>
                        <td>Battery</td>
                        <td>
                            {toLocaleFixed(telemetry.telemetryBatteryVoltage / 10, 2)} V,{" "}
                            {toLocaleFixed(telemetry.telemetryBatteryCurrent / 10, 2)} mA
                            <br />
                            <span className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}>
                                {toLocaleFixed(
                                    (telemetry.telemetryBatteryVoltage *
                                        telemetry.telemetryBatteryCurrent) /
                                        100000,
                                    2
                                )}{" "}
                                W
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Solar</td>
                        <td>
                            {toLocaleFixed(telemetry.telemetrySolarVoltage / 10, 2)} V,{" "}
                            {toLocaleFixed(telemetry.telemetrySolarCurrent / 10, 2)} mA
                            <br />
                            <span className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}>
                                {toLocaleFixed(
                                    (telemetry.telemetrySolarVoltage *
                                        telemetry.telemetrySolarCurrent) /
                                        100000,
                                    2
                                )}{" "}
                                W
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Temp.</td>
                        <td>
                            {toLocaleFixed(telemetry.telemetryTemperatureK / 100 - 273.15, 2)}{" "}
                            &deg;C
                        </td>
                    </tr>
                    <tr>
                        <td>CPU Flags</td>
                        <td>
                            0x
                            {`0000000${telemetry.telemetryCpuFlags.toString(16)}`.substr(-8)}
                        </td>
                    </tr>
                </LabelTable>
            </CollapsibleGroup>
            {device.deviceType !== DeviceType.FIELD && (
                <CollapsibleGroup label="Pending Messages">
                    {telemetry.messagesPending === 0 ? (
                        "None"
                    ) : (
                        <UL className={classes.noBullets}>
                            {(telemetry.messagesPending & 1) !== 0 && (
                                <li>{formatDeviceTypeLong(DeviceType.FIELD)}</li>
                            )}
                            {(telemetry.messagesPending & 2) !== 0 && (
                                <li>{formatDeviceTypeLong(DeviceType.STRATO)}</li>
                            )}
                            {(telemetry.messagesPending & 4) !== 0 && (
                                <li>{formatDeviceTypeLong(DeviceType.SATELLITE)}</li>
                            )}
                            {(telemetry.messagesPending & 8) !== 0 && (
                                <li>{formatDeviceTypeLong(DeviceType.GROUND_STATION)}</li>
                            )}
                            {(telemetry.messagesPending & 16) !== 0 && (
                                <li>{formatDeviceTypeLong(DeviceType.HIVE)}</li>
                            )}
                        </UL>
                    )}
                </CollapsibleGroup>
            )}
        </>
    );
};
