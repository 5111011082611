import { Button, Position } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import React from "react";
import { FaQuestionCircle } from "react-icons/fa";
import { useTimezone } from "utils";

export const LastHeard: React.FunctionComponent = () => {
    const { zoneAbbr } = useTimezone();

    return (
        <>
            Last Heard ({zoneAbbr}){" "}
            <Popover2
                content={
                    <p style={{ maxWidth: 250, padding: 24, margin: 0 }}>
                        Last Heard updates when the Hive receives new messages or device telemetry.
                        Device telemetry is updated regularly by the network and helps with network
                        operations and device management.
                    </p>
                }
                position={Position.BOTTOM}
                interactionKind="hover"
            >
                <Button small icon={<FaQuestionCircle />} minimal />
            </Popover2>
        </>
    );
};
