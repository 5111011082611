/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Metadata Service REST API
 * OpenAPI spec version: v1
 */
import { useQuery, useMutation } from "react-query";
import type {
    UseQueryOptions,
    UseMutationOptions,
    QueryFunction,
    MutationFunction,
    UseQueryResult,
    QueryKey,
} from "react-query";
import type {
    ObjectMetadataBatchUpdateResultJson,
    ApiError,
    ObjectMetadataListJson,
    ObjectMetadataQueryJson,
    StatusJson,
    ObjectMetadataFieldsJson,
    GetMetadataValuesParams,
    GetMetadataKeysParams,
} from "../models";
import { apiHandler } from "../../../../resources/apiHandler";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
    config: any,
    args: infer P
) => any
    ? P
    : never;

/**
 * Create or update multiple metadata entries. Each entry should include the organizationId, objectType, objectId, and the metadata key/value pairs to update. <b>All updated metadata must have the same organizationId and objectType.</b> The organizationId, objectType, and objectId of each metadata will not be updated, but all other fields will be updated with the corresponding fields in the request body. In the "metadata" field, only the given keys will be updated. Pass a <code>null</code> value to delete a key from an object's metadata.<br/>For example, if the existing metadata for an object is <code>{"key1": "value1", "key2": "value2", "key3": "value3"}</code>, and the update body is <code>{"key1": null, "key2": "newvalue2", "key4": "value4"}</code>, the result after updating the metadata will be <code>{"key2": "newvalue2", "key3": "value3", "key4": "value4"}</code>. 
Returns 404 (Not Found) if the metadata with the given objectType and objectId doesn't exist or is inaccessible.
Returns 400 (Bad Request) if the update fails for other reasons.
 * @summary POST metadata: create or update multiple metadata entries.
 */
export const updateObjectMetadata = (
    objectMetadataListJson: ObjectMetadataListJson,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<ObjectMetadataBatchUpdateResultJson>(
        {
            url: `/api/v1/metadata`,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: objectMetadataListJson,
        },
        options
    );
};

export const getUpdateObjectMetadataMutationOptions = <
    TError = ApiError,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof updateObjectMetadata>>,
        TError,
        { data: ObjectMetadataListJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof updateObjectMetadata>>,
    TError,
    { data: ObjectMetadataListJson },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof updateObjectMetadata>>,
        { data: ObjectMetadataListJson }
    > = (props) => {
        const { data } = props ?? {};

        return updateObjectMetadata(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type UpdateObjectMetadataMutationResult = NonNullable<
    Awaited<ReturnType<typeof updateObjectMetadata>>
>;
export type UpdateObjectMetadataMutationBody = ObjectMetadataListJson;
export type UpdateObjectMetadataMutationError = ApiError;

/**
 * @summary POST metadata: create or update multiple metadata entries.
 */
export const useUpdateObjectMetadata = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof updateObjectMetadata>>,
        TError,
        { data: ObjectMetadataListJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getUpdateObjectMetadataMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Returns metadata matching the query that the current user has permissions to access.
 * @summary POST a metadata query.
 */
export const queryObjectMetadata = (
    objectMetadataQueryJson: ObjectMetadataQueryJson,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<ObjectMetadataListJson>(
        {
            url: `/api/v1/metadata/query`,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: objectMetadataQueryJson,
        },
        options
    );
};

export const getQueryObjectMetadataMutationOptions = <
    TError = ApiError,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof queryObjectMetadata>>,
        TError,
        { data: ObjectMetadataQueryJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof queryObjectMetadata>>,
    TError,
    { data: ObjectMetadataQueryJson },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof queryObjectMetadata>>,
        { data: ObjectMetadataQueryJson }
    > = (props) => {
        const { data } = props ?? {};

        return queryObjectMetadata(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type QueryObjectMetadataMutationResult = NonNullable<
    Awaited<ReturnType<typeof queryObjectMetadata>>
>;
export type QueryObjectMetadataMutationBody = ObjectMetadataQueryJson;
export type QueryObjectMetadataMutationError = ApiError;

/**
 * @summary POST a metadata query.
 */
export const useQueryObjectMetadata = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof queryObjectMetadata>>,
        TError,
        { data: ObjectMetadataQueryJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getQueryObjectMetadataMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Returns metadata if the current user has permissions to access it. Returns 404 (Not Found) if current user does not have permissions to access the object, or object does not exist.
 * @summary GET metadata.
 */
export const getObjectMetadata = (
    objectType: string,
    objectId: string,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<ObjectMetadataListJson>(
        { url: `/api/v1/metadata/${objectType}/${objectId}`, method: "get", signal },
        options
    );
};

export const getGetObjectMetadataQueryKey = (objectType: string, objectId: string) =>
    [`/api/v1/metadata/${objectType}/${objectId}`] as const;

export const getGetObjectMetadataQueryOptions = <
    TData = Awaited<ReturnType<typeof getObjectMetadata>>,
    TError = ApiError
>(
    objectType: string,
    objectId: string,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getObjectMetadata>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getObjectMetadata>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetObjectMetadataQueryKey(objectType, objectId);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getObjectMetadata>>> = ({ signal }) =>
        getObjectMetadata(objectType, objectId, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!(objectType && objectId), ...queryOptions };
};

export type GetObjectMetadataQueryResult = NonNullable<
    Awaited<ReturnType<typeof getObjectMetadata>>
>;
export type GetObjectMetadataQueryError = ApiError;

/**
 * @summary GET metadata.
 */
export const useGetObjectMetadata = <
    TData = Awaited<ReturnType<typeof getObjectMetadata>>,
    TError = ApiError
>(
    objectType: string,
    objectId: string,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getObjectMetadata>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetObjectMetadataQueryOptions(objectType, objectId, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Swarm users only. Returns 404 (Not Found) if metadata does not exist or is inaccessible to the user.
 * @summary DELETE metadata: delete an existing metadata by objectType and objectId.
 */
export const deleteObjectMetadata = (
    objectType: string,
    objectId: string,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<StatusJson>(
        { url: `/api/v1/metadata/${objectType}/${objectId}`, method: "delete" },
        options
    );
};

export const getDeleteObjectMetadataMutationOptions = <
    TError = ApiError,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteObjectMetadata>>,
        TError,
        { objectType: string; objectId: string },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof deleteObjectMetadata>>,
    TError,
    { objectType: string; objectId: string },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deleteObjectMetadata>>,
        { objectType: string; objectId: string }
    > = (props) => {
        const { objectType, objectId } = props ?? {};

        return deleteObjectMetadata(objectType, objectId, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DeleteObjectMetadataMutationResult = NonNullable<
    Awaited<ReturnType<typeof deleteObjectMetadata>>
>;

export type DeleteObjectMetadataMutationError = ApiError;

/**
 * @summary DELETE metadata: delete an existing metadata by objectType and objectId.
 */
export const useDeleteObjectMetadata = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteObjectMetadata>>,
        TError,
        { objectType: string; objectId: string },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getDeleteObjectMetadataMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Returns all associated metadata values for a key if the current user has permissions to access it. Returns 404 (Not Found) if current user does not have permissions to access the object, or object does not exist.
 * @summary GET metadata values for a certain key in an org.
 */
export const getMetadataValues = (
    params: GetMetadataValuesParams,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<ObjectMetadataFieldsJson>(
        { url: `/api/v1/metadata/values`, method: "get", params, signal },
        options
    );
};

export const getGetMetadataValuesQueryKey = (params: GetMetadataValuesParams) =>
    [`/api/v1/metadata/values`, ...(params ? [params] : [])] as const;

export const getGetMetadataValuesQueryOptions = <
    TData = Awaited<ReturnType<typeof getMetadataValues>>,
    TError = ApiError
>(
    params: GetMetadataValuesParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getMetadataValues>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getMetadataValues>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetMetadataValuesQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMetadataValues>>> = ({ signal }) =>
        getMetadataValues(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetMetadataValuesQueryResult = NonNullable<
    Awaited<ReturnType<typeof getMetadataValues>>
>;
export type GetMetadataValuesQueryError = ApiError;

/**
 * @summary GET metadata values for a certain key in an org.
 */
export const useGetMetadataValues = <
    TData = Awaited<ReturnType<typeof getMetadataValues>>,
    TError = ApiError
>(
    params: GetMetadataValuesParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getMetadataValues>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetMetadataValuesQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Returns all metadata keys if the current user has permissions to access it. Returns 404 (Not Found) if current user does not have permissions to access the object, or object does not exist.
 * @summary GET metadata keys.
 */
export const getMetadataKeys = (
    params: GetMetadataKeysParams,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<ObjectMetadataFieldsJson>(
        { url: `/api/v1/metadata/keys`, method: "get", params, signal },
        options
    );
};

export const getGetMetadataKeysQueryKey = (params: GetMetadataKeysParams) =>
    [`/api/v1/metadata/keys`, ...(params ? [params] : [])] as const;

export const getGetMetadataKeysQueryOptions = <
    TData = Awaited<ReturnType<typeof getMetadataKeys>>,
    TError = ApiError
>(
    params: GetMetadataKeysParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getMetadataKeys>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getMetadataKeys>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetMetadataKeysQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMetadataKeys>>> = ({ signal }) =>
        getMetadataKeys(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetMetadataKeysQueryResult = NonNullable<Awaited<ReturnType<typeof getMetadataKeys>>>;
export type GetMetadataKeysQueryError = ApiError;

/**
 * @summary GET metadata keys.
 */
export const useGetMetadataKeys = <
    TData = Awaited<ReturnType<typeof getMetadataKeys>>,
    TError = ApiError
>(
    params: GetMetadataKeysParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getMetadataKeys>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetMetadataKeysQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};
