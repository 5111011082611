/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Hive Service REST API
 * OpenAPI spec version: v1
 */
import { useQuery } from "react-query";
import type { UseQueryOptions, QueryFunction, UseQueryResult, QueryKey } from "react-query";
import type {
    DeviceNetworkAssignmentsJson,
    ApiError,
    GetNetworkAssignmentsParams,
    OrganizationIdJson,
} from "../models";
import { apiHandler } from "../../../../resources/apiHandler";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
    config: any,
    args: infer P
) => any
    ? P
    : never;

/**
 * Get network assignments (based of the values of the swarm:network metadata) for groundstations and satellites in the Swarm network.
 * @summary GET groundstation/satellite network assignments
 */
export const getNetworkAssignments = (
    params?: GetNetworkAssignmentsParams,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<DeviceNetworkAssignmentsJson>(
        { url: `/hive/api/v1/network`, method: "get", params, signal },
        options
    );
};

export const getGetNetworkAssignmentsQueryKey = (params?: GetNetworkAssignmentsParams) =>
    [`/hive/api/v1/network`, ...(params ? [params] : [])] as const;

export const getGetNetworkAssignmentsQueryOptions = <
    TData = Awaited<ReturnType<typeof getNetworkAssignments>>,
    TError = ApiError
>(
    params?: GetNetworkAssignmentsParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getNetworkAssignments>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getNetworkAssignments>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetNetworkAssignmentsQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getNetworkAssignments>>> = ({
        signal,
    }) => getNetworkAssignments(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetNetworkAssignmentsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getNetworkAssignments>>
>;
export type GetNetworkAssignmentsQueryError = ApiError;

/**
 * @summary GET groundstation/satellite network assignments
 */
export const useGetNetworkAssignments = <
    TData = Awaited<ReturnType<typeof getNetworkAssignments>>,
    TError = ApiError
>(
    params?: GetNetworkAssignmentsParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getNetworkAssignments>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetNetworkAssignmentsQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Swarm-owned production satellites and groundstations should be in this org id
 * @summary GET org id used for production groundstation/satellites
 */
export const getProductionOrgId = (
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<OrganizationIdJson>(
        { url: `/hive/api/v1/network/org`, method: "get", signal },
        options
    );
};

export const getGetProductionOrgIdQueryKey = () => [`/hive/api/v1/network/org`] as const;

export const getGetProductionOrgIdQueryOptions = <
    TData = Awaited<ReturnType<typeof getProductionOrgId>>,
    TError = ApiError
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProductionOrgId>>, TError, TData>;
    request?: SecondParameter<typeof apiHandler>;
}): UseQueryOptions<Awaited<ReturnType<typeof getProductionOrgId>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetProductionOrgIdQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductionOrgId>>> = ({ signal }) =>
        getProductionOrgId(requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetProductionOrgIdQueryResult = NonNullable<
    Awaited<ReturnType<typeof getProductionOrgId>>
>;
export type GetProductionOrgIdQueryError = ApiError;

/**
 * @summary GET org id used for production groundstation/satellites
 */
export const useGetProductionOrgId = <
    TData = Awaited<ReturnType<typeof getProductionOrgId>>,
    TError = ApiError
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProductionOrgId>>, TError, TData>;
    request?: SecondParameter<typeof apiHandler>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetProductionOrgIdQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};
