export enum DeviceType {
    /** @warning in most cases this is a client-side only concept. For example filters do not respect this */
    ANY = 0,
    FIELD = 1,
    STRATO = 2,
    SATELLITE = 3,
    GROUND_STATION = 4,
    HIVE = 5,
    UNKNOWN_1 = 6,
    UNKNOWN_2 = 7,
}
