import { FilterJsonOperator, PolygonAllOf, ReportJson } from "handlers/generated/telemetry";

export const MIN_FILTERS = 1;
export const MAX_FILTERS = 4;

export enum ReportType {
    ActivityLog = 1,
    AssetDormancy = 2,
    Custom = 100,
}

export enum DisplayType {
    Table = 1,
    Map = 2,
    /** Displays a fixed number for dashboards */
    // Metric = "Metric",
    // Line = "Line",
    // Bar = "Bar",
    // Pie = "Pie",
}

export type OperatorOption = {
    value: FilterJsonOperator;
    shortLabel: string;
    label: string;
};

export interface IClientSideFilter {
    /** client side only id, needed so we can mutate the correct records (indexs are not stable) */
    id: string;
    key: string;
    operator: FilterJsonOperator;
    value?: string[];
    geometry?: PolygonAllOf;
}

export interface IStepClientSide {
    name: string;
    filters: IClientSideFilter[];
}

export interface IReportJsonClientSide extends Omit<ReportJson, "steps"> {
    steps: IStepClientSide[];
}

/**
 * datetime or a relative date from now
 * @see https://grafana.com/docs/grafana/latest/dashboards/time-range-controls/
 *
 * @example
 * exact date time
 * 2022-06-29T18:50:49
 *
 * relative date time
 * now-30m = current time - 30 minutes
 * now-12h = current time - 12 hours
 * now-7d = current time - 7 days
 * now-2y = current time - 2 years
 */

export const TELEMETRY_SWARM_ORGANIZATION_ID = "swarm:organization:id";
export const TELEMETRY_SWARM_DEVICE_ID = "swarm:device:id";
export const TELEMETRY_SWARM_LOCATION = "swarm:location";

// note: this metadata key is returned to ALL customer types
export const SWARM_DEVICE_KIT = "swarm:device:kit";

/** Possible values for SWARM_DEVICE_KIT
 * note: not comprehensive
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export enum SWARM_DEVICE_KIT_VALUES {
    asset_tracker = "asset_tracker",
    bee = "bee",
    sosbee = "sosbee",
    tile = "tile",
    tile_only = "tile_only",
    modem = "modem",
    modem_only = "modem_only",
    demo_modem = "demo_modem",
    eval01 = "eval01",
    eval03 = "eval03",
    demo_eval = "demo_eval",
    other = "other",
}

// note: this searches on the device type enum (field device, groundstation, spacebee, etc)
export const TELEMETRY_SWARM_DEVICE_TYPE = "swarm:device:type";

export const NEW_REPORT_ID = 0;

/** Operators that are valid only when filter is a location type */
export const locationOperators: FilterJsonOperator[] = [
    FilterJsonOperator.IsInside,
    FilterJsonOperator.IsOutside,
];
