/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Hive Service REST API
 * OpenAPI spec version: v1
 */
import { useQuery, useMutation } from "react-query";
import type {
    UseQueryOptions,
    UseMutationOptions,
    QueryFunction,
    MutationFunction,
    UseQueryResult,
    QueryKey,
} from "react-query";
import type { Organization, ApiError, StatusJson, OrganizationUpdateJson } from "../models";
import { apiHandler } from "../../../../resources/apiHandler";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
    config: any,
    args: infer P
) => any
    ? P
    : never;

/**
 * @summary GET all organizations
 */
export const getAllOrganizations = (
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<Organization[]>(
        { url: `/hive/api/v1/organizations`, method: "get", signal },
        options
    );
};

export const getGetAllOrganizationsQueryKey = () => [`/hive/api/v1/organizations`] as const;

export const getGetAllOrganizationsQueryOptions = <
    TData = Awaited<ReturnType<typeof getAllOrganizations>>,
    TError = ApiError
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAllOrganizations>>, TError, TData>;
    request?: SecondParameter<typeof apiHandler>;
}): UseQueryOptions<Awaited<ReturnType<typeof getAllOrganizations>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetAllOrganizationsQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllOrganizations>>> = ({ signal }) =>
        getAllOrganizations(requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetAllOrganizationsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getAllOrganizations>>
>;
export type GetAllOrganizationsQueryError = ApiError;

/**
 * @summary GET all organizations
 */
export const useGetAllOrganizations = <
    TData = Awaited<ReturnType<typeof getAllOrganizations>>,
    TError = ApiError
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAllOrganizations>>, TError, TData>;
    request?: SecondParameter<typeof apiHandler>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetAllOrganizationsQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Create a new organization. If the organizationId is left null, a new organizationId will be generated.
 * @summary Create organization
 */
export const createOrganization = (
    organization: Organization,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<Organization>(
        {
            url: `/hive/api/v1/organizations`,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: organization,
        },
        options
    );
};

export const getCreateOrganizationMutationOptions = <
    TError = ApiError,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof createOrganization>>,
        TError,
        { data: Organization },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof createOrganization>>,
    TError,
    { data: Organization },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof createOrganization>>,
        { data: Organization }
    > = (props) => {
        const { data } = props ?? {};

        return createOrganization(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type CreateOrganizationMutationResult = NonNullable<
    Awaited<ReturnType<typeof createOrganization>>
>;
export type CreateOrganizationMutationBody = Organization;
export type CreateOrganizationMutationError = ApiError;

/**
 * @summary Create organization
 */
export const useCreateOrganization = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof createOrganization>>,
        TError,
        { data: Organization },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getCreateOrganizationMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary GET organization by id
 */
export const getOrganizationById = (
    orgId: number,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<Organization>(
        { url: `/hive/api/v1/organizations/${orgId}`, method: "get", signal },
        options
    );
};

export const getGetOrganizationByIdQueryKey = (orgId: number) =>
    [`/hive/api/v1/organizations/${orgId}`] as const;

export const getGetOrganizationByIdQueryOptions = <
    TData = Awaited<ReturnType<typeof getOrganizationById>>,
    TError = ApiError
>(
    orgId: number,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getOrganizationById>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getOrganizationById>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetOrganizationByIdQueryKey(orgId);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganizationById>>> = ({ signal }) =>
        getOrganizationById(orgId, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!orgId, ...queryOptions };
};

export type GetOrganizationByIdQueryResult = NonNullable<
    Awaited<ReturnType<typeof getOrganizationById>>
>;
export type GetOrganizationByIdQueryError = ApiError;

/**
 * @summary GET organization by id
 */
export const useGetOrganizationById = <
    TData = Awaited<ReturnType<typeof getOrganizationById>>,
    TError = ApiError
>(
    orgId: number,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getOrganizationById>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetOrganizationByIdQueryOptions(orgId, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Delete an organization by id.
 * @summary DELETE organization
 */
export const deleteOrganization = (orgId: number, options?: SecondParameter<typeof apiHandler>) => {
    return apiHandler<StatusJson>(
        { url: `/hive/api/v1/organizations/${orgId}`, method: "delete" },
        options
    );
};

export const getDeleteOrganizationMutationOptions = <
    TError = ApiError,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteOrganization>>,
        TError,
        { orgId: number },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof deleteOrganization>>,
    TError,
    { orgId: number },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deleteOrganization>>,
        { orgId: number }
    > = (props) => {
        const { orgId } = props ?? {};

        return deleteOrganization(orgId, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DeleteOrganizationMutationResult = NonNullable<
    Awaited<ReturnType<typeof deleteOrganization>>
>;

export type DeleteOrganizationMutationError = ApiError;

/**
 * @summary DELETE organization
 */
export const useDeleteOrganization = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteOrganization>>,
        TError,
        { orgId: number },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getDeleteOrganizationMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Edit an organization. Only the provided properties will be changed.
 * @summary PATCH organization
 */
export const patchOrganization = (
    orgId: number,
    organizationUpdateJson: OrganizationUpdateJson,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<Organization>(
        {
            url: `/hive/api/v1/organizations/${orgId}`,
            method: "patch",
            headers: { "Content-Type": "application/json" },
            data: organizationUpdateJson,
        },
        options
    );
};

export const getPatchOrganizationMutationOptions = <
    TError = ApiError,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchOrganization>>,
        TError,
        { orgId: number; data: OrganizationUpdateJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof patchOrganization>>,
    TError,
    { orgId: number; data: OrganizationUpdateJson },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof patchOrganization>>,
        { orgId: number; data: OrganizationUpdateJson }
    > = (props) => {
        const { orgId, data } = props ?? {};

        return patchOrganization(orgId, data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type PatchOrganizationMutationResult = NonNullable<
    Awaited<ReturnType<typeof patchOrganization>>
>;
export type PatchOrganizationMutationBody = OrganizationUpdateJson;
export type PatchOrganizationMutationError = ApiError;

/**
 * @summary PATCH organization
 */
export const usePatchOrganization = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchOrganization>>,
        TError,
        { orgId: number; data: OrganizationUpdateJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getPatchOrganizationMutationOptions(options);

    return useMutation(mutationOptions);
};
