/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Billing Service REST API
 * OpenAPI spec version: v1
 */
import { useQuery, useMutation } from "react-query";
import type {
    UseQueryOptions,
    UseMutationOptions,
    QueryFunction,
    MutationFunction,
    UseQueryResult,
    QueryKey,
} from "react-query";
import type {
    DueDevicesReportJson,
    ApiError,
    DueDevicesReportRequestJson,
    BillingSummaryReportJson,
    GetBillingSummaryParams,
} from "../models";
import { apiHandler } from "../../../../resources/apiHandler";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
    config: any,
    args: infer P
) => any
    ? P
    : never;

/**
 * Run a report on all devices due on the given date. Returns organization, device, due (paid through) date, billing plan type, latest message date, activation date, and whether the data plan is a renewal.
 * @summary POST run report on devices due
 */
export const getDueDevicesReport = (
    dueDevicesReportRequestJson: DueDevicesReportRequestJson,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<DueDevicesReportJson>(
        {
            url: `/api/v1/billing/report/due`,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: dueDevicesReportRequestJson,
        },
        options
    );
};

export const getGetDueDevicesReportMutationOptions = <
    TError = ApiError,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof getDueDevicesReport>>,
        TError,
        { data: DueDevicesReportRequestJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof getDueDevicesReport>>,
    TError,
    { data: DueDevicesReportRequestJson },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof getDueDevicesReport>>,
        { data: DueDevicesReportRequestJson }
    > = (props) => {
        const { data } = props ?? {};

        return getDueDevicesReport(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type GetDueDevicesReportMutationResult = NonNullable<
    Awaited<ReturnType<typeof getDueDevicesReport>>
>;
export type GetDueDevicesReportMutationBody = DueDevicesReportRequestJson;
export type GetDueDevicesReportMutationError = ApiError;

/**
 * @summary POST run report on devices due
 */
export const useGetDueDevicesReport = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof getDueDevicesReport>>,
        TError,
        { data: DueDevicesReportRequestJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getGetDueDevicesReportMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Get number of devices per org and billing status, split by `live` = 1 message within timespan vs. `dormant` = no messages within timespan.
 * @summary GET overall billing summary
 */
export const getBillingSummary = (
    params: GetBillingSummaryParams,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<BillingSummaryReportJson>(
        { url: `/api/v1/billing/report/summary`, method: "get", params, signal },
        options
    );
};

export const getGetBillingSummaryQueryKey = (params: GetBillingSummaryParams) =>
    [`/api/v1/billing/report/summary`, ...(params ? [params] : [])] as const;

export const getGetBillingSummaryQueryOptions = <
    TData = Awaited<ReturnType<typeof getBillingSummary>>,
    TError = ApiError
>(
    params: GetBillingSummaryParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getBillingSummary>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getBillingSummary>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetBillingSummaryQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getBillingSummary>>> = ({ signal }) =>
        getBillingSummary(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetBillingSummaryQueryResult = NonNullable<
    Awaited<ReturnType<typeof getBillingSummary>>
>;
export type GetBillingSummaryQueryError = ApiError;

/**
 * @summary GET overall billing summary
 */
export const useGetBillingSummary = <
    TData = Awaited<ReturnType<typeof getBillingSummary>>,
    TError = ApiError
>(
    params: GetBillingSummaryParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getBillingSummary>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetBillingSummaryQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};
