import { Button, IButtonProps } from "@blueprintjs/core";
import { Placement, Tooltip2 } from "@blueprintjs/popover2";
import React, { useCallback, useEffect, useState } from "react";
import { FaPaste } from "react-icons/fa";

const TOOLTIP_TIMEOUT = 5000;

interface Props extends Omit<IButtonProps, "onClick"> {
    data: string;
    tooltipPlacement?: Placement;
}

const ClipboardButton: React.FunctionComponent<Props> = ({
    data,
    tooltipPlacement,
    ...buttonProps
}) => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const [copySucceeded, setCopySucceeded] = useState<boolean>(true);

    const copyDataToClipboard = useCallback(() => {
        if (navigator.clipboard == null) {
            // No support or no permission
            setCopySucceeded(false);
            setShowTooltip(true);
        } else {
            navigator.clipboard
                .writeText(data)
                .then(() => setCopySucceeded(true))
                .catch(() => setCopySucceeded(false))
                .finally(() => {
                    setShowTooltip(true);
                });
        }
    }, [data]);

    // Clear the tooltip automatically after some time, cancel if component is unmounted
    useEffect(() => {
        if (showTooltip) {
            const timeout = setTimeout(() => setShowTooltip(false), TOOLTIP_TIMEOUT);
            return () => clearTimeout(timeout);
        }
        return undefined;
    }, [showTooltip]);

    return (
        <Tooltip2
            content={copySucceeded ? "Copied!" : "Copy Failed"}
            intent={copySucceeded ? "success" : "danger"}
            isOpen={showTooltip}
            placement={tooltipPlacement}
        >
            <Button
                icon={<FaPaste />}
                title="Copy to clipboard"
                onClick={copyDataToClipboard}
                {...buttonProps}
            />
        </Tooltip2>
    );
};

export default ClipboardButton;
