import { getDeviceLogoSize } from "components/DeviceMap/DeviceLogo";
import { Styles } from "react-jss";
import variables from "./variables";

const DEVICE_ICON_SIZE = 16;

export const common = {
    col: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
        justifyContent: "flex-start",
    },
    colMaxHeight: {
        height: "calc(100vh - 20px)",
    },
    formError: {
        color: variables.intentDanger,
        fontWeight: "bold",
        // Some error messages have long java FQDNs in them
        wordBreak: "break-all",
    },
    flexWithSpacing: {
        display: "flex",
        flexWrap: "wrap",
        gap: variables.gridSize,
    },
    grow: {
        flexGrow: 1,
    },
    pageHeader: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
    },
    noGrow: {
        flexGrow: 0,
    },
    scrollPane: {
        composes: "$col",
        overflowY: "auto",
        paddingLeft: variables.gridSize,
    },
    textarea: {
        resize: "vertical",
    },
    transparent: {
        background: "transparent",
    },
    elevationNone: {
        boxShadow: "none",
    },
    section: {
        marginTop: `calc(2 * ${variables.gridSize})`,
    },
    tableWithIcons: {
        "& tr td": {
            lineHeight: `${getDeviceLogoSize(DEVICE_ICON_SIZE)}px`,
        },
    },
} as const;

interface Props {
    main?: {
        padding?: string | number;
    };
}

export const appLayout: Readonly<Styles<string, Props>> = {
    app: {
        display: "flex",
        flexGrow: 1,
        minHeight: 0,
    },
    aside: {
        display: "flex",
        flexGrow: 0,
        flexShrink: 0,
    },
    main: {
        display: "flex",
        flexGrow: 1,
        flexShrink: 1,
        padding: (props: Props): string | number => props?.main?.padding ?? variables.gridSize,
    },
} as const;
