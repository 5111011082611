import React from "react";
import { currencyDisplay } from "utils";

interface ICurrencyDisplayProps {
    amount: number;

    /** @default 2 */
    minDigits?: number;

    /** @default 2 */
    maxDigits?: number;
}

export const CurrencyDisplay: React.FunctionComponent<ICurrencyDisplayProps> = (props) => {
    return (
        <>
            {currencyDisplay({
                amount: props.amount,
                minDigits: props.minDigits,
                maxDigits: props.maxDigits,
            })}
        </>
    );
};
