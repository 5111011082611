import { Organization } from "handlers/generated/hive";
import Activate from "pages/RegisterDevice";
import React from "react";

interface IOrganizationRegisterDevicesPanelProps {
    organizationData: Organization;
}

export const OrganizationRegisterDevicesPanel: React.FunctionComponent<
    IOrganizationRegisterDevicesPanelProps
> = ({ organizationData }) => {
    return <Activate organizationId={organizationData.organizationId} />;
};
