/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import axios, { AxiosRequestConfig } from "axios";
import moment from "moment-timezone";

/** @deprecated use generated handlers & hooks instead */
export const apiHandlerLegacy = axios.create({
    // baseURL: window.HIVE_CONFIG.baseApiUrl, // currently we can't use this because hive services have a /hive prefix
    timeout: 30000,
    headers: {
        Accept: "application/json",
        "Content-type": "application/json",
    },
});

/** @deprecated use generated handlers to get query keys instead */
export enum QueryKey {
    Devices = "Devices",
    DeviceTelemetry = "DeviceTelemetry",
    DeviceTelemetryPublic = "DeviceTelemetryPublic",
    Messages = "Messages",
    MessageDetails = "MessageDetails",
    DeviceBillingList = "DeviceBillingList",
    PaymentSetup = "PaymentSetup",
    PaymentMethods = "PaymentMethods",
    Featureflags = "Featureflags",
    Invoices = "Invoices",
    Metadata = "Metadata",
    PacketsList = "PacketsList",
    DecodePacket = "DecodePacket",
    RouterDataGenLatest = "RouterDataGenLatest",
}

/** -------------------------------------------------------------- */
/** @see https://orval.dev/reference/configuration/output#usedates */
/** -------------------------------------------------------------- */

const client = axios.create({
    // baseURL: window.HIVE_CONFIG.baseApiUrl, // currently we can't use this because hive services have a /hive prefix
    timeout: 30000,
    headers: {
        Accept: "application/json",
        "Content-type": "application/json",
    },
});

client.interceptors.response.use((originalResponse) => {
    handleDates(originalResponse.data);
    return originalResponse;
});

export const apiHandler = <T>(
    config: AxiosRequestConfig,
    options?: AxiosRequestConfig
): Promise<T> => {
    return client({ ...config, ...options }).then(({ data }) => data);
};

export default apiHandler;

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

function isIsoDateString(value: any): boolean {
    return value && typeof value === "string" && isoDateFormat.test(value);
}

export function handleDates(body: any) {
    if (body === null || body === undefined || typeof body !== "object") return body;

    for (const key of Object.keys(body)) {
        const value = body[key];
        if (isIsoDateString(value)) {
            body[key] = moment.utc(value).toDate();
            // body[key] = new Date(value); // default JS conversion
            // body[key] = parseISO(value); // date-fns conversion
            // body[key] = luxon.DateTime.fromISO(value); // Luxon conversion
            // body[key] = moment(value).toDate(); // Moment.js conversion
        } else if (typeof value === "object") {
            handleDates(value);
        }
    }
}
