/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Telemetry Service REST API
 * OpenAPI spec version: v1
 */
import { useQuery, useMutation } from "react-query";
import type {
    UseQueryOptions,
    UseMutationOptions,
    QueryFunction,
    MutationFunction,
    UseQueryResult,
    QueryKey,
} from "react-query";
import type {
    ReportJson,
    ApiError,
    GetReportParams,
    StatusJson,
    ReportListJson,
    GetReportsParams,
    CreateReportJson,
    ReportResultJson,
} from "../models";
import { apiHandler } from "../../../../resources/apiHandler";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
    config: any,
    args: infer P
) => any
    ? P
    : never;

/**
 * Returns report if the current user has permissions to access it. Returns 404 (Not Found) if current user does not have permissions to access the report, or report does not exist.
 * @summary GET report.
 */
export const getReport = (
    reportId: number,
    params?: GetReportParams,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<ReportJson>(
        { url: `/api/v1/telemetry/reports/${reportId}`, method: "get", params, signal },
        options
    );
};

export const getGetReportQueryKey = (reportId: number, params?: GetReportParams) =>
    [`/api/v1/telemetry/reports/${reportId}`, ...(params ? [params] : [])] as const;

export const getGetReportQueryOptions = <
    TData = Awaited<ReturnType<typeof getReport>>,
    TError = ApiError
>(
    reportId: number,
    params?: GetReportParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getReport>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getReport>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetReportQueryKey(reportId, params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getReport>>> = ({ signal }) =>
        getReport(reportId, params, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!reportId, ...queryOptions };
};

export type GetReportQueryResult = NonNullable<Awaited<ReturnType<typeof getReport>>>;
export type GetReportQueryError = ApiError;

/**
 * @summary GET report.
 */
export const useGetReport = <TData = Awaited<ReturnType<typeof getReport>>, TError = ApiError>(
    reportId: number,
    params?: GetReportParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getReport>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetReportQueryOptions(reportId, params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Update a existing report
 * @summary Update report
 */
export const updateReport = (
    reportId: number,
    reportJson: ReportJson,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<ReportJson>(
        {
            url: `/api/v1/telemetry/reports/${reportId}`,
            method: "put",
            headers: { "Content-Type": "application/json" },
            data: reportJson,
        },
        options
    );
};

export const getUpdateReportMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof updateReport>>,
        TError,
        { reportId: number; data: ReportJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof updateReport>>,
    TError,
    { reportId: number; data: ReportJson },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof updateReport>>,
        { reportId: number; data: ReportJson }
    > = (props) => {
        const { reportId, data } = props ?? {};

        return updateReport(reportId, data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type UpdateReportMutationResult = NonNullable<Awaited<ReturnType<typeof updateReport>>>;
export type UpdateReportMutationBody = ReportJson;
export type UpdateReportMutationError = ApiError;

/**
 * @summary Update report
 */
export const useUpdateReport = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof updateReport>>,
        TError,
        { reportId: number; data: ReportJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getUpdateReportMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Delete a existing report
 * @summary Delete report
 */
export const deleteReport = (reportId: number, options?: SecondParameter<typeof apiHandler>) => {
    return apiHandler<StatusJson>(
        { url: `/api/v1/telemetry/reports/${reportId}`, method: "delete" },
        options
    );
};

export const getDeleteReportMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteReport>>,
        TError,
        { reportId: number },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof deleteReport>>,
    TError,
    { reportId: number },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deleteReport>>,
        { reportId: number }
    > = (props) => {
        const { reportId } = props ?? {};

        return deleteReport(reportId, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DeleteReportMutationResult = NonNullable<Awaited<ReturnType<typeof deleteReport>>>;

export type DeleteReportMutationError = ApiError;

/**
 * @summary Delete report
 */
export const useDeleteReport = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteReport>>,
        TError,
        { reportId: number },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getDeleteReportMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Returns reports the current user has permissions to access it.
 * @summary GET reports.
 */
export const getReports = (
    params?: GetReportsParams,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<ReportListJson>(
        { url: `/api/v1/telemetry/reports`, method: "get", params, signal },
        options
    );
};

export const getGetReportsQueryKey = (params?: GetReportsParams) =>
    [`/api/v1/telemetry/reports`, ...(params ? [params] : [])] as const;

export const getGetReportsQueryOptions = <
    TData = Awaited<ReturnType<typeof getReports>>,
    TError = ApiError
>(
    params?: GetReportsParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getReports>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getReports>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetReportsQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getReports>>> = ({ signal }) =>
        getReports(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetReportsQueryResult = NonNullable<Awaited<ReturnType<typeof getReports>>>;
export type GetReportsQueryError = ApiError;

/**
 * @summary GET reports.
 */
export const useGetReports = <TData = Awaited<ReturnType<typeof getReports>>, TError = ApiError>(
    params?: GetReportsParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getReports>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetReportsQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Add a new report
 * @summary Create report
 */
export const createReport = (
    createReportJson: CreateReportJson,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<ReportJson>(
        {
            url: `/api/v1/telemetry/reports`,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: createReportJson,
        },
        options
    );
};

export const getCreateReportMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof createReport>>,
        TError,
        { data: CreateReportJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof createReport>>,
    TError,
    { data: CreateReportJson },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof createReport>>,
        { data: CreateReportJson }
    > = (props) => {
        const { data } = props ?? {};

        return createReport(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type CreateReportMutationResult = NonNullable<Awaited<ReturnType<typeof createReport>>>;
export type CreateReportMutationBody = CreateReportJson;
export type CreateReportMutationError = ApiError;

/**
 * @summary Create report
 */
export const useCreateReport = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof createReport>>,
        TError,
        { data: CreateReportJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getCreateReportMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Run a report
 * @summary Run report
 */
export const runReport = (reportJson: ReportJson, options?: SecondParameter<typeof apiHandler>) => {
    return apiHandler<ReportResultJson>(
        {
            url: `/api/v1/telemetry/reports/run`,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: reportJson,
        },
        options
    );
};

export const getRunReportMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof runReport>>,
        TError,
        { data: ReportJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof runReport>>,
    TError,
    { data: ReportJson },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof runReport>>,
        { data: ReportJson }
    > = (props) => {
        const { data } = props ?? {};

        return runReport(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type RunReportMutationResult = NonNullable<Awaited<ReturnType<typeof runReport>>>;
export type RunReportMutationBody = ReportJson;
export type RunReportMutationError = ApiError;

/**
 * @summary Run report
 */
export const useRunReport = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof runReport>>,
        TError,
        { data: ReportJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getRunReportMutationOptions(options);

    return useMutation(mutationOptions);
};
