import { Classes, MenuItem } from "@blueprintjs/core";
import { ItemRenderer, Suggest2, Suggest2Props } from "@blueprintjs/select";
import { IDeviceFormValues } from "components/InternalDeviceDetail/InternalDeviceEditor.types";
import {
    getMetadataFieldAlias,
    METADATA_DEVICE_TYPE_KEY,
} from "components/Metadata/Metadata.utils";
import { useGetMetadataKeys } from "handlers/generated/metadata";
import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

type SelectProps = Omit<Suggest2Props<string>, "itemRenderer" | "inputValueRenderer" | "items">;
interface IMetadataKeySelectProps extends SelectProps {
    displayText: string;
    allowSwarmPrefix: boolean;
    disabledValues: (string | undefined)[];
}

const renderItem: ItemRenderer<string> = (val, itemProps) => {
    return val === METADATA_DEVICE_TYPE_KEY ? null : (
        <MenuItem
            key={val}
            text={val}
            active={itemProps.modifiers.active}
            disabled={itemProps.modifiers.disabled}
            onClick={itemProps.handleClick}
        />
    );
};

function renderNewItem(
    query: string,
    active: boolean,
    handleClick: React.MouseEventHandler<HTMLElement>
) {
    return (
        <MenuItem icon="add" key={query} text={`Create "${query}"`} onClick={handleClick} active />
    );
}

function search(query: string | null, key: string | null) {
    if (query === null || key === null) {
        // query/key are null when no items are loaded yet
        return false;
    }

    return key.toLocaleLowerCase().indexOf(query.toLowerCase()) >= 0;
}

export const MetadataKeySelect: React.FunctionComponent<IMetadataKeySelectProps> = ({
    disabled,
    allowSwarmPrefix,
    disabledValues,
    displayText,
    ...otherProps
}) => {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { getValues } = useFormContext<IDeviceFormValues>();
    const [userHasFocused, setUserHasFocused] = useState<boolean>(false);

    const { data: keyData } = useGetMetadataKeys(
        {
            organizationId: getValues("organizationId"),
            objectType: "device",
        },
        { query: { enabled: !disabled && userHasFocused } }
    );

    const keys = useMemo(() => {
        if (keyData === undefined || keyData.fields === undefined) {
            return [];
        }

        if (allowSwarmPrefix) {
            return keyData.fields;
        }

        return keyData.fields.filter((key) => key.indexOf("swarm:") === -1);
    }, [keyData, allowSwarmPrefix]);

    return (
        <div
            style={{
                display: "flex-box",
                width: "90%",
            }}
        >
            {disabled ? (
                <div className={Classes.LABEL}>{getMetadataFieldAlias(displayText)}</div>
            ) : (
                <Suggest2<string>
                    items={keys}
                    selectedItem={displayText}
                    createNewItemFromQuery={(query) => query}
                    createNewItemRenderer={renderNewItem}
                    inputValueRenderer={(input) => input}
                    itemRenderer={renderItem}
                    itemPredicate={search}
                    itemDisabled={(key) => disabledValues.includes(key)}
                    inputProps={{ placeholder: "Field Name" }}
                    noResults={
                        <MenuItem
                            disabled
                            text="No suggested field names yet"
                            roleStructure="listoption"
                        />
                    }
                    popoverProps={{
                        onOpening: () => {
                            setUserHasFocused(true);
                        },
                    }}
                    {...otherProps}
                />
            )}
        </div>
    );
};
