/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Telemetry Service REST API
 * OpenAPI spec version: v1
 */

export type FilterJsonOperator = typeof FilterJsonOperator[keyof typeof FilterJsonOperator];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FilterJsonOperator = {
    Is: "Is",
    IsNot: "IsNot",
    IsInside: "IsInside",
    IsOutside: "IsOutside",
} as const;
