import { Button, FormGroup } from "@blueprintjs/core";
import {
    IDeviceFormValues,
    IDeviceFormValuesMetadata,
} from "components/InternalDeviceDetail/InternalDeviceEditor.types";
import {
    METADATA_DEVICE_TYPE_KEY,
    MetadataDeviceType,
    doesNotStartWithSwarmPrefixRegex,
    stringAsMetadataDeviceType,
} from "components/Metadata/Metadata.utils";
import { MetadataFieldDeviceType } from "components/Metadata/MetadataField/MetadataFieldDeviceType";
import { MetadataKeySelect } from "components/Metadata/MetadataKeySelect";
import {
    IMetadataValueSelectProps,
    MetadataValueSelect,
} from "components/Metadata/MetadataValueSelect";
import { SXCard } from "components/SXCard";
import React from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    minimalInputGroup: {
        marginBottom: 3,
    },
    minimalInput: {
        "& input": {
            border: "none",
            boxShadow: "none",
            maxWidth: 200,
            padding: 0,
        },
    },
    minimalInputDanger: {
        "& input": {
            boxShadow: "inherit",
            padding: "0 4px",
        },
    },
});

export interface IMetadataProps {
    editing: boolean;
    allowSwarmPrefix: boolean;
}

const MAX_TAG_COUNT = 150;
const MAX_TAG_KEY_LENGTH = 128;
const MAX_TAG_VALUE_LENGTH = 256;

const MetadataField: React.FunctionComponent<IMetadataValueSelectProps & { fieldKey: string }> = ({
    fieldKey,
    ...props
}) => {
    if (fieldKey === METADATA_DEVICE_TYPE_KEY) {
        return (
            <MetadataFieldDeviceType
                onChange={props.onItemSelect as unknown as (item: MetadataDeviceType) => void}
                value={stringAsMetadataDeviceType(props.displayText)}
                disabled={props.disabled}
            />
        );
    }
    return <MetadataValueSelect {...props} />;
};

export const Metadata: React.FunctionComponent<IMetadataProps> = ({
    editing,
    allowSwarmPrefix,
}) => {
    const classes = useStyles();
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { control, errors, setValue, getValues } = useFormContext<IDeviceFormValues>();

    const { fields, append, remove } = useFieldArray<IDeviceFormValuesMetadata>({
        control,
        name: "metadata", // unique name for your Field Array
    });

    const usedMetadataKeys = fields.flatMap((field) => field.key);

    const noMetadata = fields.length === 0;

    const formDisabled = !editing;

    return (
        <SXCard title="Metadata">
            {noMetadata && <p>This device has no metadata</p>}
            {fields
                .filter(
                    (field) =>
                        allowSwarmPrefix || field.key?.match(doesNotStartWithSwarmPrefixRegex)
                )
                .map((field, index) => {
                    const fieldKeyDisabled =
                        field.key !== undefined && [METADATA_DEVICE_TYPE_KEY].includes(field.key);

                    return (
                        <React.Fragment key={field.id}>
                            <FormGroup
                                helperText={errors.metadata && errors.metadata[index]?.key?.message}
                                intent={
                                    errors.metadata && errors.metadata[index]?.key
                                        ? "danger"
                                        : "none"
                                }
                                className={classes.minimalInputGroup}
                            >
                                <Controller
                                    control={control}
                                    name={`metadata.${index}.key`}
                                    defaultValue={field.key}
                                    rules={{
                                        required: "Field Name Required",
                                        minLength: 1,
                                        maxLength: {
                                            value: MAX_TAG_KEY_LENGTH,
                                            message: `Metadata names can have at max ${MAX_TAG_KEY_LENGTH} characters`,
                                        },
                                    }}
                                    render={({ onChange, value }) => (
                                        <MetadataKeySelect
                                            displayText={value as string}
                                            disabled={formDisabled || fieldKeyDisabled}
                                            allowSwarmPrefix={allowSwarmPrefix}
                                            disabledValues={usedMetadataKeys}
                                            onItemSelect={(item) => {
                                                // reset values
                                                setValue(`metadata.${index}.value`, undefined);
                                                onChange(item);
                                            }}
                                        />
                                    )}
                                />
                            </FormGroup>
                            <FormGroup
                                helperText={
                                    errors.metadata && errors.metadata[index]?.value?.message
                                }
                                intent={
                                    errors.metadata && errors.metadata[index]?.value
                                        ? "danger"
                                        : "none"
                                }
                            >
                                <Controller
                                    control={control}
                                    name={`metadata.${index}.value`}
                                    defaultValue={field.value}
                                    rules={{
                                        required: "Field Value Required",
                                        minLength: 1,
                                        maxLength: {
                                            value: MAX_TAG_VALUE_LENGTH,
                                            message: `Metadata values can have at max ${MAX_TAG_VALUE_LENGTH} characters`,
                                        },
                                    }}
                                    render={({ onChange, value }) => (
                                        <MetadataField
                                            fieldKey={field.key as string}
                                            index={index}
                                            onItemSelect={onChange}
                                            displayText={value as string}
                                            disabled={
                                                formDisabled ||
                                                getValues(`metadata.${index}.key`) === null
                                            }
                                            onDelete={remove}
                                        />
                                    )}
                                />
                            </FormGroup>
                        </React.Fragment>
                    );
                })}

            {fields.length < MAX_TAG_COUNT && editing && (
                <Button
                    type="button"
                    onClick={() => append({ key: "", value: "" })}
                    icon="add"
                    style={{ marginTop: 24 }}
                >
                    Add Metadata Field
                </Button>
            )}
        </SXCard>
    );
};
