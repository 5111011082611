import { Organization, OrganizationBillingType } from "handlers/generated/hive";

export const NEW_ORG_ID = -1;

export const DEFAULT_ORG: Organization = {
    organizationId: NEW_ORG_ID,
    name: "",
    billingType: OrganizationBillingType.AUTOMATICALLY_BILLED_AND_FINALIZED,
    billingEmail: undefined,
    stripeCustomerId: undefined,
};

export const BILLING_TYPES = [
    {
        value: OrganizationBillingType.AUTOMATICALLY_BILLED_MANUALLY_FINALIZED,
        label: "Automatically billed, manually finalized",
    },
    {
        value: OrganizationBillingType.AUTOMATICALLY_BILLED_AND_FINALIZED,
        label: "Automatically billed, automatically finalized",
    },
    {
        value: OrganizationBillingType.UNBILLED,
        label: "Unbilled organization (Unlimited devices free forever)",
    },
    {
        value: OrganizationBillingType.EXTERNALLY_BILLED,
        label: "Externally billed (don't automatically create invoices in Hive)",
    },
];
