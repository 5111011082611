import { Button, UL } from "@blueprintjs/core";
import ClipboardButton from "components/ClipboardButton";
import { DateDisplay } from "components/DateDisplay";
import { LatestTelemetry } from "components/InternalDeviceDetail/LatestTelemetry";
import { InternalSendMessageDialog } from "components/InternalSendMessageDialog";
import { SXCard } from "components/SXCard";
import { SXDrawer } from "components/SXDrawer";
import internalSitemap from "internalSitemap";
import { DeviceInfo } from "pages/Devices/DeviceInfo";
import React, { Suspense, useState } from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";
import { DeviceType } from "resources/DeviceResource.types";
import { formatDeviceTypeLong, IDeviceWithPaymentStatus } from "resources/DeviceResource.utils";
import { IMessagesRequest, useMessagesPaged } from "resources/MessageResource";
import { FilterStatus, MessageDirectionFilter } from "resources/MessageResource.types";
import variables from "styles/variables";
import InternalDeviceEditor from "./InternalDeviceEditor";

interface Props {
    // todo this used to be optional and defaulted to a loading state...
    device?: IDeviceWithPaymentStatus;

    /** pass if the device should appear within a drawer */
    onClose?: () => void;
}

const useStyles = createUseStyles({
    clipboardButton: {
        marginLeft: variables.gridSize,
    },
    noBullets: {
        listStyleType: "none",
        paddingLeft: 0,
        marginTop: 0,
    },
    drawerBody: {
        padding: 20,
        background: "#eee",
    },
});

const InternalDeviceDetail: React.FunctionComponent<Props> = ({ device, onClose }) => {
    const classes = useStyles();

    const [sendMessageDialogIsOpen, setSendMessageDialogIsOpen] = useState(false);

    const messageFilters: IMessagesRequest =
        device !== undefined
            ? {
                  devicetype: device.deviceType,
                  deviceid: device.deviceId.toString(),
                  organizationId: device.organizationId,
              }
            : {};

    const { data, hasNextPage } = useMessagesPaged(
        {
            direction: MessageDirectionFilter.ALL,
            status: FilterStatus.ALL,
            count: 5,
            ...messageFilters,
        },
        { enabled: device !== undefined }
    );

    const allMessages = data?.pages.flat() ?? [];

    const content = (
        <>
            {device !== undefined && (
                <>
                    <InternalDeviceEditor device={device} />
                    <DeviceInfo device={device} />
                    {device.deviceType === DeviceType.FIELD && device.authCode != null && (
                        <SXCard title="Auth Code">
                            <span>{device.authCode}</span>
                            <ClipboardButton
                                data={device.authCode}
                                minimal
                                tooltipPlacement="bottom"
                                className={classes.clipboardButton}
                            />
                        </SXCard>
                    )}
                    <LatestTelemetry device={device} />
                    {data !== undefined && (
                        <SXCard
                            title={
                                <>
                                    Recent Messages
                                    <Button
                                        style={{ float: "right" }}
                                        onClick={() => setSendMessageDialogIsOpen(true)}
                                        disabled={!device.twoWayEnabled}
                                    >
                                        Send Message
                                    </Button>
                                    {sendMessageDialogIsOpen && (
                                        <Suspense fallback={<></>}>
                                            <InternalSendMessageDialog
                                                deviceId={device.deviceId}
                                                onClose={() => setSendMessageDialogIsOpen(false)}
                                            />
                                        </Suspense>
                                    )}
                                </>
                            }
                        >
                            <UL className={classes.noBullets}>
                                {allMessages.length === 0 ? (
                                    <li>No recent messages</li>
                                ) : (
                                    allMessages.map((message) => (
                                        <li key={message.packetId}>
                                            <DateDisplay date={message.hiveRxTime} />{" "}
                                            <Link
                                                to={internalSitemap.messages.generatePath({
                                                    organizationId:
                                                        device.organizationId.toString(),
                                                    messageId: message.pk(),
                                                })}
                                            >
                                                {message.packetId}
                                            </Link>
                                        </li>
                                    ))
                                )}
                            </UL>
                            {hasNextPage && (
                                <div>
                                    <Link
                                        to={{
                                            pathname: internalSitemap.messages.generatePath({
                                                organizationId: device.organizationId.toString(),
                                            }),
                                            search: new URLSearchParams({
                                                deviceId: device.deviceId.toString(),
                                            }).toString(),
                                        }}
                                    >
                                        See More
                                    </Link>
                                </div>
                            )}
                        </SXCard>
                    )}
                </>
            )}
        </>
    );

    if (onClose === undefined) {
        return content;
    }

    return (
        <SXDrawer
            isOpen={device !== undefined}
            isCloseButtonShown
            title={
                device === undefined
                    ? "..."
                    : `${formatDeviceTypeLong(device.deviceType)} ${device.deviceName}`
            }
            onClose={onClose}
            widthPercent={33}
            minWidth={500}
        >
            {content}
        </SXDrawer>
    );
};

export default InternalDeviceDetail;
