import { Packet, RFPacketTelemetry } from "handlers/generated/hive";
import React from "react";
import { DeviceType } from "resources/DeviceResource.types";
import CollapsibleGroup from "./CollapsibleGroup";
import LabelTable from "./LabelTable";

interface IRFDisplayProps {
    packet: Packet;
    telemetry: RFPacketTelemetry;
}

export const RFDisplay: React.FunctionComponent<IRFDisplayProps> = ({ packet, telemetry }) => {
    const rfPacket = packet.rfPacket;

    return (
        <CollapsibleGroup label="Radio">
            <LabelTable>
                <tr>
                    <td>RSSI</td>
                    <td>
                        {rfPacket?.rxRssi !== undefined
                            ? (rfPacket.rxRssi - 30).toLocaleString()
                            : "unknown"}{" "}
                        dBm
                    </td>
                </tr>
                <tr>
                    <td>SNR</td>
                    <td>
                        {rfPacket?.rxSnr !== undefined
                            ? rfPacket.rxSnr.toLocaleString()
                            : "unknown"}{" "}
                        dBm
                    </td>
                </tr>
                <tr>
                    <td>Freq. Offset</td>
                    <td>
                        {rfPacket?.rxFdev !== undefined
                            ? rfPacket.rxFdev.toLocaleString()
                            : "unknown"}{" "}
                        Hz
                    </td>
                </tr>
                <tr>
                    <td>Tx Power</td>
                    <td>{telemetry.telemetryRfPower} dBm</td>
                </tr>
                {telemetry.deviceType === DeviceType.SATELLITE && (
                    <tr>
                        <td>Beacon</td>
                        <td>{telemetry.telemetryBeaconInterval * 10} s</td>
                    </tr>
                )}
            </LabelTable>
        </CollapsibleGroup>
    );
};
