import { ICalloutProps } from "@blueprintjs/core";
import { DeviceType } from "resources/DeviceResource.types";

export enum CommandFieldType {
    BITMAP,
    DATE,
    NUMBER,
    OPTIONS,
    TEXT,
}

export enum PacketType {
    ACK = 0,
    DATA = 1,
    TELEMETRY = 2,
    COMMAND = 3,
}

interface CommandFieldBase {
    readonly name: string;
    readonly type: CommandFieldType;
    readonly label: string;
    readonly defaultValue: string;
    readonly width: number;
}

export interface CommandFieldOption {
    readonly label: string;
    readonly value: string;
}

export interface OptionsCommandField extends CommandFieldBase {
    readonly type: CommandFieldType.OPTIONS | CommandFieldType.BITMAP;
    readonly options: CommandFieldOption[];
}

export interface NumberCommandField extends CommandFieldBase {
    readonly type: CommandFieldType.NUMBER;
    readonly value: {
        readonly min: number;
        readonly max: number;

        /**
         * @default false
         */
        readonly convertToHex?: boolean;
    };
}

export interface DateCommandField extends CommandFieldBase {
    readonly type: CommandFieldType.DATE;
    readonly value?: {
        /**
         * @default Jan. 1st, 20 years in the past
         */
        readonly min?: Date;

        /**
         * @default Dec. 31st of this year
         */
        readonly max?: Date;

        /**
         * @default false
         */
        readonly convertToHex?: boolean;
    };
}

export interface TextCommandField extends CommandFieldBase {
    readonly type: CommandFieldType.TEXT;
    readonly value: {
        readonly maxLength: number;
    };
}

export type CommandField =
    | OptionsCommandField
    | NumberCommandField
    | DateCommandField
    | TextCommandField;

export interface CommandCatalogEntry {
    readonly name: string;
    readonly description: string;
    readonly fields: Readonly<CommandField[]>;

    /** callout to display to the user when the command is selected */
    readonly callout?: Readonly<ICalloutProps>;
}

export type CommandCatalog = Readonly<Record<DeviceType, Readonly<CommandCatalogEntry[]>>>;
