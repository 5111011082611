import moment from "moment-timezone";
import { useInfiniteQuery, useQuery, UseQueryResult } from "react-query";
import { apiHandlerLegacy, QueryKey } from "resources/apiHandler";
import { DeviceType } from "resources/DeviceResource.types";
import HiveResource from "resources/HiveResource";
import {
    DataType,
    FilterStatus,
    MessageDirection,
    MessageDirectionFilter,
    PacketStatus,
} from "resources/MessageResource.types";
import { Method } from "rest-hooks";
import { ResourceFields } from "utils";
import { formatDeviceId, formatDeviceIdAndType } from "./DeviceResource.utils";
import type FromDeviceData from "./messageResponse.fromdevice.json";
import type ToDeviceData from "./messageResponse.todevice.json";

type ToDeviceAPIType = typeof ToDeviceData[0];
type FromDeviceAPIType = typeof FromDeviceData[0];

function sortByReceivedAtDesc(a: IMessage, b: IMessage): number {
    return b.hiveRxTime.valueOf() - a.hiveRxTime.valueOf();
}

export type MessageFields = Readonly<ResourceFields<MessageResource>>;

export interface IMessageDetailsRequest {
    messageId: number;

    /** optional, defaults to current logged in users org */
    organizationId?: number;
}

export interface IMessagesRequest {
    /**
     * Numeric Organization ID
     */
    organizationId?: number;

    /** Numeric User Application ID */
    userApplicationId?: number;

    /** Numeric Swarm device type */
    devicetype?: DeviceType;

    /** Numeric Swarm device ID */
    deviceid?: string;

    /** Numeric Swarm firsthop ground station device ID. Use -1 for last known nexthop for this device */
    viadeviceid?: string;

    /**
     * Result count requested
     * @default 100
     * @max 1000
     */
    count?: number;

    /**
     * Numeric filter for message status
     * @default 0
     */
    status?: FilterStatus;

    /** Used for pagination. Pass the last packet ID from the previous page */
    beforepacketid?: number;

    /** Numeric filter for packet ID. Returns single message matching packet ID */
    packetid?: number;

    /**
     * Filter for direction of travel. Use "fromdevice" for messages received from Swarm devices or "todevice" for messages sent to Swarm devices
     * @default fromdevice
     */
    direction?: MessageDirectionFilter;

    /** Start date filter as UTC ISO 8601 */
    startDate?: string;

    /** End date filter as UTC ISO 8601. Must be after startDate */
    endDate?: string;
}

interface IOptions {
    enabled: boolean;
}

export interface IMessage {
    pk(): string | undefined;
    deviceDisplayId: string;
    deviceDisplayIdAndType: string;
    displayStatus: string;
    sentToDeviceDisplayIdAndType: string;
    nextHopDeviceDisplayIdAndType: string;
    decodedData: string;

    direction: MessageDirection;

    packetId: number;
    deviceType: DeviceType;
    deviceId: number;
    viaDeviceId: number;
    dataType: DataType;
    userApplicationId: number;
    organizationId: number;
    len: number;

    /** base64 encoded */
    data: string;
    ackPacketId: number;
    status: PacketStatus;

    /** receivedAt */
    hiveRxTime: Date;

    /** sentAt */
    hiveTxToTime: Date | undefined;
    hiveTxToDeviceType: DeviceType | undefined;
    hiveTxToDeviceId: number | undefined;

    /** sentToNextHopAt */
    nexthopTxToTime: Date | undefined;
    nexthopTxToDeviceType: DeviceType | undefined;
    nexthopTxToDeviceId: number | undefined;
}

export interface IMessageFromDevice extends IMessage {
    direction: MessageDirection.FROM_DEVICE;
}

export interface IMessageToDevice extends IMessage {
    direction: MessageDirection.TO_DEVICE;
    hiveTxToTime: Date;
    hiveTxToDeviceType: number;
    hiveTxToDeviceId: number;
    nexthopTxToTime: Date;
    nexthopTxToDeviceType: number;
    nexthopTxToDeviceId: number;
}

/** Message typeguard */
export function isToDevice(
    message: IMessage | IMessageToDevice | IMessageFromDevice
): message is IMessageToDevice {
    return (message as IMessageToDevice).direction === MessageDirection.TO_DEVICE;
}

/** API typeguard */
export function isToDeviceAPIResponse(
    message: ToDeviceAPIType | FromDeviceAPIType
): message is ToDeviceAPIType {
    return (message as ToDeviceAPIType).direction === MessageDirection.TO_DEVICE;
}

class Message implements IMessage {
    constructor(apiResponse: ToDeviceAPIType | FromDeviceAPIType) {
        this.direction = apiResponse.direction;

        this.packetId = apiResponse.packetId;
        this.deviceType = apiResponse.deviceType;
        this.deviceId = apiResponse.deviceId;
        this.viaDeviceId = apiResponse.viaDeviceId;

        this.dataType = apiResponse.dataType;
        this.userApplicationId = apiResponse.userApplicationId;
        this.organizationId = apiResponse.organizationId;
        this.len = apiResponse.len;

        this.data = apiResponse.data;
        this.ackPacketId = apiResponse.ackPacketId;
        this.status = apiResponse.status;
        this.hiveRxTime = moment.utc(apiResponse.hiveRxTime).toDate();

        if (isToDeviceAPIResponse(apiResponse)) {
            this.hiveTxToTime =
                apiResponse.hiveTxToTime === undefined
                    ? undefined
                    : moment.utc(apiResponse.hiveTxToTime).toDate();
            this.hiveTxToDeviceType = apiResponse.hiveTxToDeviceType;
            this.hiveTxToDeviceId = apiResponse.hiveTxToDeviceId;
            this.nexthopTxToTime =
                apiResponse.nexthopTxToTime === undefined
                    ? undefined
                    : moment.utc(apiResponse.nexthopTxToTime).toDate();
            this.nexthopTxToDeviceType = apiResponse.nexthopTxToDeviceType;
            this.nexthopTxToDeviceId = apiResponse.nexthopTxToDeviceId;
        }
    }

    readonly direction: MessageDirection;

    readonly packetId: number;
    readonly deviceType: DeviceType;
    readonly deviceId: number;
    readonly viaDeviceId: number;
    readonly dataType: DataType;
    readonly userApplicationId: number;
    readonly organizationId: number;
    readonly len: number;

    /** base64 encoded */
    readonly data: string;
    readonly ackPacketId: number;

    // todo its weird we have a querystring param called status, but its not ths same as this status!
    readonly status: PacketStatus;

    /** receivedAt */
    readonly hiveRxTime: Date;

    /** sentAt */
    readonly hiveTxToTime: Date | undefined = undefined;
    readonly hiveTxToDeviceType: DeviceType | undefined = undefined;
    readonly hiveTxToDeviceId: number | undefined = undefined;

    /** sentToNextHopAt */
    readonly nexthopTxToTime: Date | undefined = undefined;
    readonly nexthopTxToDeviceType: DeviceType | undefined = undefined;
    readonly nexthopTxToDeviceId: number | undefined = undefined;

    pk(): string | undefined {
        return this.packetId?.toString();
    }

    get deviceDisplayId(): string {
        return formatDeviceId(this.deviceId);
    }

    get deviceDisplayIdAndType(): string {
        return formatDeviceIdAndType(this.deviceId, this.deviceType);
    }

    get displayStatus(): string {
        switch (this.status) {
            case PacketStatus.RECEIVED:
                return "Received";
            case PacketStatus.SENT:
                return "Sent";
            case PacketStatus.RXACKED:
                return "RxAcked";
            case PacketStatus.TXACKED:
                return "TxAcked";
            case PacketStatus.GWRXACKED:
                return "GwRxAcked";
            case PacketStatus.ERROR:
                return "Error";
            case PacketStatus.DUPLICATE:
                return "Duplicate";
            case PacketStatus.RETRYING:
                return "Retrying";
            case PacketStatus.FAILED:
                return "Failed";
            default:
                return "Unknown";
        }
    }

    get sentToDeviceDisplayIdAndType(): string {
        if (!isToDevice(this)) {
            throw new Error("sentToDeviceDisplayIdAndType can only be called on todevice messages");
        }

        return formatDeviceIdAndType(this.hiveTxToDeviceId, this.hiveTxToDeviceType);
    }

    get nextHopDeviceDisplayIdAndType(): string {
        if (!isToDevice(this)) {
            throw new Error(
                "nextHopDeviceDisplayIdAndType can only be called on todevice messages"
            );
        }

        return formatDeviceIdAndType(this.nexthopTxToDeviceId, this.nexthopTxToDeviceType);
    }

    get decodedData(): string {
        let output = this.data;
        try {
            // Data comes base64-encoded
            output = atob(output);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error("Could not base64-decode data for packet ", this.packetId);
            // Assume this is just not base64-encoded and move on.
        }
        return output;
    }
}

export class MessageDetails {
    constructor(apiResponse: IMessageDetailsResponse) {
        this.messageId = apiResponse.messageId;
        this.attempts = apiResponse.attempts.map((attempt) => new Message(attempt));
    }

    messageId: number;
    attempts: IMessage[];
}

interface IMessageDetailsResponse {
    messageId: number;
    attempts: (ToDeviceAPIType | FromDeviceAPIType)[];
}

async function fetchMessageDetails(params: IMessageDetailsRequest): Promise<MessageDetails> {
    const { data } = await apiHandlerLegacy.get<IMessageDetailsResponse>(
        `${window.HIVE_CONFIG.apiUrl}/messages/details/${params.messageId}`,
        {
            params: { organizationId: params.organizationId },
        }
    );

    return new MessageDetails(data);
}

async function fetchMessages(params: IMessagesRequest): Promise<Message[]> {
    const { data } = await apiHandlerLegacy.get<(ToDeviceAPIType | FromDeviceAPIType)[]>(
        `${window.HIVE_CONFIG.apiUrl}/messages`,
        {
            params: params,
        }
    );

    return data.map((message) => new Message(message));
}

/**
 * get a single message
 */
export function useMessageDetails(
    params: IMessageDetailsRequest
): UseQueryResult<MessageDetails, Error> {
    return useQuery([QueryKey.MessageDetails, params], () => fetchMessageDetails(params), {
        keepPreviousData: true,
    });
}

/** hacky way of storing paging information on the array
 * needed because list calls are returned as arrays today
 * todo remove once we return lists as objects, switch this over to use getNextPageParam and API key instead
 */
interface PagedArray<T> extends Array<T> {
    metadata?: {
        beforepacketid?: number;
    };
}

/**
 * gets messages from BOTH directions, warning this will make 2 API calls
 * @param queryParams api query string params
 * NOTE: because two API calls are being made (fromdevice and todevice) the page size is really count * 2
 */
export function useMessagesPaged(
    { count = 100, ...queryParams }: IMessagesRequest,
    options?: IOptions
) {
    if (count < 1) {
        throw Error("min count is 1");
    }
    if (count > 999) {
        throw Error("max count is 999");
    }

    return useInfiniteQuery(
        ["messages", queryParams],
        async ({ pageParam }: { pageParam?: IMessagesRequest }) => {
            const pageSize = count;
            const messages = await fetchMessages({
                ...queryParams,
                // get one extra message to detect if we have another page
                count: pageSize + 1,
                ...pageParam,
            });

            const messagesSorted: PagedArray<IMessage> = messages.sort(sortByReceivedAtDesc);

            return messagesSorted;
        },
        {
            getNextPageParam: (lastPage) => {
                if (lastPage.length > count) {
                    return { beforepacketid: lastPage[lastPage.length - 2].packetId };
                }

                // no more pages
                return undefined;
            },
            enabled: options?.enabled,
            select: (data) => ({
                pageParams: data.pageParams,
                // return only the selected number of items in data
                pages: data.pages.map((page) => page.slice(0, count)),
            }),
        }
    );
}

/**
 * @deprecated use react-query instead
 * @see useMessageDetails
 */
export default class MessageResource extends HiveResource {
    readonly packetId: number | undefined = undefined;
    readonly ackPacketId: number | undefined = undefined;
    // Base64-encoded
    readonly data: string = "";
    readonly dataType: number = 0;
    readonly deviceId: number = 0;
    readonly deviceType: number = 0;
    readonly direction: MessageDirectionFilter = MessageDirectionFilter.FROM_DEVICE;
    private readonly hiveRxTime: string = "";
    private readonly hiveTxToTime: string | undefined = undefined;
    readonly hiveTxToDeviceType: number = 0;
    readonly hiveTxToDeviceId: number = 0;
    readonly len: number = 0;
    private readonly nexthopTxToTime: string | undefined = undefined;
    readonly nexthopTxToDeviceType: number = 0;
    readonly nexthopTxToDeviceId: number = 0;

    readonly status: PacketStatus = PacketStatus.RECEIVED;
    readonly userApplicationId: number = 0;
    readonly viaDeviceId: number | undefined = undefined;
    readonly viaSatDeviceId: number | undefined = undefined;

    pk(): string | undefined {
        return this.packetId?.toString();
    }

    get deviceDisplayId(): string {
        return formatDeviceId(this.deviceId);
    }

    get deviceDisplayIdAndType(): string {
        return formatDeviceIdAndType(this.deviceId, this.deviceType);
    }

    get txToDeviceDisplayId(): string {
        return formatDeviceIdAndType(this.hiveTxToDeviceId, this.hiveTxToDeviceType);
    }

    get nexthopDeviceDisplayId(): string {
        return formatDeviceIdAndType(this.nexthopTxToDeviceId, this.nexthopTxToDeviceType);
    }

    get displayStatus(): string {
        switch (this.status) {
            case PacketStatus.RECEIVED:
                return "Received";
            case PacketStatus.SENT:
                return "Sent";
            case PacketStatus.RXACKED:
                return "RxAcked";
            case PacketStatus.TXACKED:
                return "TxAcked";
            case PacketStatus.GWRXACKED:
                return "GwRxAcked";
            case PacketStatus.ERROR:
                return "Error";
            case PacketStatus.DUPLICATE:
                return "Duplicate";
            case PacketStatus.RETRYING:
                return "Retrying";
            case PacketStatus.FAILED:
                return "Failed";
            default:
                return "Unknown";
        }
    }

    get receivedAt(): Date {
        return moment.utc(this.hiveRxTime).toDate();
    }

    get sentAt(): Date | undefined {
        return this.hiveTxToTime === undefined ? undefined : moment.utc(this.hiveTxToTime).toDate();
    }

    get sentToNextHopAt(): Date | undefined {
        return this.nexthopTxToTime === undefined
            ? undefined
            : moment.utc(this.nexthopTxToTime).toDate();
    }

    get sentToDeviceDisplayIdAndType(): string {
        return formatDeviceIdAndType(this.hiveTxToDeviceId, this.hiveTxToDeviceType);
    }

    get nextHopDeviceDisplayIdAndType(): string {
        return formatDeviceIdAndType(this.nexthopTxToDeviceId, this.nexthopTxToDeviceType);
    }

    get decodedData(): string {
        let output = this.data;
        try {
            // Data comes base64-encoded
            output = atob(output);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error("Could not base64-decode data for packet ", this.packetId);
            // Assume this is just not base64-encoded and move on.
        }
        return output;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fetch(method: Method, url: string, body?: Readonly<any | string>): Promise<any> {
        const encodedBody = body as { data?: string };

        if (encodedBody?.data != null) {
            // Base64-encode data
            encodedBody.data = btoa(encodedBody.data);
        }
        return super.fetch(method, url, encodedBody);
    }

    static urlRoot = `${window.HIVE_CONFIG.apiUrl}/messages`;
}
