/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Hive Service REST API
 * OpenAPI spec version: v1
 */

export type GSNetworkAssignmentAssignment =
    typeof GSNetworkAssignmentAssignment[keyof typeof GSNetworkAssignmentAssignment];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GSNetworkAssignmentAssignment = {
    commercial: "commercial",
    noncommercial: "noncommercial",
} as const;
