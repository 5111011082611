/* eslint-disable @typescript-eslint/ban-types */
import { Method, Resource } from "rest-hooks";

import { isNetworkError } from "utils";

export default abstract class HiveResource extends Resource {
    // This will provide credentials by sending back cookies provided by the server (if there are any)
    static fetchOptionsPlugin = (options: RequestInit): RequestInit => ({
        credentials: "include",
        ...options,
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fetch(method: Method, url: string, body?: Readonly<object | string>): Promise<any> {
        return super.fetch(method, url, body).catch((error: unknown) => {
            // Some of our errors have a body in the response with more details. Sometimes this is a JSON
            // object. If we can find anything of use, replace the message in the error object.
            if (!isNetworkError(error) || error.response == null) {
                throw error;
            }
            return error.response
                .text()
                .then((responseText) => {
                    let errorMessage: string | undefined = responseText;
                    try {
                        const responseJson = JSON.parse(responseText) as {
                            message?: string;
                            status?: string;
                        };
                        errorMessage = responseJson.message || responseJson.status;
                    } catch (e) {
                        // ignore
                    }
                    if (errorMessage) {
                        // eslint-disable-next-line no-param-reassign
                        error.message = errorMessage;
                    }
                })
                .finally(() => {
                    // Make sure the error is passed along regardless
                    throw error;
                });
        });
    }
}
