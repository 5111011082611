import { Spinner } from "@blueprintjs/core";
import { Organization, useGetAllOrganizations } from "handlers/generated/hive";
import React from "react";
import SimpleSelect, { Props as SimpleSelectProps } from "./SimpleSelect";

type SelectPropsForOrganizationResource = SimpleSelectProps<Readonly<Organization>>;

type Props = {
    /**
     * Callback to perform when the user creates a new organization, you should store org name
     * and create the organization on save
     * @default undefined (new org cannot be created)
     */
    onCreateNew?: (newOrg: Organization) => void;
} & Omit<
    SelectPropsForOrganizationResource,
    "getKey" | "renderItem" | "renderSelectedItem" | "items" | "itemSearchText"
>;

const orgNameAndId = ({ name, organizationId }: Organization): string => {
    if (organizationId === undefined) {
        return `${name} (New Org)`;
    }

    return `${name} (${organizationId})`;
};

const getOrganizationId = (org: Organization): React.Key => {
    if (org.organizationId === undefined) {
        return "new";
    }

    return org.organizationId;
};

const renderSelectedOrganization = (org: Readonly<Organization> | undefined): React.ReactNode => {
    if (org === undefined) {
        return "";
    }

    return orgNameAndId(org);
};

const OrganizationSelect: React.FunctionComponent<Props> = ({
    onCreateNew,
    buttonProps,
    ...otherProps
}) => {
    const { data, isLoading } = useGetAllOrganizations();

    if (isLoading) {
        return <Spinner />;
    }

    if (data === undefined) {
        return <>No organizations exist</>;
    }

    return (
        <SimpleSelect
            items={data}
            getKey={getOrganizationId}
            renderItem={orgNameAndId}
            renderSelectedItem={renderSelectedOrganization}
            itemSearchText={orgNameAndId}
            buttonProps={{ title: "Organization", ...buttonProps }}
            virtualScrollProps={{
                height: 400,
                width: "100%",
                itemSize: 30,
            }}
            {...otherProps}
        />
    );
};

export default OrganizationSelect;
