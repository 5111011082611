import { Tag } from "@blueprintjs/core";
import { DateDisplay } from "components/DateDisplay";
import { DateDisplayTimeAgo } from "components/DateDisplayTimeAgo";
import React from "react";
import { createUseStyles } from "react-jss";
import { DevicePaymentStatus, IDeviceBillingStatus } from "resources/BillingDevicesResource";
import { assertUnreachable, getNextInvoiceDate } from "utils";

interface IDeviceBillingStatusTagProps {
    billingStatus: IDeviceBillingStatus;
}

const useStyles = createUseStyles({
    // blueprintjs Tag have a transparent background by default. Meaning the color changes based on the background color
    // This makes the Tag unreadable if the Tag is used on a dark background (like row selected)

    // todo move to <SwarmTag /> component
    intentNone: {
        backgroundColor: "#E8EBEE !important",
    },
    intentSuccess: {
        backgroundColor: "#DBF0E7 !important",
    },
    intentPrimary: {
        backgroundColor: "#DCEBF5 !important",
    },
    intentWarning: {
        backgroundColor: "#F9ECDF !important",
    },
    intentDanger: {
        backgroundColor: "#FAE1E1 !important",
    },
});

export const TRIAL_MESSAGE = "First 50 messages free";

export const DeviceBillingStatusTag: React.FunctionComponent<IDeviceBillingStatusTagProps> = (
    props
) => {
    const classes = useStyles();

    switch (props.billingStatus.status) {
        case DevicePaymentStatus.ERROR:
            return (
                <Tag className={classes.intentDanger} minimal round intent="danger">
                    Error
                </Tag>
            );
        case DevicePaymentStatus.TRIAL:
            return (
                <Tag className={classes.intentNone} minimal round intent="none">
                    {TRIAL_MESSAGE}
                </Tag>
            );
        case DevicePaymentStatus.ACTIVE:
            // deprecated in favor of actual FREE_FOREVER status, but keeping for backward compat
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            if (props.billingStatus.paidThrough!.getUTCFullYear() >= 3000) {
                return (
                    <Tag className={classes.intentSuccess} minimal round intent="success">
                        Free
                    </Tag>
                );
            }

            return (
                <Tag className={classes.intentSuccess} minimal round intent="success">
                    Bill due <DateDisplay date={props.billingStatus.paidThrough} showTime={false} />
                </Tag>
            );
        case DevicePaymentStatus.CANCELED:
            return (
                <Tag className={classes.intentNone} minimal round intent="none">
                    Canceled
                </Tag>
            );
        case DevicePaymentStatus.GRACE_PERIOD:
        case DevicePaymentStatus.PAST_DUE:
            return (
                <Tag className={classes.intentDanger} minimal round intent="danger">
                    <DateDisplayTimeAgo date={props.billingStatus.paidThrough} unit="days" />{" "}
                    overdue
                </Tag>
            );
        case DevicePaymentStatus.ON_HOLD:
            if (props.billingStatus.paidThrough === null)
                return (
                    <Tag className={classes.intentWarning} minimal round intent="warning">
                        On Hold
                    </Tag>
                );
            return (
                <Tag className={classes.intentWarning} minimal round intent="warning">
                    On Hold starting{" "}
                    <DateDisplay date={props.billingStatus.paidThrough} showTime={false} />
                </Tag>
            );
        case DevicePaymentStatus.FREE_FOREVER:
            return (
                <Tag className={classes.intentSuccess} minimal round intent="success">
                    Free
                </Tag>
            );
        case DevicePaymentStatus.REACTIVATION:
            return (
                <Tag className={classes.intentSuccess} minimal round intent="success">
                    Reactivation due <DateDisplay date={getNextInvoiceDate()} showTime={false} />
                </Tag>
            );

        default:
            return assertUnreachable(props.billingStatus.status);
    }
};
