/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Hive Service REST API
 * OpenAPI spec version: v1
 */
import { useQuery, useMutation } from "react-query";
import type {
    UseQueryOptions,
    UseMutationOptions,
    QueryFunction,
    MutationFunction,
    UseQueryResult,
    QueryKey,
} from "react-query";
import type {
    WebUser,
    ApiError,
    GetUsersParams,
    CreateUserJson,
    PasswordChangeRequestJson,
    UserInfoChangeRequestJson,
    AdminUserUpdateJson,
    GetUserRoles200Item,
    GetUserRolesParams,
    GetRoles200Item,
    QueryUsersParams,
    GetUserCountParams,
} from "../models";
import { apiHandler } from "../../../../resources/apiHandler";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
    config: any,
    args: infer P
) => any
    ? P
    : never;

/**
 * Get a JSON array of users matching the given parameters.
 * @summary GET users
 */
export const getUsers = (
    params?: GetUsersParams,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<WebUser[]>(
        { url: `/hive/api/v1/users`, method: "get", params, signal },
        options
    );
};

export const getGetUsersQueryKey = (params?: GetUsersParams) =>
    [`/hive/api/v1/users`, ...(params ? [params] : [])] as const;

export const getGetUsersQueryOptions = <
    TData = Awaited<ReturnType<typeof getUsers>>,
    TError = ApiError
>(
    params?: GetUsersParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetUsersQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsers>>> = ({ signal }) =>
        getUsers(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getUsers>>>;
export type GetUsersQueryError = ApiError;

/**
 * @summary GET users
 */
export const useGetUsers = <TData = Awaited<ReturnType<typeof getUsers>>, TError = ApiError>(
    params?: GetUsersParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetUsersQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Add a new user.
 * @summary Create user
 */
export const createUser = (
    createUserJson: CreateUserJson,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<WebUser>(
        {
            url: `/hive/api/v1/users`,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: createUserJson,
        },
        options
    );
};

export const getCreateUserMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof createUser>>,
        TError,
        { data: CreateUserJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof createUser>>,
    TError,
    { data: CreateUserJson },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof createUser>>,
        { data: CreateUserJson }
    > = (props) => {
        const { data } = props ?? {};

        return createUser(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type CreateUserMutationResult = NonNullable<Awaited<ReturnType<typeof createUser>>>;
export type CreateUserMutationBody = CreateUserJson;
export type CreateUserMutationError = ApiError;

/**
 * @summary Create user
 */
export const useCreateUser = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof createUser>>,
        TError,
        { data: CreateUserJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getCreateUserMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Updates the password for the current user given their old password.
 * @summary POST update password
 */
export const updatePassword = (
    passwordChangeRequestJson: PasswordChangeRequestJson,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<void>(
        {
            url: `/hive/api/v1/users/me/password`,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: passwordChangeRequestJson,
        },
        options
    );
};

export const getUpdatePasswordMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof updatePassword>>,
        TError,
        { data: PasswordChangeRequestJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof updatePassword>>,
    TError,
    { data: PasswordChangeRequestJson },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof updatePassword>>,
        { data: PasswordChangeRequestJson }
    > = (props) => {
        const { data } = props ?? {};

        return updatePassword(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type UpdatePasswordMutationResult = NonNullable<Awaited<ReturnType<typeof updatePassword>>>;
export type UpdatePasswordMutationBody = PasswordChangeRequestJson;
export type UpdatePasswordMutationError = ApiError;

/**
 * @summary POST update password
 */
export const useUpdatePassword = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof updatePassword>>,
        TError,
        { data: PasswordChangeRequestJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getUpdatePasswordMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Update, via JSON, the account info for the current user.
 * @summary POST my account info
 */
export const updateInfo = (
    userInfoChangeRequestJson: UserInfoChangeRequestJson,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<WebUser>(
        {
            url: `/hive/api/v1/users/me/info`,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: userInfoChangeRequestJson,
        },
        options
    );
};

export const getUpdateInfoMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof updateInfo>>,
        TError,
        { data: UserInfoChangeRequestJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof updateInfo>>,
    TError,
    { data: UserInfoChangeRequestJson },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof updateInfo>>,
        { data: UserInfoChangeRequestJson }
    > = (props) => {
        const { data } = props ?? {};

        return updateInfo(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type UpdateInfoMutationResult = NonNullable<Awaited<ReturnType<typeof updateInfo>>>;
export type UpdateInfoMutationBody = UserInfoChangeRequestJson;
export type UpdateInfoMutationError = ApiError;

/**
 * @summary POST my account info
 */
export const useUpdateInfo = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof updateInfo>>,
        TError,
        { data: UserInfoChangeRequestJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getUpdateInfoMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Delete a user by user ID.
 * @summary DELETE user
 */
export const deleteUser = (userId: number, options?: SecondParameter<typeof apiHandler>) => {
    return apiHandler<string>({ url: `/hive/api/v1/users/${userId}`, method: "delete" }, options);
};

export const getDeleteUserMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteUser>>,
        TError,
        { userId: number },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof deleteUser>>,
    TError,
    { userId: number },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deleteUser>>,
        { userId: number }
    > = (props) => {
        const { userId } = props ?? {};

        return deleteUser(userId, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DeleteUserMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUser>>>;

export type DeleteUserMutationError = ApiError;

/**
 * @summary DELETE user
 */
export const useDeleteUser = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteUser>>,
        TError,
        { userId: number },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getDeleteUserMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Edit a user's email, role, and/or user application ID. Only the provided properties will be changed.
 * @summary PATCH user
 */
export const patchUser = (
    userId: number,
    adminUserUpdateJson: AdminUserUpdateJson,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<WebUser>(
        {
            url: `/hive/api/v1/users/${userId}`,
            method: "patch",
            headers: { "Content-Type": "application/json" },
            data: adminUserUpdateJson,
        },
        options
    );
};

export const getPatchUserMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchUser>>,
        TError,
        { userId: number; data: AdminUserUpdateJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof patchUser>>,
    TError,
    { userId: number; data: AdminUserUpdateJson },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof patchUser>>,
        { userId: number; data: AdminUserUpdateJson }
    > = (props) => {
        const { userId, data } = props ?? {};

        return patchUser(userId, data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type PatchUserMutationResult = NonNullable<Awaited<ReturnType<typeof patchUser>>>;
export type PatchUserMutationBody = AdminUserUpdateJson;
export type PatchUserMutationError = ApiError;

/**
 * @summary PATCH user
 */
export const usePatchUser = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchUser>>,
        TError,
        { userId: number; data: AdminUserUpdateJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getPatchUserMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Get a JSON array of the roles for a user.
 * @summary GET user's roles
 */
export const getUserRoles = (
    params: GetUserRolesParams,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<GetUserRoles200Item[]>(
        { url: `/hive/api/v1/users/userroles`, method: "get", params, signal },
        options
    );
};

export const getGetUserRolesQueryKey = (params: GetUserRolesParams) =>
    [`/hive/api/v1/users/userroles`, ...(params ? [params] : [])] as const;

export const getGetUserRolesQueryOptions = <
    TData = Awaited<ReturnType<typeof getUserRoles>>,
    TError = ApiError
>(
    params: GetUserRolesParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getUserRoles>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getUserRoles>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetUserRolesQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserRoles>>> = ({ signal }) =>
        getUserRoles(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetUserRolesQueryResult = NonNullable<Awaited<ReturnType<typeof getUserRoles>>>;
export type GetUserRolesQueryError = ApiError;

/**
 * @summary GET user's roles
 */
export const useGetUserRoles = <
    TData = Awaited<ReturnType<typeof getUserRoles>>,
    TError = ApiError
>(
    params: GetUserRolesParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getUserRoles>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetUserRolesQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Get a JSON array of the possible user roles.
 * @summary GET roles
 */
export const getRoles = (options?: SecondParameter<typeof apiHandler>, signal?: AbortSignal) => {
    return apiHandler<GetRoles200Item[]>(
        { url: `/hive/api/v1/users/roles`, method: "get", signal },
        options
    );
};

export const getGetRolesQueryKey = () => [`/hive/api/v1/users/roles`] as const;

export const getGetRolesQueryOptions = <
    TData = Awaited<ReturnType<typeof getRoles>>,
    TError = ApiError
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getRoles>>, TError, TData>;
    request?: SecondParameter<typeof apiHandler>;
}): UseQueryOptions<Awaited<ReturnType<typeof getRoles>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetRolesQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRoles>>> = ({ signal }) =>
        getRoles(requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetRolesQueryResult = NonNullable<Awaited<ReturnType<typeof getRoles>>>;
export type GetRolesQueryError = ApiError;

/**
 * @summary GET roles
 */
export const useGetRoles = <
    TData = Awaited<ReturnType<typeof getRoles>>,
    TError = ApiError
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getRoles>>, TError, TData>;
    request?: SecondParameter<typeof apiHandler>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetRolesQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Get a JSON array of users matching the given parameters.
 * @summary GET users query
 */
export const queryUsers = (
    params?: QueryUsersParams,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<WebUser[]>(
        { url: `/hive/api/v1/users/query`, method: "get", params, signal },
        options
    );
};

export const getQueryUsersQueryKey = (params?: QueryUsersParams) =>
    [`/hive/api/v1/users/query`, ...(params ? [params] : [])] as const;

export const getQueryUsersQueryOptions = <
    TData = Awaited<ReturnType<typeof queryUsers>>,
    TError = ApiError
>(
    params?: QueryUsersParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof queryUsers>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof queryUsers>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getQueryUsersQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof queryUsers>>> = ({ signal }) =>
        queryUsers(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type QueryUsersQueryResult = NonNullable<Awaited<ReturnType<typeof queryUsers>>>;
export type QueryUsersQueryError = ApiError;

/**
 * @summary GET users query
 */
export const useQueryUsers = <TData = Awaited<ReturnType<typeof queryUsers>>, TError = ApiError>(
    params?: QueryUsersParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof queryUsers>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getQueryUsersQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Get, in plaintext, the number of users matching the given parameters.
 * @summary GET user count
 */
export const getUserCount = (
    params?: GetUserCountParams,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<string>(
        { url: `/hive/api/v1/users/count`, method: "get", params, signal },
        options
    );
};

export const getGetUserCountQueryKey = (params?: GetUserCountParams) =>
    [`/hive/api/v1/users/count`, ...(params ? [params] : [])] as const;

export const getGetUserCountQueryOptions = <
    TData = Awaited<ReturnType<typeof getUserCount>>,
    TError = ApiError
>(
    params?: GetUserCountParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getUserCount>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getUserCount>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetUserCountQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserCount>>> = ({ signal }) =>
        getUserCount(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetUserCountQueryResult = NonNullable<Awaited<ReturnType<typeof getUserCount>>>;
export type GetUserCountQueryError = ApiError;

/**
 * @summary GET user count
 */
export const useGetUserCount = <
    TData = Awaited<ReturnType<typeof getUserCount>>,
    TError = ApiError
>(
    params?: GetUserCountParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getUserCount>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetUserCountQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};
