import { Card, ICardProps } from "@blueprintjs/core";
import classNames from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";
import { common } from "styles/common";

const useStyles = createUseStyles(common);

interface Props {
    className?: string;
    elevation?: ICardProps["elevation"];
    grow?: boolean;

    /* transparent background, ignores elevation prop */
    useNewStyle?: boolean;
    children: React.ReactNode;
}

// todo rename PageLayout
const FlexCard: React.FunctionComponent<Props> = ({
    children,
    className,
    elevation = 1,
    grow = false,
    useNewStyle = false,
}) => {
    const classes = useStyles();

    return (
        <div
            className={classNames(classes.col, className, {
                [classes.colMaxHeight]: !useNewStyle,
            })}
        >
            <Card
                elevation={elevation}
                className={classNames(classes.col, {
                    [classes.noGrow]: !grow,
                    [classes.transparent]: useNewStyle,
                    [classes.elevationNone]: useNewStyle,
                })}
            >
                {children}
            </Card>
        </div>
    );
};

export default FlexCard;
