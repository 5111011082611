import {
    Button,
    Classes,
    Dialog,
    Expander,
    FormGroup,
    HTMLSelect,
    Toaster,
} from "@blueprintjs/core";
import { NumberDisplay } from "components/NumberDisplay";
import { SXCard } from "components/SXCard";
import { DeviceBillingStatusPlan, useUpdateDataPlanBulk } from "handlers/generated/billing";
import React, { useCallback, useState } from "react";

const toaster = Toaster.create({ maxToasts: 1 });

interface IBulkUpdateDataPlanProps {
    organizationId: number;
    devices: number[];
    visible: boolean;
    onClose: () => void;
}

export const BulkUpdateDataPlan: React.FunctionComponent<IBulkUpdateDataPlanProps> = ({
    organizationId,
    devices,
    visible,
    onClose,
}) => {
    const [selectedDataPlan, setSelectedDataPlan] = useState<DeviceBillingStatusPlan | undefined>(
        undefined
    );

    const isValidDataPlan =
        selectedDataPlan === DeviceBillingStatusPlan.STANDARD_DATA_PLAN_YEARLY ||
        selectedDataPlan === DeviceBillingStatusPlan.REDUCED_DATA_PLAN_YEARLY;

    const { isLoading: isUpdatingDataPlan, mutateAsync: mutationUpdateDataPlan } =
        useUpdateDataPlanBulk({
            mutation: {
                onSuccess: (data) => {
                    toaster.show({
                        intent: "none",
                        message: data.message,
                        timeout: 10000,
                    });
                },
            },
        });

    const handleSetDataPlan = useCallback(async () => {
        if (isValidDataPlan) {
            await mutationUpdateDataPlan({
                data: {
                    organizationId: organizationId,
                    deviceIds: devices,
                    plan: selectedDataPlan,
                },
            });
        }

        onClose();
        setSelectedDataPlan(undefined);
    }, [
        isValidDataPlan,
        onClose,
        mutationUpdateDataPlan,
        organizationId,
        devices,
        selectedDataPlan,
    ]);

    return (
        <Dialog
            title="Bulk Update Data Plan"
            canOutsideClickClose={false}
            enforceFocus={false}
            onClose={onClose}
            isOpen={visible}
        >
            <div className={Classes.DIALOG_BODY}>
                <SXCard>
                    <FormGroup label="Data Plan" labelFor="dataPlan">
                        <HTMLSelect
                            id="dataPlan"
                            options={[
                                {
                                    value: DeviceBillingStatusPlan.ERROR,
                                    label: " ",
                                },
                                {
                                    value: DeviceBillingStatusPlan.STANDARD_DATA_PLAN_YEARLY,
                                    label: "Standard Data Plan - 750 messages per month",
                                },
                                {
                                    value: DeviceBillingStatusPlan.REDUCED_DATA_PLAN_YEARLY,
                                    label: "Reduced Data Plan - 150 messages per month",
                                },
                            ]}
                            onChange={(event) =>
                                setSelectedDataPlan(
                                    event.currentTarget.value as DeviceBillingStatusPlan
                                )
                            }
                            value={selectedDataPlan}
                        />
                    </FormGroup>
                </SXCard>
                <div style={{ display: "flex" }}>
                    <Expander />
                    <Button
                        intent="primary"
                        disabled={!isValidDataPlan}
                        loading={isUpdatingDataPlan}
                        onClick={handleSetDataPlan}
                    >
                        Update{" "}
                        <NumberDisplay
                            amount={Object.keys(devices).length}
                            minDigits={0}
                            maxDigits={0}
                        />{" "}
                        Devices
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};
