/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Billing Service REST API
 * OpenAPI spec version: v1
 */
import { useQuery, useMutation } from "react-query";
import type {
    UseQueryOptions,
    UseMutationOptions,
    QueryFunction,
    MutationFunction,
    UseQueryResult,
    QueryKey,
} from "react-query";
import type {
    StatusJson,
    ApiError,
    GenerateInvoicesParams,
    FinalizeInvoicesParams,
    ReducedInvoice,
    GetInvoicesWithLineItemsParams,
    ReducedInvoiceWithLineItems,
} from "../models";
import { apiHandler } from "../../../../resources/apiHandler";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
    config: any,
    args: infer P
) => any
    ? P
    : never;

/**
 * @summary POST generate invoices. Should be called daily
 */
export const generateInvoices = (
    organizationId: number,
    params?: GenerateInvoicesParams,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<StatusJson>(
        { url: `/api/v1/billing/invoices/generate/${organizationId}`, method: "post", params },
        options
    );
};

export const getGenerateInvoicesMutationOptions = <
    TError = ApiError,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof generateInvoices>>,
        TError,
        { organizationId: number; params?: GenerateInvoicesParams },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof generateInvoices>>,
    TError,
    { organizationId: number; params?: GenerateInvoicesParams },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof generateInvoices>>,
        { organizationId: number; params?: GenerateInvoicesParams }
    > = (props) => {
        const { organizationId, params } = props ?? {};

        return generateInvoices(organizationId, params, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type GenerateInvoicesMutationResult = NonNullable<
    Awaited<ReturnType<typeof generateInvoices>>
>;

export type GenerateInvoicesMutationError = ApiError;

/**
 * @summary POST generate invoices. Should be called daily
 */
export const useGenerateInvoices = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof generateInvoices>>,
        TError,
        { organizationId: number; params?: GenerateInvoicesParams },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getGenerateInvoicesMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary POST finalize invoices. TEST ROUTE ONLY. If not called on 1st of month, may cause missing charges.
 */
export const finalizeInvoices = (
    organizationId: number,
    params?: FinalizeInvoicesParams,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<StatusJson>(
        { url: `/api/v1/billing/invoices/finalize/${organizationId}`, method: "post", params },
        options
    );
};

export const getFinalizeInvoicesMutationOptions = <
    TError = ApiError,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof finalizeInvoices>>,
        TError,
        { organizationId: number; params?: FinalizeInvoicesParams },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof finalizeInvoices>>,
    TError,
    { organizationId: number; params?: FinalizeInvoicesParams },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof finalizeInvoices>>,
        { organizationId: number; params?: FinalizeInvoicesParams }
    > = (props) => {
        const { organizationId, params } = props ?? {};

        return finalizeInvoices(organizationId, params, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type FinalizeInvoicesMutationResult = NonNullable<
    Awaited<ReturnType<typeof finalizeInvoices>>
>;

export type FinalizeInvoicesMutationError = ApiError;

/**
 * @summary POST finalize invoices. TEST ROUTE ONLY. If not called on 1st of month, may cause missing charges.
 */
export const useFinalizeInvoices = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof finalizeInvoices>>,
        TError,
        { organizationId: number; params?: FinalizeInvoicesParams },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getFinalizeInvoicesMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Returns a List of Invoice object reduced to fields that are relevant to displaying invoices on the frontend to Swarm customers. Return 403 (Forbidden) if the user doesn't have access to the organization.
 * @summary GET invoices for this organization. Optimized for displaying on the frontend.
 */
export const getInvoicesWithLineItems = (
    organizationId: number,
    params?: GetInvoicesWithLineItemsParams,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<ReducedInvoice[]>(
        {
            url: `/api/v1/billing/invoices/display/${organizationId}`,
            method: "get",
            params,
            signal,
        },
        options
    );
};

export const getGetInvoicesWithLineItemsQueryKey = (
    organizationId: number,
    params?: GetInvoicesWithLineItemsParams
) => [`/api/v1/billing/invoices/display/${organizationId}`, ...(params ? [params] : [])] as const;

export const getGetInvoicesWithLineItemsQueryOptions = <
    TData = Awaited<ReturnType<typeof getInvoicesWithLineItems>>,
    TError = ApiError
>(
    organizationId: number,
    params?: GetInvoicesWithLineItemsParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof getInvoicesWithLineItems>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getInvoicesWithLineItems>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetInvoicesWithLineItemsQueryKey(organizationId, params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getInvoicesWithLineItems>>> = ({
        signal,
    }) => getInvoicesWithLineItems(organizationId, params, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!organizationId, ...queryOptions };
};

export type GetInvoicesWithLineItemsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getInvoicesWithLineItems>>
>;
export type GetInvoicesWithLineItemsQueryError = ApiError;

/**
 * @summary GET invoices for this organization. Optimized for displaying on the frontend.
 */
export const useGetInvoicesWithLineItems = <
    TData = Awaited<ReturnType<typeof getInvoicesWithLineItems>>,
    TError = ApiError
>(
    organizationId: number,
    params?: GetInvoicesWithLineItemsParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof getInvoicesWithLineItems>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetInvoicesWithLineItemsQueryOptions(organizationId, params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Return a reduced stripe invoice along with correlated line items from a swarm invoice, if any.Return 403 (Forbidden) if the user doesn't have access to the organization.
 * @summary GET invoice by organization and invoiceId correlated with Swarm invoice. Optimized for displaying on the frontend.
 */
export const getInvoiceWithLineItems = (
    organizationId: number,
    invoiceId: string,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<ReducedInvoiceWithLineItems>(
        {
            url: `/api/v1/billing/invoices/display/${organizationId}/${invoiceId}`,
            method: "get",
            signal,
        },
        options
    );
};

export const getGetInvoiceWithLineItemsQueryKey = (organizationId: number, invoiceId: string) =>
    [`/api/v1/billing/invoices/display/${organizationId}/${invoiceId}`] as const;

export const getGetInvoiceWithLineItemsQueryOptions = <
    TData = Awaited<ReturnType<typeof getInvoiceWithLineItems>>,
    TError = ApiError
>(
    organizationId: number,
    invoiceId: string,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getInvoiceWithLineItems>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getInvoiceWithLineItems>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetInvoiceWithLineItemsQueryKey(organizationId, invoiceId);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getInvoiceWithLineItems>>> = ({
        signal,
    }) => getInvoiceWithLineItems(organizationId, invoiceId, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!(organizationId && invoiceId), ...queryOptions };
};

export type GetInvoiceWithLineItemsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getInvoiceWithLineItems>>
>;
export type GetInvoiceWithLineItemsQueryError = ApiError;

/**
 * @summary GET invoice by organization and invoiceId correlated with Swarm invoice. Optimized for displaying on the frontend.
 */
export const useGetInvoiceWithLineItems = <
    TData = Awaited<ReturnType<typeof getInvoiceWithLineItems>>,
    TError = ApiError
>(
    organizationId: number,
    invoiceId: string,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getInvoiceWithLineItems>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetInvoiceWithLineItemsQueryOptions(organizationId, invoiceId, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};
