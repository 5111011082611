import { InvoicesList } from "components/Invoice/InvoicesList";
import { Organization } from "handlers/generated/hive";
import { RouteParams } from "internalSitemap";
import { InternalInvoiceDetails } from "pages/Invoice/InternalInvoiceDetails";
import React from "react";
import { useParams } from "react-router-dom";

interface IOrganizationPaymentPanelProps {
    organizationData: Organization;
}

export const OrganizationPaymentPanel: React.FunctionComponent<IOrganizationPaymentPanelProps> = ({
    organizationData,
}) => {
    const { stripeInvoiceId } = useParams<RouteParams<"organizations">>();

    if (stripeInvoiceId !== undefined) {
        return (
            <InternalInvoiceDetails
                organizationId={organizationData.organizationId}
                stripeInvoiceId={stripeInvoiceId}
            />
        );
    }

    // todo remove padding hack once scrollbar is on page level
    return (
        <div style={{ padding: "0 1px" }}>
            <InvoicesList organizationId={organizationData.organizationId} />
        </div>
    );
};
