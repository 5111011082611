import { useQuery, UseQueryResult } from "react-query";
import { apiHandlerLegacy, QueryKey } from "resources/apiHandler";
import { updateManualBillPay } from "resources/CustomerResource";

export type FeatureFlag = "billing-manual-bill-pay";

interface IStatusJson {
    /** HTTP status code */
    status: number;
    message: string;
}

export interface IFeatureFlags {
    featureFlagValues: {
        [key: string]: boolean;
    };
}

async function fetchOrganizationFeatureFlags(organizationId: number): Promise<IFeatureFlags> {
    const { data } = await apiHandlerLegacy.get<IFeatureFlags>(
        `${window.HIVE_CONFIG.apiUrl}/featureflags/${organizationId}`
    );

    return data;
}

interface IFlag {
    name: string;
    defaultValue: boolean;
}

interface IFeatureFlagsList {
    featureFlags: IFlag[];
}

async function fetchDefaultFeatureFlags(): Promise<IFeatureFlagsList> {
    const { data } = await apiHandlerLegacy.get<IFeatureFlagsList>(
        `${window.HIVE_CONFIG.apiUrl}/featureflags`
    );

    return data;
}

interface IUpdateFeatureFlags {
    organizationId: number;
    flags: IFeatureFlags;
}

export async function updateFeatureFlags(params: IUpdateFeatureFlags): Promise<IStatusJson> {
    const { data } = await apiHandlerLegacy.patch<IStatusJson>(
        `${window.HIVE_CONFIG.apiUrl}/featureflags/${params.organizationId}`,
        params.flags
    );

    // if we updating manual bill pay we need to update the default payment method
    const BILLING_MANUAL_BILL_PAY =
        params.flags.featureFlagValues["billing-manual-bill-pay" as FeatureFlag];
    if (BILLING_MANUAL_BILL_PAY !== undefined) {
        await updateManualBillPay({
            organizationId: params.organizationId,
            enabled: BILLING_MANUAL_BILL_PAY,
        });
    }

    return data;
}

export function useDefaultFeatureFlags(): UseQueryResult<IFeatureFlagsList, Error> {
    return useQuery([QueryKey.Featureflags], () => fetchDefaultFeatureFlags(), {
        keepPreviousData: true,
    });
}

export function useOrganizationFeatureFlags(
    organizationId: number
): UseQueryResult<IFeatureFlags, Error> {
    return useQuery(
        [QueryKey.Featureflags, organizationId],
        () => fetchOrganizationFeatureFlags(organizationId),
        {
            keepPreviousData: true,
        }
    );
}
