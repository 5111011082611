/* eslint-disable @typescript-eslint/ban-types */
import {
    Device as DeviceNew,
    DeviceMetadata,
    RFPacketTelemetryPublic,
} from "handlers/generated/hive";
import moment from "moment-timezone";
import { useQuery, UseQueryResult } from "react-query";
import { apiHandlerLegacy, QueryKey } from "resources/apiHandler";
import { DeviceType } from "resources/DeviceResource.types";
import {
    formatDeviceId,
    formatDeviceIdAndType,
    formatDeviceType,
} from "resources/DeviceResource.utils";
import HiveResource from "resources/HiveResource";

export interface ActivationParams {
    authCode: string;
    organizationId?: number;
}

/** @deprecated used IDevice instead */
export default class DeviceResource extends HiveResource {
    readonly deviceId: number | undefined = undefined;
    readonly deviceType: DeviceType = DeviceType.ANY;

    // todo switch over all usages to displayName (some devices have a empty string for deviceName)
    readonly deviceName: string = "";
    readonly deviceUid: string | null = null;
    readonly authCode: string | undefined = undefined;
    readonly organizationId: number = 0;
    readonly tleName: string | undefined = undefined;
    readonly comments: string = "";
    private readonly hiveCreationTime: string = "";
    readonly firmwareVersion: string = "";
    readonly hardwareVersion: string = "";
    readonly serverVersion: string | undefined = undefined;
    readonly lastTelemetryReportPacketId: number = 0;
    private readonly hiveFirstheardTime: string | null = null;
    private readonly hiveLastheardTime: string | null = null;
    readonly counter: number = 0;
    readonly lastHeardByGroundstationId: number = 0;
    readonly status: number = 0;
    readonly twoWayEnabled: boolean = false;
    readonly dataEncryptionEnabled: boolean = false;
    readonly dataPlanPaid: boolean = false;
    readonly metadata: DeviceMetadata = {};

    pk(): string | undefined {
        return this.deviceId == null
            ? undefined
            : formatDeviceIdAndType(this.deviceId, this.deviceType);
    }

    get displayName(): string {
        if (this.deviceName.trim().length > 0) {
            return this.deviceName;
        }

        return this.displayIdAndType;
    }

    get displayId(): string {
        return this.deviceId == null ? "" : formatDeviceId(this.deviceId);
    }

    get displayType(): string {
        return formatDeviceType(this.deviceType);
    }

    get displayIdAndType(): string {
        return this.deviceId == null ? "" : formatDeviceIdAndType(this.deviceId, this.deviceType);
    }

    get firstHeardAt(): Date | null {
        return this.hiveFirstheardTime == null
            ? null
            : moment.utc(this.hiveFirstheardTime).toDate();
    }

    get lastHeardAt(): Date | null {
        return this.hiveLastheardTime == null ? null : moment.utc(this.hiveLastheardTime).toDate();
    }

    get createdAt(): Date {
        return moment.utc(this.hiveCreationTime).toDate();
    }

    static urlRoot = `${window.HIVE_CONFIG.apiUrl}/devices`;
}

export interface IDeviceMetadata {
    [key: string]: string;
}

export interface IDeviceMetadataMutation {
    [key: string]: string | null;
}

interface IDeviceListRequest {
    devicetype?: Exclude<DeviceType, 0>;
    deviceid?: string;
    devicename?: string;
    deviceuuid?: string;
    authCode?: string;
    organizationId?: number;
    count?: number;
    /**
     * Sort descending by device id if true; sort ascending otherwise
     * @default false
     */
    sortDesc?: boolean;
}

interface IDeviceResponse {
    deviceType: DeviceType;
    deviceId: number;
    deviceName: string;
    deviceUid: string;
    organizationId: number;
    authCode: string;
    tleName: string;
    comments: string;
    sourceIpAddress: string;
    hiveCreationTime: string;
    hiveFirstheardTime: string;
    hiveLastheardTime: string;
    firmwareVersion: string;
    hardwareVersion: string;
    serverVersion: string;
    rfConfig: string;
    lastTelemetryReportPacketId: number;
    lastHeardByDeviceType: number;
    lastHeardByDeviceId: number;
    lastHeardTime: string;
    counter: number;
    dayofyear: number;
    lastHeardCounter: number;
    lastHeardDayofyear: number;
    lastHeardByGroundstationId: number;
    uptime: number;
    status: number;
    testCwCurrent: number;
    testCwPower: number;
    testGpsTime: number;
    testRxSnr: number;
    testRxRssi: number;
    pushoverNotificationsEnabled: boolean;
    slackNotificationsEnabled: boolean;
    twoWayEnabled: boolean;
    dataEncryptionEnabled: boolean;
    dataPlanPaid: boolean;
    metadata: IDeviceMetadata;
}

export interface IDevice {
    /** unique. Warning does not update when you mutate deviceId or deviceType */
    displayIdAndType: string;

    /** @deprecated deviceId is NOT unique use displayIdAndType instead  */
    deviceId: number;

    deviceType: DeviceType;

    /** WARNING: This is a computed property that is only set in the */
    deviceName: string;

    deviceUid: string;
    organizationId: number;
    authCode: string;
    tleName: string;
    comments: string;
    sourceIpAddress: string;
    hiveCreationTime: Date | undefined;
    hiveFirstheardTime: Date | undefined;
    hiveLastheardTime: Date | undefined;
    firmwareVersion: string;
    hardwareVersion: string;
    serverVersion: string;
    rfConfig: string;
    lastTelemetryReportPacketId: number;
    lastHeardByDeviceType: number;
    lastHeardByDeviceId: number;
    lastHeardTime: Date | undefined;
    counter: number;
    dayofyear: number;
    lastHeardCounter: number;
    lastHeardDayofyear: number;
    lastHeardByGroundstationId: number;
    uptime: number;
    status: number;
    testCwCurrent: number;
    testCwPower: number;
    testGpsTime: number;
    testRxSnr: number;
    testRxRssi: number;
    pushoverNotificationsEnabled: boolean;
    slackNotificationsEnabled: boolean;
    twoWayEnabled: boolean;
    dataEncryptionEnabled: boolean;
    dataPlanPaid: boolean;

    metadata: IDeviceMetadata;

    /**
     * @warning NOT populated by the API. You must populate telemerty yourself
     */
    telemetry: RFPacketTelemetryPublic | undefined;
}

class Device implements IDevice {
    constructor(apiResponse: IDeviceResponse) {
        this.deviceType = apiResponse.deviceType;
        this.deviceId = apiResponse.deviceId;
        this.deviceUid = apiResponse.deviceUid;
        this.organizationId = apiResponse.organizationId;
        this.authCode = apiResponse.authCode;
        this.tleName = apiResponse.tleName;
        this.comments = apiResponse.comments;
        this.sourceIpAddress = apiResponse.sourceIpAddress;

        this.hiveCreationTime =
            apiResponse.hiveCreationTime === undefined
                ? undefined
                : moment.utc(apiResponse.hiveCreationTime).toDate();

        this.hiveFirstheardTime =
            apiResponse.hiveFirstheardTime === undefined
                ? undefined
                : moment.utc(apiResponse.hiveFirstheardTime).toDate();

        this.hiveLastheardTime =
            apiResponse.hiveLastheardTime === undefined
                ? undefined
                : moment.utc(apiResponse.hiveLastheardTime).toDate();

        this.firmwareVersion = apiResponse.firmwareVersion;
        this.hardwareVersion = apiResponse.hardwareVersion;
        this.serverVersion = apiResponse.serverVersion;
        this.rfConfig = apiResponse.rfConfig;
        this.lastTelemetryReportPacketId = apiResponse.lastTelemetryReportPacketId;
        this.lastHeardByDeviceType = apiResponse.lastHeardByDeviceType;
        this.lastHeardByDeviceId = apiResponse.lastHeardByDeviceId;

        this.lastHeardTime =
            apiResponse.lastHeardTime === undefined
                ? undefined
                : moment.utc(apiResponse.lastHeardTime).toDate();

        this.counter = apiResponse.counter;
        this.dayofyear = apiResponse.dayofyear;
        this.lastHeardCounter = apiResponse.lastHeardCounter;
        this.lastHeardDayofyear = apiResponse.lastHeardDayofyear;
        this.lastHeardByGroundstationId = apiResponse.lastHeardByGroundstationId;
        this.uptime = apiResponse.uptime;
        this.status = apiResponse.status;
        this.testCwCurrent = apiResponse.testCwCurrent;
        this.testCwPower = apiResponse.testCwPower;
        this.testGpsTime = apiResponse.testGpsTime;
        this.testRxSnr = apiResponse.testRxSnr;
        this.testRxRssi = apiResponse.testRxRssi;
        this.pushoverNotificationsEnabled = apiResponse.pushoverNotificationsEnabled;
        this.slackNotificationsEnabled = apiResponse.slackNotificationsEnabled;
        this.twoWayEnabled = apiResponse.twoWayEnabled;
        this.dataEncryptionEnabled = apiResponse.dataEncryptionEnabled;
        this.dataPlanPaid = apiResponse.dataPlanPaid;
        // todo should probably default to null or something?
        this.metadata = apiResponse.metadata ?? {};

        this.displayIdAndType = formatDeviceIdAndType(this.deviceId, this.deviceType);

        if (apiResponse.deviceName.trim().length > 0) {
            this.deviceName = apiResponse.deviceName;
        } else {
            this.deviceName = this.displayIdAndType;
        }
    }

    /** WARNING: This is a computed property that is only set in the */
    displayIdAndType: string;

    /** WARNING: This is a computed property that is only set in the */
    deviceName: string;

    telemetry: RFPacketTelemetryPublic | undefined;
    deviceType: DeviceType;
    deviceId: number;
    deviceUid: string;
    organizationId: number;
    authCode: string;
    tleName: string;
    comments: string;
    sourceIpAddress: string;
    hiveCreationTime: Date | undefined;
    hiveFirstheardTime: Date | undefined;
    hiveLastheardTime: Date | undefined;
    firmwareVersion: string;
    hardwareVersion: string;
    serverVersion: string;
    rfConfig: string;
    lastTelemetryReportPacketId: number;
    lastHeardByDeviceType: number;
    lastHeardByDeviceId: number;
    lastHeardTime: Date | undefined;
    counter: number;
    dayofyear: number;
    lastHeardCounter: number;
    lastHeardDayofyear: number;
    lastHeardByGroundstationId: number;
    uptime: number;
    status: number;
    testCwCurrent: number;
    testCwPower: number;
    testGpsTime: number;
    testRxSnr: number;
    testRxRssi: number;
    pushoverNotificationsEnabled: boolean;
    slackNotificationsEnabled: boolean;
    twoWayEnabled: boolean;
    dataEncryptionEnabled: boolean;
    dataPlanPaid: boolean;
    metadata: IDeviceMetadata;
}

/**
 * Get devices unique id "DeviceType-DeviceId"
 * @example F-0x0001
 * @example S-0x0101
 * Note for display values use deviceDisplayName
 * @see deviceDisplayName
 * @deprecated This method uses the old IDevice device object, use deviceUniqueId if you can
 */
export function deviceUniqueIdDeprecated(device: IDevice): string {
    return formatDeviceIdAndType(device.deviceId, device.deviceType);
}

/**
 * Get devices unique id "DeviceType-DeviceId"
 * @example F-0x0001
 * @example S-0x0101
 * Note for display values use deviceDisplayName
 * @see deviceDisplayName
 */
export function deviceUniqueId(device: DeviceNew): string {
    return formatDeviceIdAndType(device.deviceId, device.deviceType);
}

/**
 * Gets the device display name and falls back to unique device id
 */
export function deviceDisplayName(device: DeviceNew): string {
    const deviceLength = device.deviceName?.trim().length;
    if (deviceLength !== undefined && deviceLength > 0) {
        return device.deviceName?.trim() ?? "";
    }

    return formatDeviceIdAndType(device.deviceId, device.deviceType);
}

async function fetchDeviceList(params: IDeviceListRequest): Promise<IDevice[]> {
    const { data } = await apiHandlerLegacy.get<IDeviceResponse[]>("/hive/api/v1/devices", {
        params: params,
    });

    return data.map((device) => new Device(device));
}

export function useDeviceList(params: IDeviceListRequest): UseQueryResult<IDevice[], Error> {
    return useQuery([QueryKey.Devices, params], () => fetchDeviceList(params), {
        keepPreviousData: true,
    });
}
