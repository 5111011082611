/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Hive Service REST API
 * OpenAPI spec version: v1
 */
import { useQuery, useMutation } from "react-query";
import type {
    UseQueryOptions,
    UseMutationOptions,
    QueryFunction,
    MutationFunction,
    UseQueryResult,
    QueryKey,
} from "react-query";
import type {
    Device,
    ApiError,
    GetDevicesParams,
    StatusJson,
    DeviceMetadataUpdateJson,
    RegisterDeviceParams,
    DeviceRegistrationStatus,
    DeviceRegistrationRequest,
    DeregisterDeviceParams,
    GetDeviceIdsParams,
    GetDeviceIdsByTypeParams,
    GetDeviceCountParams,
    DeviceCountJson,
    GetRegisteredDeviceCountParams,
} from "../models";
import { apiHandler } from "../../../../resources/apiHandler";

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y
    ? 1
    : 2
    ? A
    : B;

type WritableKeys<T> = {
    [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>;
}[keyof T];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void
    ? I
    : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
    ? {
          [P in keyof Writable<T>]: T[P] extends object ? NonReadonly<NonNullable<T[P]>> : T[P];
      }
    : DistributeReadOnlyOverUnions<T>;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
    config: any,
    args: infer P
) => any
    ? P
    : never;

/**
 * <p>Get a JSON array of devices visible to the user, filtered by the parameters.</p><p>Recommended use cases:</p><li>Get all user devices</li><li>Get by devicetype</li><li>Get by devicetype and deviceid</li><li>Get by devicename</li><li>Get by deviceuuid</li></ul><p>Note that getting by multiple fields will try to filter by <strong>all</strong> of those fields. For example, trying to get by devicetype + devicename could return no results if the device with the given name doesn't have the given type.</p>
 * @summary GET devices
 */
export const getDevices = (
    params?: GetDevicesParams,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<Device[]>(
        { url: `/hive/api/v1/devices`, method: "get", params, signal },
        options
    );
};

export const getGetDevicesQueryKey = (params?: GetDevicesParams) =>
    [`/hive/api/v1/devices`, ...(params ? [params] : [])] as const;

export const getGetDevicesQueryOptions = <
    TData = Awaited<ReturnType<typeof getDevices>>,
    TError = ApiError
>(
    params?: GetDevicesParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getDevices>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getDevices>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetDevicesQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDevices>>> = ({ signal }) =>
        getDevices(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetDevicesQueryResult = NonNullable<Awaited<ReturnType<typeof getDevices>>>;
export type GetDevicesQueryError = ApiError;

/**
 * @summary GET devices
 */
export const useGetDevices = <TData = Awaited<ReturnType<typeof getDevices>>, TError = ApiError>(
    params?: GetDevicesParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getDevices>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetDevicesQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Modify all metadata for a device (admin only).
 * @summary PUT device
 */
export const putDevice = (
    device: NonReadonly<Device>,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<StatusJson>(
        {
            url: `/hive/api/v1/devices`,
            method: "put",
            headers: { "Content-Type": "application/json" },
            data: device,
        },
        options
    );
};

export const getPutDeviceMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putDevice>>,
        TError,
        { data: NonReadonly<Device> },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof putDevice>>,
    TError,
    { data: NonReadonly<Device> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof putDevice>>,
        { data: NonReadonly<Device> }
    > = (props) => {
        const { data } = props ?? {};

        return putDevice(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type PutDeviceMutationResult = NonNullable<Awaited<ReturnType<typeof putDevice>>>;
export type PutDeviceMutationBody = NonReadonly<Device>;
export type PutDeviceMutationError = ApiError;

/**
 * @summary PUT device
 */
export const usePutDevice = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putDevice>>,
        TError,
        { data: NonReadonly<Device> },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getPutDeviceMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Add a new device with metadata. Returns 400 if device already exists.
 * @summary POST device
 */
export const addDevice = (
    device: NonReadonly<Device>,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<string>(
        {
            url: `/hive/api/v1/devices`,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: device,
        },
        options
    );
};

export const getAddDeviceMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof addDevice>>,
        TError,
        { data: NonReadonly<Device> },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof addDevice>>,
    TError,
    { data: NonReadonly<Device> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof addDevice>>,
        { data: NonReadonly<Device> }
    > = (props) => {
        const { data } = props ?? {};

        return addDevice(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type AddDeviceMutationResult = NonNullable<Awaited<ReturnType<typeof addDevice>>>;
export type AddDeviceMutationBody = NonReadonly<Device>;
export type AddDeviceMutationError = ApiError;

/**
 * @summary POST device
 */
export const useAddDevice = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof addDevice>>,
        TError,
        { data: NonReadonly<Device> },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getAddDeviceMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Modify metadata for a device.
 * @summary PUT device metadata
 */
export const putDeviceMetadata = (
    deviceType: number,
    deviceId: number,
    deviceMetadataUpdateJson: DeviceMetadataUpdateJson,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<StatusJson>(
        {
            url: `/hive/api/v1/devices/metadata/${deviceType}/${deviceId}`,
            method: "put",
            headers: { "Content-Type": "application/json" },
            data: deviceMetadataUpdateJson,
        },
        options
    );
};

export const getPutDeviceMetadataMutationOptions = <
    TError = ApiError,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putDeviceMetadata>>,
        TError,
        { deviceType: number; deviceId: number; data: DeviceMetadataUpdateJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof putDeviceMetadata>>,
    TError,
    { deviceType: number; deviceId: number; data: DeviceMetadataUpdateJson },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof putDeviceMetadata>>,
        { deviceType: number; deviceId: number; data: DeviceMetadataUpdateJson }
    > = (props) => {
        const { deviceType, deviceId, data } = props ?? {};

        return putDeviceMetadata(deviceType, deviceId, data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type PutDeviceMetadataMutationResult = NonNullable<
    Awaited<ReturnType<typeof putDeviceMetadata>>
>;
export type PutDeviceMetadataMutationBody = DeviceMetadataUpdateJson;
export type PutDeviceMetadataMutationError = ApiError;

/**
 * @summary PUT device metadata
 */
export const usePutDeviceMetadata = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putDeviceMetadata>>,
        TError,
        { deviceType: number; deviceId: number; data: DeviceMetadataUpdateJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getPutDeviceMetadataMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Register a field device using its auth code. Returns the registered device.
 * @summary POST register device
 */
export const registerDevice = (
    params: RegisterDeviceParams,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<Device>(
        { url: `/hive/api/v1/devices/register`, method: "post", params },
        options
    );
};

export const getRegisterDeviceMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof registerDevice>>,
        TError,
        { params: RegisterDeviceParams },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof registerDevice>>,
    TError,
    { params: RegisterDeviceParams },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof registerDevice>>,
        { params: RegisterDeviceParams }
    > = (props) => {
        const { params } = props ?? {};

        return registerDevice(params, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type RegisterDeviceMutationResult = NonNullable<Awaited<ReturnType<typeof registerDevice>>>;

export type RegisterDeviceMutationError = ApiError;

/**
 * @summary POST register device
 */
export const useRegisterDevice = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof registerDevice>>,
        TError,
        { params: RegisterDeviceParams },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getRegisterDeviceMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Register multiple field devices using their auth codes. Returns DeviceRegistrationStatus object.
 * @summary POST register devices
 */
export const batchRegisterDevice = (
    deviceRegistrationRequest: DeviceRegistrationRequest,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<DeviceRegistrationStatus>(
        {
            url: `/hive/api/v1/devices/register/batch`,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: deviceRegistrationRequest,
        },
        options
    );
};

export const getBatchRegisterDeviceMutationOptions = <
    TError = ApiError,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof batchRegisterDevice>>,
        TError,
        { data: DeviceRegistrationRequest },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof batchRegisterDevice>>,
    TError,
    { data: DeviceRegistrationRequest },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof batchRegisterDevice>>,
        { data: DeviceRegistrationRequest }
    > = (props) => {
        const { data } = props ?? {};

        return batchRegisterDevice(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type BatchRegisterDeviceMutationResult = NonNullable<
    Awaited<ReturnType<typeof batchRegisterDevice>>
>;
export type BatchRegisterDeviceMutationBody = DeviceRegistrationRequest;
export type BatchRegisterDeviceMutationError = ApiError;

/**
 * @summary POST register devices
 */
export const useBatchRegisterDevice = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof batchRegisterDevice>>,
        TError,
        { data: DeviceRegistrationRequest },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getBatchRegisterDeviceMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Add a new device with metadata. Leave the deviceId field blank; it will automatically be populated with the next auto-increment deviceId for the given deviceType. Currently support deviceTypes: 1 (FieldBEE), 3 (SpaceBEE)
 * @summary POST device for provisioning
 */
export const addDeviceForProvisioning = (
    device: NonReadonly<Device>,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<Device>(
        {
            url: `/hive/api/v1/devices/provision`,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: device,
        },
        options
    );
};

export const getAddDeviceForProvisioningMutationOptions = <
    TError = ApiError,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof addDeviceForProvisioning>>,
        TError,
        { data: NonReadonly<Device> },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof addDeviceForProvisioning>>,
    TError,
    { data: NonReadonly<Device> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof addDeviceForProvisioning>>,
        { data: NonReadonly<Device> }
    > = (props) => {
        const { data } = props ?? {};

        return addDeviceForProvisioning(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type AddDeviceForProvisioningMutationResult = NonNullable<
    Awaited<ReturnType<typeof addDeviceForProvisioning>>
>;
export type AddDeviceForProvisioningMutationBody = NonReadonly<Device>;
export type AddDeviceForProvisioningMutationError = ApiError;

/**
 * @summary POST device for provisioning
 */
export const useAddDeviceForProvisioning = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof addDeviceForProvisioning>>,
        TError,
        { data: NonReadonly<Device> },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getAddDeviceForProvisioningMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Deregister a field device using its auth code or ID.
 * @summary POST deregister device
 */
export const deregisterDevice = (
    params?: DeregisterDeviceParams,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<StatusJson>(
        { url: `/hive/api/v1/devices/deregister`, method: "post", params },
        options
    );
};

export const getDeregisterDeviceMutationOptions = <
    TError = ApiError,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deregisterDevice>>,
        TError,
        { params?: DeregisterDeviceParams },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof deregisterDevice>>,
    TError,
    { params?: DeregisterDeviceParams },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deregisterDevice>>,
        { params?: DeregisterDeviceParams }
    > = (props) => {
        const { params } = props ?? {};

        return deregisterDevice(params, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DeregisterDeviceMutationResult = NonNullable<
    Awaited<ReturnType<typeof deregisterDevice>>
>;

export type DeregisterDeviceMutationError = ApiError;

/**
 * @summary POST deregister device
 */
export const useDeregisterDevice = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deregisterDevice>>,
        TError,
        { params?: DeregisterDeviceParams },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getDeregisterDeviceMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Get a list of available device types (as integers).
 * @summary GET device types
 */
export const getDeviceTypes = (
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<number[]>(
        { url: `/hive/api/v1/devices/types`, method: "get", signal },
        options
    );
};

export const getGetDeviceTypesQueryKey = () => [`/hive/api/v1/devices/types`] as const;

export const getGetDeviceTypesQueryOptions = <
    TData = Awaited<ReturnType<typeof getDeviceTypes>>,
    TError = ApiError
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getDeviceTypes>>, TError, TData>;
    request?: SecondParameter<typeof apiHandler>;
}): UseQueryOptions<Awaited<ReturnType<typeof getDeviceTypes>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetDeviceTypesQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDeviceTypes>>> = ({ signal }) =>
        getDeviceTypes(requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetDeviceTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getDeviceTypes>>>;
export type GetDeviceTypesQueryError = ApiError;

/**
 * @summary GET device types
 */
export const useGetDeviceTypes = <
    TData = Awaited<ReturnType<typeof getDeviceTypes>>,
    TError = ApiError
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getDeviceTypes>>, TError, TData>;
    request?: SecondParameter<typeof apiHandler>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetDeviceTypesQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Get a list of device ids visible to the user.
 * @summary GET device ids
 */
export const getDeviceIds = (
    params?: GetDeviceIdsParams,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<number[]>(
        { url: `/hive/api/v1/devices/ids`, method: "get", params, signal },
        options
    );
};

export const getGetDeviceIdsQueryKey = (params?: GetDeviceIdsParams) =>
    [`/hive/api/v1/devices/ids`, ...(params ? [params] : [])] as const;

export const getGetDeviceIdsQueryOptions = <
    TData = Awaited<ReturnType<typeof getDeviceIds>>,
    TError = ApiError
>(
    params?: GetDeviceIdsParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getDeviceIds>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getDeviceIds>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetDeviceIdsQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDeviceIds>>> = ({ signal }) =>
        getDeviceIds(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetDeviceIdsQueryResult = NonNullable<Awaited<ReturnType<typeof getDeviceIds>>>;
export type GetDeviceIdsQueryError = ApiError;

/**
 * @summary GET device ids
 */
export const useGetDeviceIds = <
    TData = Awaited<ReturnType<typeof getDeviceIds>>,
    TError = ApiError
>(
    params?: GetDeviceIdsParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getDeviceIds>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetDeviceIdsQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Get a list of device ids visible to the user for the given device type.
 * @summary GET device ids
 */
export const getDeviceIdsByType = (
    deviceType: number,
    params?: GetDeviceIdsByTypeParams,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<number[]>(
        { url: `/hive/api/v1/devices/ids/${deviceType}`, method: "get", params, signal },
        options
    );
};

export const getGetDeviceIdsByTypeQueryKey = (
    deviceType: number,
    params?: GetDeviceIdsByTypeParams
) => [`/hive/api/v1/devices/ids/${deviceType}`, ...(params ? [params] : [])] as const;

export const getGetDeviceIdsByTypeQueryOptions = <
    TData = Awaited<ReturnType<typeof getDeviceIdsByType>>,
    TError = ApiError
>(
    deviceType: number,
    params?: GetDeviceIdsByTypeParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getDeviceIdsByType>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getDeviceIdsByType>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetDeviceIdsByTypeQueryKey(deviceType, params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDeviceIdsByType>>> = ({ signal }) =>
        getDeviceIdsByType(deviceType, params, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!deviceType, ...queryOptions };
};

export type GetDeviceIdsByTypeQueryResult = NonNullable<
    Awaited<ReturnType<typeof getDeviceIdsByType>>
>;
export type GetDeviceIdsByTypeQueryError = ApiError;

/**
 * @summary GET device ids
 */
export const useGetDeviceIdsByType = <
    TData = Awaited<ReturnType<typeof getDeviceIdsByType>>,
    TError = ApiError
>(
    deviceType: number,
    params?: GetDeviceIdsByTypeParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getDeviceIdsByType>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetDeviceIdsByTypeQueryOptions(deviceType, params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Get a plaintext string of the number of devices visible to the user, filtered by the parameters.
 * @summary GET count of devices
 */
export const getDeviceCount = (
    params?: GetDeviceCountParams,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<string>(
        { url: `/hive/api/v1/devices/count`, method: "get", params, signal },
        options
    );
};

export const getGetDeviceCountQueryKey = (params?: GetDeviceCountParams) =>
    [`/hive/api/v1/devices/count`, ...(params ? [params] : [])] as const;

export const getGetDeviceCountQueryOptions = <
    TData = Awaited<ReturnType<typeof getDeviceCount>>,
    TError = ApiError
>(
    params?: GetDeviceCountParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getDeviceCount>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getDeviceCount>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetDeviceCountQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDeviceCount>>> = ({ signal }) =>
        getDeviceCount(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetDeviceCountQueryResult = NonNullable<Awaited<ReturnType<typeof getDeviceCount>>>;
export type GetDeviceCountQueryError = ApiError;

/**
 * @summary GET count of devices
 */
export const useGetDeviceCount = <
    TData = Awaited<ReturnType<typeof getDeviceCount>>,
    TError = ApiError
>(
    params?: GetDeviceCountParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getDeviceCount>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetDeviceCountQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Get the number of field devices in an org.
 * @summary GET count of registered devices
 */
export const getRegisteredDeviceCount = (
    params?: GetRegisteredDeviceCountParams,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<DeviceCountJson>(
        { url: `/hive/api/v1/devices/count/registered`, method: "get", params, signal },
        options
    );
};

export const getGetRegisteredDeviceCountQueryKey = (params?: GetRegisteredDeviceCountParams) =>
    [`/hive/api/v1/devices/count/registered`, ...(params ? [params] : [])] as const;

export const getGetRegisteredDeviceCountQueryOptions = <
    TData = Awaited<ReturnType<typeof getRegisteredDeviceCount>>,
    TError = ApiError
>(
    params?: GetRegisteredDeviceCountParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof getRegisteredDeviceCount>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getRegisteredDeviceCount>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetRegisteredDeviceCountQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRegisteredDeviceCount>>> = ({
        signal,
    }) => getRegisteredDeviceCount(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetRegisteredDeviceCountQueryResult = NonNullable<
    Awaited<ReturnType<typeof getRegisteredDeviceCount>>
>;
export type GetRegisteredDeviceCountQueryError = ApiError;

/**
 * @summary GET count of registered devices
 */
export const useGetRegisteredDeviceCount = <
    TData = Awaited<ReturnType<typeof getRegisteredDeviceCount>>,
    TError = ApiError
>(
    params?: GetRegisteredDeviceCountParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof getRegisteredDeviceCount>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetRegisteredDeviceCountQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Delete a device by device type and id.
 * @summary DELETE device by device type and id
 */
export const deleteDevice = (
    deviceType: number,
    deviceId: number,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<string>(
        { url: `/hive/api/v1/devices/types/${deviceType}/ids/${deviceId}`, method: "delete" },
        options
    );
};

export const getDeleteDeviceMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteDevice>>,
        TError,
        { deviceType: number; deviceId: number },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof deleteDevice>>,
    TError,
    { deviceType: number; deviceId: number },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deleteDevice>>,
        { deviceType: number; deviceId: number }
    > = (props) => {
        const { deviceType, deviceId } = props ?? {};

        return deleteDevice(deviceType, deviceId, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DeleteDeviceMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDevice>>>;

export type DeleteDeviceMutationError = ApiError;

/**
 * @summary DELETE device by device type and id
 */
export const useDeleteDevice = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deleteDevice>>,
        TError,
        { deviceType: number; deviceId: number },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getDeleteDeviceMutationOptions(options);

    return useMutation(mutationOptions);
};
