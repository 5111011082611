import { DeviceType } from "resources/DeviceResource.types";

/** When should the design cut over to a "mobile view" */
export const MOBILE_DESIGN_WIDTH = 768;
export const SIDE_PANEL_WIDTH = 400;

export const GPS_PINGER_APPLICATION_ID = 65002;
// export const TELEMETRY_MESSAGE_APPLICATION_ID = 65535;

export enum MapLayer {
    DevicesIcon = "layer-devices-icon",
    DevicesActive = "layer-devices-active",
    DevicesText = "layer-devices-text",
    DevicesHeatmap = "layer-devices-heatmap",
    DevicesLocationHistory = "devices-location-history",
    DevicesLocationHistoryCircle = "devices-location-history-circle",
}

export enum MapStyle {
    Streets = "mapbox://styles/mapbox/streets-v11",
    SatelliteStreets = "mapbox://styles/mapbox/satellite-streets-v11",
    Light = "mapbox://styles/mapbox/light-v11",
}

export enum MapSource {
    Devices = "devices",
    DevicesActive = "devices-active",
    DevicesLocationHistory = "devices-location-history",
    DevicesLocationHistoryCircle = "devices-location-history-circle",
}

export interface IDevicesProperties {
    /** should be truncated to 20 characters */
    title: string;
    "text-field": string;
    "icon-image": string;
    "icon-opacity": number;
    index: number;
    isActive?: boolean;
}

export interface IActiveDeviceProperties {
    "icon-color": string;
}

export interface IAssetTrackerPacketV1 {
    al: number;
    bv: number;
    /** GPS datetime of sample epoch seconds */
    dt: number;
    gj: number;
    gs: number;
    hd: number;
    ln: number;
    lt: number;
    rs: number;
    sp: number;

    /** Telemetry timestamp epoch seconds */
    td: number;
    tp: number;
    tr: number;
    ts: number;
}

/*
{
  "dt": 1646590987, // (epoch secs) GPS datetime of sample
  "lt": 37.2864,    // (degrees)    GPS latitude
  "ln": -122.0387,  // (degrees)    GPS longitude
  "al": 109,        // (meters)     GPS altitude
  "sp": 0,          // (mps)        GPS speed
  "hd": 0,          // (degrees)    GPS heading
  "gj": 23,         // (relative 0-255) GPS jamming level
  "gs": 1,          // (discrete)   GPS spoofing indication
  "bv": 1976,       // (mV)         GPIO1 battery voltage
  "tp": 25,         // (degrees C)  temperature (STM32L5)
  "rs": -111,       // (dB)         RSSI background
  "tr": -75,        // (dB)         Telemetry RSSI
  "ts": 13,         // (dB)         Telemetry SNR
  "td": 1646583803  // (epoch secs) Telemetry timestamp
}
*/

export enum AssetTrackerVersion {
    V1 = "V1",
}

export interface IAssetTrackerPacket {
    /** null when a GPS lock could not be established */
    latitude: number | null;

    /** null when a GPS lock could not be established */
    longitude: number | null;

    /** meters */
    altitude: number;

    /** meters per second */
    speed: number;

    /** degrees */
    heading: number;

    /** 0 to 255 */
    GPSjamming: number;

    GPSspoofing: boolean;

    /** mV */
    batteryVoltage: number;

    /** degrees C */
    temperature: number;

    // todo telemetry

    date: Date;

    deviceId: number;
    deviceType: DeviceType;
}

export interface IHasDeviceId {
    deviceId: number;
    deviceType: DeviceType;
}

export class AssetTrackerPacket implements IAssetTrackerPacket {
    constructor(message: IHasDeviceId, data: IAssetTrackerPacketV1) {
        this.latitude = data.lt;
        this.longitude = data.ln;

        if (this.latitude === 0 && this.longitude === 0) {
            this.latitude = null;
            this.longitude = null;
        }

        this.altitude = data.al;
        this.speed = data.sp;
        this.heading = data.hd;
        this.GPSjamming = data.gj;
        this.GPSspoofing = data.gs === 0;
        this.batteryVoltage = data.bv;
        this.temperature = data.tp;

        this.date = new Date(data.dt * 1000);

        this.deviceId = message.deviceId;
        this.deviceType = message.deviceType;
    }

    version: AssetTrackerVersion = AssetTrackerVersion.V1;

    latitude: number | null;
    longitude: number | null;

    /** meters */
    altitude: number;

    /** meters per second */
    speed: number;

    /** degrees */
    heading: number;

    /** 0 to 255 */
    GPSjamming: number;
    GPSspoofing: boolean;

    /** mV */
    batteryVoltage: number;

    /** degrees C */
    temperature: number;

    date: Date;

    deviceId: number;
    deviceType: DeviceType;
}

/** processed GPS timeline info, grouped into times spent at each geo coord */
export interface IGroupedGPSTimeline {
    latitude: number | null;
    longitude: number | null;

    data: IAssetTrackerPacket[];
}

export type StretchableImage = {
    stretchX: [[number, number]];
    stretchY: [[number, number]];
    content?: [number, number, number, number];
    pixelRatio: number;
};
