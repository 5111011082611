import {
    Button,
    Callout,
    Checkbox,
    Classes,
    Dialog,
    Expander,
    FormGroup,
    Toaster,
} from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import { NumberDisplay } from "components/NumberDisplay";
import { SXCard } from "components/SXCard";
import { useUpdatePaidThroughBulk } from "handlers/generated/billing";
import moment from "moment-timezone";
import React, { useCallback, useState } from "react";
import { FaCalendar } from "react-icons/fa";
import { MAX_PAID_THROUGH_DATE, PREFERRED_FORMAT_DAYS, SWARM_NETWORK_START } from "utils";

const toaster = Toaster.create({ maxToasts: 1 });

interface IBulkUpdatePaidThroughProps {
    organizationId: number;
    devices: number[];
    visible: boolean;
    onClose: () => void;
}

export const BulkUpdatePaidThrough: React.FunctionComponent<IBulkUpdatePaidThroughProps> = ({
    organizationId,
    devices,
    visible,
    onClose,
}) => {
    const [freeForever, setFreeForever] = useState(false);
    const [paidThroughDate, setPaidThroughDate] = useState<Date | null>(new Date());

    const { isLoading: isUpdatingPaidThroughDate, mutateAsync: mutationUpdatePaidThroughDate } =
        useUpdatePaidThroughBulk({
            mutation: {
                onSuccess: (data) => {
                    toaster.show({
                        intent: "none",
                        message: data.message,
                        timeout: 10000,
                    });
                },
            },
        });

    const handleSetPaidThrough = useCallback(async () => {
        await mutationUpdatePaidThroughDate({
            organizationId,
            data: {
                deviceIds: devices,
                paidThrough: freeForever ? new Date(Date.UTC(3000, 0, 1)) : paidThroughDate,
            },
        });

        onClose();
    }, [
        mutationUpdatePaidThroughDate,
        organizationId,
        devices,
        freeForever,
        paidThroughDate,
        onClose,
    ]);

    function isNotFirstDayOfMonth(day: Date) {
        return day.getDate() !== 1;
    }

    return (
        <Dialog
            title="Bulk Update Paid Through Date"
            canOutsideClickClose={false}
            enforceFocus={false}
            onClose={onClose}
            isOpen={visible}
        >
            <div className={Classes.DIALOG_BODY}>
                {!freeForever && paidThroughDate === null && (
                    <Callout intent="warning" style={{ marginBottom: 16 }}>
                        Setting <strong>Paid Through</strong> to no date does <strong>not</strong>{" "}
                        give a device an additional 50 free messages. If the device has already sent
                        50 messages and transmits again, it will be billed.
                        <br />
                        <br />
                        If a customer would like to cancel their device, please instruct them to
                        stop transmitting.
                    </Callout>
                )}
                {freeForever && (
                    <Callout intent="warning" style={{ marginBottom: 16 }}>
                        Avoid marking devices free forever if possible. Is setting{" "}
                        <strong>Paid Through</strong> to no date more appropriate?
                    </Callout>
                )}
                <SXCard>
                    <Checkbox
                        checked={freeForever}
                        onChange={(event) => {
                            setFreeForever(event.currentTarget.checked);
                        }}
                    >
                        Free forever
                    </Checkbox>
                    <FormGroup label="Paid Through">
                        <DateInput
                            disabled={freeForever}
                            minDate={SWARM_NETWORK_START}
                            maxDate={MAX_PAID_THROUGH_DATE}
                            formatDate={(date) => moment(date).format(PREFERRED_FORMAT_DAYS)}
                            parseDate={(date) => {
                                if (!date) {
                                    return null;
                                }
                                const parsedDate = moment(date, PREFERRED_FORMAT_DAYS);
                                return parsedDate.isValid() ? parsedDate.toDate() : false;
                            }}
                            dayPickerProps={{ disabledDays: isNotFirstDayOfMonth }}
                            placeholder="No Date"
                            invalidDateMessage="Invalid date"
                            inputProps={{
                                leftIcon: <FaCalendar />,
                            }}
                            value={paidThroughDate}
                            onChange={(date) => {
                                setPaidThroughDate(date);
                            }}
                            highlightCurrentDay
                            showActionsBar
                        />
                    </FormGroup>
                </SXCard>
                <div style={{ display: "flex" }}>
                    <Expander />
                    <Button
                        intent="primary"
                        loading={isUpdatingPaidThroughDate}
                        onClick={handleSetPaidThrough}
                    >
                        Update{" "}
                        <NumberDisplay
                            amount={Object.keys(devices).length}
                            minDigits={0}
                            maxDigits={0}
                        />{" "}
                        Devices
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};
