import { Button, Classes, Dialog, FormGroup, InputGroup, TextArea } from "@blueprintjs/core";
import { useUserContext } from "AppProvider";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { createUseStyles } from "react-jss";
import { useUIDSeed } from "react-uid";
import { QueryKey } from "resources/apiHandler";
import DeviceResource from "resources/DeviceResource";
import { DeviceType } from "resources/DeviceResource.types";
import { globalQueryClient } from "resources/globalQueryClient";
import MessageResource, { MessageFields } from "resources/MessageResource";
import { useFetcher, useResource } from "rest-hooks";
import { common } from "styles/common";
import { handleNetworkError } from "utils";
import { DeviceSelect } from "./DeviceSelect";

interface IInternalSendMessageDialogProps {
    deviceId?: number;
    onClose: () => void;
}

const MAX_CONTENT_BYTES = 192;

const useStyles = createUseStyles(common);

function getDeviceKey({ deviceId: id }: Readonly<DeviceResource>): number {
    return id ?? 0;
}

/**
 * @deprecated this dialog needs to be merged with <SendMessageDialog>
 */
export const InternalSendMessageDialog: React.FunctionComponent<
    IInternalSendMessageDialogProps
> = ({ deviceId, onClose }) => {
    const classes = useStyles();
    const uidSeed = useUIDSeed();
    const [serverError, setServerError] = useState<string>();
    const { organizationId } = useUserContext();
    const allDevices = useResource(DeviceResource.listShape(), {});
    const createMessage = useFetcher(MessageResource.createShape());

    const fieldDevices = useMemo(
        () => allDevices.filter(({ deviceType }) => deviceType === DeviceType.FIELD),
        [allDevices]
    );

    const groundStationOptions = useMemo(
        () => [
            DeviceResource.fromJS({
                deviceId: -2,
                deviceType: DeviceType.GROUND_STATION,
                deviceName: "Choose automatically",
            }),
            DeviceResource.fromJS({
                deviceId: -1,
                deviceType: DeviceType.GROUND_STATION,
                deviceName: "Use last heard via",
            }),
            ...allDevices.filter(({ deviceType }) => deviceType === DeviceType.GROUND_STATION),
        ],
        [allDevices]
    );

    const satelliteOptions = useMemo(
        () => [
            DeviceResource.fromJS({
                deviceId: -2,
                deviceType: DeviceType.SATELLITE,
                deviceName: "Choose automatically",
            }),
            DeviceResource.fromJS({
                deviceId: -1,
                deviceType: DeviceType.SATELLITE,
                deviceName: "Use last heard via",
            }),
            ...allDevices.filter(({ deviceType }) => deviceType === DeviceType.SATELLITE),
        ],
        [allDevices]
    );

    const defaultValues = useMemo(
        () => ({
            deviceId,
            userApplicationId: organizationId,
            viaDeviceId: -2, // Use the smart router
            viaSatDeviceId: -2, // Use the smart router
        }),
        [deviceId, organizationId]
    );

    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { control, handleSubmit, errors, formState, register, reset, watch } =
        useForm<MessageFields>({
            defaultValues,
        });

    const handleClose = useCallback(() => {
        // Clear any server error when the dialog is closed
        setServerError(undefined);
        // eslint-disable-next-line no-void
        void globalQueryClient.invalidateQueries(QueryKey.Messages);

        onClose();
    }, [onClose]);

    const handleSend = useCallback(
        async (formValues: Partial<MessageFields>) => {
            try {
                // We need to add some stuff to the request that isn't in the form
                await createMessage(
                    {},
                    {
                        ...formValues,
                        len: formValues.data?.length ?? 0,
                        // These aren't modifiable by the user
                        deviceType: DeviceType.FIELD,
                    }
                );

                handleClose();
            } catch (error) {
                setServerError(handleNetworkError(error));
            }
        },
        [createMessage, handleClose]
    );

    // When the default values change, we need to reset the form (this doesn't happen automatically
    // with react-hook-form)
    useEffect(() => reset(defaultValues), [defaultValues, reset]);

    return (
        <Dialog
            title="Send Message"
            canOutsideClickClose={false}
            enforceFocus={false}
            onClose={handleClose}
            isOpen
        >
            <form onSubmit={handleSubmit(handleSend)}>
                <div className={Classes.DIALOG_BODY}>
                    <FormGroup
                        label="Destination Device"
                        helperText={errors.deviceId?.message}
                        intent={errors.deviceId ? "danger" : "none"}
                    >
                        <Controller
                            name="deviceId"
                            control={control}
                            rules={{ required: "Required" }}
                            render={({ value, onChange }) => (
                                <DeviceSelect
                                    items={fieldDevices}
                                    getKey={getDeviceKey}
                                    onSelectionChange={({ deviceId: id }) => onChange(Number(id))}
                                    selectedKey={value as number | undefined}
                                    fill
                                    buttonProps={{
                                        intent: errors.deviceId ? "danger" : "none",
                                    }}
                                />
                            )}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Via Ground Station"
                        intent={errors.viaDeviceId ? "danger" : "none"}
                        helperText={errors.viaDeviceId?.message}
                    >
                        <Controller
                            name="viaDeviceId"
                            control={control}
                            rules={{ required: "Required" }}
                            render={({ value, onChange }) => (
                                <DeviceSelect
                                    items={groundStationOptions}
                                    getKey={getDeviceKey}
                                    onSelectionChange={({ deviceId: id }) => onChange(Number(id))}
                                    selectedKey={value as number | undefined}
                                    fill
                                    buttonProps={{
                                        intent: errors.viaSatDeviceId ? "danger" : "none",
                                    }}
                                />
                            )}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Via Satellite"
                        intent={errors.viaSatDeviceId ? "danger" : "none"}
                        helperText={errors.viaSatDeviceId?.message}
                    >
                        <Controller
                            name="viaSatDeviceId"
                            control={control}
                            rules={{ required: "Required" }}
                            render={({ value, onChange }) => (
                                <DeviceSelect
                                    items={satelliteOptions}
                                    getKey={getDeviceKey}
                                    onSelectionChange={({ deviceId: id }) => onChange(Number(id))}
                                    selectedKey={value as number | undefined}
                                    fill
                                    buttonProps={{
                                        intent: errors.viaSatDeviceId ? "danger" : "none",
                                    }}
                                />
                            )}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Application ID"
                        labelFor={uidSeed("userApplicationId")}
                        intent={errors.userApplicationId ? "danger" : "none"}
                        helperText={errors.userApplicationId?.message}
                    >
                        <InputGroup
                            id={uidSeed("userApplicationId")}
                            type="number"
                            name="userApplicationId"
                            inputRef={register({
                                required: "Required",
                                min: { value: 0, message: "Out of range" },
                                max: { value: 65535, message: "Out of range" },
                                valueAsNumber: true,
                            })}
                            intent={errors.userApplicationId ? "danger" : "none"}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Content"
                        labelInfo={`${watch("data", "").length} of ${MAX_CONTENT_BYTES} bytes used`}
                        labelFor={uidSeed("data")}
                        intent={errors.data ? "danger" : "none"}
                        helperText={errors.data?.message}
                    >
                        <TextArea
                            id={uidSeed("data")}
                            className={`${classes.textarea} ${Classes.MONOSPACE_TEXT}`}
                            name="data"
                            fill
                            inputRef={register({
                                maxLength: MAX_CONTENT_BYTES,
                                required: "Required",
                            })}
                            intent={errors.data ? "danger" : "none"}
                            maxLength={MAX_CONTENT_BYTES}
                        />
                    </FormGroup>
                    {serverError != null && <div className={classes.formError}>{serverError}</div>}
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button disabled={formState.isSubmitting} onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            intent="primary"
                            disabled={!formState.isDirty || formState.isSubmitting}
                        >
                            Send
                        </Button>
                    </div>
                </div>
            </form>
        </Dialog>
    );
};
