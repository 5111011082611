import React from "react";
import { NetworkError } from "rest-hooks";

import NetworkErrorPage from "./NetworkErrorPage";

interface Props {
    error: NetworkError;
}

const InternalNetworkErrorPage: React.FunctionComponent<Props> = ({ error }) => (
    <NetworkErrorPage error={error} />
);

export default InternalNetworkErrorPage;
