import { FormatPlural } from "components/FormatPlural";
import moment from "moment-timezone";
import React from "react";

interface IDateDisplayTimeAgoProps {
    date: Date | null | undefined;
    unit:
        | "auto"
        | "years"
        | "months"
        | "weeks"
        | "days"
        | "hours"
        | "minutes"
        | "seconds"
        | "milliseconds";
}

export const DateDisplayTimeAgo: React.FunctionComponent<IDateDisplayTimeAgoProps> = ({
    date,
    unit,
}) => {
    if (date === null || date === undefined) {
        return <></>;
    }

    if (unit === "auto") {
        return <>{moment(date).fromNow()}</>;
    }

    const now = moment();
    const unitsAway = now.diff(date, unit);
    const unitSingular = unit.slice(0, unit.length - 1);

    return (
        <>
            {unitsAway} <FormatPlural singular={unitSingular} plural={unit} count={unitsAway} />
        </>
    );
};
