/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Hive Service REST API
 * OpenAPI spec version: v1
 */
import { useQuery } from "react-query";
import type { UseQueryOptions, QueryFunction, UseQueryResult, QueryKey } from "react-query";
import type {
    RFPacketTelemetry,
    ApiError,
    GetTelemetryInternalParams,
    GetLatestTelemetryInternalParams,
    RFPacketTelemetryPublic,
    GetTelemetryParams,
    GetLatestTelemetryParams,
} from "../models";
import { apiHandler } from "../../../../resources/apiHandler";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
    config: any,
    args: infer P
) => any
    ? P
    : never;

/**
 * Get a JSON array of rfPacketTelemetry matching the given parameters.
 * @summary (SUPER ADMIN ONLY) GET telemetry packets
 */
export const getTelemetryInternal = (
    params: GetTelemetryInternalParams,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<RFPacketTelemetry[]>(
        { url: `/hive/api/v1/telemetry`, method: "get", params, signal },
        options
    );
};

export const getGetTelemetryInternalQueryKey = (params: GetTelemetryInternalParams) =>
    [`/hive/api/v1/telemetry`, ...(params ? [params] : [])] as const;

export const getGetTelemetryInternalQueryOptions = <
    TData = Awaited<ReturnType<typeof getTelemetryInternal>>,
    TError = ApiError
>(
    params: GetTelemetryInternalParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getTelemetryInternal>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getTelemetryInternal>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetTelemetryInternalQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTelemetryInternal>>> = ({ signal }) =>
        getTelemetryInternal(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetTelemetryInternalQueryResult = NonNullable<
    Awaited<ReturnType<typeof getTelemetryInternal>>
>;
export type GetTelemetryInternalQueryError = ApiError;

/**
 * @summary (SUPER ADMIN ONLY) GET telemetry packets
 */
export const useGetTelemetryInternal = <
    TData = Awaited<ReturnType<typeof getTelemetryInternal>>,
    TError = ApiError
>(
    params: GetTelemetryInternalParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getTelemetryInternal>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetTelemetryInternalQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Get JSON array of latest rfPacketTelemetry for devices matching given parameters.
 * @summary (SUPER ADMIN ONLY) GET latest telemetry packet for all devices matching parameters
 */
export const getLatestTelemetryInternal = (
    params?: GetLatestTelemetryInternalParams,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<RFPacketTelemetry[]>(
        { url: `/hive/api/v1/telemetry/latest`, method: "get", params, signal },
        options
    );
};

export const getGetLatestTelemetryInternalQueryKey = (params?: GetLatestTelemetryInternalParams) =>
    [`/hive/api/v1/telemetry/latest`, ...(params ? [params] : [])] as const;

export const getGetLatestTelemetryInternalQueryOptions = <
    TData = Awaited<ReturnType<typeof getLatestTelemetryInternal>>,
    TError = ApiError
>(
    params?: GetLatestTelemetryInternalParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof getLatestTelemetryInternal>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getLatestTelemetryInternal>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetLatestTelemetryInternalQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getLatestTelemetryInternal>>> = ({
        signal,
    }) => getLatestTelemetryInternal(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetLatestTelemetryInternalQueryResult = NonNullable<
    Awaited<ReturnType<typeof getLatestTelemetryInternal>>
>;
export type GetLatestTelemetryInternalQueryError = ApiError;

/**
 * @summary (SUPER ADMIN ONLY) GET latest telemetry packet for all devices matching parameters
 */
export const useGetLatestTelemetryInternal = <
    TData = Awaited<ReturnType<typeof getLatestTelemetryInternal>>,
    TError = ApiError
>(
    params?: GetLatestTelemetryInternalParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof getLatestTelemetryInternal>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetLatestTelemetryInternalQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Get a JSON array of rfPacketTelemetry matching the given parameters.
 * @summary GET telemetry packets
 */
export const getTelemetry = (
    params: GetTelemetryParams,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<RFPacketTelemetryPublic[]>(
        { url: `/hive/api/v1/telemetry/devices`, method: "get", params, signal },
        options
    );
};

export const getGetTelemetryQueryKey = (params: GetTelemetryParams) =>
    [`/hive/api/v1/telemetry/devices`, ...(params ? [params] : [])] as const;

export const getGetTelemetryQueryOptions = <
    TData = Awaited<ReturnType<typeof getTelemetry>>,
    TError = ApiError
>(
    params: GetTelemetryParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getTelemetry>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getTelemetry>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetTelemetryQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTelemetry>>> = ({ signal }) =>
        getTelemetry(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetTelemetryQueryResult = NonNullable<Awaited<ReturnType<typeof getTelemetry>>>;
export type GetTelemetryQueryError = ApiError;

/**
 * @summary GET telemetry packets
 */
export const useGetTelemetry = <
    TData = Awaited<ReturnType<typeof getTelemetry>>,
    TError = ApiError
>(
    params: GetTelemetryParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getTelemetry>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetTelemetryQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Get a JSON array of rfPacketTelemetry matching the given parameters.
 * @summary GET telemetry packets
 */
export const getLatestTelemetry = (
    params?: GetLatestTelemetryParams,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<RFPacketTelemetryPublic[]>(
        { url: `/hive/api/v1/telemetry/devices/latest`, method: "get", params, signal },
        options
    );
};

export const getGetLatestTelemetryQueryKey = (params?: GetLatestTelemetryParams) =>
    [`/hive/api/v1/telemetry/devices/latest`, ...(params ? [params] : [])] as const;

export const getGetLatestTelemetryQueryOptions = <
    TData = Awaited<ReturnType<typeof getLatestTelemetry>>,
    TError = ApiError
>(
    params?: GetLatestTelemetryParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getLatestTelemetry>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getLatestTelemetry>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetLatestTelemetryQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getLatestTelemetry>>> = ({ signal }) =>
        getLatestTelemetry(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetLatestTelemetryQueryResult = NonNullable<
    Awaited<ReturnType<typeof getLatestTelemetry>>
>;
export type GetLatestTelemetryQueryError = ApiError;

/**
 * @summary GET telemetry packets
 */
export const useGetLatestTelemetry = <
    TData = Awaited<ReturnType<typeof getLatestTelemetry>>,
    TError = ApiError
>(
    params?: GetLatestTelemetryParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getLatestTelemetry>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetLatestTelemetryQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};
