/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Telemetry Service REST API
 * OpenAPI spec version: v1
 */

export type TelemetryTelemetryType =
    typeof TelemetryTelemetryType[keyof typeof TelemetryTelemetryType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TelemetryTelemetryType = {
    RFPACKET_TELEMETRY: "RFPACKET_TELEMETRY",
    EVAL_KIT: "EVAL_KIT",
    ASSET_TRACKER: "ASSET_TRACKER",
} as const;
