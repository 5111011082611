import {
    AssetTrackerPacket,
    GPS_PINGER_APPLICATION_ID,
    IAssetTrackerPacket,
    IAssetTrackerPacketV1,
    IHasDeviceId,
} from "components/DeviceMap/map.types";
import { MetadataDeviceType, METADATA_DEVICE_TYPE_KEY } from "components/Metadata/Metadata.utils";
import { INetworkDevice } from "components/NetworkManagement/NetworkManagementForm.types";
import { Device, useGetLatestTelemetry } from "handlers/generated/hive";
import { useMemo } from "react";
import DeviceResource, { IDevice, useDeviceList } from "resources/DeviceResource";
import { DeviceType } from "resources/DeviceResource.types";
import { formatDeviceIdAndType } from "resources/DeviceResource.utils";
import { IMessage, useMessagesPaged } from "resources/MessageResource";
import { FilterStatus, MessageDirectionFilter } from "resources/MessageResource.types";
import variables from "styles/variables";
import { assertUnreachable, compareDates, compareNatural } from "utils";

/** How often to refresh telemetry locations (in milliseconds) */
const REFRESH_TELEMETRY_INTERNAL = 60_000;

export function getIcon(
    device: IDevice | Device | Readonly<DeviceResource> | INetworkDevice
): MetadataDeviceType {
    if (device.deviceType === DeviceType.GROUND_STATION) {
        return MetadataDeviceType.BroadcastTower;
    }
    if (device.deviceType === DeviceType.SATELLITE) {
        return MetadataDeviceType.Satellite;
    }

    const deviceMetadata = (device as IDevice).metadata ?? {};

    return (
        (deviceMetadata[METADATA_DEVICE_TYPE_KEY] as MetadataDeviceType) ?? MetadataDeviceType.Other
    );
}

export function getGeneratedIconName(device: IDevice) {
    const deviceType = getIcon(device);

    return createIconInfo(deviceType, getIconColor(device));
}

/** @returns hex color */
export function getIconColor(
    device: IDevice | Device | Readonly<DeviceResource> | INetworkDevice
): string {
    const iconType = getIcon(device);

    return getColorForType(iconType);
}

/** @returns hex color */
export function getColorForType(iconType: MetadataDeviceType): string {
    switch (iconType) {
        case MetadataDeviceType.Satellite:
            return variables.red1;

        case MetadataDeviceType.BroadcastTower:
            return variables.green1;

        case MetadataDeviceType.Crane:
        case MetadataDeviceType.Electrical:
            return variables.orange3;

        case MetadataDeviceType.Container:
        case MetadataDeviceType.Package:
            return variables.sepia1;

        case MetadataDeviceType.PressureWasher:
        case MetadataDeviceType.StorageTank:
            return variables.darkGray1;

        case MetadataDeviceType.Pickup:
        case MetadataDeviceType.Tractor:
        case MetadataDeviceType.Trailer:
        case MetadataDeviceType.Truck:
            return variables.blue1;

        case MetadataDeviceType.Ship:
        case MetadataDeviceType.Rocket:
            return variables.red1;

        case MetadataDeviceType.Modem:
        case MetadataDeviceType.Other:
            return variables.darkGray5;

        default:
            return assertUnreachable(iconType);
    }
}

export function asAssetTracker(
    data: string,
    message: IHasDeviceId
): IAssetTrackerPacket | undefined {
    try {
        const dataString = atob(data);
        const mapData = JSON.parse(dataString) as IAssetTrackerPacketV1;
        return new AssetTrackerPacket(message, mapData);
    } catch (e) {
        // packet was not a asset tracker packet
        return undefined;
    }
}

export function messagesAsAssetTracker(messages: IMessage[]): IAssetTrackerPacket[] {
    const assetTrackerMessages: IAssetTrackerPacket[] = [];

    messages.forEach((message) => {
        const assetTrackerPacket = asAssetTracker(message.data, message);

        if (assetTrackerPacket) {
            assetTrackerMessages.push(assetTrackerPacket);
        }
    });

    return assetTrackerMessages;
}

/**
 * Gets devices and includes the most recent telemetry data
 */
export function useDeviceListWithTelemetry(organizationId: number | undefined): {
    data: IDevice[];
    isLoading: boolean;
    error: Error | null;
} {
    const deviceList = useDeviceList({
        organizationId: organizationId,
    });

    // todo remove once GPS Pinger firmware has been fixed and sends actual device telemetry instead of 0/0
    const allDeviceMessages = useMessagesPaged({
        devicetype: DeviceType.FIELD,
        status: FilterStatus.ALL,
        direction: MessageDirectionFilter.FROM_DEVICE,
        count: 999,
        organizationId: organizationId,
        userApplicationId: GPS_PINGER_APPLICATION_ID,
    });

    const latestTelemetry = useGetLatestTelemetry(
        {
            startTime: undefined,
            endTime: undefined,
            organizationId: organizationId,
        },
        { query: { refetchInterval: REFRESH_TELEMETRY_INTERNAL } }
    );

    const allDeviceHistory: IAssetTrackerPacket[] = useMemo(() => {
        const recentLocations = allDeviceMessages.data?.pages.flat() ?? [];
        const locationsMapped = messagesAsAssetTracker(recentLocations);
        return locationsMapped.sort((a, b) => compareDates(b.date, a.date));
    }, [allDeviceMessages.data?.pages]);

    const devicesWithTelemetry: IDevice[] = useMemo(() => {
        const deviceTelemetryMap = new Map(
            latestTelemetry.data?.map((dt) => [
                formatDeviceIdAndType(dt.deviceId, dt.deviceType),
                dt,
            ])
        );

        // devicePingMap will be used to overwrite device telemetry because
        // (1) device telemetry is not updated as often as GPS pings
        // (2) a firmware bug exists on v1 of the Pinger. Telemetry is always sent as lat=0, lon=0
        const devicePingMap = new Map<string, { latitude: number; longitude: number }>();
        allDeviceHistory.forEach((dt) => {
            const key = formatDeviceIdAndType(dt.deviceId, dt.deviceType);

            if (!devicePingMap.has(key)) {
                const hasValidLocation =
                    dt.latitude !== null &&
                    dt.longitude !== null &&
                    dt.latitude !== 0 &&
                    dt.longitude !== 0;
                if (hasValidLocation) {
                    devicePingMap.set(key, {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        latitude: dt.latitude!,
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        longitude: dt.longitude!,
                    });
                }
            }
        });

        // update deviceTelemetryMap with latest ping
        deviceTelemetryMap.forEach((dt) => {
            const key = formatDeviceIdAndType(dt.deviceId, dt.deviceType);
            const latestPing = devicePingMap.get(key);

            if (latestPing !== undefined) {
                const newDeviceTelemetry = {
                    ...dt,
                    telemetryLatitude: latestPing.latitude,
                    telemetryLongitude: latestPing.longitude,
                };
                deviceTelemetryMap.set(
                    formatDeviceIdAndType(dt.deviceId, dt.deviceType),
                    newDeviceTelemetry
                );
            }
        });

        const unsortedDevicesWithTelemetry =
            deviceList.data?.map((device) => ({
                ...device,
                telemetry: deviceTelemetryMap.get(
                    formatDeviceIdAndType(device.deviceId, device.deviceType)
                ),
            })) ?? [];

        return [...unsortedDevicesWithTelemetry].sort((a, b) => {
            // place devices without telemetry at the bottom of the list
            const aHasTelemetry = a.telemetry !== undefined;
            const bHasTelemetry = b.telemetry !== undefined;

            if (aHasTelemetry && bHasTelemetry) {
                return compareNatural(a.displayIdAndType, b.displayIdAndType);
            }
            if (aHasTelemetry) {
                // sort a before b
                return -1;
            }
            // sort b before a
            return 1;
        });
    }, [allDeviceHistory, deviceList.data, latestTelemetry.data]);

    return {
        isLoading: deviceList.isLoading,
        error: deviceList.error,
        data: devicesWithTelemetry,
    };
}

export function getIconInfo(str: string): {
    name: MetadataDeviceType;
    color: string;
} {
    const [name, color] = str.split("-");

    return {
        name: name as MetadataDeviceType,
        color,
    };
}

export function createIconInfo(name: MetadataDeviceType, color: string) {
    return `${name}-${color}`;
}
