import { Blockquote, Button, Classes, Collapse, ICollapseProps } from "@blueprintjs/core";
import React, { useState } from "react";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";
import { createUseStyles } from "react-jss";
import variables from "styles/variables";

interface Props extends ICollapseProps {
    label: React.ReactNode;
    labelInfo?: React.ReactNode;

    /** @default true */
    initialIsOpen?: boolean;
}

const useStyles = createUseStyles({
    labelButton: {
        fontSize: variables.fontSizeSmall,
        fontWeight: 600,
        marginBottom: `calc(${variables.gridSize} / 2)`,
        padding: "0 7px 0 2px !important", // Need to override the "small" styles
    },
    labelInfo: {
        composes: `${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`,
        fontWeight: "normal",
    },
    blockquote: {
        marginLeft: "8px",
    },
});

const CollapsibleGroup: React.FunctionComponent<Props> = ({
    children,
    label,
    labelInfo,
    initialIsOpen = true,
    ...collapseProps
}) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(initialIsOpen);

    return (
        <div>
            <Button
                className={classes.labelButton}
                minimal
                small
                icon={isOpen ? <FaCaretDown /> : <FaCaretRight />}
                onClick={() => setIsOpen(!isOpen)}
            >
                {label}
                {labelInfo != null && (
                    <>
                        {" "}
                        <span className={classes.labelInfo}>{labelInfo}</span>
                    </>
                )}
            </Button>
            <Collapse {...collapseProps} isOpen={isOpen}>
                <Blockquote className={classes.blockquote}>{children}</Blockquote>
            </Collapse>
        </div>
    );
};

export default CollapsibleGroup;
