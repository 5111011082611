import { MetadataDeviceType } from "components/Metadata/Metadata.utils";
import { ReactComponent as Container } from "images/container.svg";
import React from "react";
import {
    FaBroadcastTower,
    FaRocket,
    FaSatellite,
    FaSatelliteDish,
    FaSprayCan,
    FaTractor,
    FaTrailer,
    FaTruck,
    FaTruckPickup,
} from "react-icons/fa";
import { GiElectric, GiFuelTank } from "react-icons/gi";
import { GoPackage } from "react-icons/go";
import { TbCrane, TbShip } from "react-icons/tb";
import { assertUnreachable } from "utils";

interface IIconForMetadataDeviceTypeProps {
    type: MetadataDeviceType;
    color?: string;
    size?: number;
}

export const IconForMetadataDeviceType: React.FunctionComponent<
    IIconForMetadataDeviceTypeProps
> = ({ type, color, size = 24 }) => {
    switch (type) {
        case MetadataDeviceType.Container:
            return <Container color={color} />;

        case MetadataDeviceType.Crane:
            return <TbCrane color={color} size={size} />;

        case MetadataDeviceType.Electrical:
            return <GiElectric color={color} size={size} />;

        case MetadataDeviceType.Package:
            return <GoPackage color={color} size={size} />;

        case MetadataDeviceType.Pickup:
            return <FaTruckPickup color={color} size={size} />;

        case MetadataDeviceType.PressureWasher:
            return <FaSprayCan color={color} size={size} />;

        case MetadataDeviceType.Rocket:
            return <FaRocket color={color} size={size} />;

        case MetadataDeviceType.Ship:
            return <TbShip color={color} size={size} />;

        case MetadataDeviceType.StorageTank:
            return <GiFuelTank color={color} size={size} />;

        case MetadataDeviceType.Tractor:
            return <FaTractor color={color} size={size} />;

        case MetadataDeviceType.Trailer:
            return <FaTrailer color={color} size={size} />;

        case MetadataDeviceType.Truck:
            return <FaTruck color={color} size={size} />;

        case MetadataDeviceType.Other:
            return <FaBroadcastTower color={color} size={size} />;

        case MetadataDeviceType.Satellite:
            return <FaSatellite color={color} size={size} />;

        case MetadataDeviceType.Modem:
            return <FaSatelliteDish color={color} size={size} />;

        case MetadataDeviceType.BroadcastTower:
            return <FaBroadcastTower color={color} size={size} />;

        default:
            return assertUnreachable(type);
    }
};
