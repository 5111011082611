/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Hive Service REST API
 * OpenAPI spec version: v1
 */

export type SatNetworkAssignmentAssignment =
    typeof SatNetworkAssignmentAssignment[keyof typeof SatNetworkAssignmentAssignment];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SatNetworkAssignmentAssignment = {
    commercial: "commercial",
    noncommercial: "noncommercial",
} as const;
