import {
    Button,
    Callout,
    Classes,
    Expander,
    FormGroup,
    InputGroup,
    TextArea,
    Toaster,
} from "@blueprintjs/core";
import { IDeviceFormValues } from "components/InternalDeviceDetail/InternalDeviceEditor.types";
import { Metadata } from "components/Metadata/Metadata";
import {
    metadataMutation,
    metadataObjectToArray,
    metadataWithDefaults,
} from "components/Metadata/Metadata.utils";
import OrganizationSelect from "components/OrganizationSelect";
import { SXCard } from "components/SXCard";
import { DeviceMetadata, usePutDevice } from "handlers/generated/hive";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { createUseStyles } from "react-jss";
import { useUIDSeed } from "react-uid";
import { QueryKey } from "resources/apiHandler";
import DeviceResource, { IDevice } from "resources/DeviceResource";
import { DeviceType } from "resources/DeviceResource.types";
import { globalQueryClient } from "resources/globalQueryClient";
import { useInvalidator } from "rest-hooks";
import { common } from "styles/common";
import { handleNetworkError } from "utils";

interface Props {
    device: IDevice;
}

const toaster = Toaster.create({ maxToasts: 1 });
const useStyles = createUseStyles(common);

// todo migrate over to IDevice
const InternalDeviceEditor: React.FunctionComponent<Props> = ({ device }) => {
    const classes = useStyles();
    const uidSeed = useUIDSeed();
    const [editing, setEditing] = useState(false);
    const [serverError, setServerError] = useState<string | undefined>(undefined);

    const defaultValues = useMemo(
        () => ({
            ...device,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            metadata: metadataObjectToArray(metadataWithDefaults(device)),
        }),
        [device]
    );

    const methods = useForm<IDeviceFormValues>({
        defaultValues: defaultValues,
        mode: "onChange",
    });

    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { control, handleSubmit, errors, formState, register, reset } = methods;

    // todo can be removed once the list page is using new resource pattern
    const invalidateDevices = useInvalidator(DeviceResource.listShape());

    const { isLoading: isDeviceUpdating, mutateAsync: mutationUpdateDevice } = usePutDevice({
        mutation: {
            async onSuccess() {
                await globalQueryClient.invalidateQueries(QueryKey.Devices);

                // todo remove when legacy DeviceResource is deleted
                invalidateDevices({ devicetype: DeviceType.FIELD });
            },
        },
    });

    const handleSave = useCallback(
        async (formValues: IDeviceFormValues) => {
            try {
                const data = await mutationUpdateDevice({
                    data: {
                        ...formValues,
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        metadata: metadataMutation(
                            device.metadata,
                            formValues.metadata
                        ) as DeviceMetadata,
                        deviceType: device.deviceType,
                        deviceId: device.deviceId,
                    },
                });

                toaster.show({
                    intent: "none",
                    message: data.message,
                    timeout: 10000,
                });

                setServerError(undefined);
                setEditing(false);
            } catch (error) {
                setServerError(handleNetworkError(error));
            }

            setServerError(undefined);
        },
        [device.deviceId, device.deviceType, device.metadata, mutationUpdateDevice]
    );

    const handleCancel = useCallback(() => {
        reset(defaultValues);
        setEditing(false);
        setServerError(undefined);
    }, [defaultValues, reset]);

    // Make sure the form defaults update when switching devices
    useEffect(handleCancel, [device, handleCancel]);

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleSave)}>
                <SXCard title="Details">
                    <FormGroup
                        label="Name"
                        labelFor={uidSeed("deviceName")}
                        intent={errors.deviceName ? "danger" : "none"}
                        helperText={errors.deviceName?.message}
                    >
                        <InputGroup
                            id={uidSeed("deviceName")}
                            name="deviceName"
                            inputRef={register({
                                required: "Required",
                                maxLength: { value: 255, message: "Max 255 characters" },
                            })}
                            intent={errors.deviceName ? "danger" : "none"}
                            disabled={!editing}
                        />
                    </FormGroup>
                    {device.deviceType === DeviceType.SATELLITE && (
                        <FormGroup
                            label="TLE Name"
                            labelFor={uidSeed("tleName")}
                            intent={errors.tleName ? "danger" : "none"}
                            helperText={errors.tleName?.message}
                        >
                            <InputGroup
                                id={uidSeed("tleName")}
                                name="tleName"
                                inputRef={register({
                                    maxLength: { value: 255, message: "Max 255 characters" },
                                })}
                                intent={errors.tleName ? "danger" : "none"}
                                placeholder="Not set"
                                disabled={!editing}
                            />
                        </FormGroup>
                    )}
                    <FormGroup
                        label="Comments"
                        labelFor={uidSeed("comments")}
                        intent={errors.comments ? "danger" : "none"}
                        helperText={errors.comments?.message}
                    >
                        <TextArea
                            id={uidSeed("comments")}
                            name="comments"
                            inputRef={register({
                                maxLength: { value: 255, message: "Max 255 characters" },
                            })}
                            intent={errors.comments ? "danger" : "none"}
                            fill
                            className={classes.textarea}
                            disabled={!editing}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Organization"
                        labelFor={uidSeed("organizationId")}
                        helperText={errors.organizationId?.message}
                        intent={errors.organizationId ? "danger" : "none"}
                    >
                        <Controller
                            id={uidSeed("organizationId")}
                            name="organizationId"
                            control={control}
                            rules={{ required: "Required" }}
                            render={({ value, onChange }) => (
                                <OrganizationSelect
                                    disabled={!editing}
                                    fill
                                    selectedKey={value as number}
                                    onSelectionChange={(item) => onChange(item.organizationId)}
                                />
                            )}
                        />
                    </FormGroup>
                    {/* Ground stations have a "server" version, everything else has a firmware version */}
                    {device.deviceType === DeviceType.GROUND_STATION ? (
                        <FormGroup
                            label="Server Version"
                            labelFor={uidSeed("serverVersion")}
                            intent={errors.serverVersion ? "danger" : "none"}
                            helperText={errors.serverVersion?.message}
                        >
                            <InputGroup
                                id={uidSeed("serverVersion")}
                                name="serverVersion"
                                inputRef={register({
                                    maxLength: { value: 255, message: "Max 255 characters" },
                                })}
                                intent={errors.serverVersion ? "danger" : "none"}
                                placeholder="Not set"
                                disabled={!editing}
                            />
                        </FormGroup>
                    ) : (
                        <FormGroup
                            label="Firmware Version"
                            labelFor={uidSeed("firmwareVersion")}
                            intent={errors.firmwareVersion ? "danger" : "none"}
                            helperText={errors.firmwareVersion?.message}
                        >
                            <InputGroup
                                id={uidSeed("firmwareVersion")}
                                name="firmwareVersion"
                                inputRef={register({
                                    maxLength: { value: 255, message: "Max 255 characters" },
                                })}
                                intent={errors.firmwareVersion ? "danger" : "none"}
                                placeholder="Not set"
                                disabled={!editing}
                            />
                        </FormGroup>
                    )}
                    {(device.deviceType === DeviceType.FIELD ||
                        device.deviceType === DeviceType.SATELLITE) && (
                        <>
                            <FormGroup
                                label="UID"
                                labelFor={uidSeed("deviceUid")}
                                intent={errors.deviceUid ? "danger" : "none"}
                                helperText={errors.deviceUid?.message}
                            >
                                <InputGroup
                                    id={uidSeed("deviceUid")}
                                    name="deviceUid"
                                    inputRef={register({
                                        pattern: {
                                            value: /^[0-9a-fA-F]{24}$/,
                                            message: "UID must be a 12-byte hex string (24 hex characters)",
                                        },
                                    })}
                                    intent={errors.deviceUid ? "danger" : "none"}
                                    placeholder="Not set"
                                    disabled={!editing}
                                />
                            </FormGroup>
                            {/* We can't use the blueprint Checkbox component because it mishandles the ref */}
                            <label
                                className={`${Classes.CONTROL} ${Classes.CHECKBOX}`}
                                htmlFor={uidSeed("dataEncryptionEnabled")}
                            >
                                <input
                                    id={uidSeed("dataEncryptionEnabled")}
                                    type="checkbox"
                                    name="dataEncryptionEnabled"
                                    ref={register()}
                                    disabled={!editing}
                                />
                                <span className={Classes.CONTROL_INDICATOR} />
                                Data Encryption
                            </label>
                        </>
                    )}
                    {device.deviceType === DeviceType.FIELD && (
                        <>
                            <label
                                className={`${Classes.CONTROL} ${Classes.CHECKBOX}`}
                                htmlFor={uidSeed("twoWayEnabled")}
                            >
                                <input
                                    id={uidSeed("twoWayEnabled")}
                                    type="checkbox"
                                    name="twoWayEnabled"
                                    ref={register()}
                                    disabled={!editing}
                                />
                                <span className={Classes.CONTROL_INDICATOR} />
                                Two Way
                            </label>
                            <label
                                className={`${Classes.CONTROL} ${Classes.CHECKBOX}`}
                                htmlFor={uidSeed("dataPlanPaid")}
                            >
                                <input
                                    id={uidSeed("dataPlanPaid")}
                                    type="checkbox"
                                    name="dataPlanPaid"
                                    ref={register()}
                                    disabled={!editing}
                                />
                                <span className={Classes.CONTROL_INDICATOR} />
                                Data Plan Paid
                            </label>
                        </>
                    )}
                    <label
                        className={`${Classes.CONTROL} ${Classes.CHECKBOX}`}
                        htmlFor={uidSeed("pushoverNotificationsEnabled")}
                    >
                        <input
                            id={uidSeed("pushoverNotificationsEnabled")}
                            type="checkbox"
                            name="pushoverNotificationsEnabled"
                            ref={register()}
                            disabled={!editing}
                        />
                        <span className={Classes.CONTROL_INDICATOR} />
                        Pushover Notifications
                    </label>
                    <label
                        className={`${Classes.CONTROL} ${Classes.CHECKBOX}`}
                        htmlFor={uidSeed("slackNotificationsEnabled")}
                    >
                        <input
                            id={uidSeed("slackNotificationsEnabled")}
                            type="checkbox"
                            name="slackNotificationsEnabled"
                            ref={register()}
                            disabled={!editing}
                        />
                        <span className={Classes.CONTROL_INDICATOR} />
                        Slack Notifications
                    </label>
                </SXCard>
                <Metadata editing={editing} allowSwarmPrefix />
                <FormGroup>
                    <div className={classes.flexWithSpacing}>
                        <Expander />

                        {editing && (
                            <Button onClick={handleCancel} disabled={formState.isSubmitting}>
                                Cancel
                            </Button>
                        )}

                        {editing && (
                            <Button
                                type="submit"
                                intent="primary"
                                disabled={
                                    !formState.isDirty || formState.isSubmitting || isDeviceUpdating
                                }
                                loading={isDeviceUpdating}
                            >
                                Save
                            </Button>
                        )}

                        {!editing && <Button onClick={() => setEditing(true)}>Edit</Button>}

                        {serverError !== undefined && (
                            <Callout intent="danger">Save Failed ({serverError})</Callout>
                        )}
                    </div>
                </FormGroup>
            </form>
        </FormProvider>
    );
};

export default InternalDeviceEditor;
