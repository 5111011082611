import { FormGroup, Spinner } from "@blueprintjs/core";
import { DateDisplay } from "components/DateDisplay";
import { DateDisplayTimeAgo } from "components/DateDisplayTimeAgo";
import CollapsibleGroup from "components/InternalDeviceDetail/CollapsibleGroup";
import { TelemetryDisplay } from "components/InternalDeviceDetail/TelemetryDisplay";
import { SXCard } from "components/SXCard";
import add from "date-fns/add";
import { useGetTelemetryInternal } from "handlers/generated/hive";
import React, { useRef } from "react";
import { IDevice } from "resources/DeviceResource";
import { SWARM_NETWORK_START, useTimezone } from "utils";

interface ILatestTelemetryProps extends React.HTMLProps<HTMLTableElement> {
    device: IDevice;
}

// Swarm did not exist before this, so no valid telemetry can be from before this

export const LatestTelemetry: React.FunctionComponent<ILatestTelemetryProps> = ({ device }) => {
    const { zoneAbbr } = useTimezone();

    // allow slight errors in GPS time computation
    // useRef() so we don't keep recomputing the endTime, causing an infinite number of requests
    const endTime = useRef<Date>(add(new Date(), { hours: 1 }));
    const { data: telemetry, isLoading: telemetryIsLoading } = useGetTelemetryInternal({
        srcDeviceType: device.deviceType,
        srcDeviceId: device.deviceId,
        startTime: SWARM_NETWORK_START,
        endTime: endTime.current,
        count: 5,
    });

    return (
        <SXCard title="Telemetry (latest 5)">
            {telemetryIsLoading && <Spinner />}
            {!telemetryIsLoading && telemetry?.length === 0 && (
                <FormGroup
                    label={`Last Heard (${zoneAbbr})`}
                    helperText={<DateDisplayTimeAgo unit="auto" date={device.hiveLastheardTime} />}
                >
                    <DateDisplay date={device.hiveLastheardTime} />
                </FormGroup>
            )}
            {!telemetryIsLoading &&
                telemetry?.map((telem, index) => (
                    <CollapsibleGroup
                        key={telem.packetId}
                        label={<DateDisplay date={telem.telemetryAt} />}
                        initialIsOpen={index === 0}
                    >
                        <TelemetryDisplay device={device} telemetry={telem} />
                    </CollapsibleGroup>
                ))}
        </SXCard>
    );
};
