import { TabId } from "pages/InternalOrganizations/OrganizationEditor";
import { generatePath } from "react-router-dom";

export const INTERNAL_ROOT = "/internal" as const;

export type OrganizationId = {
    /** On some pages you can pass "new" to create a new organization, "all" to list all organizations */
    organizationId?: string;
};

export type DeviceKey = {
    deviceKey?: string;
};

export type LatLon = {
    lat?: string;
    lon?: string;
};

type SentPacketKey = {
    sentPacketKey?: string;
};

type CommandToSend = {
    commandToSend?: string;
};

type MessageId = {
    messageId?: string;
};

type UserId = {
    userId?: string;
};

const internalSitemap = {
    login: {
        pattern: "/login",
        generatePath: (): string => internalSitemap.login.pattern,
    },
    logout: {
        pattern: "/logout",
        generatePath: (): string => internalSitemap.logout.pattern,
    },
    commands: {
        pattern: `${INTERNAL_ROOT}/commands/:deviceKey?/:sentPacketKey?`,
        generatePath: (params?: DeviceKey & SentPacketKey & CommandToSend): string => {
            const basePath = generatePath(internalSitemap.commands.pattern, params);

            if (params?.commandToSend) {
                return `${basePath}?commandToSend=${encodeURIComponent(params.commandToSend)}`;
            }

            return basePath;
        },
    },
    packetTools: {
        pattern: `${INTERNAL_ROOT}/packetTools`,
        generatePath: (): string => generatePath(internalSitemap.packetTools.pattern),
    },
    dashboard: {
        pattern: `${INTERNAL_ROOT}/`,
        generatePath: (): string => internalSitemap.dashboard.pattern,
    },
    devices: {
        pattern: `${INTERNAL_ROOT}/devices/:organizationId?/:deviceKey?`,
        generatePath: (params?: OrganizationId & DeviceKey): string =>
            generatePath(internalSitemap.devices.pattern, params),
    },
    map: {
        pattern: `${INTERNAL_ROOT}/map/:organizationId?/:deviceKey?`,
        generatePath: (params?: OrganizationId & DeviceKey & LatLon): string => {
            const basePath = generatePath(internalSitemap.map.pattern, params);

            if (params?.lat && params?.lon) {
                return `${basePath}?lat=${encodeURIComponent(params.lat)}&lon=${encodeURIComponent(
                    params.lon
                )}`;
            }

            return basePath;
        },
    },
    messages: {
        pattern: `${INTERNAL_ROOT}/messages/:organizationId?/:messageId?`,
        /**
         * @param params pass "new" as messageId to send a new message
         */
        generatePath: (params?: OrganizationId & MessageId): string =>
            generatePath(internalSitemap.messages.pattern, params),
    },
    networkManagement: {
        pattern: `${INTERNAL_ROOT}/networkManagement/:deviceKey?`,
        generatePath: (params?: DeviceKey): string =>
            generatePath(internalSitemap.networkManagement.pattern, params),
    },
    // todo delete
    users: {
        // todo standardize this route (/users/:userId?)
        pattern: [`${INTERNAL_ROOT}/users`, `${INTERNAL_ROOT}/users/user/:userId`],
        generatePath: (params?: UserId): string =>
            generatePath(
                params?.userId == null
                    ? `${INTERNAL_ROOT}/users`
                    : `${INTERNAL_ROOT}/users/user/:userId`,
                params
            ),
    },
    // todo standardize this route - users should be one route route with "new" passed in as userId
    newUser: {
        pattern: `${INTERNAL_ROOT}/users/new`,
        generatePath: (): string => internalSitemap.newUser.pattern,
    },
    APIDocs: {
        pattern: `${INTERNAL_ROOT}/apiDocs`,
        generatePath: (): string => generatePath(internalSitemap.APIDocs.pattern),
    },
    organizations: {
        pattern: [
            `${INTERNAL_ROOT}/organizations/:organizationId?/:tab?`,
            `${INTERNAL_ROOT}/organizations/:organizationId/devices/:deviceKey?`,
            `${INTERNAL_ROOT}/organizations/:organizationId/:tab?/:stripeInvoiceId?/:deviceKey?`,
        ],
        generatePath: (params?: {
            organizationId?: string;
            tab?: TabId;
            deviceKey?: string;
            stripeInvoiceId?: string;
        }): string => {
            switch (params?.tab) {
                case TabId.devices:
                    return generatePath(internalSitemap.organizations.pattern[1], params);
                case TabId.payment:
                    return generatePath(internalSitemap.organizations.pattern[2], params);
                default:
                    return generatePath(internalSitemap.organizations.pattern[0], params);
            }
        },
    },
    metrics: {
        pattern: `${INTERNAL_ROOT}/metrics`,
        generatePath: (): string => generatePath(internalSitemap.metrics.pattern),
    },
    invoice: {
        pattern: `${INTERNAL_ROOT}/invoice/:organizationId/:stripeInvoiceId/:deviceKey?`,
        generatePath: (params: {
            organizationId: string;
            stripeInvoiceId: string;
            deviceKey?: string;
        }): string => generatePath(internalSitemap.invoice.pattern, params),
    },
};

// This type is meant to be used with react-router's useParams(), e.g.:
// const { foo, bar } = useParams<RouteParams<'myPage'>>();
export type RouteParams<T extends keyof typeof internalSitemap> = NonNullable<
    Parameters<typeof internalSitemap[T]["generatePath"]>[0]
>;

export default internalSitemap;
