import { Tag } from "@blueprintjs/core";
import React from "react";

interface Props {
    status: string | undefined;
}

export const StatusTag: React.FunctionComponent<Props> = ({ status }) => {
    switch (status) {
        case "paid":
            return (
                <Tag minimal round large intent="success">
                    Paid
                </Tag>
            );
        case "draft":
            return (
                <Tag minimal round large intent="primary">
                    Upcoming
                </Tag>
            );
        case "void":
            return (
                <Tag minimal round large intent="none">
                    Void
                </Tag>
            );
        case "open":
            return (
                <Tag minimal round large intent="warning">
                    Open
                </Tag>
            );
        default:
            return (
                <Tag minimal round large intent="danger">
                    Unknown
                </Tag>
            );
    }
};
