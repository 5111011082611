import { Classes } from "@blueprintjs/core";
import { DeviceLogo } from "components/DeviceMap/DeviceLogo";
import { getIcon, getIconColor } from "components/DeviceMap/map.utils";
import { Device } from "handlers/generated/hive";
import React from "react";
import DeviceResource, { deviceDisplayName } from "resources/DeviceResource";
import SimpleSelect, { Props as SimpleSelectProps } from "./SimpleSelect";

type Props = Omit<
    SimpleSelectProps<Readonly<DeviceResource>>,
    "renderItem" | "renderSelectedItem" | "getMenuItemLabel" | "itemSearchText"
>;

export const renderSelectedDevice = (
    device: Readonly<DeviceResource> | undefined
): NonNullable<React.ReactNode> => {
    if (device === undefined) {
        return "";
    }

    if (device.deviceId === undefined || device.deviceId < 0) {
        return device.deviceName;
    }

    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <DeviceLogo type={getIcon(device)} size={12} color={getIconColor(device)} />
            {device.deviceName} ({device.displayIdAndType})
        </div>
    );
};

const getDeviceMenuItemLabel = ({ deviceId, displayIdAndType }: Readonly<DeviceResource>): string =>
    // Don't show IDs for the special options (which all have negative IDs)
    deviceId == null || deviceId < 0 ? "" : displayIdAndType;

export const deviceSearchText = (device: Readonly<DeviceResource>): string =>
    `${device.deviceName} ${
        device.comments?.length > 0 ? device.comments : ""
    } ${getDeviceMenuItemLabel(device)}`;

const optionHeight = 40;
const optionPadding = 10;
const optionItemHeight = optionHeight + optionPadding;

export const renderDeviceOption = (device: Device): NonNullable<React.ReactNode> => (
    <div
        style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        }}
    >
        <DeviceLogo type={getIcon(device)} size={12} color={getIconColor(device)} />
        <div>{deviceDisplayName(device)}</div>
    </div>
);

/** @deprecated */
export const legacyRenderDeviceOption = (
    device: Readonly<DeviceResource>
): NonNullable<React.ReactNode> => (
    <div
        style={{
            height: optionHeight,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        }}
    >
        <DeviceLogo type={getIcon(device)} size={24} color={getIconColor(device)} />
        <div>
            <div>{device.deviceName}</div>
            {device.comments?.length > 0 && (
                <div className={Classes.TEXT_SMALL}>{device.comments}</div>
            )}
        </div>
    </div>
);

export const DeviceSelect: React.FunctionComponent<Props> = (props) => (
    <SimpleSelect<Readonly<DeviceResource>>
        renderItem={legacyRenderDeviceOption}
        renderSelectedItem={renderSelectedDevice}
        getMenuItemLabel={getDeviceMenuItemLabel}
        itemSearchText={deviceSearchText}
        virtualScrollProps={{
            height: 400,
            width: "100%",
            itemSize: optionItemHeight,
            overscanCount: 5,
        }}
        fill
        {...props}
    />
);
