import { H2 } from "@blueprintjs/core";
import React from "react";
import { Helmet } from "react-helmet";

interface IPageTitleProps {
    children: string;
    /**
     * Display the header
     * @default true
     */
    displayHeader?: boolean;

    className?: string;
}

export const PageTitle: React.FunctionComponent<IPageTitleProps> = ({
    children,
    displayHeader = true,
    className,
}) => {
    return (
        <>
            <Helmet>
                <title>{children}</title>
            </Helmet>
            {displayHeader && <H2 className={className}>{children}</H2>}
        </>
    );
};
