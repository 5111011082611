import {
    Button,
    Classes,
    IButtonProps,
    IInputGroupProps2,
    Menu,
    MenuItem,
} from "@blueprintjs/core";
import {
    IItemListRendererProps,
    ItemPredicate,
    ItemRenderer,
    ItemsEqualComparator,
    Select2,
} from "@blueprintjs/select";
import React, { ReactNode, useCallback, useMemo } from "react";
import { createUseStyles } from "react-jss";
import { FixedSizeList, FixedSizeListProps } from "react-window";
import { includesIgnoringCase } from "utils";

export interface Props<OptionType> {
    items: OptionType[];
    getKey: (item: OptionType) => React.Key;
    buttonProps?: IButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>;
    inputProps?: IInputGroupProps2;
    getMenuItemLabel?: (item: OptionType) => string | undefined;

    /**
     * callback to render the content of each select option
     * @default item content and label
     */
    renderItem: (item: OptionType) => ReactNode; // NonNullable<React.ReactNode>;

    /**
     * callback to get the text value of each select option - used for filtering
     */
    itemSearchText: (item: OptionType) => string;

    /**
     * callback to render the content of the SELECTED item, this item appears in the dropdown button
     * Normally this differs from renderItem because it fits on a single line
     * The itme will be undefined when no itme is selected
     */
    renderSelectedItem: (item: OptionType | undefined) => ReactNode;

    /**
     * the currently selected items key
     */
    selectedKey: React.Key | undefined;

    /**
     * callback when the selected option changes. The key of this item should be passed into the selectedKey prop
     */
    onSelectionChange: (selectedItem: OptionType) => void;

    disabled?: boolean;
    loading?: boolean;

    /**
     * Select will fill the full width of its container
     * @default false
     */
    fill?: boolean;

    filterable?: boolean;

    /**
     * Props for virtual scrolling the options dropdown (for performance)
     * @default undefined do not use virtual scrolling
     */
    virtualScrollProps?: Omit<FixedSizeListProps<OptionType>, "itemCount" | "children">;

    /**
     * Determine if the given item is disabled. Provide a callback function, or
     * simply provide the name of a boolean property on the item that exposes
     * its disabled state.
     */
    itemDisabled?: (item: OptionType, index: number) => boolean;
}

const useStyles = createUseStyles({
    selectOverflow: {
        [`& .${Classes.MENU}`]: {
            maxHeight: 500,
            overflowY: "auto",
        },
    },
    selectTrigger: {
        minWidth: 200,
    },
});

function SimpleSelect<OptionType>({
    items,
    renderItem,
    itemSearchText,
    renderSelectedItem,
    getKey,
    getMenuItemLabel = () => undefined,
    selectedKey,
    onSelectionChange,
    buttonProps,
    inputProps,
    loading,
    disabled,
    filterable,
    fill = false,
    virtualScrollProps,
    itemDisabled,
}: Props<OptionType>): React.ReactElement<Props<OptionType>> {
    const classes = useStyles();
    const noItemsMessage = useMemo(
        () => (items.length === 0 ? "None available" : "No matches"),
        [items.length]
    );

    const selectedItem = useMemo(
        () => items.find((item) => getKey(item) === selectedKey),
        [items, getKey, selectedKey]
    );

    const itemPredicate: ItemPredicate<OptionType> = useCallback(
        (query: string, item: OptionType) => includesIgnoringCase(itemSearchText(item), query),
        [itemSearchText]
    );

    const itemsEqual: ItemsEqualComparator<OptionType> = useCallback(
        (a: OptionType, b: OptionType) => getKey(a) === getKey(b),
        [getKey]
    );

    const handleItemSelect = useCallback(
        (newSelection: OptionType): void => {
            onSelectionChange(newSelection);
        },
        [onSelectionChange]
    );

    const renderItemWrapper: ItemRenderer<OptionType> = useCallback(
        (item, { modifiers, handleClick }) => {
            if (!modifiers.matchesPredicate) {
                return null;
            }
            return (
                <MenuItem
                    active={modifiers.active}
                    key={getKey(item).toString()}
                    onClick={handleClick}
                    shouldDismissPopover={false}
                    label={getMenuItemLabel(item)}
                    text={renderItem(item)}
                    disabled={modifiers.disabled}
                />
            );
        },
        [getKey, getMenuItemLabel, renderItem]
    );

    const itemListRenderer = useCallback(
        (itemListProps: IItemListRendererProps<OptionType>) => {
            if (itemListProps.items.length === 0) {
                return (
                    <Menu ulRef={itemListProps.itemsParentRef}>
                        <MenuItem disabled text={noItemsMessage} />
                    </Menu>
                );
            }

            return (
                <Menu ulRef={itemListProps.itemsParentRef}>
                    <FixedSizeList
                        itemCount={itemListProps.filteredItems.length}
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        {...virtualScrollProps!}
                    >
                        {({ index, style }) => (
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                            <div style={style}>
                                {itemListProps.renderItem(
                                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                                    itemListProps.filteredItems[index],
                                    index
                                )}
                            </div>
                        )}
                    </FixedSizeList>
                </Menu>
            );
        },
        [noItemsMessage, virtualScrollProps]
    );

    const TypedSelect = Select2.ofType<OptionType>();

    return (
        <TypedSelect
            inputProps={inputProps}
            items={items}
            itemRenderer={renderItemWrapper}
            itemPredicate={itemPredicate}
            itemsEqual={itemsEqual}
            onItemSelect={handleItemSelect}
            noResults={<MenuItem disabled text={noItemsMessage} />}
            popoverProps={{
                minimal: true,
                popoverClassName: classes.selectOverflow,
                matchTargetWidth: fill,
            }}
            disabled={disabled}
            activeItem={selectedItem}
            fill={fill}
            filterable={filterable}
            itemListRenderer={virtualScrollProps === undefined ? undefined : itemListRenderer}
            itemDisabled={itemDisabled}
        >
            <Button
                className={classes.selectTrigger}
                alignText="left"
                fill={fill}
                {...buttonProps}
                rightIcon="caret-down"
                disabled={disabled}
                loading={loading}
            >
                {renderSelectedItem(selectedItem)}{" "}
            </Button>
        </TypedSelect>
    );
}

export default SimpleSelect;
