import { Classes, FocusStyleManager } from "@blueprintjs/core";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { AppProvider, UserContextProvider } from "AppProvider";
import { createBrowserHistory } from "history";
import moment from "moment-timezone";
import React, { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom";
import { IconContext } from "react-icons/lib";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { CacheProvider, NetworkErrorBoundary } from "rest-hooks";
import { ErrorBoundary } from "./ErrorBoundary";
import "./index.scss";
import { INTERNAL_ROOT } from "./internalSitemap";
import InternalNetworkErrorPage from "./pages/InternalNetworkErrorPage";
import NetworkErrorPage from "./pages/NetworkErrorPage";
import sitemap from "./sitemap";
import { PREFERRED_FORMAT_SECONDS } from "./utils";

const history = createBrowserHistory();

Sentry.init({
    dsn: "https://7be79f192e7f44fa8996b7f5118129b5@o1085220.ingest.sentry.io/6095721",
    environment: window.location.hostname,
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
    ],
    tracesSampleRate: 0.05, // 5% sample rate
});

const App = React.lazy(() => import("./App"));
const InternalApp = React.lazy(() => import("./InternalApp"));
const ForgotPassword = React.lazy(() => import("./pages/ForgotPassword"));
const RegisterOrResetPassword = React.lazy(() => import("./pages/RegisterOrResetPassword"));
const PassChecker = React.lazy(() => import("./pages/PassChecker"));
const Login = React.lazy(() => import("./pages/Login"));

// Recommended by blueprint, see https://blueprintjs.com/docs/#core/accessibility.focus-management
FocusStyleManager.onlyShowFocusOnTabs();

// Set the default date format for the whole app
moment.defaultFormat = PREFERRED_FORMAT_SECONDS;

// createRoot
ReactDOM.render(
    <StrictMode>
        <AppProvider>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                {/* Style react-icons to match blueprint's default icon styling */}
                <IconContext.Provider value={{ className: Classes.ICON, size: "16" }}>
                    {/* TODO: put error boundaries inside the pages as well */}
                    <ErrorBoundary>
                        <Suspense fallback={<div />}>
                            <Switch>
                                <Route path={sitemap.login.pattern} exact>
                                    <Login />
                                </Route>
                                <Route path={sitemap.forgotPassword.pattern} exact>
                                    <ForgotPassword />
                                </Route>
                                <Route path={sitemap.register.pattern} exact>
                                    <RegisterOrResetPassword registered={false} />
                                </Route>
                                <Route path={sitemap.resetPassword.pattern} exact>
                                    <RegisterOrResetPassword registered />
                                </Route>
                                <Route path={INTERNAL_ROOT}>
                                    <CacheProvider>
                                        <NetworkErrorBoundary
                                            fallbackComponent={InternalNetworkErrorPage}
                                        >
                                            <UserContextProvider>
                                                <InternalApp />
                                            </UserContextProvider>
                                        </NetworkErrorBoundary>
                                    </CacheProvider>
                                </Route>
                                <Route path="/pass-checker/">
                                    <PassChecker />
                                </Route>
                                <Route path="/">
                                    <CacheProvider>
                                        <NetworkErrorBoundary fallbackComponent={NetworkErrorPage}>
                                            <UserContextProvider>
                                                <App />
                                            </UserContextProvider>
                                        </NetworkErrorBoundary>
                                    </CacheProvider>
                                </Route>
                            </Switch>
                        </Suspense>
                    </ErrorBoundary>
                </IconContext.Provider>
            </BrowserRouter>
        </AppProvider>
    </StrictMode>,
    document.getElementById("root")
);
