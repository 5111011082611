import { Organization } from "handlers/generated/hive";
import { PageSkeleton } from "PageSkeleton";
import { FunctionComponent, lazy, Suspense } from "react";

const Settings = lazy(() => import("../Settings"));

interface IOrganizationUsersPanelProps {
    organizationData: Organization;
}

export const OrganizationUsersPanel: FunctionComponent<IOrganizationUsersPanelProps> = ({
    organizationData,
}) => {
    return (
        <Suspense fallback={<PageSkeleton />}>
            <Settings organizationId={organizationData.organizationId} />
        </Suspense>
    );
};
