/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Billing Service REST API
 * OpenAPI spec version: v1
 */
import { useQuery, useMutation } from "react-query";
import type {
    UseQueryOptions,
    UseMutationOptions,
    QueryFunction,
    MutationFunction,
    UseQueryResult,
    QueryKey,
} from "react-query";
import type {
    StatusJson,
    ApiError,
    UpdatePushBillingJson,
    SwarmStripeCustomer,
    CustomerCreationStatusJson,
    NewStripeCustomerJson,
    LinkStripeCustomerJson,
    PaymentMethods,
    PaymentDelete,
    DeletePaymentMethodJson,
    PaymentUpdate,
    UpdatePaymentMethodJson,
    SwarmCustomer,
    PaymentSetupIntent,
} from "../models";
import { apiHandler } from "../../../../resources/apiHandler";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
    config: any,
    args: infer P
) => any
    ? P
    : never;

/**
 * Return 403 (Forbidden) if the user doesn't have access to modify the billing setting for a customer.
 * @summary PUT billing setting for a customer
 */
export const updateManualBillPay = (
    organizationId: number,
    updatePushBillingJson: UpdatePushBillingJson,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<StatusJson>(
        {
            url: `/api/v1/billing/customers/${organizationId}/manualBillPay`,
            method: "put",
            headers: { "Content-Type": "application/json" },
            data: updatePushBillingJson,
        },
        options
    );
};

export const getUpdateManualBillPayMutationOptions = <
    TError = ApiError,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof updateManualBillPay>>,
        TError,
        { organizationId: number; data: UpdatePushBillingJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof updateManualBillPay>>,
    TError,
    { organizationId: number; data: UpdatePushBillingJson },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof updateManualBillPay>>,
        { organizationId: number; data: UpdatePushBillingJson }
    > = (props) => {
        const { organizationId, data } = props ?? {};

        return updateManualBillPay(organizationId, data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type UpdateManualBillPayMutationResult = NonNullable<
    Awaited<ReturnType<typeof updateManualBillPay>>
>;
export type UpdateManualBillPayMutationBody = UpdatePushBillingJson;
export type UpdateManualBillPayMutationError = ApiError;

/**
 * @summary PUT billing setting for a customer
 */
export const useUpdateManualBillPay = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof updateManualBillPay>>,
        TError,
        { organizationId: number; data: UpdatePushBillingJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getUpdateManualBillPayMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Return 403 (Forbidden) if the user doesn't have access to the organization.
 * @summary GET a customer by organization id.
 */
export const getCustomer = (
    organizationId: number,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<SwarmStripeCustomer>(
        { url: `/api/v1/billing/customers/${organizationId}`, method: "get", signal },
        options
    );
};

export const getGetCustomerQueryKey = (organizationId: number) =>
    [`/api/v1/billing/customers/${organizationId}`] as const;

export const getGetCustomerQueryOptions = <
    TData = Awaited<ReturnType<typeof getCustomer>>,
    TError = ApiError
>(
    organizationId: number,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getCustomer>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getCustomer>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetCustomerQueryKey(organizationId);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomer>>> = ({ signal }) =>
        getCustomer(organizationId, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!organizationId, ...queryOptions };
};

export type GetCustomerQueryResult = NonNullable<Awaited<ReturnType<typeof getCustomer>>>;
export type GetCustomerQueryError = ApiError;

/**
 * @summary GET a customer by organization id.
 */
export const useGetCustomer = <TData = Awaited<ReturnType<typeof getCustomer>>, TError = ApiError>(
    organizationId: number,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getCustomer>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetCustomerQueryOptions(organizationId, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary POST create a new Stripe customer with the given organization details.
 */
export const createCustomer = (
    organizationId: number,
    newStripeCustomerJson: NewStripeCustomerJson,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<CustomerCreationStatusJson>(
        {
            url: `/api/v1/billing/customers/${organizationId}`,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: newStripeCustomerJson,
        },
        options
    );
};

export const getCreateCustomerMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof createCustomer>>,
        TError,
        { organizationId: number; data: NewStripeCustomerJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof createCustomer>>,
    TError,
    { organizationId: number; data: NewStripeCustomerJson },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof createCustomer>>,
        { organizationId: number; data: NewStripeCustomerJson }
    > = (props) => {
        const { organizationId, data } = props ?? {};

        return createCustomer(organizationId, data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type CreateCustomerMutationResult = NonNullable<Awaited<ReturnType<typeof createCustomer>>>;
export type CreateCustomerMutationBody = NewStripeCustomerJson;
export type CreateCustomerMutationError = ApiError;

/**
 * @summary POST create a new Stripe customer with the given organization details.
 */
export const useCreateCustomer = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof createCustomer>>,
        TError,
        { organizationId: number; data: NewStripeCustomerJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getCreateCustomerMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary POST link an organization to an existing Stripe customer. Temporary route for data onboarding.
 */
export const linkCustomer = (
    organizationId: number,
    linkStripeCustomerJson: LinkStripeCustomerJson,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<CustomerCreationStatusJson>(
        {
            url: `/api/v1/billing/customers/link/${organizationId}`,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: linkStripeCustomerJson,
        },
        options
    );
};

export const getLinkCustomerMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof linkCustomer>>,
        TError,
        { organizationId: number; data: LinkStripeCustomerJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof linkCustomer>>,
    TError,
    { organizationId: number; data: LinkStripeCustomerJson },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof linkCustomer>>,
        { organizationId: number; data: LinkStripeCustomerJson }
    > = (props) => {
        const { organizationId, data } = props ?? {};

        return linkCustomer(organizationId, data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type LinkCustomerMutationResult = NonNullable<Awaited<ReturnType<typeof linkCustomer>>>;
export type LinkCustomerMutationBody = LinkStripeCustomerJson;
export type LinkCustomerMutationError = ApiError;

/**
 * @summary POST link an organization to an existing Stripe customer. Temporary route for data onboarding.
 */
export const useLinkCustomer = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof linkCustomer>>,
        TError,
        { organizationId: number; data: LinkStripeCustomerJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getLinkCustomerMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary GET payment methods without any PCI info for a customer.
 */
export const getPaymentMethods = (
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<PaymentMethods>(
        { url: `/api/v1/billing/customers/payment/methods`, method: "get", signal },
        options
    );
};

export const getGetPaymentMethodsQueryKey = () =>
    [`/api/v1/billing/customers/payment/methods`] as const;

export const getGetPaymentMethodsQueryOptions = <
    TData = Awaited<ReturnType<typeof getPaymentMethods>>,
    TError = ApiError
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPaymentMethods>>, TError, TData>;
    request?: SecondParameter<typeof apiHandler>;
}): UseQueryOptions<Awaited<ReturnType<typeof getPaymentMethods>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetPaymentMethodsQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPaymentMethods>>> = ({ signal }) =>
        getPaymentMethods(requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetPaymentMethodsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getPaymentMethods>>
>;
export type GetPaymentMethodsQueryError = ApiError;

/**
 * @summary GET payment methods without any PCI info for a customer.
 */
export const useGetPaymentMethods = <
    TData = Awaited<ReturnType<typeof getPaymentMethods>>,
    TError = ApiError
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPaymentMethods>>, TError, TData>;
    request?: SecondParameter<typeof apiHandler>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetPaymentMethodsQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Return 403 (Forbidden) if the user doesn't have access to delete this payment method.
 * @summary Delete payment method.
 */
export const deletePaymentMethod = (
    deletePaymentMethodJson: DeletePaymentMethodJson,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<PaymentDelete>(
        {
            url: `/api/v1/billing/customers/payment/methods`,
            method: "delete",
            headers: { "Content-Type": "application/json" },
            data: deletePaymentMethodJson,
        },
        options
    );
};

export const getDeletePaymentMethodMutationOptions = <
    TError = ApiError,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deletePaymentMethod>>,
        TError,
        { data: DeletePaymentMethodJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof deletePaymentMethod>>,
    TError,
    { data: DeletePaymentMethodJson },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deletePaymentMethod>>,
        { data: DeletePaymentMethodJson }
    > = (props) => {
        const { data } = props ?? {};

        return deletePaymentMethod(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DeletePaymentMethodMutationResult = NonNullable<
    Awaited<ReturnType<typeof deletePaymentMethod>>
>;
export type DeletePaymentMethodMutationBody = DeletePaymentMethodJson;
export type DeletePaymentMethodMutationError = ApiError;

/**
 * @summary Delete payment method.
 */
export const useDeletePaymentMethod = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deletePaymentMethod>>,
        TError,
        { data: DeletePaymentMethodJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getDeletePaymentMethodMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Return 403 (Forbidden) if the user doesn't have access this payment method.
 * @summary Set payment method as default.
 */
export const defaultPaymentMethod = (
    updatePaymentMethodJson: UpdatePaymentMethodJson,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<PaymentUpdate>(
        {
            url: `/api/v1/billing/customers/payment/methods`,
            method: "patch",
            headers: { "Content-Type": "application/json" },
            data: updatePaymentMethodJson,
        },
        options
    );
};

export const getDefaultPaymentMethodMutationOptions = <
    TError = ApiError,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof defaultPaymentMethod>>,
        TError,
        { data: UpdatePaymentMethodJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof defaultPaymentMethod>>,
    TError,
    { data: UpdatePaymentMethodJson },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof defaultPaymentMethod>>,
        { data: UpdatePaymentMethodJson }
    > = (props) => {
        const { data } = props ?? {};

        return defaultPaymentMethod(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DefaultPaymentMethodMutationResult = NonNullable<
    Awaited<ReturnType<typeof defaultPaymentMethod>>
>;
export type DefaultPaymentMethodMutationBody = UpdatePaymentMethodJson;
export type DefaultPaymentMethodMutationError = ApiError;

/**
 * @summary Set payment method as default.
 */
export const useDefaultPaymentMethod = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof defaultPaymentMethod>>,
        TError,
        { data: UpdatePaymentMethodJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getDefaultPaymentMethodMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary GET all accessible Stripe customers.
 */
export const getCustomers = (
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<SwarmStripeCustomer[]>(
        { url: `/api/v1/billing/customers`, method: "get", signal },
        options
    );
};

export const getGetCustomersQueryKey = () => [`/api/v1/billing/customers`] as const;

export const getGetCustomersQueryOptions = <
    TData = Awaited<ReturnType<typeof getCustomers>>,
    TError = ApiError
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCustomers>>, TError, TData>;
    request?: SecondParameter<typeof apiHandler>;
}): UseQueryOptions<Awaited<ReturnType<typeof getCustomers>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetCustomersQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomers>>> = ({ signal }) =>
        getCustomers(requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetCustomersQueryResult = NonNullable<Awaited<ReturnType<typeof getCustomers>>>;
export type GetCustomersQueryError = ApiError;

/**
 * @summary GET all accessible Stripe customers.
 */
export const useGetCustomers = <
    TData = Awaited<ReturnType<typeof getCustomers>>,
    TError = ApiError
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCustomers>>, TError, TData>;
    request?: SecondParameter<typeof apiHandler>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetCustomersQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary GET all Swarm organizations with registered customer ids.
 */
export const getSwarmCustomers = (
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<SwarmCustomer[]>(
        { url: `/api/v1/billing/customers/swarm`, method: "get", signal },
        options
    );
};

export const getGetSwarmCustomersQueryKey = () => [`/api/v1/billing/customers/swarm`] as const;

export const getGetSwarmCustomersQueryOptions = <
    TData = Awaited<ReturnType<typeof getSwarmCustomers>>,
    TError = ApiError
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getSwarmCustomers>>, TError, TData>;
    request?: SecondParameter<typeof apiHandler>;
}): UseQueryOptions<Awaited<ReturnType<typeof getSwarmCustomers>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetSwarmCustomersQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSwarmCustomers>>> = ({ signal }) =>
        getSwarmCustomers(requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetSwarmCustomersQueryResult = NonNullable<
    Awaited<ReturnType<typeof getSwarmCustomers>>
>;
export type GetSwarmCustomersQueryError = ApiError;

/**
 * @summary GET all Swarm organizations with registered customer ids.
 */
export const useGetSwarmCustomers = <
    TData = Awaited<ReturnType<typeof getSwarmCustomers>>,
    TError = ApiError
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getSwarmCustomers>>, TError, TData>;
    request?: SecondParameter<typeof apiHandler>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetSwarmCustomersQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary GET create a setup intent for a payment.
 */
export const getPaymentSetup = (
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<PaymentSetupIntent>(
        { url: `/api/v1/billing/customers/payment/setup`, method: "get", signal },
        options
    );
};

export const getGetPaymentSetupQueryKey = () =>
    [`/api/v1/billing/customers/payment/setup`] as const;

export const getGetPaymentSetupQueryOptions = <
    TData = Awaited<ReturnType<typeof getPaymentSetup>>,
    TError = ApiError
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPaymentSetup>>, TError, TData>;
    request?: SecondParameter<typeof apiHandler>;
}): UseQueryOptions<Awaited<ReturnType<typeof getPaymentSetup>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetPaymentSetupQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPaymentSetup>>> = ({ signal }) =>
        getPaymentSetup(requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetPaymentSetupQueryResult = NonNullable<Awaited<ReturnType<typeof getPaymentSetup>>>;
export type GetPaymentSetupQueryError = ApiError;

/**
 * @summary GET create a setup intent for a payment.
 */
export const useGetPaymentSetup = <
    TData = Awaited<ReturnType<typeof getPaymentSetup>>,
    TError = ApiError
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPaymentSetup>>, TError, TData>;
    request?: SecondParameter<typeof apiHandler>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetPaymentSetupQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};
