/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Hive Service REST API
 * OpenAPI spec version: v1
 */

export type UserContextRole = typeof UserContextRole[keyof typeof UserContextRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserContextRole = {
    SUPER_ADMIN: "SUPER_ADMIN",
    ADMIN: "ADMIN",
    USER: "USER",
    GROUND_STATION: "GROUND_STATION",
    TOOLS: "TOOLS",
} as const;
