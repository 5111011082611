import React from "react";
import { formatPlural } from "utils";

interface IFormatPluralProps {
    singular: string;
    plural: string;
    count: number;
}

/**
 * @example <FormatPlural singular="item" plural="items" count={list.length} />
 * @see formatPlural util function
 */
export const FormatPlural: React.FunctionComponent<IFormatPluralProps> = ({
    singular,
    plural,
    count,
}) => {
    return <>{formatPlural(singular, plural, count)}</>;
};
