/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Hive Service REST API
 * OpenAPI spec version: v1
 */
import { useQuery, useMutation } from "react-query";
import type {
    UseQueryOptions,
    UseMutationOptions,
    QueryFunction,
    MutationFunction,
    UseQueryResult,
    QueryKey,
} from "react-query";
import type {
    Packet,
    ApiError,
    GetPacketsParams,
    PacketPostReturn,
    DeletePackets1Params,
    PacketAck,
    GsUplinkExpired,
    UserMessage,
    PacketGetReturn,
    GetPacketsForTxDeviceParams,
    GetPacketCountURIParams,
    PacketForDisplay,
    GetPacketsForDisplayParams,
    GetPacketCountParams,
} from "../models";
import { apiHandler } from "../../../../resources/apiHandler";

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y
    ? 1
    : 2
    ? A
    : B;

type WritableKeys<T> = {
    [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>;
}[keyof T];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void
    ? I
    : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
    ? {
          [P in keyof Writable<T>]: T[P] extends object ? NonReadonly<NonNullable<T[P]>> : T[P];
      }
    : DistributeReadOnlyOverUnions<T>;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
    config: any,
    args: infer P
) => any
    ? P
    : never;

/**
 * Get a JSON array of packets matching the given parameters.
 * @summary GET packets
 */
export const getPackets = (
    params?: GetPacketsParams,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<Packet[]>(
        { url: `/hive/api/v1/packets`, method: "get", params, signal },
        options
    );
};

export const getGetPacketsQueryKey = (params?: GetPacketsParams) =>
    [`/hive/api/v1/packets`, ...(params ? [params] : [])] as const;

export const getGetPacketsQueryOptions = <
    TData = Awaited<ReturnType<typeof getPackets>>,
    TError = ApiError
>(
    params?: GetPacketsParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getPackets>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getPackets>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetPacketsQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPackets>>> = ({ signal }) =>
        getPackets(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetPacketsQueryResult = NonNullable<Awaited<ReturnType<typeof getPackets>>>;
export type GetPacketsQueryError = ApiError;

/**
 * @summary GET packets
 */
export const useGetPackets = <TData = Awaited<ReturnType<typeof getPackets>>, TError = ApiError>(
    params?: GetPacketsParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getPackets>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetPacketsQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Post a packet in JSON form.
 * @summary POST packet
 */
export const addPacketJSON = (
    packet: NonReadonly<Packet>,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<PacketPostReturn>(
        {
            url: `/hive/api/v1/packets`,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: packet,
        },
        options
    );
};

export const getAddPacketJSONMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof addPacketJSON>>,
        TError,
        { data: NonReadonly<Packet> },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof addPacketJSON>>,
    TError,
    { data: NonReadonly<Packet> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof addPacketJSON>>,
        { data: NonReadonly<Packet> }
    > = (props) => {
        const { data } = props ?? {};

        return addPacketJSON(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type AddPacketJSONMutationResult = NonNullable<Awaited<ReturnType<typeof addPacketJSON>>>;
export type AddPacketJSONMutationBody = NonReadonly<Packet>;
export type AddPacketJSONMutationError = ApiError;

/**
 * @summary POST packet
 */
export const useAddPacketJSON = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof addPacketJSON>>,
        TError,
        { data: NonReadonly<Packet> },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getAddPacketJSONMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Delete packet with the given packet id.
 * @summary DELETE packet
 */
export const deletePackets1 = (
    params?: DeletePackets1Params,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<string>({ url: `/hive/api/v1/packets`, method: "delete", params }, options);
};

export const getDeletePackets1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deletePackets1>>,
        TError,
        { params?: DeletePackets1Params },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof deletePackets1>>,
    TError,
    { params?: DeletePackets1Params },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof deletePackets1>>,
        { params?: DeletePackets1Params }
    > = (props) => {
        const { params } = props ?? {};

        return deletePackets1(params, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DeletePackets1MutationResult = NonNullable<Awaited<ReturnType<typeof deletePackets1>>>;

export type DeletePackets1MutationError = ApiError;

/**
 * @summary DELETE packet
 */
export const useDeletePackets1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof deletePackets1>>,
        TError,
        { params?: DeletePackets1Params },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getDeletePackets1MutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Post an TX NAck packet; returns a JSON response.
 * @summary POST packet tx nack
 */
export const nackPacketTxApplicationJSON = (
    packetAck: PacketAck,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<PacketPostReturn>(
        {
            url: `/hive/api/v1/packets/txnack`,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: packetAck,
        },
        options
    );
};

export const getNackPacketTxApplicationJSONMutationOptions = <
    TError = ApiError,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof nackPacketTxApplicationJSON>>,
        TError,
        { data: PacketAck },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof nackPacketTxApplicationJSON>>,
    TError,
    { data: PacketAck },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof nackPacketTxApplicationJSON>>,
        { data: PacketAck }
    > = (props) => {
        const { data } = props ?? {};

        return nackPacketTxApplicationJSON(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type NackPacketTxApplicationJSONMutationResult = NonNullable<
    Awaited<ReturnType<typeof nackPacketTxApplicationJSON>>
>;
export type NackPacketTxApplicationJSONMutationBody = PacketAck;
export type NackPacketTxApplicationJSONMutationError = ApiError;

/**
 * @summary POST packet tx nack
 */
export const useNackPacketTxApplicationJSON = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof nackPacketTxApplicationJSON>>,
        TError,
        { data: PacketAck },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getNackPacketTxApplicationJSONMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Post an TX Ack packet; supports both JSON and plaintext return types.
 * @summary POST packet tx ack
 */
export const ackPacketTxTextPlain1 = (
    packetAck: PacketAck,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<string>(
        {
            url: `/hive/api/v1/packets/txack`,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: packetAck,
        },
        options
    );
};

export const getAckPacketTxTextPlain1MutationOptions = <
    TError = ApiError,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof ackPacketTxTextPlain1>>,
        TError,
        { data: PacketAck },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof ackPacketTxTextPlain1>>,
    TError,
    { data: PacketAck },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof ackPacketTxTextPlain1>>,
        { data: PacketAck }
    > = (props) => {
        const { data } = props ?? {};

        return ackPacketTxTextPlain1(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type AckPacketTxTextPlain1MutationResult = NonNullable<
    Awaited<ReturnType<typeof ackPacketTxTextPlain1>>
>;
export type AckPacketTxTextPlain1MutationBody = PacketAck;
export type AckPacketTxTextPlain1MutationError = ApiError;

/**
 * @summary POST packet tx ack
 */
export const useAckPacketTxTextPlain1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof ackPacketTxTextPlain1>>,
        TError,
        { data: PacketAck },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getAckPacketTxTextPlain1MutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Post an RX Ack packet; supports both JSON and plaintext return types.
 * @summary POST packet rx ack
 */
export const ackPacketRxTextPlain1 = (
    packetAck: PacketAck,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<string>(
        {
            url: `/hive/api/v1/packets/rxack`,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: packetAck,
        },
        options
    );
};

export const getAckPacketRxTextPlain1MutationOptions = <
    TError = ApiError,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof ackPacketRxTextPlain1>>,
        TError,
        { data: PacketAck },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof ackPacketRxTextPlain1>>,
    TError,
    { data: PacketAck },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof ackPacketRxTextPlain1>>,
        { data: PacketAck }
    > = (props) => {
        const { data } = props ?? {};

        return ackPacketRxTextPlain1(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type AckPacketRxTextPlain1MutationResult = NonNullable<
    Awaited<ReturnType<typeof ackPacketRxTextPlain1>>
>;
export type AckPacketRxTextPlain1MutationBody = PacketAck;
export type AckPacketRxTextPlain1MutationError = ApiError;

/**
 * @summary POST packet rx ack
 */
export const useAckPacketRxTextPlain1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof ackPacketRxTextPlain1>>,
        TError,
        { data: PacketAck },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getAckPacketRxTextPlain1MutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Post notification of gs->sat uplink failure; returns a JSON response.
 * @summary POST gs->sat uplink expired event
 */
export const gsUplinkExpired = (
    gsUplinkExpired: GsUplinkExpired,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<PacketPostReturn>(
        {
            url: `/hive/api/v1/packets/gsuplinkexpired`,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: gsUplinkExpired,
        },
        options
    );
};

export const getGsUplinkExpiredMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof gsUplinkExpired>>,
        TError,
        { data: GsUplinkExpired },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof gsUplinkExpired>>,
    TError,
    { data: GsUplinkExpired },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof gsUplinkExpired>>,
        { data: GsUplinkExpired }
    > = (props) => {
        const { data } = props ?? {};

        return gsUplinkExpired(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type GsUplinkExpiredMutationResult = NonNullable<
    Awaited<ReturnType<typeof gsUplinkExpired>>
>;
export type GsUplinkExpiredMutationBody = GsUplinkExpired;
export type GsUplinkExpiredMutationError = ApiError;

/**
 * @summary POST gs->sat uplink expired event
 */
export const useGsUplinkExpired = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof gsUplinkExpired>>,
        TError,
        { data: GsUplinkExpired },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getGsUplinkExpiredMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * POST a dummy device->hive message packet for testing. Similar to POST /messages. Use hiveTxToDevice for the groundstation and nexthopTxToDevice for the satellite.
 * @summary POST dummy device->hive message packet
 */
export const postTestMessagePacket = (
    userMessage: NonReadonly<UserMessage>,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<PacketPostReturn>(
        {
            url: `/hive/api/v1/packets/dummy`,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: userMessage,
        },
        options
    );
};

export const getPostTestMessagePacketMutationOptions = <
    TError = ApiError,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postTestMessagePacket>>,
        TError,
        { data: NonReadonly<UserMessage> },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof postTestMessagePacket>>,
    TError,
    { data: NonReadonly<UserMessage> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postTestMessagePacket>>,
        { data: NonReadonly<UserMessage> }
    > = (props) => {
        const { data } = props ?? {};

        return postTestMessagePacket(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type PostTestMessagePacketMutationResult = NonNullable<
    Awaited<ReturnType<typeof postTestMessagePacket>>
>;
export type PostTestMessagePacketMutationBody = NonReadonly<UserMessage>;
export type PostTestMessagePacketMutationError = ApiError;

/**
 * @summary POST dummy device->hive message packet
 */
export const usePostTestMessagePacket = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postTestMessagePacket>>,
        TError,
        { data: NonReadonly<UserMessage> },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getPostTestMessagePacketMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Get a JSON array of packets whose hive tx device matches the given device type and device id, and satisfying the given parameters. txstate defaults to 0 (unsent).
 * @summary GET packets by Hive tx device type and device id
 */
export const getPacketsForTxDevice = (
    hiveTxDeviceType: string,
    hiveTxDeviceId: string,
    params?: GetPacketsForTxDeviceParams,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<PacketGetReturn[]>(
        {
            url: `/hive/api/v1/packets/${hiveTxDeviceType}/${hiveTxDeviceId}`,
            method: "get",
            params,
            signal,
        },
        options
    );
};

export const getGetPacketsForTxDeviceQueryKey = (
    hiveTxDeviceType: string,
    hiveTxDeviceId: string,
    params?: GetPacketsForTxDeviceParams
) =>
    [
        `/hive/api/v1/packets/${hiveTxDeviceType}/${hiveTxDeviceId}`,
        ...(params ? [params] : []),
    ] as const;

export const getGetPacketsForTxDeviceQueryOptions = <
    TData = Awaited<ReturnType<typeof getPacketsForTxDevice>>,
    TError = ApiError
>(
    hiveTxDeviceType: string,
    hiveTxDeviceId: string,
    params?: GetPacketsForTxDeviceParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getPacketsForTxDevice>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getPacketsForTxDevice>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetPacketsForTxDeviceQueryKey(hiveTxDeviceType, hiveTxDeviceId, params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPacketsForTxDevice>>> = ({
        signal,
    }) => getPacketsForTxDevice(hiveTxDeviceType, hiveTxDeviceId, params, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!(hiveTxDeviceType && hiveTxDeviceId), ...queryOptions };
};

export type GetPacketsForTxDeviceQueryResult = NonNullable<
    Awaited<ReturnType<typeof getPacketsForTxDevice>>
>;
export type GetPacketsForTxDeviceQueryError = ApiError;

/**
 * @summary GET packets by Hive tx device type and device id
 */
export const useGetPacketsForTxDevice = <
    TData = Awaited<ReturnType<typeof getPacketsForTxDevice>>,
    TError = ApiError
>(
    hiveTxDeviceType: string,
    hiveTxDeviceId: string,
    params?: GetPacketsForTxDeviceParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getPacketsForTxDevice>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetPacketsForTxDeviceQueryOptions(
        hiveTxDeviceType,
        hiveTxDeviceId,
        params,
        options
    );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Get, in plaintext, the number of packets whose Hive tx device type and device id satisfy the given parameters.
 * @summary GET packet count by Hive tx device type and device id
 */
export const getPacketCountURI = (
    hiveTxDeviceType: string,
    hiveTxDeviceId: string,
    params?: GetPacketCountURIParams,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<string>(
        {
            url: `/hive/api/v1/packets/${hiveTxDeviceType}/${hiveTxDeviceId}/count`,
            method: "get",
            params,
            signal,
        },
        options
    );
};

export const getGetPacketCountURIQueryKey = (
    hiveTxDeviceType: string,
    hiveTxDeviceId: string,
    params?: GetPacketCountURIParams
) =>
    [
        `/hive/api/v1/packets/${hiveTxDeviceType}/${hiveTxDeviceId}/count`,
        ...(params ? [params] : []),
    ] as const;

export const getGetPacketCountURIQueryOptions = <
    TData = Awaited<ReturnType<typeof getPacketCountURI>>,
    TError = ApiError
>(
    hiveTxDeviceType: string,
    hiveTxDeviceId: string,
    params?: GetPacketCountURIParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getPacketCountURI>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getPacketCountURI>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetPacketCountURIQueryKey(hiveTxDeviceType, hiveTxDeviceId, params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPacketCountURI>>> = ({ signal }) =>
        getPacketCountURI(hiveTxDeviceType, hiveTxDeviceId, params, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!(hiveTxDeviceType && hiveTxDeviceId), ...queryOptions };
};

export type GetPacketCountURIQueryResult = NonNullable<
    Awaited<ReturnType<typeof getPacketCountURI>>
>;
export type GetPacketCountURIQueryError = ApiError;

/**
 * @summary GET packet count by Hive tx device type and device id
 */
export const useGetPacketCountURI = <
    TData = Awaited<ReturnType<typeof getPacketCountURI>>,
    TError = ApiError
>(
    hiveTxDeviceType: string,
    hiveTxDeviceId: string,
    params?: GetPacketCountURIParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getPacketCountURI>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetPacketCountURIQueryOptions(
        hiveTxDeviceType,
        hiveTxDeviceId,
        params,
        options
    );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Get a JSON array of display-optimized packets (showing only a subset of the packet's fields) matching the given parameters.Use useinnerpacket=true to get packets where the <strong>inner</strong> packet has the given devicetype and deviceid. This is useful when we want packets <strong>originating</strong> from that device,e.g. when we want packets from a field device.
 * @summary GET packets for display
 */
export const getPacketsForDisplay = (
    params: GetPacketsForDisplayParams,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<PacketForDisplay[]>(
        { url: `/hive/api/v1/packets/display`, method: "get", params, signal },
        options
    );
};

export const getGetPacketsForDisplayQueryKey = (params: GetPacketsForDisplayParams) =>
    [`/hive/api/v1/packets/display`, ...(params ? [params] : [])] as const;

export const getGetPacketsForDisplayQueryOptions = <
    TData = Awaited<ReturnType<typeof getPacketsForDisplay>>,
    TError = ApiError
>(
    params: GetPacketsForDisplayParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getPacketsForDisplay>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getPacketsForDisplay>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetPacketsForDisplayQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPacketsForDisplay>>> = ({ signal }) =>
        getPacketsForDisplay(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetPacketsForDisplayQueryResult = NonNullable<
    Awaited<ReturnType<typeof getPacketsForDisplay>>
>;
export type GetPacketsForDisplayQueryError = ApiError;

/**
 * @summary GET packets for display
 */
export const useGetPacketsForDisplay = <
    TData = Awaited<ReturnType<typeof getPacketsForDisplay>>,
    TError = ApiError
>(
    params: GetPacketsForDisplayParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getPacketsForDisplay>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetPacketsForDisplayQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Get, in plaintext, the number of packets matching the given parameters.
 * @summary GET packet count
 */
export const getPacketCount = (
    params?: GetPacketCountParams,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<string>(
        { url: `/hive/api/v1/packets/count`, method: "get", params, signal },
        options
    );
};

export const getGetPacketCountQueryKey = (params?: GetPacketCountParams) =>
    [`/hive/api/v1/packets/count`, ...(params ? [params] : [])] as const;

export const getGetPacketCountQueryOptions = <
    TData = Awaited<ReturnType<typeof getPacketCount>>,
    TError = ApiError
>(
    params?: GetPacketCountParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getPacketCount>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getPacketCount>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetPacketCountQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPacketCount>>> = ({ signal }) =>
        getPacketCount(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetPacketCountQueryResult = NonNullable<Awaited<ReturnType<typeof getPacketCount>>>;
export type GetPacketCountQueryError = ApiError;

/**
 * @summary GET packet count
 */
export const useGetPacketCount = <
    TData = Awaited<ReturnType<typeof getPacketCount>>,
    TError = ApiError
>(
    params?: GetPacketCountParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getPacketCount>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetPacketCountQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};
