import {
    Button,
    Card,
    Expander,
    FormGroup,
    H4,
    NonIdealState,
    OverflowList,
    Spinner,
    Tag,
    TextArea,
    Toaster,
} from "@blueprintjs/core";
import { useFeatureFlag, useUserContext } from "AppProvider";
import FlexCard from "components/FlexCard";
import { PageTitle } from "components/PageTitle/PageTitle";
import {
    DeviceRegistrationRequest,
    useBatchRegisterDevice,
    UserContextRole,
} from "handlers/generated/hive";
import { ImageSize } from "images/types";
import internalSitemap from "internalSitemap";
import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { FaQrcode, FaRegCreditCard } from "react-icons/fa";
import { createUseStyles } from "react-jss";
import { OnResultFunction, QrReader } from "react-qr-reader";
import { Link } from "react-router-dom";
import { usePaymentMethods } from "resources/CustomerResource";
import DeviceResource from "resources/DeviceResource";
import sitemap from "sitemap";
import { common } from "styles/common";
import variables from "styles/variables";
import { handleNetworkError } from "utils";

interface DeviceCode {
    ac?: string;
    did?: string;
}

interface RegistrationResult {
    device?: Readonly<DeviceResource>;
    organizationId?: string;
    message: string;
    error: boolean;
}

function isSwarmQrCode(scannedJson: unknown): scannedJson is DeviceCode {
    return (
        scannedJson != null &&
        (Object.prototype.hasOwnProperty.call(scannedJson, "did") ||
            Object.prototype.hasOwnProperty.call(scannedJson, "ac"))
    );
}

// A normalized, human-readable string that can be used as a unique identifier for a device code,
// regardless of format
function deviceCodeToKey(deviceCode: DeviceCode): string {
    if (deviceCode.ac != null) {
        return `Auth Code ${deviceCode.ac}`;
    }
    if (deviceCode.did != null) {
        return `Device ID ${deviceCode.did}`;
    }
    // Should never happen
    return "Unknown";
}

const toaster = Toaster.create({ maxToasts: 1 });

const useStyles = createUseStyles({
    ...common,
    scanner: {
        // Hack to size the video output to fit the window since the library always maintains a 1:1
        // aspect ratio for the video output (based on its width)
        maxWidth: "calc(100vh - 186px)",
        flexGrow: 1,
    },
    scannerFooter: {
        composes: "$flexWithSpacing",
        alignItems: "baseline",
        marginTop: variables.gridSize,
    },
    scannerTag: {
        marginRight: variables.gridSize,
    },
    successText: {
        color: variables.intentSuccess,
        fontWeight: "bold",
    },
    resultContainer: {
        composes: "$flexWithSpacing",
        padding: `${variables.gridSize} 1px`,
    },
    registerButton: {
        composes: "$noGrow",
        height: 10,
        alignSelf: "end",
    },
});

interface IRegisterDeviceProps {
    /** defaults to users current organization id */
    organizationId?: number;
}

const RegisterDevice: React.FC<IRegisterDeviceProps> = (props) => {
    const classes = useStyles();
    const { role, organizationId, billingType } = useUserContext();
    const BILLING_MANUAL_BILL_PAY = useFeatureFlag("billing-manual-bill-pay");
    const [scannerActive, setScannerActive] = useState<boolean>(false);
    const [deviceCodes, setDeviceCodes] = useState<DeviceCode[]>([]);
    const [scanned, setScanned] = useState<DeviceCode[]>([]);
    const [lastRawScan, setLastRawScan] = useState<string | null>(null);
    const [activationResults, setActivationResults] = useState<Record<string, RegistrationResult>>(
        {}
    );
    const {
        data: paymentMethods,
        isLoading: paymentMethodsLoading,
        error: paymentMethodsError,
    } = usePaymentMethods();

    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { errors, formState, handleSubmit, register, reset } = useForm<{ authCode: string }>({
        mode: "onChange",
    });

    const parseQrCode = useCallback(
        (data: string) => {
            let added = false;
            let message = "Not a Swarm device";

            // Check for expected format
            try {
                const jsonData = JSON.parse(data) as unknown;

                if (isSwarmQrCode(jsonData)) {
                    if (
                        deviceCodes.find(
                            ({ ac, did }) => ac === jsonData.ac && did === jsonData.did
                        ) != null
                    ) {
                        message = "Device already scanned";
                    } else if (jsonData.ac == null) {
                        message = "Contact support to register this device";
                    } else {
                        added = true;
                        message = "Device scanned";
                        setScanned(scanned.concat([jsonData]));
                    }
                }
            } catch (error: unknown) {
                // Fall through
            }

            return { added, message };
        },
        [deviceCodes, scanned]
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const handleResult = useCallback<OnResultFunction>(
        (result, error) => {
            if (error?.message) {
                toaster.show({
                    intent: "danger",
                    message: error.message,
                    timeout: 10000,
                });
            }

            if (result !== undefined && result !== null) {
                if (result.getText() === lastRawScan) {
                    // Don't process the same QR code multiple times. This could happen if the user keeps the code
                    // in view of the camera for multiple scans in a row.
                    return;
                }
                setLastRawScan(result.getText());

                // The scanner didn't see any QR codes, nothing to do.
                if (result == null) {
                    return;
                }

                const { message, added } = parseQrCode(result.getText());

                toaster.show({ intent: added ? "success" : "danger", message, timeout: 10000 });
            }
        },
        [lastRawScan, parseQrCode]
    );

    const { mutateAsync: registerDevices } = useBatchRegisterDevice({
        mutation: {
            onError: (error) => {
                const message = handleNetworkError(error);
                toaster.show({ intent: "danger", message, timeout: 5000 });
            },
        },
    });

    const handleBulkActivation = useCallback(
        async ({ authCode: userInput }: { authCode: string }) => {
            reset();

            const authCodes = userInput.split(/\r?\n/);
            authCodes.map((code) => code.trim());

            const body: DeviceRegistrationRequest = {
                organizationId: props.organizationId ?? organizationId,
                authCodes: authCodes.filter((code) => code.length > 0),
            };

            const response = await registerDevices({ data: body });

            let arr = activationResults;
            let key: string;
            let updatedDeviceCodes = deviceCodes;
            response.registered?.forEach((device) => {
                const deviceCode: DeviceCode = { did: device.deviceName };
                if (updatedDeviceCodes.findIndex((dc) => dc.did === deviceCode.did) < 0) {
                    updatedDeviceCodes = [...updatedDeviceCodes, deviceCode];
                }

                key = deviceCodeToKey(deviceCode);
                arr = {
                    ...arr,
                    [key]: {
                        device: DeviceResource.fromJS(device),
                        organizationId: device.organizationId?.toString(),
                        error: device.organizationId === 0,
                        message:
                            device.organizationId === 0
                                ? "Not registered"
                                : "Device successfully registered. Enjoy your first 50 messages on us!",
                    },
                };
            });

            response.invalidCodes?.forEach((invalidCode) => {
                const deviceCode: DeviceCode = { ac: invalidCode };
                if (updatedDeviceCodes.findIndex((dc) => dc.ac === deviceCode.ac) < 0) {
                    updatedDeviceCodes = [...updatedDeviceCodes, deviceCode];
                }

                key = deviceCodeToKey(deviceCode);
                arr = {
                    ...arr,
                    [key]: {
                        error: true,
                        message:
                            "The following auth code is invalid, it doest not correspond to any device",
                    },
                };
            });

            if (response.errorMessages) {
                Object.keys(response.errorMessages).forEach((deviceId) => {
                    const deviceCode: DeviceCode = { did: deviceId };
                    if (updatedDeviceCodes.findIndex((dc) => dc.did === deviceCode.did) < 0) {
                        updatedDeviceCodes = [...updatedDeviceCodes, deviceCode];
                    }
                    key = deviceCodeToKey(deviceCode);
                    arr = {
                        ...arr,
                        [key]: {
                            error: true,
                            message:
                                (response.errorMessages && response.errorMessages[deviceId]) ||
                                "Unknown Error, please contact support",
                        },
                    };
                });
            }

            setDeviceCodes(updatedDeviceCodes);
            setActivationResults(arr);
        },
        [
            activationResults,
            deviceCodes,
            organizationId,
            props.organizationId,
            registerDevices,
            reset,
        ]
    );

    const handleBulkQrActivation = () => {
        if (scanned.length > 0) {
            const authCodes = scanned.map((deviceCode) => deviceCode.ac).join(" \n");
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            handleBulkActivation({ authCode: authCodes });
        }
        setScannerActive(false);
    };

    // note: if we fail to load payment info from stripe, let the user register the device anyway (fail safe)
    const hasPaymentMethod =
        (paymentMethods !== undefined && paymentMethods.length >= 1) ||
        (!paymentMethodsLoading && paymentMethodsError !== null);

    const allowDeviceRegistration =
        hasPaymentMethod ||
        BILLING_MANUAL_BILL_PAY ||
        billingType === "EXTERNALLY_BILLED" ||
        billingType === "UNBILLED";

    return (
        <FlexCard elevation={0} useNewStyle>
            <PageTitle>Register Devices</PageTitle>
            <div className={classes.section}>
                {paymentMethodsLoading && <Spinner />}
                {!paymentMethodsLoading && (
                    <>
                        {allowDeviceRegistration && (
                            <Card>
                                {scannerActive ? (
                                    <div className={classes.scanner}>
                                        <QrReader
                                            onResult={handleResult}
                                            constraints={{ facingMode: "environment" }}
                                        />
                                        <div className={classes.scannerFooter}>
                                            <OverflowList
                                                collapseFrom="start"
                                                minVisibleItems={1}
                                                items={scanned}
                                                overflowRenderer={(overflowItems) => (
                                                    <Tag
                                                        minimal
                                                        large
                                                        title={`${overflowItems.length} more`}
                                                    >
                                                        &hellip;
                                                    </Tag>
                                                )}
                                                visibleItemRenderer={(code) => {
                                                    const key = deviceCodeToKey(code);
                                                    return (
                                                        <Tag
                                                            key={key}
                                                            large
                                                            intent="success"
                                                            className={classes.scannerTag}
                                                        >
                                                            {key}
                                                        </Tag>
                                                    );
                                                }}
                                            />
                                            <Expander />
                                            <Button
                                                intent="primary"
                                                large
                                                onClick={handleBulkQrActivation}
                                            >
                                                Done Scanning
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <NonIdealState
                                            icon={<FaQrcode size={ImageSize.LARGE} />}
                                            title="Register a New Device"
                                            description={
                                                <p>
                                                    To register your device(s), scan the QR code on
                                                    the sticker. If you cannot scan QR codes with
                                                    this browser, you can also enter the contents of
                                                    the QR code manually below (after scanning them
                                                    with a different device).
                                                </p>
                                            }
                                            action={
                                                <Button
                                                    intent="primary"
                                                    onClick={() => setScannerActive(true)}
                                                >
                                                    Start Scanning
                                                </Button>
                                            }
                                        />
                                        <form onSubmit={handleSubmit(handleBulkActivation)}>
                                            <FormGroup
                                                label="Enter registration code(s) manually"
                                                helperText={
                                                    <div>
                                                        <p>
                                                            If the scanned QR code is&nbsp;
                                                            <code>
                                                                {'{"ac":"ABCDE.12345$%XYZ"}'}
                                                            </code>
                                                            ,
                                                        </p>
                                                        <p>
                                                            enter the registration code&nbsp;
                                                            <code>ABCDE.12345$%XYZ</code>
                                                        </p>
                                                        <p>
                                                            Enter multiple codes on separate lines.
                                                        </p>
                                                        <p>Do not enter the device id.</p>
                                                    </div>
                                                }
                                            >
                                                <div className={classes.flexWithSpacing}>
                                                    <TextArea
                                                        name="authCode"
                                                        inputRef={register({ required: true })}
                                                        intent={errors.authCode ? "danger" : "none"}
                                                        placeholder={
                                                            "ABCDE.12345$%XYZ \r\nVWXYZ!98765/&762 \r\n..."
                                                        }
                                                        rows={4}
                                                    />
                                                    <Button
                                                        className={classes.registerButton}
                                                        type="submit"
                                                        disabled={
                                                            !formState.isDirty || !formState.isValid
                                                        }
                                                        intent="success"
                                                    >
                                                        Register
                                                    </Button>
                                                </div>
                                            </FormGroup>
                                            <div className={classes.resultContainer}>
                                                {deviceCodes.map((code) => {
                                                    const key = deviceCodeToKey(code);
                                                    const result = activationResults[key];

                                                    return (
                                                        <Card elevation={0} key={key}>
                                                            <H4>{key}</H4>
                                                            {result?.device != null ? (
                                                                <FormGroup label="Device">
                                                                    <Link
                                                                        to={
                                                                            role ===
                                                                            UserContextRole.SUPER_ADMIN
                                                                                ? internalSitemap.devices.generatePath(
                                                                                      {
                                                                                          deviceKey:
                                                                                              result.device.pk(),
                                                                                      }
                                                                                  )
                                                                                : sitemap.devices.generatePath(
                                                                                      {
                                                                                          deviceKey:
                                                                                              result.device.pk(),
                                                                                      }
                                                                                  )
                                                                        }
                                                                    >
                                                                        {result.device.displayId}
                                                                    </Link>
                                                                </FormGroup>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            <FormGroup label="Status">
                                                                {result != null && (
                                                                    <span
                                                                        className={
                                                                            result.error
                                                                                ? classes.formError
                                                                                : classes.successText
                                                                        }
                                                                    >
                                                                        {result.message}
                                                                    </span>
                                                                )}
                                                            </FormGroup>
                                                        </Card>
                                                    );
                                                })}
                                            </div>
                                        </form>
                                    </>
                                )}
                            </Card>
                        )}
                        {!allowDeviceRegistration && (
                            <NonIdealState
                                icon={<FaRegCreditCard size={ImageSize.LARGE} />}
                                title="Payment method is required"
                                description={
                                    <p>
                                        You need a payment method before you can register a device.
                                    </p>
                                }
                                action={
                                    <Link to={sitemap.billing.generatePath()}>
                                        <Button intent="primary">Add a Payment Method</Button>
                                    </Link>
                                }
                            />
                        )}
                    </>
                )}
            </div>
        </FlexCard>
    );
};

export default RegisterDevice;
