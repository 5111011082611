import InternalDeviceDetail from "components/InternalDeviceDetail";
import { Organization } from "handlers/generated/hive";
import internalSitemap, { RouteParams } from "internalSitemap";
import InternalDeviceList from "pages/InternalDevices/InternalDeviceList";
import { TabId } from "pages/InternalOrganizations/OrganizationEditor";
import React, { useCallback, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { formatDeviceIdAndType, useDevicesWithBilling } from "resources/DeviceResource.utils";

interface IOrganizationDevicesPanelProps {
    organizationData: Organization;
}

export const OrganizationDevicesPanel: React.FunctionComponent<IOrganizationDevicesPanelProps> = ({
    organizationData,
}) => {
    const { devicesWithPaymentStatus } = useDevicesWithBilling(
        organizationData.organizationId ?? null
    );
    const { organizationId: orgId, deviceKey: activeDeviceKey } =
        useParams<RouteParams<"organizations">>();
    const history = useHistory();

    const activeDevice = useMemo(() => {
        if (devicesWithPaymentStatus === undefined) {
            return undefined;
        }

        return devicesWithPaymentStatus.find(
            (device) =>
                formatDeviceIdAndType(device.deviceId, device.deviceType) === activeDeviceKey
        );
    }, [activeDeviceKey, devicesWithPaymentStatus]);

    const setActiveDevice = useCallback(
        (deviceKey?: string): void => {
            history.push(
                internalSitemap.organizations.generatePath({
                    organizationId: orgId,
                    tab: TabId.devices,
                    deviceKey,
                })
            );
        },
        [history, orgId]
    );

    return (
        <>
            <InternalDeviceList
                organizationId={organizationData.organizationId}
                devicesWithPaymentStatus={devicesWithPaymentStatus ?? []}
                onActiveDeviceChange={(device) => setActiveDevice(device.displayIdAndType)}
                canSetPaidThrough
            />
            <InternalDeviceDetail
                device={activeDevice}
                onClose={() => setActiveDevice(undefined)}
            />
        </>
    );
};
