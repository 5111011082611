import { Classes, Drawer, DrawerProps } from "@blueprintjs/core";
import classNames from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    drawerWrapper: {
        minWidth: (props: IResponsiveDrawer) => [`min(${props.minWidth}px, 100vw)`, "!important"],
        width: "33% !important",
    },
    drawerBody: {
        padding: 20,
        background: "#eee",
    },
});

interface IResponsiveDrawer {
    minWidth: number;
    widthPercent: number;
}

interface ISXDrawerProps extends Omit<DrawerProps, "size">, IResponsiveDrawer {
    children: React.ReactNode;
}

/** Responsive Drawer wrapper */
export const SXDrawer: React.FunctionComponent<ISXDrawerProps> = ({
    children,
    className,
    minWidth,
    widthPercent,
    ...otherProps
}) => {
    const classes = useStyles({ minWidth, widthPercent });

    return (
        <Drawer {...otherProps} className={classNames(className, classes.drawerWrapper)}>
            <div className={classNames(classes.drawerBody, Classes.DRAWER_BODY)}>{children}</div>
        </Drawer>
    );
};
