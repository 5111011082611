import { Button, MenuItem } from "@blueprintjs/core";
import { ItemRenderer, Select2Props, Suggest2 } from "@blueprintjs/select";
import { IDeviceFormValues } from "components/InternalDeviceDetail/InternalDeviceEditor.types";
import { METADATA_DEVICE_TYPE_KEY } from "components/Metadata/Metadata.utils";
import { useGetMetadataValues } from "handlers/generated/metadata";
import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

type SelectProps = Omit<Select2Props<string>, "itemRenderer" | "items">;
export interface IMetadataValueSelectProps extends SelectProps {
    index: number;
    displayText: string;
    onDelete: (index?: number | number[] | undefined) => void;
}

const renderItem: ItemRenderer<string> = (val, itemProps) => {
    return val === METADATA_DEVICE_TYPE_KEY ? null : (
        <MenuItem
            key={val}
            text={val}
            active={itemProps.modifiers.active}
            disabled={itemProps.modifiers.disabled}
            onClick={itemProps.handleClick}
        />
    );
};

function renderNewItem(
    query: string,
    active: boolean,
    handleClick: React.MouseEventHandler<HTMLElement>
) {
    return (
        <MenuItem icon="add" key={query} text={`Create "${query}"`} onClick={handleClick} active />
    );
}

function search(query: string | null, key: string | null) {
    if (query === null || key === null) {
        // query/key are null when no items are loaded yet
        return false;
    }

    return key.toLocaleLowerCase().indexOf(query.toLowerCase()) >= 0;
}

export const MetadataValueSelect: React.FunctionComponent<IMetadataValueSelectProps> = ({
    index,
    disabled,
    displayText,
    onDelete,
    ...otherProps
}) => {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { getValues, watch } = useFormContext<IDeviceFormValues>();
    const key: string = watch(`metadata.${index}.key`);
    const [userHasFocused, setUserHasFocused] = useState<boolean>(false);

    const { data } = useGetMetadataValues(
        {
            organizationId: getValues("organizationId"),
            objectType: "device",
            metadataKey: key,
        },
        { query: { enabled: !disabled && key?.length > 0 && userHasFocused } }
    );

    const values = useMemo(() => {
        if (data === undefined || data.fields === undefined) {
            return [];
        }

        return data.fields;
    }, [data]);

    return (
        <div style={{ display: "flex-box" }}>
            <div style={{ width: "90%", display: "inline-block" }} data-testid="metadatavalue">
                <Suggest2<string>
                    items={values}
                    selectedItem={displayText}
                    createNewItemFromQuery={(query) => query}
                    createNewItemRenderer={renderNewItem}
                    itemRenderer={renderItem}
                    itemPredicate={search}
                    inputValueRenderer={(item) => item}
                    disabled={disabled}
                    inputProps={{ placeholder: "Field Value" }}
                    noResults={
                        <MenuItem
                            disabled
                            text="No values found for key"
                            roleStructure="listoption"
                        />
                    }
                    popoverProps={{
                        onOpening: () => {
                            setUserHasFocused(true);
                        },
                    }}
                    {...otherProps}
                />
            </div>
            {!disabled ? (
                <Button
                    style={{ width: "10%" }}
                    onClick={() => onDelete(index)}
                    icon="trash"
                    minimal
                    intent="danger"
                />
            ) : undefined}
        </div>
    );
};
