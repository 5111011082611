import { Classes, Spinner } from "@blueprintjs/core";
import classNames from "classnames";
import React, { Suspense } from "react";

interface ILoadingPanelProps {
    render: () => React.ReactElement;
    shouldRender: boolean;
}

export const LoadingPanel: React.FunctionComponent<ILoadingPanelProps> = ({
    render,
    shouldRender,
}) => {
    if (shouldRender === false) {
        return (
            <div className={Classes.CARD}>
                <h5 className={Classes.HEADING}>
                    <div className={Classes.SKELETON}>Card heading</div>
                </h5>
                <p className={Classes.SKELETON}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eget tortor
                    felis. Fusce dapibus metus in dapibus mollis. Quisque eget ex diam.
                </p>
                <button type="button" className={classNames(Classes.BUTTON, Classes.SKELETON)}>
                    Submit
                </button>
            </div>
        );
    }

    return <Suspense fallback={<Spinner />}>{render()}</Suspense>;
};
