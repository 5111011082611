import { DeviceLogo } from "components/DeviceMap/DeviceLogo";
import { getColorForType } from "components/DeviceMap/map.utils";
import { MetadataDeviceType, MetadataDeviceTypes } from "components/Metadata/Metadata.utils";
import SimpleSelect from "components/SimpleSelect";
import React, { useCallback } from "react";

interface IMetadataFieldDeviceTypeProps {
    onChange: (item: MetadataDeviceType) => void;
    value: MetadataDeviceType;
    disabled?: boolean;
}

export const MetadataFieldDeviceType: React.FunctionComponent<IMetadataFieldDeviceTypeProps> = ({
    onChange,
    value,
    disabled = false,
}) => {
    const renderItem = useCallback(
        (item: MetadataDeviceType) => (
            <>
                <DeviceLogo type={item} size={24} color={getColorForType(item)} />
                {item}
            </>
        ),
        []
    );

    const renderSelectedItem = useCallback((item: MetadataDeviceType | undefined) => {
        if (item === undefined) {
            return "";
        }

        return (
            <>
                <DeviceLogo type={item} size={16} color={getColorForType(item)} />
                {item}
            </>
        );
    }, []);

    return (
        <SimpleSelect
            fill
            items={MetadataDeviceTypes}
            getKey={(item) => item}
            renderItem={renderItem}
            renderSelectedItem={renderSelectedItem}
            itemSearchText={(item) => item}
            selectedKey={value}
            onSelectionChange={onChange}
            disabled={disabled}
        />
    );
};
