import { Classes } from "@blueprintjs/core";
import classNames from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";

import variables from "styles/variables";

interface Props extends React.HTMLProps<HTMLTableElement> {
    condensed?: boolean;
}

const useStyles = createUseStyles({
    table: ({ condensed }: { condensed: boolean }) => ({
        "& > tbody > tr > td": {
            padding: `calc(${variables.gridSize} / 4) calc(${variables.gridSize} / ${
                condensed ? 4 : 2
            })`,
            verticalAlign: "baseline",
        },
        "& > tbody > tr > td:first-child": {
            fontSize: variables.fontSizeSmall,
            fontWeight: 600,
            paddingLeft: 0,
        },
        "& > tbody > tr > td:last-child": {
            paddingRight: 0,
        },
    }),
});

const LabelTable: React.FunctionComponent<Props> = ({
    children,
    condensed = false,
    className,
    ...tableProps
}) => {
    const classes = useStyles({ condensed });

    return (
        <table
            className={classNames(classes.table, { [Classes.TEXT_SMALL]: condensed }, className)}
            {...tableProps}
        >
            <tbody>{children}</tbody>
        </table>
    );
};

export default LabelTable;
