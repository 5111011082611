import * as Sentry from "@sentry/react";
import ErrorPage from "pages/ErrorPage";
import React from "react";

interface IErrorBoundaryProps {
    children: React.ReactNode;
}

const renderError: Sentry.FallbackRender = (errorData) => <ErrorPage error={errorData.error} />;

/**
 * @see https://reactjs.org/docs/error-boundaries.html
 */
export const ErrorBoundary: React.FunctionComponent<IErrorBoundaryProps> = (props) => (
    <Sentry.ErrorBoundary fallback={renderError}>{props.children}</Sentry.ErrorBoundary>
);
