import { Card } from "@blueprintjs/core";
import classNames from "classnames";
import { CurrencyDisplay } from "components/CurrencyDisplay";
import { ReducedLineItems } from "handlers/generated/billing";
import internalSitemap, { RouteParams } from "internalSitemap";
import { TabId } from "pages/InternalOrganizations/OrganizationEditor";
import React from "react";
import { createUseStyles } from "react-jss";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { DeviceType } from "resources/DeviceResource.types";
import { formatDeviceIdAndType } from "resources/DeviceResource.utils";
import sitemap from "sitemap";
import { common } from "styles/common";

interface ICardInvoiceItemProps {
    lineItem: ReducedLineItems;
}

const useStyles = createUseStyles({
    ...common,
    flex: {
        display: "flex",
        fontSize: 16,
        lineHeight: "24px",
    },
    col: {
        flex: "1",
        alignSelf: "center",
    },
    colWide: {
        flex: "2",
        paddingLeft: "5px",
        paddingRight: "5px",
    },
    textCenter: {
        textAlign: "center",
    },
    textLeft: {
        textAlign: "left",
    },
    textRight: {
        textAlign: "right",
    },
    textSpacing: {
        paddingLeft: 8,
    },
});

export const CardLineItem: React.FunctionComponent<ICardInvoiceItemProps> = ({ lineItem }) => {
    const classes = useStyles();
    const unitPrice = (lineItem.totalPrice ?? 0) / (lineItem.quantity ?? 1) / 100;

    // not an elegant solution- ideally the internal view should be a separate component
    const customerViewUrl = useParams<RouteParams<"invoice">>();
    const internalViewUrl = useParams<RouteParams<"organizations">>();
    const isInternal = useRouteMatch(internalSitemap.organizations.pattern[2])?.isExact;
    const selectedSitemap = isInternal ? internalSitemap.organizations : sitemap.invoice;
    const organizationId =
        (isInternal ? internalViewUrl.organizationId : customerViewUrl.organizationId) ?? "";
    const stripeInvoiceId =
        (isInternal ? internalViewUrl.stripeInvoiceId : customerViewUrl.stripeInvoiceId) ?? "";

    return (
        <Card className={classes.flex}>
            <div className={classNames(classes.colWide, classes.textLeft)}>
                <span>{lineItem.description}</span>
            </div>
            <div className={classNames(classes.colWide)}>
                {lineItem.deviceIds !== null && (
                    <div>
                        {lineItem.deviceIds?.map((deviceId, index) => (
                            <Link
                                key={deviceId}
                                // tab param is only needed for internal view
                                to={selectedSitemap.generatePath({
                                    organizationId,
                                    tab: TabId.payment,
                                    stripeInvoiceId,
                                    deviceKey: formatDeviceIdAndType(deviceId, DeviceType.FIELD),
                                })}
                            >
                                {index !== 0 && <span>, </span>}
                                <span>{formatDeviceIdAndType(deviceId, DeviceType.FIELD)}</span>
                            </Link>
                        ))}
                    </div>
                )}
            </div>
            <div className={classNames(classes.col, classes.textCenter)}>
                <span>{lineItem.quantity ?? lineItem.deviceIds?.length}</span>
            </div>
            <div className={classNames(classes.col, classes.textCenter)}>
                <CurrencyDisplay amount={unitPrice} />
            </div>
            <div className={classNames(classes.col, classes.textCenter)}>
                <CurrencyDisplay amount={(lineItem.totalPrice ?? 0) / 100} />
            </div>
        </Card>
    );
};
