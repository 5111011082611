/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Hive Service REST API
 * OpenAPI spec version: v1
 */

export type OrganizationBillingType =
    typeof OrganizationBillingType[keyof typeof OrganizationBillingType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationBillingType = {
    UNBILLED: "UNBILLED",
    AUTOMATICALLY_BILLED_MANUALLY_FINALIZED: "AUTOMATICALLY_BILLED_MANUALLY_FINALIZED",
    AUTOMATICALLY_BILLED_AND_FINALIZED: "AUTOMATICALLY_BILLED_AND_FINALIZED",
    EXTERNALLY_BILLED: "EXTERNALLY_BILLED",
} as const;
