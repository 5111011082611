import { DeviceKey, LatLon } from "internalSitemap";
import { generatePath } from "react-router-dom";

export type ReportKey = {
    /** set to "new" to create a new report */
    reportKey: string;
};

const sitemap = {
    login: {
        pattern: "/login/",
        generatePath: (params?: { continue?: string }): string => {
            if (params?.continue) {
                return `${sitemap.login.pattern}?continue=${encodeURIComponent(params.continue)}`;
            }

            return sitemap.login.pattern;
        },
    },
    logout: {
        pattern: "/logout",
        generatePath: () => sitemap.logout.pattern,
    },
    forgotPassword: {
        pattern: "/forgot-password",
        generatePath: () => sitemap.forgotPassword.pattern,
    },
    register: {
        pattern: "/register/:token",
        generatePath: (params: { token: string }) => generatePath(sitemap.register.pattern, params),
    },
    resetPassword: {
        pattern: "/reset-password/:token",
        generatePath: (params: { token: string }) =>
            generatePath(sitemap.resetPassword.pattern, params),
    },
    signUp: {
        pattern: "/sign-up",
        generatePath: () => sitemap.signUp.pattern,
    },
    dashboard: {
        pattern: "/",
        generatePath: () => sitemap.dashboard.pattern,
    },
    actions: {
        pattern: "/actions/:actionId?",
        generatePath: (params?: { actionId?: string }): string =>
            generatePath(sitemap.actions.pattern, params),
    },
    devices: {
        pattern: "/devices/:deviceKey?",
        generatePath: (params?: { deviceKey?: string }) =>
            generatePath(sitemap.devices.pattern, params),
    },
    registerDevice: {
        pattern: "/registerDevice",
        generatePath: () => sitemap.registerDevice.pattern,
    },
    map: {
        pattern: "/map/:deviceKey?",
        generatePath: (params?: DeviceKey & LatLon): string => {
            const basePath = generatePath(sitemap.map.pattern, params);

            if (params?.lat && params?.lon) {
                return `${basePath}?lat=${encodeURIComponent(params.lat)}&lon=${encodeURIComponent(
                    params.lon
                )}`;
            }

            return basePath;
        },
    },
    reportsList: {
        pattern: "/reports",
        generatePath: (): string => sitemap.reportsList.pattern,
    },
    reportDetail: {
        pattern: "/reports/:reportKey",
        generatePath: (params: ReportKey): string =>
            generatePath(sitemap.reportDetail.pattern, params),
    },
    messages: {
        pattern: "/messages/:messageId?",
        generatePath: (params?: { messageId?: string }) =>
            generatePath(sitemap.messages.pattern, params),
    },
    profile: {
        pattern: "/account/profile",
        generatePath: () => sitemap.profile.pattern,
    },
    billing: {
        pattern: "/billing",
        generatePath: () => sitemap.billing.pattern,
    },
    invoice: {
        pattern: "/invoice/:organizationId/:stripeInvoiceId/:deviceKey?",
        generatePath: (params: {
            organizationId: string;
            stripeInvoiceId: string;
            deviceKey?: string;
        }): string => generatePath(sitemap.invoice.pattern, params),
    },
    APIDocs: {
        pattern: "/apiDocs",
        generatePath: (): string => sitemap.APIDocs.pattern,
    },
    settings: {
        pattern: "/settings/:userKey?",
        generatePath: (): string => generatePath(sitemap.settings.pattern),
    },
} as const;

// This type is meant to be used with react-router's useParams(), e.g.:
// const { foo, bar } = useParams<RouteParams<'myPage'>>();
export type RouteParams<T extends keyof typeof sitemap> = NonNullable<
    Parameters<typeof sitemap[T]["generatePath"]>[0]
>;

export default sitemap;
