import { Button, NonIdealState, Pre } from "@blueprintjs/core";
import { ImageSize } from "images/types";
import React from "react";
import { FaExclamationTriangle } from "react-icons/fa";
import { createUseStyles } from "react-jss";
import { Redirect, useHistory } from "react-router-dom";
import { NetworkError } from "rest-hooks";
import { getMailToLink, getUnauthenticatedRedirect } from "utils";

interface Props {
    error: NetworkError;
}

const useStyles = createUseStyles({
    error: {
        maxHeight: "200px",
        overflow: "auto",
    },
});

const NetworkErrorPage: React.FunctionComponent<Props> = ({ error }) => {
    const classes = useStyles();
    const history = useHistory();

    let title = "Network Error";
    let message = "";
    let showContactSupport = true;
    let showError = true;

    if (error.status == null) {
        message =
            "There was an error communicating with the server, check your internet connection.";
    } else if (error.status === 503) {
        title = "Swarm is undergoing maintenance";
        message = "Please check back soon.";
        showError = false;
        showContactSupport = false;
    } else if (error.status === 401) {
        return <Redirect to={getUnauthenticatedRedirect(history.location.pathname)} />;
    } else if (error.status === 403) {
        message = "You are not authorized to perform this action.";
        showContactSupport = false;
    } else if (error.status >= 400) {
        message = `An unexpected error occurred (${error.status}).`;
    }

    return (
        <NonIdealState
            icon={<FaExclamationTriangle size={ImageSize.LARGE} />}
            title={title}
            description={
                <div>
                    <p>
                        {message}
                        {showContactSupport && (
                            <>
                                {" "}
                                Please{" "}
                                <a href={getMailToLink("support@swarm.space", "", "")}>
                                    contact support
                                </a>{" "}
                                if the issue persists.
                            </>
                        )}
                    </p>
                    {showError && error?.message?.length > 0 && (
                        <Pre className={classes.error}>{error.message}</Pre>
                    )}
                </div>
            }
            action={
                <Button intent="primary" onClick={() => window.location.reload()}>
                    Reload
                </Button>
            }
        />
    );
};

export default NetworkErrorPage;
