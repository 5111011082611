import { Button, Menu, MenuItem, NonIdealState } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import DataTable, { Column, Filter } from "components/DataTable";
import { DateDisplay } from "components/DateDisplay";
import { DeviceBillingStatusTag } from "components/DeviceBillingStatusTag";
import { LastHeard } from "components/LastHeard";
import { ImageSize } from "images/types";
import internalSitemap from "internalSitemap";
import { BulkUpdateDataPlan } from "pages/InternalDevices/BulkUpdateDataPlan";
import { BulkUpdatePaidThrough } from "pages/InternalDevices/BulkUpdatePaidThrough";
import React, { useCallback, useMemo, useState } from "react";
import { FaAngleDown, FaCalendarCheck, FaSimCard } from "react-icons/fa";
import { RiSignalTowerFill } from "react-icons/ri";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";
import { QueryKey } from "resources/apiHandler";
import { IDevice } from "resources/DeviceResource";
import { IDeviceWithPaymentStatus } from "resources/DeviceResource.utils";
import { globalQueryClient } from "resources/globalQueryClient";
import { common } from "styles/common";
import { compareDates, compareNatural, comparePaymentStatus, includesIgnoringCase } from "utils";

const getDeviceId = (device: Readonly<IDevice>): string => device.displayIdAndType ?? "";

const getSearchFilter = (searchQuery: string) => (device: Readonly<IDevice>) =>
    [String(device.organizationId), device.displayIdAndType, device.deviceName].some((value) =>
        includesIgnoringCase(value, searchQuery)
    );

const BILLING_STATUS_COLUMN = "billingStatus";

const defaultSort = { columnId: BILLING_STATUS_COLUMN, descending: false };

const useStyles = createUseStyles({
    ...common,
    checkboxWrapper: {
        display: "inline-block",
        marginRight: -8,
        "& label": {
            margin: 0,
        },
    },
});

interface Props {
    devicesWithPaymentStatus: IDeviceWithPaymentStatus[];
    organizationId?: number;
    activeDeviceId?: string;
    onActiveDeviceChange: (selectedDevice: Readonly<IDeviceWithPaymentStatus>) => void;
    searchQuery?: string;

    /** @default false */
    canSetPaidThrough?: boolean;
}

const InternalDeviceList: React.FunctionComponent<Props> = ({
    devicesWithPaymentStatus,
    organizationId,
    activeDeviceId,
    onActiveDeviceChange,
    searchQuery = "",
    canSetPaidThrough = false,
}) => {
    const classes = useStyles();
    const [selectedDevices, setSelectedDevices] = useState<number[]>([]);
    const [bulkUpdatePromptVisible, setBulkUpdatePromptVisible] = useState<boolean>(false);
    const [bulkUpdateDataPlanPromptVisible, setBulkUpdateDataPlanPromptVisible] =
        useState<boolean>(false);

    const columns: Column<Readonly<IDeviceWithPaymentStatus>>[] = useMemo(() => {
        return [
            {
                id: "organizationId",
                label: "Org ID",
                format: ({ organizationId: orgId }) => String(orgId),
                compare: (a, b) => a.organizationId - b.organizationId,
            },
            {
                id: "id",
                label: "Device ID",
                format: ({ displayIdAndType }) => displayIdAndType,
            },
            {
                id: "name",
                label: "Name",
                format: ({ deviceName }) => deviceName,
                compare: (a, b) => compareNatural(a.deviceName, b.deviceName),
            },
            {
                id: "heardAt",
                label: <LastHeard />,
                format: ({ hiveLastheardTime }) => <DateDisplay date={hiveLastheardTime} />,
                compare: (a, b) => compareDates(a.hiveLastheardTime, b.hiveLastheardTime),
            },
            {
                id: BILLING_STATUS_COLUMN,
                label: "Billing Status",
                compare: (a, b) => comparePaymentStatus(a.billingStatus, b.billingStatus),
                format: ({ billingStatus }) => {
                    if (billingStatus === undefined) {
                        return <></>;
                    }

                    return <DeviceBillingStatusTag billingStatus={billingStatus} />;
                },
                allowSort: true,
            },
        ];
    }, []);

    const filters = useMemo(
        (): Filter<Readonly<IDevice>>[] => (searchQuery ? [getSearchFilter(searchQuery)] : []),
        [searchQuery]
    );

    const handleActiveRowChange = useCallback(
        (rowId: string, device: Readonly<IDevice>) => {
            onActiveDeviceChange(device);
        },
        [onActiveDeviceChange]
    );

    const closeAndReload = useCallback(() => {
        // eslint-disable-next-line no-void
        void globalQueryClient.invalidateQueries(QueryKey.DeviceBillingList);

        setBulkUpdatePromptVisible(false);
    }, []);

    const closeAndReloadDataPlan = useCallback(() => {
        // eslint-disable-next-line no-void
        void globalQueryClient.invalidateQueries(QueryKey.DeviceBillingList);

        setBulkUpdateDataPlanPromptVisible(false);
    }, []);

    return (
        <>
            {organizationId !== undefined && (
                <BulkUpdatePaidThrough
                    organizationId={organizationId}
                    devices={selectedDevices}
                    visible={bulkUpdatePromptVisible}
                    onClose={closeAndReload}
                />
            )}
            {organizationId !== undefined && (
                <BulkUpdateDataPlan
                    organizationId={organizationId}
                    devices={selectedDevices}
                    visible={bulkUpdateDataPlanPromptVisible}
                    onClose={closeAndReloadDataPlan}
                />
            )}
            <DataTable
                activeRowId={activeDeviceId}
                columns={columns}
                data={devicesWithPaymentStatus}
                defaultSort={defaultSort}
                clientSideFilters={filters}
                getRowId={getDeviceId}
                onActiveRowChange={handleActiveRowChange}
                onRowSelection={
                    canSetPaidThrough
                        ? (selectedRows) => {
                              setSelectedDevices(selectedRows.map((row) => row.deviceId));
                          }
                        : undefined
                }
                checkedActionsRender={(checkbox) => (
                    <Popover2
                        content={
                            <Menu>
                                <MenuItem
                                    text={`Update Paid Through for ${selectedDevices.length} Selected Devices`}
                                    icon={<FaCalendarCheck />}
                                    onClick={() => setBulkUpdatePromptVisible(true)}
                                    disabled={selectedDevices.length === 0}
                                />
                                <MenuItem
                                    text={`Update Data Plan for ${selectedDevices.length} Selected Devices`}
                                    icon={<FaSimCard />}
                                    onClick={() => setBulkUpdateDataPlanPromptVisible(true)}
                                    disabled={selectedDevices.length === 0}
                                />
                            </Menu>
                        }
                        position="bottom-left"
                    >
                        <Button minimal>
                            <span className={classes.checkboxWrapper}>{checkbox}</span>{" "}
                            <FaAngleDown />
                        </Button>
                    </Popover2>
                )}
                renderEmptyState={() => (
                    <NonIdealState
                        icon={<RiSignalTowerFill size={ImageSize.MEDIUM} />}
                        title="No devices found"
                        description="Modify your filters or"
                        action={
                            <Link to={internalSitemap.organizations.generatePath()}>
                                Register a Device
                            </Link>
                        }
                    />
                )}
                paginationSize={1000}
            />
        </>
    );
};

export default InternalDeviceList;
