import moment from "moment-timezone";
import { useQuery, UseQueryResult } from "react-query";
import { apiHandlerLegacy, QueryKey } from "resources/apiHandler";
import { compareNatural } from "utils";

export interface IPaymentSetup {
    clientSecret: string;
}

export class PaymentSetup implements IPaymentSetup {
    constructor(apiResponse: IPaymentSetupResponse) {
        this.clientSecret = apiResponse.clientSecret;
    }

    clientSecret: string;
}

interface IPaymentSetupResponse {
    clientSecret: string;
}

async function fetchPaymentSetup(): Promise<IPaymentSetup> {
    const { data } = await apiHandlerLegacy.get<IPaymentSetupResponse>(
        `${window.HIVE_CONFIG.baseApiUrl}/billing/customers/payment/setup`
    );

    return new PaymentSetup(data);
}

export function usePaymentSetup(): UseQueryResult<IPaymentSetup, Error> {
    return useQuery([QueryKey.PaymentSetup], () => fetchPaymentSetup(), {
        keepPreviousData: true,
    });
}

export interface IPaymentMethod {
    id: string;
    card: ISwarmCardPaymentMethod | null;
    bank: ISwarmUSBankAccountPaymentMethod | null;
}

export interface ISwarmCardPaymentMethod {
    id: string;
    brand: CardBrand;
    expMonth: number;
    expYear: number;
    last4: string;
    created: Date;
    defaultMethod: boolean;
}

/**
 * @see https://stripe.com/docs/api/cards/object#card_object-brand
 */
export type CardBrand =
    | "amex"
    | "diners"
    | "discover"
    | "jcb"
    | "mastercard"
    | "unionpay"
    | "visa"
    | "unknown";

interface ISwarmCardPaymentMethodResponse {
    id: string;
    brand: CardBrand;
    expMonth: number;
    expYear: number;
    last4: string;
    created: number;
    defaultMethod: boolean;
}

export class SwarmCardPaymentMethod implements ISwarmCardPaymentMethod {
    constructor(apiResponse: ISwarmCardPaymentMethodResponse) {
        this.id = apiResponse.id;
        this.brand = apiResponse.brand;
        this.expMonth = apiResponse.expMonth;
        this.expYear = apiResponse.expYear;
        this.last4 = apiResponse.last4;
        this.created = moment.utc(apiResponse.created * 1000).toDate();
        this.defaultMethod = apiResponse.defaultMethod;
    }

    id: string;
    brand: CardBrand;
    expMonth: number;
    expYear: number;
    last4: string;
    created: Date;
    defaultMethod: boolean;
}

interface ISwarmUSBankAccountPaymentMethod {
    id: string;
    bankName: string;
    accountHolderType: string;
    last4: string;
    defaultMethod: boolean;
}

interface ISwarmUSBankAccountPaymentMethodResponse {
    id: string;
    bankName: string;
    accountHolderType: string;
    last4: string;
    created: number;
    defaultMethod: boolean;
}

export class SwarmUSBankAccountPaymentMethod implements ISwarmUSBankAccountPaymentMethod {
    constructor(apiResponse: ISwarmUSBankAccountPaymentMethodResponse) {
        this.id = apiResponse.id;
        this.bankName = apiResponse.bankName;
        this.accountHolderType = apiResponse.accountHolderType;
        this.last4 = apiResponse.last4;
        this.created = moment.utc(apiResponse.created * 1000).toDate();
        this.defaultMethod = apiResponse.defaultMethod;
    }

    id: string;
    bankName: string;
    accountHolderType: string;
    last4: string;
    created: Date;
    defaultMethod: boolean;
}

class PaymentMethod implements IPaymentMethod {
    constructor(method: IPaymentMethodResponseData) {
        this.id = method.id;
        this.card = null;
        this.bank = null;

        if (method.card !== null) {
            this.card = new SwarmCardPaymentMethod(method.card);
        }
        if (method.bank !== null) {
            this.bank = new SwarmUSBankAccountPaymentMethod(method.bank);
        }
    }

    id: string;
    card: ISwarmCardPaymentMethod | null;
    bank: ISwarmUSBankAccountPaymentMethod | null;
}

interface IPaymentMethodResponseData {
    id: string;
    card: ISwarmCardPaymentMethodResponse | null;
    bank: ISwarmUSBankAccountPaymentMethodResponse | null;
}

interface IPaymentMethodsResponse {
    data: IPaymentMethodResponseData[];
}

async function fetchPaymentMethods(): Promise<IPaymentMethod[]> {
    const { data } = await apiHandlerLegacy.get<IPaymentMethodsResponse>(
        `${window.HIVE_CONFIG.baseApiUrl}/billing/customers/payment/methods`
    );

    const allPaymentMethods = data.data
        .map((paymentMethod) => new PaymentMethod(paymentMethod))
        .sort((a, b) => compareNatural(b.id, a.id));

    return allPaymentMethods;
}

export function usePaymentMethods(): UseQueryResult<IPaymentMethod[], Error> {
    return useQuery([QueryKey.PaymentMethods], () => fetchPaymentMethods(), {
        keepPreviousData: true,
    });
}

interface IPaymentDeleteResponse {
    message: string;
    success: boolean;
}

export async function deletePaymentMethod(id: string): Promise<IPaymentDeleteResponse> {
    const { data } = await apiHandlerLegacy.delete<IPaymentDeleteResponse>(
        `${window.HIVE_CONFIG.baseApiUrl}/billing/customers/payment/methods`,
        { data: { id } }
    );

    return data;
}

interface IUpdatePaymentMethodRequest {
    id: string;
}

interface IUpdatePaymentMethodResponse {
    message: string;
    success: boolean;
}

export async function updatePaymentMethod(
    params: IUpdatePaymentMethodRequest
): Promise<IUpdatePaymentMethodResponse> {
    const { data } = await apiHandlerLegacy.patch<IUpdatePaymentMethodResponse>(
        `${window.HIVE_CONFIG.baseApiUrl}/billing/customers/payment/methods`,
        params
    );

    return data;
}

interface IUpdateManualBillPayRequest {
    enabled: boolean;
    organizationId: number;
}

interface IUpdateManualBillPayResponse {
    message: string;
    success: boolean;
}

export async function updateManualBillPay(
    params: IUpdateManualBillPayRequest
): Promise<IUpdateManualBillPayResponse> {
    const { data } = await apiHandlerLegacy.put<IUpdateManualBillPayResponse>(
        `${window.HIVE_CONFIG.baseApiUrl}/billing/customers/${params.organizationId}/manualBillPay`,
        { enabled: params.enabled }
    );

    return data;
}
