/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { H4, Tab, Tabs } from "@blueprintjs/core";
import { useGetOrganizationById } from "handlers/generated/hive";
import internalSitemap, { RouteParams } from "internalSitemap";
import { LoadingPanel } from "pages/InternalOrganizations/LoadingPanel";
import { DEFAULT_ORG, NEW_ORG_ID } from "pages/InternalOrganizations/organization.types";
import { OrganizationDetailsPanel } from "pages/InternalOrganizations/OrganizationDetailsPanel";
import { OrganizationDevicesPanel } from "pages/InternalOrganizations/OrganizationDevicesPanel";
import { OrganizationFeaturesPanel } from "pages/InternalOrganizations/OrganizationFeaturesPanel";
import { OrganizationPaymentPanel } from "pages/InternalOrganizations/OrganizationPaymentPanel";
import { OrganizationRegisterDevicesPanel } from "pages/InternalOrganizations/OrganizationRegisterDevicesPanel";
import { OrganizationUsersPanel } from "pages/InternalOrganizations/OrganizationUsersPanel";
import React, { useCallback, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { useHistory, useParams } from "react-router-dom";
import editor from "styles/editor";

export enum TabId {
    details = "details",
    features = "features",
    devices = "devices",
    users = "users",
    registerDevices = "registerDevices",
    payment = "payment",
}

const useStyles = createUseStyles(editor);

interface IOrganizationEditorProps {
    organizationId: number;
}

export const OrganizationEditor: React.FunctionComponent<IOrganizationEditorProps> = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { organizationId, tab: selectedTab } = useParams<RouteParams<"organizations">>();

    const isNew = props.organizationId === NEW_ORG_ID;
    const { data: organizationData, isLoading } = useGetOrganizationById(props.organizationId, {
        query: {
            enabled: !isNew,
            initialData: isNew ? DEFAULT_ORG : undefined,
        },
    });

    // new orgs can only see details tab
    useEffect(() => {
        if (isNew) {
            history.push(
                internalSitemap.organizations.generatePath({ organizationId, tab: TabId.details })
            );
        }
    }, [history, isNew, organizationData, organizationId]);

    const handleTabChange = useCallback(
        (newTabId: TabId) => {
            history.push(
                internalSitemap.organizations.generatePath({
                    organizationId,
                    tab: newTabId,
                })
            );
        },
        [history, organizationId]
    );

    const organizationTitle = isNew
        ? "New Organization"
        : `Organization ${organizationData?.name ?? ""}`;

    return (
        <div className={classes.root}>
            <H4>{organizationTitle}</H4>
            <Tabs
                id="OrganizationTabs"
                selectedTabId={selectedTab}
                onChange={handleTabChange}
                large
            >
                <Tab
                    id={TabId.details}
                    title="Details"
                    panel={
                        <LoadingPanel
                            render={() => (
                                <OrganizationDetailsPanel organizationData={organizationData} />
                            )}
                            shouldRender={!isLoading}
                        />
                    }
                />
                <Tab
                    id={TabId.users}
                    title="Users"
                    disabled={organizationData === undefined || isNew}
                    panel={
                        <LoadingPanel
                            render={() => (
                                <OrganizationUsersPanel organizationData={organizationData!} />
                            )}
                            shouldRender={!isLoading}
                        />
                    }
                />
                <Tab
                    id={TabId.features}
                    title="Features"
                    disabled={organizationData === undefined || isNew}
                    panel={
                        <LoadingPanel
                            render={() => (
                                <OrganizationFeaturesPanel organizationData={organizationData!} />
                            )}
                            shouldRender={!isLoading && !isNew}
                        />
                    }
                />
                <Tab
                    id={TabId.devices}
                    title="Devices"
                    disabled={organizationData === undefined || isNew}
                    panel={
                        <LoadingPanel
                            render={() => (
                                <OrganizationDevicesPanel organizationData={organizationData!} />
                            )}
                            shouldRender={!isLoading && !isNew}
                        />
                    }
                />
                <Tab
                    id={TabId.registerDevices}
                    title="Register Devices"
                    disabled={organizationData === undefined || isNew}
                    panel={
                        <LoadingPanel
                            render={() => (
                                <OrganizationRegisterDevicesPanel
                                    organizationData={organizationData!}
                                />
                            )}
                            shouldRender={!isLoading && !isNew}
                        />
                    }
                />
                <Tab
                    id={TabId.payment}
                    title="Invoices"
                    disabled={organizationData === undefined || isNew}
                    panel={
                        <LoadingPanel
                            render={() => (
                                <OrganizationPaymentPanel organizationData={organizationData!} />
                            )}
                            shouldRender={!isLoading && !isNew}
                        />
                    }
                />
            </Tabs>
        </div>
    );
};
