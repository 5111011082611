import { Card, Elevation } from "@blueprintjs/core";
import { externalSitemap } from "externalSitemap";
import { SwarmStripeCustomer } from "handlers/generated/billing";
import { ImageSize } from "images/types";
import React from "react";
import { FaExternalLinkAlt, FaStripe } from "react-icons/fa";
import { createUseStyles } from "react-jss";
import { common } from "styles/common";
import variables from "styles/variables";

const orgCard = {
    ...common,
    root: {
        ...common.col,
        flexDirection: "row",
        alignItems: "center",
        flexGrow: "auto",
    },
    leftPane: {
        composes: "$flexWithSpacing",
        marginRight: variables.gridSize,
    },
    cardTitle: {
        margin: 0,
    },
    cardSubTitle: {
        marginBottom: variables.gridSize,
    },
    cardAction: {
        marginRight: variables.gridSize,
    },
    warning: {
        marginTop: variables.gridSize,
    },
} as const;

const useStyles = createUseStyles(orgCard);

interface IBillingCustomerCardProps {
    billingCustomer: SwarmStripeCustomer;
}

export const BillingCustomerCard: React.FunctionComponent<IBillingCustomerCardProps> = ({
    billingCustomer,
}) => {
    if (billingCustomer === undefined) {
        throw new Error("billing customer can not be new");
    }

    const classes = useStyles();

    return (
        <Card elevation={Elevation.ONE} className={classes.root}>
            <div className={classes.leftPane}>
                <FaStripe size={ImageSize.SMALL} className={classes.leftPane} />
            </div>
            <div>
                <h5 className={classes.cardTitle}>
                    {billingCustomer.name ?? "No Stripe Customer Name. Please set one in stripe"}
                </h5>
                <div className={classes.cardSubTitle}>{billingCustomer.email}</div>

                <a
                    href={externalSitemap.stripeCustomer.generatePath({
                        customerId: billingCustomer.id,
                    })}
                    target="_blank"
                    className={classes.cardAction}
                    rel="noreferrer"
                >
                    View Stripe Customer <FaExternalLinkAlt />
                </a>
            </div>
        </Card>
    );
};
