import { Spinner } from "@blueprintjs/core";
import { useGetInvoicesWithLineItems } from "handlers/generated/billing";
import React from "react";
import { CardInvoice } from "./CardInvoice";

interface IInvoicesListProps {
    organizationId: number;
}

export const InvoicesList: React.FunctionComponent<IInvoicesListProps> = (props) => {
    const { data, isLoading, error } = useGetInvoicesWithLineItems(props.organizationId, {
        count: 100,
    });

    if (isLoading) {
        return (
            <div style={{ textAlign: "center", margin: "32px 0 64px 0" }}>
                <Spinner />
            </div>
        );
    }

    if (data === undefined || data.length === 0) {
        return (
            <div style={{ textAlign: "center", margin: "32px 0 64px 0" }}>
                You do not have any invoices
            </div>
        );
    }

    if (error) {
        return (
            <div style={{ textAlign: "center", margin: "32px 0 64px 0" }}>
                Could not load invoices at this time
            </div>
        );
    }

    return (
        <>
            {data.map((invoice) => (
                <CardInvoice key={invoice.invoiceNumber} invoice={invoice} />
            ))}
        </>
    );
};
