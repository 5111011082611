import moment from "moment-timezone";
import { useQuery, UseQueryResult } from "react-query";
import { apiHandlerLegacy, QueryKey } from "resources/apiHandler";

export enum DevicePaymentStatus {
    ERROR = "ERROR",
    TRIAL = "TRIAL",
    ACTIVE = "ACTIVE",
    CANCELED = "CANCELED",
    GRACE_PERIOD = "GRACE_PERIOD",
    PAST_DUE = "PAST_DUE",
    ON_HOLD = "ON_HOLD",
    FREE_FOREVER = "FREE_FOREVER",
    REACTIVATION = "REACTIVATION",
}

export type DeviceBillingPlan = typeof DeviceBillingPlan[keyof typeof DeviceBillingPlan];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeviceBillingPlan = {
    ERROR: "ERROR",
    NONE: "NONE",
    STANDARD_DATA_PLAN_YEARLY: "STANDARD_DATA_PLAN_YEARLY",
    REDUCED_DATA_PLAN_YEARLY: "REDUCED_DATA_PLAN_YEARLY",
} as const;

export interface IDeviceBillingResponse {
    organizationId: number | null;
    deviceBillingStatuses: IDeviceBillingStatus[];
}

interface IDeviceBillingListRequest {
    organizationId: number | null;
    deviceIds: number[];
}

class DeviceBillingStatus implements IDeviceBillingStatus {
    constructor(apiResponse: IDeviceBillingStatus) {
        this.devicePaymentId = apiResponse.devicePaymentId;
        this.organizationId = apiResponse.organizationId;
        this.deviceId = apiResponse.deviceId;
        this.plan = apiResponse.plan;
        this.paidThrough =
            apiResponse.paidThrough === null ? null : moment(apiResponse.paidThrough).toDate();
        this.activationDate =
            apiResponse.activationDate === null
                ? null
                : moment(apiResponse.activationDate).toDate();
        this.lastMessageAt =
            apiResponse.lastMessageAt === null ? null : moment(apiResponse.lastMessageAt).toDate();
        this.status = apiResponse.status;
    }

    devicePaymentId: number;
    organizationId: number;
    deviceId: number;
    plan: DeviceBillingPlan;
    paidThrough: Date | null;
    activationDate: Date | null;
    lastMessageAt: Date | null;
    status: DevicePaymentStatus;
}

class DeviceBillingResponse implements IDeviceBillingResponse {
    constructor(apiResponse: IDeviceBillingStatusResponse) {
        this.organizationId = apiResponse.organizationId ?? null;
        this.deviceBillingStatuses = apiResponse.deviceBillingStatuses.map(
            (b) => new DeviceBillingStatus(b)
        );
    }

    organizationId: number | null;
    deviceBillingStatuses: IDeviceBillingStatus[];
}

interface IDeviceBillingStatusResponse {
    organizationId: number | null;
    deviceBillingStatuses: IDeviceBillingStatus[];
}

export interface IDeviceBillingStatus {
    devicePaymentId: number;
    organizationId: number;
    deviceId: number;
    plan: DeviceBillingPlan;
    paidThrough: Date | null;
    activationDate: Date | null;
    lastMessageAt: Date | null;
    status: DevicePaymentStatus;
}

async function fetchDeviceBillingList(
    params: IDeviceBillingListRequest
): Promise<IDeviceBillingResponse> {
    const { data } = await apiHandlerLegacy.post<IDeviceBillingStatusResponse>(
        `/api/v1/billing/devices`,
        params
    );

    return new DeviceBillingResponse(data);
}

/**
 * gets a list of billing statuses for devices
 */
export function useDeviceBillingList(
    params: IDeviceBillingListRequest
): UseQueryResult<IDeviceBillingResponse, Error> {
    return useQuery([QueryKey.DeviceBillingList, params], () => fetchDeviceBillingList(params), {
        keepPreviousData: true,
    });
}
