export enum PacketStatus {
    /** failed to deliver, will not re-attempt */
    FAILED = -4,

    /** failed to deliver, retrying */
    RETRYING = -3,

    DUPLICATE = -2,

    ERROR = -1,

    /** incoming message (from a device) */
    RECEIVED = 0,

    /** outgoing message (to a device) */
    SENT = 1,

    /**
     * incoming message, acknowledged as seen by customer
     * OR a outgoing message packet is on ground station
     */
    RXACKED = 2,

    /** outgoing message, packet is on satellite */
    TXACKED = 3,

    /** ???? Dont use - sent by hive and received by satellite */
    GWRXACKED = 4,
}

export enum FilterStatus {
    /** do not filter (return all) */
    ALL = -1,

    /** not yet processed by user */
    UNACKNOWLEDGED = 0,
    ACKNOWLEDGED = 2,
}

export enum DataType {
    SENSOR = 0,
    TEXT_MESSAGE = 1,
    SAVED_TELEMETRY = 2,
    VEHICLE_DYNAMICS = 3,
    EXECUTED_COMMAND = 4,
    FIRMWARE_COMMAND = 5,
    USER_APPLICATION = 6,
}

export enum MessageDirectionFilter {
    ALL = "all",
    TO_DEVICE = "todevice",
    FROM_DEVICE = "fromdevice",
}

export enum MessageDirection {
    TO_DEVICE = 0,
    FROM_DEVICE = 1,
}
