import { Card } from "@blueprintjs/core";
import classNames from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    card: {
        boxShadow: "none",
        marginBottom: 16,
    },
    cardTitle: {
        marginTop: 0,
    },
    minimal: {
        background: "transparent",
        boxShadow: "none",
        paddingLeft: 0,
        paddingRight: 0,
    },
});

interface ISXCardProps {
    title?: React.ReactNode;

    /** @default false */
    minimal?: boolean;

    className?: string;

    style?: React.CSSProperties;
    children: React.ReactNode | React.ReactNode[];
}

export const SXCard: React.FunctionComponent<ISXCardProps> = ({
    title,
    minimal = false,
    className,
    children,
    style,
}) => {
    const classes = useStyles();

    return (
        <Card
            className={classNames(classes.card, className, {
                [classes.minimal]: minimal,
            })}
            style={style}
        >
            {title !== undefined && <h3 className={classes.cardTitle}>{title}</h3>}
            {children}
        </Card>
    );
};
