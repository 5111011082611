/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Billing Service REST API
 * OpenAPI spec version: v1
 */
import { useQuery, useMutation } from "react-query";
import type {
    UseQueryOptions,
    UseMutationOptions,
    QueryFunction,
    MutationFunction,
    UseQueryResult,
    QueryKey,
} from "react-query";
import type {
    DeviceBillingStatusResponseJson,
    ApiError,
    DeviceBillingStatusRequestJson,
    StatusJson,
    DeviceBillingDataPlanUpdateJson,
    DeviceBillingPaidThroughUpdateJson,
    NewDeviceBillingStatusJson,
    DeviceActivationDateList,
    DeviceStatsJson,
    GetDevicesStatsParams,
} from "../models";
import { apiHandler } from "../../../../resources/apiHandler";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
    config: any,
    args: infer P
) => any
    ? P
    : never;

/**
 * @summary POST retrieve device billing status for given organization and deviceIds
 */
export const getDevicePayments = (
    deviceBillingStatusRequestJson: DeviceBillingStatusRequestJson,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<DeviceBillingStatusResponseJson>(
        {
            url: `/api/v1/billing/devices`,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: deviceBillingStatusRequestJson,
        },
        options
    );
};

export const getGetDevicePaymentsMutationOptions = <
    TError = ApiError,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof getDevicePayments>>,
        TError,
        { data: DeviceBillingStatusRequestJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof getDevicePayments>>,
    TError,
    { data: DeviceBillingStatusRequestJson },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof getDevicePayments>>,
        { data: DeviceBillingStatusRequestJson }
    > = (props) => {
        const { data } = props ?? {};

        return getDevicePayments(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type GetDevicePaymentsMutationResult = NonNullable<
    Awaited<ReturnType<typeof getDevicePayments>>
>;
export type GetDevicePaymentsMutationBody = DeviceBillingStatusRequestJson;
export type GetDevicePaymentsMutationError = ApiError;

/**
 * @summary POST retrieve device billing status for given organization and deviceIds
 */
export const useGetDevicePayments = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof getDevicePayments>>,
        TError,
        { data: DeviceBillingStatusRequestJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getGetDevicePaymentsMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary POST bulk update data plan for multiple devices
 */
export const updateDataPlanBulk = (
    deviceBillingDataPlanUpdateJson: DeviceBillingDataPlanUpdateJson,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<StatusJson>(
        {
            url: `/api/v1/billing/devices/plan`,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: deviceBillingDataPlanUpdateJson,
        },
        options
    );
};

export const getUpdateDataPlanBulkMutationOptions = <
    TError = ApiError,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof updateDataPlanBulk>>,
        TError,
        { data: DeviceBillingDataPlanUpdateJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof updateDataPlanBulk>>,
    TError,
    { data: DeviceBillingDataPlanUpdateJson },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof updateDataPlanBulk>>,
        { data: DeviceBillingDataPlanUpdateJson }
    > = (props) => {
        const { data } = props ?? {};

        return updateDataPlanBulk(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type UpdateDataPlanBulkMutationResult = NonNullable<
    Awaited<ReturnType<typeof updateDataPlanBulk>>
>;
export type UpdateDataPlanBulkMutationBody = DeviceBillingDataPlanUpdateJson;
export type UpdateDataPlanBulkMutationError = ApiError;

/**
 * @summary POST bulk update data plan for multiple devices
 */
export const useUpdateDataPlanBulk = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof updateDataPlanBulk>>,
        TError,
        { data: DeviceBillingDataPlanUpdateJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getUpdateDataPlanBulkMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary POST bulk update paidThrough date for multiple devices
 */
export const updatePaidThroughBulk = (
    organizationId: number,
    deviceBillingPaidThroughUpdateJson: DeviceBillingPaidThroughUpdateJson,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<StatusJson>(
        {
            url: `/api/v1/billing/devices/paidthrough/${organizationId}`,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: deviceBillingPaidThroughUpdateJson,
        },
        options
    );
};

export const getUpdatePaidThroughBulkMutationOptions = <
    TError = ApiError,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof updatePaidThroughBulk>>,
        TError,
        { organizationId: number; data: DeviceBillingPaidThroughUpdateJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof updatePaidThroughBulk>>,
    TError,
    { organizationId: number; data: DeviceBillingPaidThroughUpdateJson },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof updatePaidThroughBulk>>,
        { organizationId: number; data: DeviceBillingPaidThroughUpdateJson }
    > = (props) => {
        const { organizationId, data } = props ?? {};

        return updatePaidThroughBulk(organizationId, data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type UpdatePaidThroughBulkMutationResult = NonNullable<
    Awaited<ReturnType<typeof updatePaidThroughBulk>>
>;
export type UpdatePaidThroughBulkMutationBody = DeviceBillingPaidThroughUpdateJson;
export type UpdatePaidThroughBulkMutationError = ApiError;

/**
 * @summary POST bulk update paidThrough date for multiple devices
 */
export const useUpdatePaidThroughBulk = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof updatePaidThroughBulk>>,
        TError,
        { organizationId: number; data: DeviceBillingPaidThroughUpdateJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getUpdatePaidThroughBulkMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary POST initialize device billing status for given deviceId.
 */
export const createDevicePayment = (
    newDeviceBillingStatusJson: NewDeviceBillingStatusJson,
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<StatusJson>(
        {
            url: `/api/v1/billing/devices/new`,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: newDeviceBillingStatusJson,
        },
        options
    );
};

export const getCreateDevicePaymentMutationOptions = <
    TError = ApiError,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof createDevicePayment>>,
        TError,
        { data: NewDeviceBillingStatusJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof createDevicePayment>>,
    TError,
    { data: NewDeviceBillingStatusJson },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof createDevicePayment>>,
        { data: NewDeviceBillingStatusJson }
    > = (props) => {
        const { data } = props ?? {};

        return createDevicePayment(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type CreateDevicePaymentMutationResult = NonNullable<
    Awaited<ReturnType<typeof createDevicePayment>>
>;
export type CreateDevicePaymentMutationBody = NewDeviceBillingStatusJson;
export type CreateDevicePaymentMutationError = ApiError;

/**
 * @summary POST initialize device billing status for given deviceId.
 */
export const useCreateDevicePayment = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof createDevicePayment>>,
        TError,
        { data: NewDeviceBillingStatusJson },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getCreateDevicePaymentMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary POST initialize device billing status for given organization and deviceIds. Temporary route for initial migration. Will create a billing status for each org/device pair for which a billing status doesn't yet exist.
 */
export const createDevicePayments = (
    newDeviceBillingStatusJson: NewDeviceBillingStatusJson[],
    options?: SecondParameter<typeof apiHandler>
) => {
    return apiHandler<StatusJson>(
        {
            url: `/api/v1/billing/devices/new/bulk`,
            method: "post",
            headers: { "Content-Type": "application/json" },
            data: newDeviceBillingStatusJson,
        },
        options
    );
};

export const getCreateDevicePaymentsMutationOptions = <
    TError = ApiError,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof createDevicePayments>>,
        TError,
        { data: NewDeviceBillingStatusJson[] },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof createDevicePayments>>,
    TError,
    { data: NewDeviceBillingStatusJson[] },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof createDevicePayments>>,
        { data: NewDeviceBillingStatusJson[] }
    > = (props) => {
        const { data } = props ?? {};

        return createDevicePayments(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type CreateDevicePaymentsMutationResult = NonNullable<
    Awaited<ReturnType<typeof createDevicePayments>>
>;
export type CreateDevicePaymentsMutationBody = NewDeviceBillingStatusJson[];
export type CreateDevicePaymentsMutationError = ApiError;

/**
 * @summary POST initialize device billing status for given organization and deviceIds. Temporary route for initial migration. Will create a billing status for each org/device pair for which a billing status doesn't yet exist.
 */
export const useCreateDevicePayments = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof createDevicePayments>>,
        TError,
        { data: NewDeviceBillingStatusJson[] },
        TContext
    >;
    request?: SecondParameter<typeof apiHandler>;
}) => {
    const mutationOptions = getCreateDevicePaymentsMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Get list of activation date (based on when the 51st message was sent) for each activated device in an organization. Internal only.
 * @summary GET activation dates for organization
 */
export const getActivationDates1 = (
    organizationId: number,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<DeviceActivationDateList>(
        { url: `/api/v1/billing/devices/${organizationId}/activation`, method: "get", signal },
        options
    );
};

export const getGetActivationDates1QueryKey = (organizationId: number) =>
    [`/api/v1/billing/devices/${organizationId}/activation`] as const;

export const getGetActivationDates1QueryOptions = <
    TData = Awaited<ReturnType<typeof getActivationDates1>>,
    TError = ApiError
>(
    organizationId: number,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getActivationDates1>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getActivationDates1>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetActivationDates1QueryKey(organizationId);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getActivationDates1>>> = ({ signal }) =>
        getActivationDates1(organizationId, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!organizationId, ...queryOptions };
};

export type GetActivationDates1QueryResult = NonNullable<
    Awaited<ReturnType<typeof getActivationDates1>>
>;
export type GetActivationDates1QueryError = ApiError;

/**
 * @summary GET activation dates for organization
 */
export const useGetActivationDates1 = <
    TData = Awaited<ReturnType<typeof getActivationDates1>>,
    TError = ApiError
>(
    organizationId: number,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getActivationDates1>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetActivationDates1QueryOptions(organizationId, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Get the current count of free (paidThrough set to the freeForever date), activated (transmitted >50 msgs), overdue (devices past paidThrough date) and live (transmitted in the last 30 days) devices. Pass in an optional set of unbilled org ids to not include in the count. By default devices in org 0 are excluded from count.
 * @summary GET a count of device stats
 */
export const getDevicesStats = (
    params?: GetDevicesStatsParams,
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<DeviceStatsJson>(
        { url: `/api/v1/billing/devices/count`, method: "get", params, signal },
        options
    );
};

export const getGetDevicesStatsQueryKey = (params?: GetDevicesStatsParams) =>
    [`/api/v1/billing/devices/count`, ...(params ? [params] : [])] as const;

export const getGetDevicesStatsQueryOptions = <
    TData = Awaited<ReturnType<typeof getDevicesStats>>,
    TError = ApiError
>(
    params?: GetDevicesStatsParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getDevicesStats>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryOptions<Awaited<ReturnType<typeof getDevicesStats>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetDevicesStatsQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDevicesStats>>> = ({ signal }) =>
        getDevicesStats(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetDevicesStatsQueryResult = NonNullable<Awaited<ReturnType<typeof getDevicesStats>>>;
export type GetDevicesStatsQueryError = ApiError;

/**
 * @summary GET a count of device stats
 */
export const useGetDevicesStats = <
    TData = Awaited<ReturnType<typeof getDevicesStats>>,
    TError = ApiError
>(
    params?: GetDevicesStatsParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof getDevicesStats>>, TError, TData>;
        request?: SecondParameter<typeof apiHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetDevicesStatsQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Temp route to backfill prometheus paid through metric
 * @summary GET backfill device billing status counts into prometheus
 */
export const backfillMetric = (
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<number>(
        { url: `/api/v1/billing/devices/backfill`, method: "get", signal },
        options
    );
};

export const getBackfillMetricQueryKey = () => [`/api/v1/billing/devices/backfill`] as const;

export const getBackfillMetricQueryOptions = <
    TData = Awaited<ReturnType<typeof backfillMetric>>,
    TError = ApiError
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof backfillMetric>>, TError, TData>;
    request?: SecondParameter<typeof apiHandler>;
}): UseQueryOptions<Awaited<ReturnType<typeof backfillMetric>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getBackfillMetricQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof backfillMetric>>> = ({ signal }) =>
        backfillMetric(requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type BackfillMetricQueryResult = NonNullable<Awaited<ReturnType<typeof backfillMetric>>>;
export type BackfillMetricQueryError = ApiError;

/**
 * @summary GET backfill device billing status counts into prometheus
 */
export const useBackfillMetric = <
    TData = Awaited<ReturnType<typeof backfillMetric>>,
    TError = ApiError
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof backfillMetric>>, TError, TData>;
    request?: SecondParameter<typeof apiHandler>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getBackfillMetricQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};
