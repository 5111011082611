/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Billing Service REST API
 * OpenAPI spec version: v1
 */

export type DeviceBillingStatusPlan =
    typeof DeviceBillingStatusPlan[keyof typeof DeviceBillingStatusPlan];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeviceBillingStatusPlan = {
    ERROR: "ERROR",
    NONE: "NONE",
    STANDARD_DATA_PLAN_YEARLY: "STANDARD_DATA_PLAN_YEARLY",
    REDUCED_DATA_PLAN_YEARLY: "REDUCED_DATA_PLAN_YEARLY",
} as const;
