import { DateRange } from "@blueprintjs/datetime";
import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import { ScaleTime, scaleTime } from "d3-scale";
import { timeDay, timeHour, timeMinute, timeSecond, timeWeek } from "d3-time";
import { UserContextRole } from "handlers/generated/hive";
import { getReasonPhrase } from "http-status-codes";
import moment, { Moment, MomentInput } from "moment-timezone";
import { useMemo } from "react";
import { DevicePaymentStatus, IDeviceBillingStatus } from "resources/BillingDevicesResource";
import { NetworkError, Resource } from "rest-hooks";
import sitemap from "sitemap";

// This type is useful for working with rest-hooks resource classes, particularly when combining
// them with react-hook-form forms. It provides a type that contains only the fields specific to
// that resource without any methods or fields from the base class.
export type ResourceFields<T extends Resource> = Omit<T, keyof Resource>;

export interface ParsedSearchQuery {
    text: string[];
    keywords: Record<string, string>;
}

/** Standard swarm error response object
 * todo verify all error responses are this shape
 */
interface ISwarmNetworkResponse {
    /** @example BAD_REQUEST */
    status: string;
    message: string;
}

/** Jan 1st, 2020 - rough time network went live to customers */
export const SWARM_NETWORK_START = new Date(2020, 0, 1);
export const MAX_PAID_THROUGH_DATE = new Date(3000, 0, 1);

export const PREFERRED_FORMAT_DAYS = "YYYY-MM-DD";
export const PREFERRED_FORMAT_MINUTES = "YYYY-MM-DD HH:mm";
export const PREFERRED_FORMAT_SECONDS = "YYYY-MM-DD HH:mm:ss";
// See https://emailregex.com/
export const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const ADMIN_EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(swarm.space)|(spacex.com)$/;

export function useTimezone(): { zone: string; zoneAbbr: string } {
    return useMemo(() => {
        const zone = moment.tz.guess();
        return { zone, zoneAbbr: moment.tz(zone).zoneAbbr() };
    }, []);
}

export function isInDateRange(date: Date | Moment, dateRange?: DateRange): boolean {
    if (dateRange == null || (dateRange[0] == null && dateRange[1] == null)) {
        return true;
    }

    const parsedDate = date instanceof Date ? moment(date) : date;

    if (dateRange[0] == null) {
        return parsedDate.isBefore(dateRange[1], "minutes");
    }
    if (dateRange[1] == null) {
        return parsedDate.isSameOrAfter(dateRange[0], "minutes");
    }
    return parsedDate.isBetween(dateRange[0], dateRange[1], "minutes", "[)");
}

export function formatDate(
    date: Date | null | undefined,
    zone: string,
    showTime = true,
    showYear = true
): string {
    if (date != null) {
        const parsedDate = moment(date).tz(zone);

        if (parsedDate.isValid()) {
            return parsedDate.format(
                `MMM D${showYear ? ", YYYY" : ""}${showTime ? " h:mm a" : ""}`
            );
        }
    }

    return "Never";
}

export function formatPlural(singular: string, plural: string, count: number): string {
    return count === 1 ? singular : plural;
}

export function formatDateRange(startDate: Date, endDate: Date): string {
    const getFormat = ({
        year,
        date,
        time,
        seconds,
    }: {
        year: boolean;
        date: boolean;
        time: boolean;
        seconds: boolean;
    }): string => {
        let format = "";

        if (year) {
            format += "YYYY-";
        }
        if (date) {
            format += "MM-DD ";
        }
        if (time) {
            format += "HH:mm";
            if (seconds) {
                format += ":ss";
            }
        }

        return format;
    };
    const start = moment(startDate);
    const end = moment(endDate);
    const startFormat = getFormat({
        year: true,
        date: true,
        time: true,
        seconds: start.seconds() !== 0 || end.seconds() !== 0,
    });
    const endFormat = getFormat({
        year: start.year() !== end.year(),
        date:
            start.month() !== end.month() ||
            start.date() !== end.date() ||
            start.year() !== end.year(),
        time: true,
        seconds: start.seconds() !== 0 || end.seconds() !== 0,
    });

    return `${start.format(startFormat)} to ${end.format(endFormat)}`;
}

// Map the binInterval prop (a string, e.g. "day"), to the corresponding d3 interval variable
export const binIntervals = {
    day: timeDay,
    hour: timeHour,
    minute: timeMinute,
    second: timeSecond,
    week: timeWeek,
} as const;

export type BinInterval = keyof typeof binIntervals;

export function getTimeScaleForBins(
    binCount: number,
    binInterval: BinInterval,
    endDate?: MomentInput
): ScaleTime<Date, Date> {
    // Include the entire current hour/day/etc. so that the bins line up nicely.
    const minTime = moment(endDate)
        .startOf(binInterval)
        .subtract(binCount - 1, binInterval)
        .toDate();
    const maxTime = moment(endDate).endOf(binInterval).toDate();
    return scaleTime<Date, Date>().domain([minTime, maxTime]);
}

export function includesIgnoringCase(value: string, searchString: string): boolean {
    return value.toLocaleLowerCase().includes(searchString.toLocaleLowerCase());
}

// Shortcut for case-insensitive, number-friendly compare function. It doesn't use "this" so no
// need to bind.
// eslint-disable-next-line @typescript-eslint/unbound-method
export const compareNatural = new Intl.Collator(undefined, { numeric: true, sensitivity: "base" })
    .compare;

export function compareDates(a: MomentInput, b: MomentInput): number {
    if (a == null && b == null) {
        return 0;
    }
    if (a == null) {
        return -Infinity;
    }
    if (b == null) {
        return Infinity;
    }
    return moment(a).diff(b);
}

export function comparePaymentStatus(
    a: IDeviceBillingStatus | undefined | null,
    b: IDeviceBillingStatus | undefined | null
): number {
    if (a == null && b == null) {
        return 0;
    }
    if (a == null) {
        return Infinity;
    }
    if (b == null) {
        return -Infinity;
    }

    // sort order for payment status (lower number = first)
    const devicePaymentStatusOrder: Record<DevicePaymentStatus, number> = {
        [DevicePaymentStatus.PAST_DUE]: 0,
        [DevicePaymentStatus.GRACE_PERIOD]: 1,
        [DevicePaymentStatus.REACTIVATION]: 2,
        [DevicePaymentStatus.TRIAL]: 3,
        [DevicePaymentStatus.ACTIVE]: 4,
        [DevicePaymentStatus.FREE_FOREVER]: 5,
        [DevicePaymentStatus.ON_HOLD]: 6,
        [DevicePaymentStatus.CANCELED]: 7,
        [DevicePaymentStatus.ERROR]: 8,
    };

    if (
        (a.status === DevicePaymentStatus.ACTIVE && b.status === DevicePaymentStatus.ACTIVE) ||
        (a.status === DevicePaymentStatus.PAST_DUE && b.status === DevicePaymentStatus.PAST_DUE)
    ) {
        return compareDates(a.paidThrough, b.paidThrough);
    }

    if (a.status === DevicePaymentStatus.ON_HOLD && b.status === DevicePaymentStatus.ON_HOLD) {
        // Multiplied by -1 to show "upcoming on Hold" devices at the top of the onHold devices section
        return -1 * compareDates(a.paidThrough, b.paidThrough);
    }

    return devicePaymentStatusOrder[a.status] - devicePaymentStatusOrder[b.status];
}

/** @deprecated use DateDisplayTimeAgo */
export function useFormattedDate(date?: Date): [string | null, string | null] {
    const { zone } = useTimezone();
    const fullFormattedDate = useMemo(
        () => (date == null ? null : moment(date).tz(zone).format()),
        [date, zone]
    );
    return [fullFormattedDate, date == null ? null : moment(date).fromNow()];
}

export function toHexString(value: string): string {
    return value
        .split("")
        .map((char) => {
            const charCode = char.charCodeAt(0);
            // Zero-pad
            return charCode < 16 ? `0${charCode.toString(16)}` : charCode.toString(16);
        })
        .join(" ");
}

export function toLocaleFixed(value: number, digits: number): string {
    return value.toLocaleString(undefined, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
    });
}

// This does exact match on keyword arguments since the primary use case is cross-linking
// (e.g. only show messages with deviceId X)
export function matchesParsedSearchQuery<T extends Record<string, string>>(
    item: T,
    parsedQuery: ParsedSearchQuery
): boolean {
    return Object.entries(item).some(
        ([key, value]) =>
            parsedQuery.text.some((query) => includesIgnoringCase(value, query)) ||
            (parsedQuery.keywords[key] != null && parsedQuery.keywords[key] === value)
    );
}

// Stolen from the rest-hooks source code
export function isNetworkError(error: NetworkError | unknown): error is NetworkError {
    return Object.prototype.hasOwnProperty.call(error, "status");
}

export function isAxiosNetworkError(
    error: AxiosError<ISwarmNetworkResponse> | unknown
): error is AxiosError<ISwarmNetworkResponse> {
    return Object.prototype.hasOwnProperty.call(error, "isAxiosError");
}

const SESSION_EXPIRED = "Session expired. Please login";

/**
 * Gets the error message from the API
 */
export function getNetworkErrorMessage(error: unknown): string {
    const unknownErrorString = "Unknown error";

    if (isAxiosNetworkError(error)) {
        if (error.response?.status === 401) {
            return SESSION_EXPIRED;
        }

        const responseMessage = error.response?.data?.message;
        if (responseMessage !== undefined && responseMessage.length > 0) {
            return responseMessage;
        }
    }

    if (isNetworkError(error)) {
        if (error.status === 401) {
            return SESSION_EXPIRED;
        }

        return (
            error.message ||
            error.response?.statusText ||
            (error.status == null ? unknownErrorString : getReasonPhrase(error.status))
        );
    }

    if (error instanceof Error) {
        return error.message || unknownErrorString;
    }
    return String(error) || unknownErrorString;
}

/**
 * Redirects the user to the login page if they are not logged in
 * If they are logged in return the error message from the API
 * @param error
 * @returns
 */
export function handleNetworkError(error: unknown): string {
    const networkError = getNetworkErrorMessage(error);

    if (networkError === SESSION_EXPIRED) {
        // redirect to login page
        window.location.href = getUnauthenticatedRedirect(window.location.pathname);
    }

    return networkError;
}

// todo move to type file
export enum Timespan {
    DAY = "24h",
    THIRTY_DAYS = "30d",
    ALL = "all",
}

export enum UserPortal {
    USER = 0,
    ADMIN = 1,
}

/**
 * detects what portal the current user is on
 */
export function getUserPortal(pathname: string = window.location.pathname): UserPortal {
    if (pathname.toLowerCase().startsWith("/internal/")) {
        return UserPortal.ADMIN;
    }

    return UserPortal.USER;
}

/**
 * Special handling for unauthenticated responses, redirect to login
 * @param currentPath pass the current path the user is on UseHistory.location.pathName or window.location.pathname
 * @returns
 */
export function getUnauthenticatedRedirect(currentPath: string): string {
    let continuePath: string | undefined = currentPath;

    if (currentPath === "/") {
        continuePath = undefined;
    }

    return sitemap.login.generatePath({ continue: continuePath });
}

interface ICurrencyDisplay {
    amount: number;

    /** @default 2 */
    minDigits?: number;

    /** @default 2 */
    maxDigits?: number;
}

export function currencyDisplay({
    amount,
    minDigits = 2,
    maxDigits = 2,
}: ICurrencyDisplay): string {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",

        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
    });

    return formatter.format(amount);
}

export function numberDisplay({ amount, minDigits = 2, maxDigits = 2 }: ICurrencyDisplay): string {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "decimal",

        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
    });

    return formatter.format(amount);
}

type SwarmEmails = "support@swarm.space" | "orders@swarm.space";

/** generates a mailto link */
export function getMailToLink(to: SwarmEmails, subject: string, body: string): string {
    const args: string[] = [];
    if (typeof subject !== "undefined") {
        args.push(`subject=${encodeURIComponent(subject)}`);
    }
    if (typeof body !== "undefined") {
        args.push(`body=${encodeURIComponent(body)}`);
    }

    let url = `mailto:${encodeURIComponent(to)}`;
    if (args.length > 0) {
        url += `?${args.join("&")}`;
    }

    return url;
}

/**
 * Outputs error to console and logs to sentry.io
 */
export function logError(message: string): void {
    console.error(message);
    Sentry.captureMessage(message);
}

/**
 * Use to give a typescript error on non-exhaustive switch statements
 * @example
 * switch (twoValues) {
    case TwoValues.One:
        return 1;
    default:
        return assertUnreachable(twoValues); // <-- typescript error here
  }
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function assertUnreachable(unreachableItem: never): never {
    throw new Error(`assertUnreachable ${JSON.stringify(unreachableItem)}`);
}

/**
 * parses the integer id from a route param, or return "new"
 * Use for determine which entity id the user is viewing (or creating)
 */
export function getIdOrNew(routeParam: string | undefined): number | "new" {
    if (routeParam === undefined || routeParam === "new") {
        return "new";
    }

    const routeParamAsNumber = parseInt(routeParam, 10);
    if (Number.isNaN(routeParamAsNumber)) {
        return "new";
    }

    return routeParamAsNumber;
}

export function isSuperAdmin(role: UserContextRole) {
    return role === UserContextRole.SUPER_ADMIN;
}

export function getNextInvoiceDate() {
    const date = new Date();
    if (date.getDate() === 1) {
        return date;
    }
    return new Date(date.getFullYear(), date.getMonth() + 1, 1);
}
