import {
    IDeviceFormValuesMetadata,
    IDeviceFormValuesMetadataMutation,
} from "components/InternalDeviceDetail/InternalDeviceEditor.types";
import { IDevice, IDeviceMetadata, IDeviceMetadataMutation } from "resources/DeviceResource";
import { DeviceType } from "resources/DeviceResource.types";

export const startsWithSwarmPrefixRegex = /^(\s*swarm)/i;
export const doesNotStartWithSwarmPrefixRegex = /^(?!\s*swarm:.*$).*/i;

export const METADATA_DEVICE_TYPE_KEY = "device:type";

export function getMetadataFieldAlias(fieldName: string): string {
    if (fieldName === METADATA_DEVICE_TYPE_KEY) {
        return "Device Type";
    }

    return fieldName;
}

/**
 * @default Unknown
 * @see getIconForMetadataDeviceType
 */
export enum MetadataDeviceType {
    // heavy industry
    Crane = "Crane",
    Electrical = "Electrical",

    // shipping
    Container = "Container",
    Package = "Package",

    // vehicles
    Pickup = "Pickup",
    Tractor = "Tractor",
    Trailer = "Trailer",
    Truck = "Truck",

    Rocket = "Rocket",
    Ship = "Ship",

    PressureWasher = "Pressure Washer",
    StorageTank = "Storage Tank",

    Other = "Other",

    Satellite = "satellite",
    Modem = "modem",
    BroadcastTower = "broadcastTower",
}

export const MetadataDeviceTypes: MetadataDeviceType[] = [
    // heavy industry
    MetadataDeviceType.Crane,
    MetadataDeviceType.Electrical,

    // shipping
    MetadataDeviceType.Container,
    MetadataDeviceType.Package,

    // vehicles
    MetadataDeviceType.Pickup,
    MetadataDeviceType.Tractor,
    MetadataDeviceType.Trailer,
    MetadataDeviceType.Truck,

    MetadataDeviceType.Rocket,
    MetadataDeviceType.Ship,

    MetadataDeviceType.PressureWasher,
    MetadataDeviceType.StorageTank,

    MetadataDeviceType.Other,
];

export function stringAsMetadataDeviceType(
    str: string | undefined,
    defaultValue: MetadataDeviceType = MetadataDeviceType.Other
): MetadataDeviceType {
    const match = MetadataDeviceTypes.find((name) => name === str);

    if (match) {
        return match;
    }

    return defaultValue;
}

export function metadataObjectToArray(metadata: IDeviceMetadata): { key: string; value: string }[] {
    return Object.keys(metadata).map((key) => ({ key: key, value: metadata[key] }));
}

/**
 * Adds default metadata values (like device:type)
 * @param metadata
 * @returns
 */
export function metadataWithDefaults(device: IDevice): IDeviceMetadata {
    if (device.deviceType === DeviceType.FIELD) {
        return {
            [METADATA_DEVICE_TYPE_KEY]: MetadataDeviceType.Other,
            ...device.metadata,
        };
    }

    return device.metadata;
}

/**
 * converts a metadata object from a form array into a key/value object
 * @param metadata
 * @returns
 */
function metadataArrayToObject(metadata: IDeviceFormValuesMetadataMutation[]): IDeviceMetadata {
    if (metadata === undefined) {
        return {};
    }

    return metadata.reduce((previousValue, currentValue) => {
        return {
            ...previousValue,
            [currentValue.key]: currentValue.value,
        };
    }, {});
}

/**
 * results in an object that passes null for deleted keys, strings for added/edited keys
 * @param originalMetadata the original metadata object (whats on the server at the time of page load)
 * @param newMetadata an array of modified metadata values (what the user modified)
 * @example { "deleted-item": null, "added-item": "added", "edited-item": "edited" }
 */
export function metadataMutation(
    originalMetadata: IDeviceMetadata,
    newMetadata: IDeviceFormValuesMetadata[]
): IDeviceMetadataMutation {
    const metadataAllNullKeys: { [key: string]: null } = {};
    Object.keys(originalMetadata).forEach((key) => {
        metadataAllNullKeys[key] = null;
    });

    return {
        ...metadataAllNullKeys,
        ...metadataArrayToObject(newMetadata),
    };
}
