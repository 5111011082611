import { common } from "./common";
import variables from "./variables";

const editor = {
    ...common,
    root: common.col,
    controls: {
        composes: "$flexWithSpacing",
        flexGrow: 1,
        alignContent: "flex-start",
        marginTop: variables.gridSize,
    },
    partWidth: {
        flexBasis: "300px",
    },
    fullWidth: {
        flexBasis: "100%",
    },
    footer: {
        composes: "$flexWithSpacing",
        alignItems: "center",
        justifyContent: "flex-end",
        flexWrap: "nowrap",
        flexGrow: 0,
        marginTop: variables.gridSize,
    },
    successMessage: {
        color: variables.intentSuccess,
        fontWeight: "bold",
    },
} as const;

export default editor;
