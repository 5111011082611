import { Classes, H2 } from "@blueprintjs/core";
import React from "react";
import FlexCard from "./components/FlexCard";

export const PageSkeleton: React.FunctionComponent = () => (
    <FlexCard grow useNewStyle>
        <H2>
            <span className={Classes.SKELETON}>Loading</span>
        </H2>
    </FlexCard>
);
