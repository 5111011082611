import { Button, Checkbox, CheckboxProps, HTMLTable, NonIdealState } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import classNames from "classnames";
import { NumberDisplay } from "components/NumberDisplay";
import { sort } from "fast-sort";
import { ImageSize } from "images/types";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
    FaChevronLeft,
    FaChevronRight,
    FaFilter,
    FaSort,
    FaSortDown,
    FaSortUp,
} from "react-icons/fa";
import { createUseStyles } from "react-jss";
import scrollIntoView from "scroll-into-view-if-needed";
import variables from "styles/variables";

export interface Column<T> {
    id: string;
    label: React.ReactNode;
    format: (value: T, rowId: string) => NonNullable<React.ReactNode>;

    /** @default true */
    allowSort?: boolean;

    /** @default false */
    allowSelect?: boolean;

    filter?: {
        content: React.ReactElement;
        isActive: boolean;
        onClose?: (event: React.SyntheticEvent<HTMLElement>) => void;
    };

    compare?: (a: T, b: T) => number;

    /** custom header styles */
    style?: React.CSSProperties;

    /** custom cell styles */
    cellStyle?: React.CSSProperties;
}

export interface Filter<T> {
    (row: T): boolean;
}

export interface SortState {
    columnId: string;
    descending: boolean;
}

export interface DataTableProps<T> {
    columns: Column<T>[];
    data: T[];
    clientSideFilters?: Filter<T>[];
    getRowId: (row: T) => string;
    activeRowId?: string;
    defaultSort?: SortState;

    // todo replace all usages of "rowId" with "row". Once done delete rowId callback prop
    /**
     * callback whenever a row is clicked
     */
    onActiveRowChange?: (rowId: string, row: T) => void;

    // todo make renderEmptyState required
    /**
     * Empty State to render when no items are in the list
     * You should pass @see NonIdealState with action(s) to resolve the issue (ex: create new)
     * @default Tells the user to modify their filters or add a new item
     */
    renderEmptyState?: () => React.ReactNode;

    striped?: boolean;

    className?: string;

    stickyActions?: boolean;

    /**
     * Pass this callback if you'd like to enable selecting rows in a table.
     * Supports shift+click to select multiple items
     * @example
     * <DataTable
     *   // note: recommended you use a ref here to prevent rerenders
     *   onRowSelection={(selectedRows) => selectedItemsRef.current = selectedRows}
     * />
     */
    onRowSelection?: (selectedRows: T[]) => void;

    /**
     * Pass in custom props to the selection checkbox
     * Note: {row} will be undefined when rendering the header
     */
    getCheckboxProps?: (row: T | undefined) => CheckboxProps;

    paginationSize?: number;

    /**
     * Renderer for checked actions
     */
    checkedActionsRender?: (checkbox: React.ReactNode) => React.ReactNode;
}

const useStyles = createUseStyles({
    root: {
        width: "100%",
    },
    active: {
        "& > td": {
            backgroundColor: `${variables.intentPrimary} !important`,
            color: `${variables.white} !important`,
        },
    },
    sortIndicator: {
        marginLeft: "0.5em",
    },
    filterIndicator: {
        marginLeft: "0.5em",
        position: "relative",
    },
    filterActive: {
        minWidth: 10,
        minHeight: 10,
        position: "absolute",
        zIndex: 1,
        top: 0,
        right: 0,
    },
    stickyActions: {
        "& thead": {
            position: "sticky",
            top: 0,
            insetBlockStart: 0 /* "top" */,
            backgroundColor: variables.white,
            // Without this, some elements (like Tags) may be drawn on top
            zIndex: 1,
        },
        "& tfoot": {
            position: "sticky",
            bottom: 0,
            insetBlockEnd: 0 /* "bottom" */,
            // Without this, some elements (like Tags) may be drawn on top
            backgroundColor: variables.white,
            zIndex: 1,
        },
    },
    selectColumn: {
        "&&": {
            // increase specificity to overwrite blueprint
            paddingLeft: 21, // default padding (10) + button padding (10) + checkbox border (1)
        },
    },
});

interface IRowRenderProps<T> {
    rowId: string;
    active: boolean;
    columns: Column<T>[];
    showSelectColumn: boolean;
    handleRowSelection: (rowId: string, event: React.FormEvent<HTMLInputElement>) => void;
    handleCellClick: (rowId: string, row: T, col: Column<T>) => void;
    getCheckboxProps: (row: T | undefined) => CheckboxProps;
    checkedActionsRender?: (checkbox: React.ReactNode) => React.ReactNode;
    row: T;
}

function RowRender<T>({
    rowId,
    active,
    columns,
    showSelectColumn,
    handleRowSelection,
    checkedActionsRender = (checkbox: React.ReactNode) => checkbox,
    handleCellClick,
    getCheckboxProps,
    row,
}: IRowRenderProps<T>): React.ReactElement<IRowRenderProps<T>> {
    const classes = useStyles();
    return (
        <tr id={`row-${rowId}`} key={rowId} className={classNames({ [classes.active]: active })}>
            {showSelectColumn && (
                <td className={classes.selectColumn}>
                    {checkedActionsRender(
                        <Checkbox
                            onChange={(e) => handleRowSelection(rowId, e)}
                            {...getCheckboxProps(undefined)}
                        />
                    )}
                </td>
            )}
            {columns.map((column) => (
                // eslint-disable-next-line
                <td
                    key={column.id}
                    onClick={() => handleCellClick(rowId, row, column)}
                    style={column.cellStyle}
                >
                    {column.format(row, rowId)}
                </td>
            ))}
        </tr>
    );
}

const RowRenderMemo = React.memo(RowRender) as typeof RowRender;

const emptyArray = [];
const fnEmptyObject = () => ({});
const noOpt = () => {};

function DataTable<T>({
    columns,
    data,
    clientSideFilters = emptyArray,
    getRowId,
    defaultSort,
    activeRowId,
    onActiveRowChange,
    renderEmptyState = () => (
        <NonIdealState
            icon={<FaFilter size={ImageSize.MEDIUM} />}
            title="No items found"
            description="Modify your filters or add a new item"
        />
    ),
    striped = true,
    className,
    stickyActions = true,
    onRowSelection,
    getCheckboxProps = fnEmptyObject,
    paginationSize,
    checkedActionsRender = (checkbox: React.ReactNode) => checkbox,
}: DataTableProps<T>): React.ReactElement<DataTableProps<T>> {
    const classes = useStyles();
    const [sortState, setSortState] = useState<SortState | undefined>(defaultSort);
    const lastSelectedRowId = useRef<string | null>(null);
    const checkAll = useRef<HTMLInputElement>(null);
    const tableRef = useRef<HTMLTableElement | null>(null);

    // Filter and sort
    const tableRows: T[] = useMemo(() => {
        if (sortState == null && clientSideFilters.length === 0) {
            return data;
        }

        const filteredData = data.filter((row) => clientSideFilters.every((filter) => filter(row)));

        if (sortState == null) {
            return filteredData;
        }

        const sortCol = columns.find(({ id }) => id === sortState.columnId);

        if (sortCol == null) {
            // TODO: should we produce some sort of error?
            return filteredData;
        }

        // If no compare function is provided, use the default sort on the output of the format function
        // (which is what's visible in the table). Otherwise, pass the whole item to the compare
        // function.
        const accessor =
            sortCol.compare == null ? (sortCol.format as (item: T) => T) : (item: T) => item;

        return sort(filteredData).by({
            ...(sortState.descending ? { desc: accessor } : { asc: accessor }),
            comparer: sortCol.compare,
        });
    }, [columns, data, clientSideFilters, sortState]);

    const [offset, setOffset] = useState(0);
    const usePagination = paginationSize === undefined ? false : tableRows.length > paginationSize;
    const tableRowsCurrentPage =
        paginationSize === undefined ? tableRows : tableRows.slice(offset, offset + paginationSize);
    const hasNextPage = paginationSize !== undefined && offset + paginationSize < tableRows.length;
    const hasPrevPage = offset > 0;

    const handleNextPage = useCallback(() => {
        if (paginationSize !== undefined) {
            setOffset(offset + paginationSize);
        }
    }, [offset, paginationSize]);

    const handlePrevPage = useCallback(() => {
        if (paginationSize !== undefined) {
            setOffset(Math.max(offset - paginationSize, 0));
        }
    }, [offset, paginationSize]);

    // Scroll the active row into view when the table contents change
    useEffect(() => {
        if (activeRowId != null && tableRef.current) {
            const $rowElement = tableRef.current.querySelector(`#row-${activeRowId}`);
            if ($rowElement) {
                scrollIntoView($rowElement, {
                    scrollMode: "if-needed",
                    block: "nearest",
                });
            }
        }
    }, [activeRowId]);

    const isEmpty = tableRows.length === 0;
    const isInteractive = onActiveRowChange !== undefined && !isEmpty;

    const handleCellClick = useCallback(
        (rowId: string, row: T, col: Column<T>) => {
            const allowSelect = col.allowSelect ?? true;
            if (onActiveRowChange != null && allowSelect) {
                onActiveRowChange(rowId, row);
            }
        },
        [onActiveRowChange]
    );
    const updateCheckAll = useCallback(() => {
        if (checkAll.current) {
            const rowCount = data.length;
            const checkedItems = data.reduce((previous, current) => {
                const rowCheckbox = tableRef.current?.querySelector<HTMLInputElement>(
                    `#row-${getRowId(current)} input[type=checkbox]`
                );
                if (rowCheckbox?.checked) {
                    return previous + 1;
                }

                return previous;
            }, 0);

            checkAll.current.checked = rowCount === checkedItems && checkedItems > 0;
            checkAll.current.indeterminate = rowCount !== checkedItems && checkedItems > 0;
        }
    }, [data, getRowId]);

    const handleAllRowSelection = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const newValue = event.currentTarget.checked;

            // toggle items using refs - note: refs are used to avoid a rerender on large tables
            data.forEach((value) => {
                const rowCheckbox = tableRef.current?.querySelector<HTMLInputElement>(
                    `#row-${getRowId(value)} input[type=checkbox]`
                );
                if (rowCheckbox) {
                    rowCheckbox.checked = newValue;
                }
            });

            if (onRowSelection) {
                onRowSelection(newValue ? data : []);
            }
            updateCheckAll();
            lastSelectedRowId.current = null;
        },
        [data, getRowId, onRowSelection, updateCheckAll]
    );

    const handleRowSelection = useCallback(
        (rowId: string, event: React.FormEvent<HTMLInputElement>) => {
            const newValue = event.currentTarget.checked;

            // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
            const shiftKeyPressed = (event.nativeEvent as any).shiftKey;
            if (shiftKeyPressed && lastSelectedRowId.current !== null) {
                const currentDeviceIndex = tableRows.findIndex((item) => rowId === getRowId(item));
                const lasSelectedDeviceIndex = tableRows.findIndex(
                    (item) => lastSelectedRowId.current === getRowId(item)
                );

                const direction = currentDeviceIndex - lasSelectedDeviceIndex > 0 ? -1 : 1;

                // toggle selected state between the last selected item and the current selected item
                for (
                    let currentKey = currentDeviceIndex;
                    currentKey !== lasSelectedDeviceIndex + direction;
                    currentKey += direction
                ) {
                    const rowCheckbox = tableRef.current?.querySelector<HTMLInputElement>(
                        `#row-${getRowId(tableRows[currentKey])} input[type=checkbox]`
                    );

                    if (rowCheckbox) {
                        rowCheckbox.checked = newValue;
                    }
                }
            }

            const selectedRows = tableRows.filter((value) => {
                const rowCheckbox = tableRef.current?.querySelector<HTMLInputElement>(
                    `#row-${getRowId(value)} input[type=checkbox]`
                );

                return rowCheckbox?.checked;
            });

            if (onRowSelection) {
                onRowSelection(selectedRows);
            }
            updateCheckAll();
            lastSelectedRowId.current = rowId;
        },
        [getRowId, onRowSelection, tableRows, updateCheckAll]
    );

    const showSelectColumn = onRowSelection !== undefined;

    const columnsCount = columns.length + (showSelectColumn ? 1 : 0);

    return (
        <HTMLTable
            className={classNames(
                classes.root,
                {
                    [classes.stickyActions]: stickyActions,
                },
                className
            )}
            interactive={isInteractive}
            striped={striped && !isEmpty}
            elementRef={tableRef}
        >
            <thead>
                <tr>
                    {showSelectColumn && (
                        <th>
                            {checkedActionsRender(
                                <Checkbox
                                    onChange={handleAllRowSelection}
                                    inputRef={checkAll}
                                    {...getCheckboxProps(undefined)}
                                />
                            )}
                        </th>
                    )}
                    {columns.map(({ id, label, allowSort = true, style, filter }) => (
                        <th key={id} style={style}>
                            {label}
                            {allowSort && (
                                <Button
                                    className={classes.sortIndicator}
                                    onClick={() => {
                                        if (allowSort) {
                                            setSortState({
                                                columnId: id,
                                                descending:
                                                    sortState?.columnId === id &&
                                                    !sortState?.descending,
                                            });

                                            // go back to first page
                                            setOffset(0);
                                        }
                                    }}
                                    minimal
                                    small
                                    icon={
                                        sortState?.columnId === id ? (
                                            sortState.descending ? (
                                                <FaSortDown />
                                            ) : (
                                                <FaSortUp />
                                            )
                                        ) : (
                                            <FaSort />
                                        )
                                    }
                                    title={`Sort${
                                        sortState?.columnId === id && !sortState.descending
                                            ? " descending"
                                            : " ascending"
                                    }`}
                                />
                            )}
                            {filter && (
                                <Popover2
                                    interactionKind="click"
                                    placement="bottom"
                                    content={filter.content}
                                    onClose={filter.onClose}
                                >
                                    <Button
                                        className={classes.filterIndicator}
                                        minimal
                                        small
                                        intent={filter.isActive ? "primary" : "none"}
                                        icon={<FaFilter color={variables.gray4} />}
                                    />
                                </Popover2>
                            )}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {isEmpty && (
                    <tr>
                        <td colSpan={columnsCount}>{renderEmptyState()}</td>
                    </tr>
                )}
                {tableRowsCurrentPage.map((row) => (
                    <RowRenderMemo<T>
                        key={getRowId(row)}
                        rowId={getRowId(row)}
                        active={activeRowId === getRowId(row)}
                        columns={columns}
                        row={row}
                        showSelectColumn={showSelectColumn}
                        handleRowSelection={showSelectColumn ? handleRowSelection : noOpt}
                        handleCellClick={handleCellClick}
                        getCheckboxProps={getCheckboxProps}
                    />
                ))}
            </tbody>
            {usePagination && paginationSize !== undefined && (
                <tfoot>
                    <tr>
                        <td colSpan={columnsCount} style={{ textAlign: "right" }}>
                            <NumberDisplay amount={offset + 1} minDigits={0} />-
                            <NumberDisplay
                                amount={Math.min(offset + paginationSize, tableRows.length)}
                                minDigits={0}
                            />{" "}
                            of <NumberDisplay amount={tableRows.length} minDigits={0} /> Items{" "}
                            <Button
                                disabled={!hasPrevPage}
                                icon={<FaChevronLeft />}
                                title="Previous Page"
                                minimal
                                onClick={handlePrevPage}
                            />
                            <Button
                                disabled={!hasNextPage}
                                icon={<FaChevronRight />}
                                title="Next Page"
                                minimal
                                onClick={handleNextPage}
                            />
                        </td>
                    </tr>
                </tfoot>
            )}
        </HTMLTable>
    );
}

export default React.memo(DataTable) as typeof DataTable;
