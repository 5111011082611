/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Hive Service REST API
 * OpenAPI spec version: v1
 */
import { useQuery } from "react-query";
import type { UseQueryOptions, QueryFunction, UseQueryResult, QueryKey } from "react-query";
import type { UserContext, ApiError } from "../models";
import { apiHandler } from "../../../../resources/apiHandler";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
    config: any,
    args: infer P
) => any
    ? P
    : never;

/**
 * Get a the user context for the current user.
 * @summary GET user context
 */
export const getUserContext = (
    options?: SecondParameter<typeof apiHandler>,
    signal?: AbortSignal
) => {
    return apiHandler<UserContext>(
        { url: `/hive/api/v1/usercontext`, method: "get", signal },
        options
    );
};

export const getGetUserContextQueryKey = () => [`/hive/api/v1/usercontext`] as const;

export const getGetUserContextQueryOptions = <
    TData = Awaited<ReturnType<typeof getUserContext>>,
    TError = ApiError
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserContext>>, TError, TData>;
    request?: SecondParameter<typeof apiHandler>;
}): UseQueryOptions<Awaited<ReturnType<typeof getUserContext>>, TError, TData> & {
    queryKey: QueryKey;
} => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetUserContextQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserContext>>> = ({ signal }) =>
        getUserContext(requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetUserContextQueryResult = NonNullable<Awaited<ReturnType<typeof getUserContext>>>;
export type GetUserContextQueryError = ApiError;

/**
 * @summary GET user context
 */
export const useGetUserContext = <
    TData = Awaited<ReturnType<typeof getUserContext>>,
    TError = ApiError
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserContext>>, TError, TData>;
    request?: SecondParameter<typeof apiHandler>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getGetUserContextQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};
